<template>
  <div class="vadis-molecule-chat-action">
    <div class="vadis-molecule-avatar">
      <p>{{ member.initials }}</p>
    </div>
    <div class="content">
      <div class="name">
        <span>{{ member.fullName }}</span> ({{ ts[member.role.toLowerCase()] }})
        {{ ts.startedDueDiligenceOn({ q: dueDiligence.relationships.company.name }) }}
      </div>
      <div class="date">
        {{ event.createdAt | humanDateHour }}
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.StartEvent">
{
  "startedDueDiligenceOn": "started a Due Diligence on {q}",
  "analyst": "Analyst",
  "manager": "Manager"
}
</i18n>

<script>

export default {
  name: 'StartEvent',
  props: {
    dueDiligence: {
      type: Object,
      default: null,
      required: true,
    },
    member: {
      type: Object,
      default: null,
      required: true,
    },
    event: {
      type: Object,
      default: null,
      required: true,
    },
  },
};
</script>
