export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RiskRating",
        translations: 






















{
  "computed": "Computed",
  "revisedRisk": "Revised risk"
}

      }
    }