export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SubmitSurvey",
        translations: 






























{
  "finished": "Have you finished the survey?",
  "surveyCompleted": "Your completed survey is ready",
  "WouldYouSubmit": "Would you like to submit it?",
  "buttons": {
    "submit": "Submit definitely",
    "review": "Review from start"
  }
}

      }
    }