<template>
  <div class="portfolio-statistics">
    <SwitcherBar
      v-if="risks && statistic"
      :items="riskItems"
      :i18n="ts"
      :active-item="currentRisk"
      @click="switchRisk($event)"
    />
    <div v-if="statistic">
      <div
        v-if="isHomePage"
        class="vadis-organism-table-subheader-nav"
      >
        <i
          class="icon-Arrow-left"
          @click="nextTeam(index - 1)"
        />
        <h5>{{ currentTeam.name }}</h5>
        <i
          class="icon-Arrow-right"
          @click="nextTeam(index + 1)"
        />
      </div>
      <div
        v-if="statistic.attributes.numberOfCompanies !== 0"
        class="my-chart-view"
      >
        <PortfolioHealthPieChart
          :risks="statistic.attributes.drilldown"
          :team-name="currentTeam.name"
        />
        <div class="chart-info-portfolio">
          <div>
            <p class="normal">
              {{ ts.MonitoredCompanies({name: currentTeam.name}) }}
            </p>
            <p class="big">
              {{ statistic.attributes.numberOfCompanies }}
            </p>
          </div>
          <div>
            <p class="normal">
              {{ ts.GlobalRiskHealth }}
            </p>
            <p class="big">
              {{ statistic.attributes.riskHealth | percent }}
            </p>
          </div>
        </div>
        <PortfolioHealthBarChart :risks="statistic.attributes.drilldown" />
      </div>
      <div
        v-else
        class="portfolio-statistics-empty"
      >
        <h6>{{ ts.title }}</h6>
      </div>
    </div>
    <div
      v-else
      class="vadis-molecule-loader"
    >
      <Loader
        :global="false"
        :shown="true"
      />
    </div>
  </div>
</template>

<i18n namespace="views.PortfolioStatistics">
{
  "MonitoredCompanies": "Monitored companies ({name})",
  "GlobalRiskHealth": "Global risk health",
  "global": "Global risk",
  "geographical": "Geographical",
  "pep": "PEP",
  "adverse_media": "Adverse media",
  "financial": "Financial",
  "sanction": "Sanctions",
  "title": "This portfolio is empty"
}
</i18n>

<script>
import PortfolioHealthPieChart from '@/components/portfolio/PortfolioHealthPieChart';
import PortfolioHealthBarChart from '@/components/portfolio/PortfolioHealthBarChart';
import SwitcherBar from '@/components/SwitcherBar';
import Loader from '@/components/Loader';

export default {
  name: 'PortfolioStatistics',
  components: {
    PortfolioHealthPieChart,
    PortfolioHealthBarChart,
    SwitcherBar,
    Loader,
  },
  props: {
    isHomePage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      teams: null,
      currentRisk: 'global',
      currentTeam: null,
      statistic: null,
      index: 0,
      risks: null,
    };
  },
  computed: {
    riskItems() {
      const items = this.risks.attributes.children.map(r => {
        return {
          code: r.indicator,
        };
      });
      items.unshift({ code: this.risks.attributes.indicator });
      return items;
    },
  },
  mounted() {
    this.risks = this.$flairClient.PortfolioService.getRiskTreeCompany();
    this.fetchTeams()
      .then(() => {
        this.fetchStatistics(this.teams.items[this.index]);
      });
  },
  methods: {
    switchRisk(risk) {
      this.currentRisk = risk.code;
      this.fetchStatistics(this.teams.items[this.index], risk.code);
    },
    nextTeam(index) {
      this.index = index;
      if (this.index >= this.teams.items.length) {
        this.index = 0;
      }
      if (this.index < 0) {
        this.index = this.teams.items.length - 1;
      }
      this.fetchStatistics(this.teams.items[this.index], this.currentRisk);
    },
    fetchTeams() {
      return this.$flairClient.MyService.getMyTeams().promise
        .then(res => {
          this.teams = res;
        });
    },
    fetchStatistics(team, indicator = 'global') {
      this.currentTeam = team;
      return this.$flairClient.MyService.getTeamStatisticsByIndicator({ id: team.id, indicator }).promise
        .then(res => {
          this.statistic = res;
        });
    },
  },
};
</script>
