<template>
  <div
    v-click-outside="clickOutside"
    class="vadis-controller-menu"
  >
    <i
      class="icon-Contextuel"
      @click.stop="toggleMenu()"
    />
    <div>
      <ul v-if="menuOpen">
        <li
          v-for="(def, id) in items"
          :key="id"
          @click.stop="onClick(def, id)"
        >
          <a>{{ def.label }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ClickOutside } from '@/directives';

export default {
  name: 'ContextualMenu',
  directives: {
    ClickOutside,
  },
  props: {
    context: {
      type: Object,
      default: null,
      required: false,
    },
    items: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    menuOpen: false,
  }),
  methods: {
    clickOutside() {
      this.menuOpen = false;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    onClick(def) {
      this.menuOpen = false;
      if (def.onClick) {
        def.onClick(this.context);
      }
    },
  },
};
</script>
