export default function (Component) {
      Component.options._i18n = {
        namespace: "components.FinancialList",
        translations: 
























































{
  "latestAccount": "Latest account",
  "turnover": "Turnover",
  "ebitda": "EBITDA",
  "workingCapital": "Working capital",
  "shareholderCapital": "Shareholder capital",
  "shareholderFunds": "Shareholder funds",
  "consolidatedAccounts": "Consolidated accounts",
  "numberOfEmployees": "Number of employees",
  "yes": "Yes",
  "no": "No"
}

      }
    }