export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PortfolioList",
        translations: 































































































































{
  "th": {
    "name": "Name",
    "global-risk": "Global risk",
    "risk-evolution": "Risk evolution",
    "last-update": "Last update"
  },
  "stable": "stable"
}

      }
    }