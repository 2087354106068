export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SubsidiaryList",
        translations: 
























































































{
  "th": {
    "name": "Name",
    "city": "City",
    "country": "Country",
    "identifier": "ID",
    "group": "Group",
    "companyType": "Type",
    "companySize": "Size",
    "directParticipation": "Direct part. (%)",
    "totalParticipation": "Total part. (%)"
  }
}

      }
    }