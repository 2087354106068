export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SurveyExplanation",
        translations: 






















































{
  "surveyExplanation": {
    "surveyContains": "This survey contains",
    "questions": "questions",
    "completedQuestions": "You have completed {a} / {b} questions",
    "answersSaved": "Answers are auto-saved so you can return to the form at any time using the link you received by mail",
    "mandatoryAnswers": "All questions with an asterisk (*) next to them are mandatory and must be answered to be able to submit the survey",
    "submissionDetails": "Answers will not be submitted until you confirm the submission of the questionnaire on the last screen"
  },
  "buttons": {
    "start": "Start",
    "previewPDF": "Preview in PDF",
    "reviewAndContinue": "Review answers and continue",
    "continueAnswer": "Answer remaining questions"
  }
}

      }
    }