<template>
  <div class="search-view">
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb>
        <li v-if="!showTitle()">
          <a>{{ title() }}</a>
        </li>
      </Breadcrumb>
      <h1
        v-if="showTitle()"
        class="page"
      >
        {{ title() }}
      </h1>
      <div class="search">
        <SearchBar
          ref="searchBar"
          :search="search"
        />
      </div>
    </div>
    <div
      v-show="hasSearch()"
      class="vadis-organism-padded-area"
    >
      <OpenableCompanyList
        v-if="isCompanyMode"
        ref="searchCompanyList"
        :search="search"
        :companies="search.results"
      />
      <OpenablePersonList
        v-if="isPersonMode"
        ref="searchPersonList"
        :people="search.results"
      />
    </div>
    <div
      v-show="!hasSearch()"
      class="vadis-organism-padded-area"
    >
      <PortfolioStatistics
        v-if="$featureEnabled('teamPortfolio') && $featureEnabled('portfolio') && $featureEnabled('portfolioStatistics')"
      />
      <OpenableCompanyList
        v-if="isCompanyMode"
        ref="recentCompanyList"
        :title="showTitle() ? ts.recentCompanies : undefined"
        :companies="recentCompanies"
      />
      <OpenablePersonList
        v-if="isPersonMode"
        ref="recentPersonList"
        :title="showTitle() ? ts.recentPeople : undefined"
        :people="recentPeople"
      />
    </div>
  </div>
</template>

<i18n namespace="views.Search">
{
  "welcome": "Welcome {who}!",
  "searchResults": "Search results for {q}",
  "companies": "Companies",
  "recentCompanies": "Recent companies",
  "recentPeople": "Recent people"
}
</i18n>

<script>
import _ from 'lodash';
import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';
import SearchBar from '@/components/SearchBar';
import OpenableCompanyList from '@/components/openable/OpenableCompanyList';
import OpenablePersonList from '@/components/openable/OpenablePersonList';
import PortfolioStatistics from '@/components/portfolio/PortfolioStatistics';
import { SearchState, SearchService } from 'flair-client';

const cleanQ = (s) => {
  if (!s) {
    return null;
  }
  const clean = s.replace(/\s+/g, ' ');
  if (clean === '' || clean === ' ') {
    return null;
  }
  return clean;
};

const DEFAULT_MODE = SearchService.SEARCH_MODE_COMPANY;

export default {
  name: 'Search',
  components: {
    TopBar,
    Breadcrumb,
    SearchBar,
    OpenableCompanyList,
    OpenablePersonList,
    PortfolioStatistics,
  },
  data({ $flairClient }) {
    const mode = this.$route.query.mode || DEFAULT_MODE;
    const q = cleanQ(this.$route.query.search);
    return {
      search: new SearchState($flairClient, { q, mode }),
      recentCompanies: this.getRecentlyBrowsedCompanies(),
      recentPeople: this.$featureEnabled('searchPeople') ? $flairClient.MyService.getRecentlyBrowsedPeople({ 'page[size]': 15 }) : [],
      me: $flairClient.AuthenticationService.me,
    };
  },
  events: {
    'my:browseHistory:*': (vm) => {
      vm.recentCompanies = vm.getRecentlyBrowsedCompanies();
    },
  },
  computed: {
    mode() {
      return this.search.current.mode;
    },
    isCompanyMode() {
      return this.mode === SearchService.SEARCH_MODE_COMPANY;
    },
    isPersonMode() {
      return this.mode === SearchService.SEARCH_MODE_PERSON;
    },
  },
  watch: {
    '$route.params.search': {
      handler() {
        // typically when user clicks on navbar to clean the search
        const searchParams = this.$router.currentRoute.query;
        if (!searchParams.search && !searchParams.mode) {
          this.$refs.searchBar.clearSearch();
        }
      },
      deep: true,
    },
    'search.current': {
      handler(value) {
        this.ensureUrlParams();
        if (!value) {
          this.resetChildren();
        }
      },
    },
  },
  methods: {
    getRecentlyBrowsedCompanies() {
      return this.$flairClient.MyService.getRecentlyBrowsedCompanies({ 'page[size]': 15 });
    },
    showTitle() {
      return !this.hasSearch();
    },
    resetChildren() {
      Object.keys(this.$refs).forEach((chName) => {
        const child = this.$refs[chName];
        if (child && child.clearCurrent) {
          child.clearCurrent();
        }
      });
    },
    ensureUrlParams() {
      const query = {
        search: this.search.current.q,
        mode: this.search.current.mode,
      };
      const currentRoute = this.$router.currentRoute;
      if (currentRoute.name !== 'search-screen'
        || currentRoute.query.search !== query.search
        || currentRoute.query.mode !== query.mode) {
        this.$router.replace({
          name: 'search-screen',
          query,
        }).catch(err => {
        }); // TODO @llambeau investigate
      }
    },
    hasSearch() {
      return this.search.hasSearch();
    },
    title() {
      if (this.hasSearch()) {
        return this.ts.searchResults({ q: this.search.getQ() });
      } else {
        const who = _.get(this, 'me.firstName') || 'Stranger';
        return this.ts.welcome({ who: who });
      }
    },
  },
};
</script>
