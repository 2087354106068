export default function (Component) {
      Component.options._i18n = {
        namespace: "views.Help",
        translations: 



























{
  "title": "Help",
  "flairRiskIndicators": "Flair risk indicators",
  "global": "Global",
  "dueDiligenceRoles": "Due diligence roles"
}

      }
    }