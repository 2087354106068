export default function (Component) {
      Component.options._i18n = {
        namespace: "components.InviteDueDiligenceMemberModal",
        translations: 
























































































































{
  "inviteMember": "Invite a team member",
  "teamMember": "Who would you like to invite to join the team",
  "noTeamMembers": "There is currently nobody in your team, would you like to add a member?",
  "errorUserAlreadyExists": "A user with the same ID already exists with this role in the due diligence.",
  "buttons": {
    "inviteLink": "Invite team member",
    "cancel": "Cancel",
    "inviteMember": "Invite a member"
  },
  "radioButtons": {
      "Manager": "A manager",
      "Analyst": "An analyst",
      "ComplianceOfficer": "A Compliance Officer"
  },
  "explanations": {
    "Manager": {
       "will": "The manager will :",
       "actions": [
          "Monitor your diligence",
          "Give the final recommendation for companies whose risks have been identified as high."
       ]
    },
    "Analyst": {
        "will": "The analyst will :",
        "actions": [
          "Analyse your diligence",
          "Can revise risk"
        ]
    },
    "ComplianceOfficer": {
        "will": "The compliance officer will :",
        "actions": [
          "Help the analyst to analyse the due diligence",
          "Can make the decision for low risk due diligence"
        ]
    }
  }
}

      }
    }