<template>
  <form class="vadis-form-field-search-large-darkBg">
    <div>
      <input
        ref="searchInput"
        v-model="currentValue"
        type="text"
        :placeholder="ts.placeholders.searchInput[mode]"
        @input="bouncedSearch"
        @keydown.enter.prevent="bouncedSearch"
      >
      <div class="options">
        <div />
        <div>
          <template
            v-for="(item, i) in searchModes"
          >
            <a
              v-if="$featureEnabled(item.feature)"
              :key="i"
              :class="{'active': mode === item.name}"
              @click="setMode(item.name)"
            >
              {{ ts.search[i] }}
            </a>
          </template>
        </div>
        <a
          class="icon"
          @click="clearSearch"
        >
          <i :class="{'icon-Search': !hasSearch(), 'icon-Close': hasSearch()}" />
        </a>
      </div>
    </div>
    <div
      class="previous-results"
      :style="{ visibility: previousResultsVisibility() }"
    >
      <i class="icon-History" /><span>{{ ts.ui.recent }}</span>
      <ul>
        <li
          v-for="r in recent.items"
          :key="r.id"
        >
          <a
            v-if="r.mode === mode"
            @click="applyRecentSearch(r)"
          >{{ r.q }}</a>
        </li>
      </ul>
    </div>
  </form>
</template>

<i18n namespace="components.SearchBar">
{
  "placeholders": {
    "searchInput": {
      "Company": "Search a company by name, VAT, …",
      "Person": "Search a person by name, function, date of birth ..."
    }
  },
  "ui": {
    "recent": "Recently searched :"
  },
  "search": {
    "companies": "Companies",
    "people": "People"
  }
}
</i18n>

<script>
import { SearchService, SearchState } from 'flair-client';
import _ from 'lodash';

const searchModes = {
  companies: {
    name: SearchService.SEARCH_MODE_COMPANY,
    feature: 'searchCompanies',
  },
  people: {
    name: SearchService.SEARCH_MODE_PERSON,
    feature: 'searchPeople',
  },
};

export default {
  name: 'SearchBar',
  props: {
    search: {
      type: SearchState,
      required: true,
    },
  },
  data: () => ({
    currentValue: '',
    searchModes,
  }),
  computed: {
    mode() {
      return this.search.current.mode;
    },
    recent() {
      return this.search.recent;
    },
  },
  created() {
    this.bouncedSearch = _.debounce(($event) => this.search.setQ($event.target.value), 300);
    if (this.search.hasSearch()) {
      return this.currentValue = this.search.getQ();
    }
  },
  mounted() {
    this.$refs.searchInput.focus();
  },
  methods: {
    hasSearch() {
      return this.search.hasSearch();
    },
    clearSearch() {
      this.currentValue = null;
      this.search.clearSearch();
      this.$refs.searchInput.focus();
    },
    applyRecentSearch(search) {
      this.currentValue = search.attributes.q;
      this.search.setQ(search.attributes.q);
    },
    previousResultsVisibility() {
      if (this.recent && this.recent.items.length > 0) {
        return 'visible';
      } else {
        return 'hidden';
      }
    },
    setMode(mode) {
      this.search.setMode(mode);
    },
  },
};
</script>
