export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PortfolioDetailsList",
        translations: 



















































































































{
  "th": {
    "name": "Name"
  }
}

      }
    }