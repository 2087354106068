import AbstractGraph from './abstract.graph';
import Transformation from './transformation';

const DEFAULT_CONFIG = {
  centerNodeId: null,
};

export default class BaseGraph extends AbstractGraph {
  #config;

  constructor(config) {
    super();
    this.#config = Object.assign({}, DEFAULT_CONFIG, config);
    this.#config.centerNodeId = this.#config.centerNodeId
      || (this.#config.nodes[0] ? this.#config.nodes[0].id : undefined);
  }

  static transformation(transformer) {
    return new Transformation(transformer);
  }

  static buildFromJson(json, config) {
    const nodes = json.attributes.nodes.reduce((nodes, n) => {
      const nodeId = n.id.toString();
      nodes[nodeId] = {
        nodeKind: 'company',
        id: n.id.toString(),
        companyId: n.company.id,
        ...n.company.attributes,
      };
      return nodes;
    }, {});
    const edges = json.attributes.edges.map(e => {
      const sourceId = e.from.toString();
      const targetId = e.to.toString();
      return {
        source: nodes[sourceId],
        target: nodes[targetId],
        type: e.link.type,
        ...e.link.attributes,
      };
    });
    return new BaseGraph(Object.assign({}, config, {
      nodes: Object.values(nodes),
      edges,
    }));
  }

  // public

  get nodes() {
    return this.#config.nodes;
  }

  get edges() {
    return this.#config.edges;
  }

  get centerNodeId() {
    return this.#config.centerNodeId;
  }

  remove(transformed) {
    return this;
  }

}
