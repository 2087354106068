export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyNeighborhoodPane",
        translations: 








































































































































































































{
  "ui": {
    "name": "Graphical Explorer Tool",
    "filterCategories": {
      "clustering": "Clustering",
      "participation": "Participation",
      "riskIndicators": "Risk indicator"
    },
    "filters": {
      "showPercentage": "Show percentage",
      "strongLinksOnly": "Only 25% and more",
      "clusterize": "Aggregate similar companies"
    },
    "leftPanel": {
      "setAsCenterNodeBtn": "Set as company focus",
      "companyDetailsBtn": "Company details"
    },
    "legend": {
      "people": "People",
      "company": "Company"
    },
    "d3modes": {
      "label": "Layout",
      "cluster": "Vertical",
      "tree": "Circular"
    }
  },
  "modes": {
    "group": "Group",
    "subsidiaries": "Subsidiaries",
    "shareholders": "Shareholders"
  },
  "labels": {
    "type": "Type",
    "country": "Country",
    "activity": "Activity",
    "europeanVAT": "VAT"
  }
}

      }
    }