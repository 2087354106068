export default function (Component) {
      Component.options._i18n = {
        namespace: "views.CompanyPDFRiskAnalysisTable",
        translations: 


























{
  "year": "Year",
  "complianceEntry": "Entry n°",
  "subjectName": "Subject",
  "publishedAt": "Published at"
}

      }
    }