<template>
  <div
    v-if="address"
    class="vadis-molecule-detail-address"
  >
    <div> {{ address.street }} </div>
    <div> {{ address.zip }} {{ address.city }} </div>
    <CountryFlag :country="address.country" />
  </div>
</template>

<script>
import CountryFlag from '@/components/CountryFlag';

export default {
  name: 'CompanyAddress',
  components: {
    CountryFlag,
  },
  props: {
    address:{
      type: Object,
      default: null,
      required: true,
    },
  },
};
</script>
