<template>
  <div>
    <div
      v-for="(members, role) in membersByRole"
      :key="role"
      class="item"
    >
      <span class="label">{{ ts[role.toLowerCase()] }}</span>
      <div class="list">
        <span
          v-for="(member) in members"
          :key="member.id"
          class="value"
        >
          <div class="vadis-molecule-avatar-small">
            <p>{{ member.initials }}</p>
          </div>
          {{ member.fullName }}
        </span>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.MembersList">
{
  "analyst": "Analyst",
  "manager": "Manager",
  "complianceofficer": "Compliance Officer"
}
</i18n>

<script>
export default {
  name: 'MembersList',
  props: {
    membersByRole: {
      type: Object,
      default: null,
      required: true,
    },
  },
};
</script>
