export default function (Component) {
      Component.options._i18n = {
        namespace: "components.InviteMemberEvent",
        translations: 

















{
  "invitedAs": "invited {fullName} as {role}",
  "analyst": "Analyst",
  "manager": "Manager",
  "complianceofficer": "Compliance Officer"
}

      }
    }