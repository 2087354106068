export default function (Component) {
      Component.options._i18n = {
        namespace: "components.MatchingCompanyModal",
        translations: 












































































































































{
  "companies": "companies",
  "matchingDetected": "Matching companies detected",
  "providedBy": "Provided by",
  "associateData": "Associate your data",
  "placeholders": {
    "country": "Country..."
  },
  "labels": {
    "name": "Name",
    "country": "Country",
    "vat": "VAT/SIREN"
  },
  "buttons": {
    "associate": "Associate",
    "cancel": "Cancel"
  },
  "noMatch": "No match found",
  "notFound": "Sorry, we didn't found any related company in creditsafe search for those countries:",
  "refineSearch": "Can't find the corresponding company? Refine your search by country:",
  "OtherSearchCountry": "Do you want to do another search with a different country ?"
}

      }
    }