export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligenceListTabs",
        translations: 



















{
  "myAssignments": "My assignments"
}

      }
    }