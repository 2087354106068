<template>
  <div>
    <div class="header">
      <h5> {{ ts.title.dataConsumption }} </h5>
      <div class="vadis-controller-view-switcher-bar">
        <ul>
          <li
            v-for="(c, m) in YEAR"
            :key="m"
            :class="{ 'active-view': currentMode === m }"
          >
            <a @click="switchMode(m)">{{ ts.year[m] }}</a>
          </li>
        </ul>
      </div>
    </div>
    <DataConsumptionGraph
      v-if="consumption"
      :consumption="consumption"
    />
    <div
      v-if="consumption"
      class="result"
    >
      <div>
        <div class="flex">
          <span class="ovale ovale-orange" /> <p>{{ ts.screeningSpent }}</p>
        </div>
        <h3>
          {{ consumption.consumption[consumption.consumption.length -1].cumulativeScreening }}
          <span v-if="$featureEnabled('subscription')"> (+950)</span>
        </h3>
        <p v-if="$featureEnabled('subscription')">
          {{ ts.remaining }} : 0
        </p>
      </div>
      <div>
        <div class="flex">
          <span class="ovale ovale-blue" /> <p> {{ ts.monitoringSpent }}</p>
        </div>
        <h3>{{ consumption.consumption[consumption.consumption.length -1].cumulativeMonitoring }}</h3>
        <p v-if="$featureEnabled('subscription')">
          {{ ts.remaining }} : 1 770
        </p>
      </div>
    </div>
  </div>
</template>

<i18n namespace="views.DataconsumptionWidget">
{
  "title": {
    "dataConsumption": "Data consumption"
  },
  "year": {
    "currentYear": "Current year",
    "previousYear": "Y - 1"
  },
  "screeningSpent": "Screening spent this year",
  "monitoringSpent": "Monitoring spent this year",
  "remaining": "Remaining"
}
</i18n>

<script>
import DataConsumptionGraph from '@/components/data-consumption/DataConsumptionGraph';

const YEAR = {
  currentYear: 'currentYear',
  previousYear: 'previousYear',
};

export default {
  name: 'DataconsumptionWidget',
  components: {
    DataConsumptionGraph,
  },
  data: () => ({
    info: null,
    YEAR,
    currentMode: 'currentYear',
    consumption: null,
  }),
  mounted() {
    this.getDataConsumption(new Date().getFullYear());
  },
  methods: {
    getDataConsumption(year) {
      alert();
      this.$flairClient.AdminService.getDataConsumption({ year }).promise.then((res) => {
        this.consumption = res;
      });
    },
    switchMode(mode) {
      let year = new Date().getFullYear();
      if (mode === 'previousYear') {
        year = year - 1;
      }
      this.currentMode = mode;
      this.getDataConsumption(year);
    },
  },
};
</script>
