<template>
  <div class="vadis-organism-tab-empty">
    <img
      class=""
      src="../../assets/tab_empty.svg"
    >
    <h6>{{ ts.noInformation({ dataProvider: getCurrentProvider }) }}</h6>
  </div>
</template>

<i18n namespace="components.CompanyEmptyPane">
{
  "noInformation": "There is no information available for this section in the database of {dataProvider}."
}
</i18n>

<script>
import _ from 'lodash';

export default {
  name: 'CompanyEmptyPane',
  data: () => ({
    instance: {},
  }),
  events: {
    'app:instance:updated': (vm) => {
      vm.refreshVersions();
    },
  },
  computed: {
    getCurrentProvider: function() {
      return localStorage.getItem('provider') || this.instance.currentProvider;
    },
  },
  created() {
    this.refreshVersions();
  },
  methods: {
    refreshVersions() {
      this.instance = this.$flairClient.AppService.instance;
    },
  },

};
</script>
