export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CRIComposition",
        translations: 



























































{
  "switcher": {
    "weight": "Weight",
    "score": "Score"
  },
  "explanation": {
    "giveWeight": "Give weight to your indicators to form a total of 100%.",
    "finalScore": "The final score of your custom indicator will be based on this."
  },
  "contact": "Please contact Vadis for more information",
  "sendTo": "Send an email to",
  "completion": "Custom indicator completion"
}

      }
    }