<template>
  <article
    class="company-details-view"
  >
    <header>
      <h1>{{ company.name }}</h1>
      <p>{{ ts.printedOn }} {{ printingDate|humanDate }}</p>
      <button
        v-if="this.$featureEnabled('printpdf')"
        class="vadis-form-button-standard-icon"
        @click="printIt();"
      >
        <i class="icon-Import" />
        {{ ts.savePDF }}
      </button>
    </header>
    <section class="summary vadis-organism-padded-area">
      <h2> {{ ts.summary }} </h2>
      <CompanySummaryPane :company="company" />
    </section>
    <section class="financial vadis-organism-padded-area">
      <h2> {{ ts.financial }} </h2>
      <CompanyFinancialPane :company="company" />
    </section>
    <section class="vadis-organism-padded-area">
      <h2> {{ ts.management }} </h2>
      <CompanyManagementPane
        :company="company"
        :paging="false"
      />
    </section>
    <section class="vadis-organism-padded-area">
      <h2> {{ ts.shareholders }} </h2>
      <CompanyShareholdersPane
        :company="company"
        :paging="false"
      />
    </section>
    <section class="vadis-organism-padded-area">
      <h2> {{ ts.subsidiaries }} </h2>
      <CompanySubsidiariesPane
        :company="company"
        :paging="false"
      />
    </section>
    <section class="vadis-organism-padded-area">
      <h2> {{ ts.geographicalRisk({q: company.name}) }} </h2>
      <table class="vadis-organism-table group">
        <thead>
          <tr>
            <th>{{ ts.year }}</th>
            <th>{{ ts.score }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(c, i) in geographicalRisk.history"
            :key="i"
          >
            <td>{{ c.year }}</td>
            <td>{{ c.score }}</td>
          </tr>
        </tbody>
      </table>
    </section>
    <section
      v-if="group"
      class="vadis-organism-padded-area"
    >
      <h2> {{ ts.geographicalGroupRisk({q: company.primaryGroup.name}) }} </h2>
      <table class="vadis-organism-table group">
        <thead>
          <tr>
            <th>{{ ts.country }}</th>
            <th>{{ ts.presence }}</th>
            <th>{{ ts.score }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(c, i) in group.geographicalPresence"
            :key="i"
          >
            <td><CountryFlag :country="c.country" /></td>
            <td>{{ c.numberOfCompanies }}</td>
            <td>{{ countries[c.country] }}</td>
          </tr>
        </tbody>
      </table>
    </section>
    <section
      v-if="pepRisk.details"
      class="pep vadis-organism-padded-area"
    >
      <h2> {{ ts.pepRisk }} </h2>
      <CompanyPDFRiskAnalysisTable :analysis="pepRisk.details.analysis" />
      <MediaLinkList :media-links="pepRisk.relationships.mediaLinks.items" />
    </section>
    <section
      v-if="sanctionRisk.details"
      class="pep vadis-organism-padded-area"
    >
      <h2> {{ ts.sanctionRisk }} </h2>
      <CompanyPDFRiskAnalysisTable :analysis="sanctionRisk.details.analysis" />
      <MediaLinkList :media-links="sanctionRisk.relationships.mediaLinks.items" />
    </section>
    <section
      v-if="adverseMediaRisk.details"
      class="pep vadis-organism-padded-area"
    >
      <h2> {{ ts.adverseMediaRisk }} </h2>
      <CompanyPDFRiskAnalysisTable :analysis="adverseMediaRisk.details.analysis" />
      <MediaLinkList :media-links="adverseMediaRisk.relationships.mediaLinks.items" />
    </section>
  </article>
</template>

<i18n namespace="components.CompanyPDFReport">
{
  "summary": "Summary",
  "financial": "Financial",
  "management": "Management",
  "shareholders": "Shareholders",
  "subsidiaries": "Subsidiaries",
  "printedOn": "Printed on",
  "geographicalRisk": "Geographical risk for {q}",
  "geographicalGroupRisk": "Geographical risk for the group {q}",
  "pepRisk": "PEP risk",
  "sanctionRisk": "Sanctions risk",
  "adverseMediaRisk": "Adverse media risk",
  "country": "Country",
  "score": "Score",
  "presence": "Presence",
  "year": "Year",
  "savePDF": "Save PDF"
}
</i18n>

<script>
import CompanySummaryPane from '@/components/company/CompanySummaryPane';
import CompanyFinancialPane from '@/components/company/CompanyFinancialPane';
import CompanyManagementPane from '@/components/company/CompanyManagementPane';
import CompanyShareholdersPane from '@/components/company/CompanyShareholdersPane';
import CompanySubsidiariesPane from '@/components/company/CompanySubsidiariesPane';
import CompanyPDFRiskAnalysisTable from '@/components/company/CompanyPDFRiskAnalysisTable';
import MediaLinkList from '@/components/risk/MediaLinkList';
import CountryFlag from '@/components/CountryFlag';
import { CompanyPDFReport } from 'flair-client/src/services/pdf.report.service';
import moment from 'moment';

export default {
  name: 'CompanyPDF',
  components: {
    CompanySummaryPane,
    CompanyFinancialPane,
    CompanyShareholdersPane,
    CompanySubsidiariesPane,
    CompanyManagementPane,
    CompanyPDFRiskAnalysisTable,
    MediaLinkList,
    CountryFlag,
  },
  props: {
    report: {
      type: CompanyPDFReport,
      default: null,
      required: true,
    },
  },
  computed: {
    company() {
      return this.report.company;
    },
    geographicalRisk() {
      return this.report.geographicalRisk;
    },
    pepRisk() {
      return this.report.pepRisk;
    },
    sanctionRisk() {
      return this.report.sanctionRisk;
    },
    adverseMediaRisk() {
      return this.report.adverseMediaRisk;
    },
    countries() {
      return this.report.countries;
    },
    presentCountries() {
      return this.report.presentCountries;
    },
    group() {
      return this.report.group;
    },
    printingDate() {
      return moment();
    },
  },
  methods: {
    printIt() {
      this.loading = true;
      const attachment = `${this.company.name}.pdf`;
      this.$flairClient.PrintService.print(attachment).then((pdf) => {
        this.loading = false;
        this.pdf = pdf.download();
      });
    },
  },
};

</script>
