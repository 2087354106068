export default function (Component) {
      Component.options._i18n = {
        namespace: "views.DueDiligenceDetails",
        translations: 




































































{
  "title": "Due Diligence",
  "printPDF": "Print PDF"
}

      }
    }