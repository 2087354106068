<template>
  <div
    class="company-details-view"
  >
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb>
        <li>{{ ts.title }}</li>
      </Breadcrumb>
      <div class="header">
        <h1>{{ ts.title }}</h1>
      </div>
      <TabsComponent
        ref="tabs"
        v-model="currentTab"
        :default-active="defaultActive"
        :tabs="tabs"
        :i18n="ts"
        @input="setTab"
      />
    </div>
    <div class="vadis-organism-padded-area">
      <component
        :is="tabComponent"
      />
    </div>
  </div>
</template>

<i18n namespace="views.MySettings">
{
  "title": "Settings",
  "departments": "Departments",
  "teams": "Teams",
  "users": "Users",
  "surveys": "Surveys"
}
</i18n>

<script>
import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';
import TabsComponent from '@/components/TabsComponent';
import SettingsTeamPane from '@/components/team/SettingsTeamPane';
import DepartmentsPane from '@/components/department/DepartmentsPane';
import UsersPane from '@/components/user/UsersPane';
import SettingsSurveyPane from '@/components/surveys/SettingsSurveyPane';

export default {
  name: 'MySettings',
  components: {
    TopBar,
    Breadcrumb,
    TabsComponent,
    SettingsTeamPane,
    SettingsSurveyPane,
  },
  data: (vm) => {
    return {
      currentTab: null,
      defaultActive: null,
      tabs: [
        {
          id: 'departments',
          component: DepartmentsPane,
          validator: () => vm.$featureEnabled('userManagement'),
        }, {
          id: 'teams',
          component: SettingsTeamPane,
          validator: () => vm.$featureEnabled('teamManagement'),
        }, {
          id: 'users',
          component: UsersPane,
          validator: () => vm.$featureEnabled('userManagement'),
        },
        {
          id: 'surveys',
          component: SettingsSurveyPane,
          validator: () => vm.$featureEnabled('teamManagement') || vm.$featureEnabled('userManagement'),
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!to.params.tab) {
        return;
      }
      const tab = vm.tabs.find(tab => tab.id === to.params.tab);
      if (tab && vm.$refs.tabs) {
        vm.$refs.tabs.setActive(tab);
      }
    });
  },
  computed: {
    tabComponent() {
      return this.currentTab ? this.currentTab.component : null;
    },
  },
  methods: {
    setTab(tab) {
      this.$router.push({
        name: 'my-settings-screen',
        params: { tab: tab.id },
      });
    },
  },
};
</script>
