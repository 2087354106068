<template>
  <div>
    <UserEditModal
      v-if="createUser"
      :team="currentTeam"
      :role="'TeamManager'"
      :user="currentTeam.teamManager"
      @close="createUser = false;"
    />
    <Accordion
      :open="accordionOpen"
      :disabled="!teamsPresent"
    >
      <template v-slot:title>
        <div
          v-if="!renamingDepartment"
          class="title"
        >
          <div class="left">
            <h5>{{ department.name }}</h5>
            <p> {{ department.teams.length }} {{ ts.teams }}</p>
          </div>
          <div class="right">
            <div v-if="department.departmentLeader">
              <p> {{ department.departmentLeader.firstName }} {{ department.departmentLeader.lastName }} </p>
            </div>
            <!-- <div v-else>
            <a>{{ ts.link.addLeader }}</a>
          </div> -->
            <ContextualMenu
              :items="contextualMenuItems"
            />
          </div>
        </div>
        <DepartmentEditForm
          v-else
          :rename="true"
          :department="department"
          @close="renamingDepartment = false;"
        />
      </template>

      <div
        v-for="(team) in department.teams"
        :key="team.id"
        class="team-section table-border"
      >
        <TeamAccordionItem
          :team="team"
        />
      </div>
    </Accordion>
    <div
      v-if="department.teams.length"
      class="team-section table-border"
    >
      <button
        v-if="!creatingTeam"
        class="vadis-form-button-main"
        @click="creatingTeam = true;"
      >
        {{ ts.button.createTeam }}
      </button>
      <TeamEditForm
        v-else
        :department-id="department.id"
        @close="creatingTeam = false;"
      />
    </div>
    <template v-if="!department.teams.length">
      <div class="team-section-empty">
        <div class="text-info">
          <p>
            <i class="icon-Submitted" />
            {{ ts.chooseManager }}
          </p>
          <p>
            <i class="icon-Submitted" />
            {{ ts.theManagerWill }}
          </p>
        </div>
        <button
          v-if="!creatingTeam"
          class="vadis-form-button-main"
          @click="creatingTeam = true;"
        >
          {{ ts.button.createTeam }}
        </button>
        <TeamEditForm
          v-else
          :department-id="department.id"
          @close="creatingTeam = false;"
        />
      </div>
    </template>
  </div>
</template>

<i18n namespace="components.DepartmentAccordion">
{
  "button": {
    "createTeam": "Create team"
  },
  "link": {
    "send": "Send invitation",
    "resend": "Resend invitation",
    "addLeader": "Add department leader"
  },
  "members": "members",
  "teams": "teams",
  "renameDepartment":"Rename department",
  "removeDepartment": "Remove department...",
  "renameTeam": "Rename team",
  "editManager": "Edit manager",
  "removeTeam": "Remove team...",
  "remove": "Remove '{q}'?",
  "removeTeamSubtitle": "All accounts of people within team will be suspended.",
  "removeDepartmentSubtitle": "All accounts of people within department will be suspended.",
  "chooseManager": "You must choose a manager for each team",
  "theManagerWill": "The manager will manage their own team of analysts"
}
</i18n>

<script>
import UserEditModal from '@/components/UserEditModal';
import TeamAccordionItem from '@/components/team/TeamAccordionItem';
import ContextualMenu from '@/components/ContextualMenu';
import DepartmentEditForm from '@/components/department/DepartmentEditForm';
import TeamEditForm from '@/components/team/TeamEditForm';
import Accordion from '@/components/Accordion';

export default {
  name: 'DepartmentAccordion',
  components: {
    Accordion,
    UserEditModal,
    TeamAccordionItem,
    ContextualMenu,
    DepartmentEditForm,
    TeamEditForm,
  },
  props: {
    department: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    creatingTeam: false,
    createUser: false,
    currentTeam: null,
    currentItem: null,
    accordionOpen: true,
    renamingDepartment: false,
    teamsPresent: true,
  }),
  computed: {
    contextualMenuItems() {
      return {
        renameDepartment: {
          label: this.ts.renameDepartment,
          onClick: this.clickRenameDepartment,
        },
        removeDepartment: {
          label: this.ts.removeDepartment,
          onClick: this.clickRemoveDepartment,
        },
      };
    },
  },
  mounted() {
    this.hasTeams();
  },
  methods: {
    hasTeams() {
      if (!this.department.teams.length) {
        this.teamsPresent = false;
      }
    },
    sendEmail(team) {
      this.$flairClient.AdminService.sendUserInvite({ id: team.teamManager.id });
    },
    clickRemoveDepartment() {
      this.openConfirmationModal({
        title: this.ts.remove({ q: this.department.name }),
        subtitle: this.ts.removeDepartmentSubtitle,
        onConfirm: (modal) => {
          this.$flairClient.AdminService.removeDepartment({ id: this.department.id })
            .promise.then(() => modal.close());
        },
      });
    },
    clickRenameDepartment() {
      this.renamingDepartment = true;
      this.newDepartmentName = this.department.name;
    },
    showItemsMenu(team) {
      if (!team.teamManager) {
        return ['removeTeam'];
      } else {
        return ['editManager', 'removeTeam'];
      }
    },
  },
};
</script>
