import axios from 'axios';

const fileExists = (url) => {
  return axios.head(url)
    .then(() => {
      return url;
    })
    .catch(err => {
      if (err.response.status !== 404) {
        return err;
      }
    });
};

export default fileExists;
