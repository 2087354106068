export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PasswordInvalidToken",
        translations: 

















{
  "title": "Invalid token",
  "error": "The link has expired",
  "tryAgain": "Try again"
}

      }
    }