<template>
  <div class="survey-submitted">
    <i class="icon-Check" />
    <h2>{{ ts.thankyou }}</h2>
    <h3>
      {{ ts.submit }}
    </h3>
  </div>
</template>

<i18n namespace="components.SurveySubmitted">
{
  "thankyou": "Thank you!",
  "submit": "Your answers have been submitted"
}
</i18n>

<script>

export default {
  name: 'SurveySubmitted',
};
</script>
