<template>
  <div v-if="isOpen">
    <div class="vadis-style-global-DialogBg" />
    <div class="vadis-modal-matching-person-company">
      <div class="title">
        <h5 v-if="results.items.length">
          {{ results.meta.totalItems }} {{ ts.matchingDetected }}
        </h5>
        <h5 v-else>
          {{ ts.noMatch }}
        </h5>
        <p>
          {{ ts.providedBy }}
          <img
            src="/img/creditsafe.png"
            alt="creditsafe"
          >
        </p>
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>
      <div
        class="content"
      >
        <h6 v-if="results.items.length">
          {{ ts.associateData }}
        </h6>
        <h6 v-else>
          {{ ts.notFound }}
          <span>{{ translatedCountries }}</span>
        </h6>
        <div
          v-if="results.loading || (results.items && results.items.length)"
          class="matching-list table-border"
        >
          <table class="table-hover">
            <thead>
              <tr>
                <th />
                <th> {{ ts.labels.name }} </th>
                <th> {{ ts.labels.country }} </th>
                <th> {{ ts.labels.vat }} </th>
              </tr>
            </thead>
            <tbody
              v-if="results.loading"
              class="vadis-molecule-loader"
            >
              <tr>
                <td colspan="5">
                  <Loader
                    :global="false"
                    :shown="true"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-if="results.loaded">
              <tr
                v-for="(r, i) in results.items"
                :key="i"
                :class="{'selected': matching == r}"
                @click="matching = r"
              >
                <td>
                  <input
                    :id="r"
                    v-model="matching"
                    :value="r"
                    type="radio"
                  >
                </td>
                <td> {{ r.name }} </td>
                <td>
                  <CountryFlag
                    :country="r.address.country"
                    :flag-only="false"
                  />
                </td>
                <td> {{ r.europeanVAT }} </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            v-if="results.loaded && results.hasPagination()"
            :current-page="results.meta.pageNumber"
            :total-items="results.meta.totalItems"
            :number-of-lines="results.meta.pageSize"
            :links="results.links"
            @click="onClickPagination($event)"
          />
        </div>
        <div class="search">
          <p>
            {{ results.items.length ? ts.refineSearch : ts.OtherSearchCountry }}
          </p>
          <form class="vadis-form-field-select">
            <div class="field">
              <FilterDropdown
                v-model="country"
                :values="tsCountries"
                :placeholder="ts.placeholders.country"
              >
                <template v-slot="slotProps">
                  <CountryFlag
                    :country="slotProps.value.k"
                    :flag-only="true"
                  />
                  <a>{{ slotProps.value.v }}</a>
                </template>
              </FilterDropdown>
            </div>
          </form>
        </div>
      </div>
      <div
        v-if="results.items.length"
        class="form-row buttons"
      >
        <button
          class="vadis-form-button-main"
          :disabled="!matching"
          type="submit"
          @click="associate()"
        >
          {{ ts.buttons.associate }}
        </button>
        <button
          class="vadis-form-button-standard"
          @click="close()"
        >
          {{ ts.buttons.cancel }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.MatchingCompanyModal">
{
  "companies": "companies",
  "matchingDetected": "Matching companies detected",
  "providedBy": "Provided by",
  "associateData": "Associate your data",
  "placeholders": {
    "country": "Country..."
  },
  "labels": {
    "name": "Name",
    "country": "Country",
    "vat": "VAT/SIREN"
  },
  "buttons": {
    "associate": "Associate",
    "cancel": "Cancel"
  },
  "noMatch": "No match found",
  "notFound": "Sorry, we didn't found any related company in creditsafe search for those countries:",
  "refineSearch": "Can't find the corresponding company? Refine your search by country:",
  "OtherSearchCountry": "Do you want to do another search with a different country ?"
}
</i18n>

<script>
import _ from 'lodash';
import Loader from '@/components/Loader';
import CountryFlag from '@/components/CountryFlag';
import Pagination from '@/components/Pagination';
import FilterDropdown from '@/components/FilterDropdown';

export default {
  name: 'MatchingCompanyModal',
  components: {
    CountryFlag,
    Loader,
    Pagination,
    FilterDropdown,
  },
  data: () => ({
    isOpen: false,
    currentCompany: null,
    country: '',
    matching: null,
    results: null,
  }),
  computed: {
    tsCountries() {
      return this.$flairClient.ts('countries');
    },
    translatedCountries() {
      if (this.results.meta && this.results.meta.countries) {
        const countries = this.results.meta.countries.split(',');
        return _.map(countries, (c) => this.tsCountries[c]);
      }
      return '';
    },
  },
  watch: {
    country(newV, oldV) {
      if (oldV !== newV) {
        this.findMatch(newV);
      }
    },
  },
  methods: {
    open(company) {
      this.isOpen = true;
      this.currentCompany = company;
      this.findMatch('');
    },
    close() {
      this.isOpen = false;
    },
    onClickPagination(event) {
      this.results[event]();
    },
    findMatch(country) {
      const payload = {
        attributes: {
          q: this.currentCompany.name,
          'page[size]': 5,
        },
      };
      if (country) {
        payload.attributes['country'] = country;
      }
      this.results = this.$flairClient.SearchService.searchCompanies(payload);
    },
    goToCompany(company) {
      this.$router.push({ name: 'company-details-screen', params: { id: company.id, tab: 'summary' } });
    },
    associate() {
      this.goToCompany(this.matching);
      this.close();
    },
  },
};
</script>
