export default function (Component) {
      Component.options._i18n = {
        namespace: "components.ChangeProviderModal",
        translations: 

















































{
  "changeProvider": "Change provider",
  "buttons": {
    "change": "Change provider",
    "cancel": "Cancel"
  }
}

      }
    }