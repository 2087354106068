<template>
  <div
    v-if="hasNeighborhood"
    class="vadis-organism-get-view"
    :class="{'fullscreen': fullscreen}"
  >
    <div class="vadis-organism-table-header">
      <h5>{{ ts.ui.name }}</h5>
      <div class="vadis-controller-view-switcher">
        <ul class="demo-ul">
          <li
            v-for="(c, m) in MODES"
            :key="m"
            :class="{ 'active-view': currentMode === m }"
          >
            <a @click="switchMode(m)">{{ ts.modes[m] }}</a>
          </li>
        </ul>
      </div>
      <a
        class="fullscreen"
        @click="toggleFullcreen()"
      >
        <i
          :class="{'icon-Fulllscreen-on': !fullscreen, 'icon-Fullscreen-off': fullscreen}"
        />
      </a>
    </div>
    <div class="vadis-organism-table-subheader-filter">
      <div class="left">
        <div class="category">
          <p>{{ ts.ui.filterCategories.participation }}</p>
        </div>
        <div
          class="filter"
          @click="toggleFilter('showPercentage', true)"
        >
          <i
            :class="{ 'icon-Switch-on': showPercentage, 'icon-Switch-off': !showPercentage }"
          />
          <p>{{ ts.ui.filters.showPercentage }}</p>
        </div>
        <div
          class="filter"
          @click="toggleFilter('dropEdgesLt', 25)"
        >
          <i
            :class="{ 'icon-Switch-on': dropEdgesLt, 'icon-Switch-off': !dropEdgesLt }"
          />
          <p>{{ ts.ui.filters.strongLinksOnly }}</p>
        </div>
        <div class="category">
          <p>{{ ts.ui.filterCategories.clustering }}</p>
        </div>
        <div
          class="filter"
          @click="toggleFilter('cluster', true)"
        >
          <i
            :class="{ 'icon-Switch-on': cluster, 'icon-Switch-off': !cluster }"
          />
          <p>{{ ts.ui.filters.clusterize }}</p>
        </div>
      </div>
      <div class="right">
        <div class="category">
          <p>{{ ts.ui.filterCategories.riskIndicators }}</p>
        </div>
        <div class="vadis-form-field-select">
          <select v-model="shownRiskIndicator">
            <option
              v-for="i in riskIndicators"
              :key="i.id"
              :value="i.id"
            >
              {{ i.label }}
            </option>
          </select>
          <i class="icon-Dropdown" />
        </div>
        <div
          v-if="layoutCustomisable"
          class="category"
        >
          <p>{{ ts.ui.d3modes.label }}</p>
        </div>
        <div class="filter">
          <select
            v-if="layoutCustomisable"
            v-model="d3Layout"
          >
            <option value="tree">
              {{ ts.ui.d3modes.tree }}
            </option>
            <option value="cluster">
              {{ ts.ui.d3modes.cluster }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="content">
      <div
        v-if="highlightedCompany"
        class="left"
      >
        <div
          v-if="highlightedCompany.loading"
          class="vadis-molecule-loader"
        >
          <Loader
            :global="false"
            :shown="true"
          />
        </div>
        <div v-else>
          <div class="company-name">
            <div>
              <span>{{ highlightedCompany.name }}</span>
            </div>
            <span @click="removeHighlightedCompany()">
              <i class="icon-Close" />
            </span>
          </div>
          <div class="company-details">
            <ItemDetails
              class="vadis-organism-detail-group"
              :items="highlightedItems"
              :i18n="ts"
              :context="highlightedCompany"
              :item-class="'vadis-molecule-detail-item'"
              :label-class="'vadis-molecule-detail-label'"
              :value-class="'vadis-molecule-detail-value'"
            />
          </div>
          <div class="buttons">
            <router-link
              :to="{ name: 'company-details-screen', params: {id: highlightedCompany.id, tab: 'summary'} }"
              target="_blank"
              class="vadis-form-button vadis-form-button-main"
            >
              {{ ts.ui.leftPanel.companyDetailsBtn }}
            </router-link>
            <button
              class="vadis-form-button vadis-form-button-standard"
              @click="setAsCenterNode()"
            >
              {{ ts.ui.leftPanel.setAsCenterNodeBtn }}
            </button>
          </div>
        </div>
      </div>
      <div class="right">
        <div
          v-if="company.relationships.hierarchicalGraph.loading"
          class="vadis-molecule-loader neighborhood"
        >
          <Loader
            :global="false"
            :shown="true"
          />
        </div>
        <template v-else>
          <componentgraphComponent
            :is="graphComponent"
            v-if="graphComponent"
            ref="d3Graph"
            :graph="graph"
            :show-percentage="showPercentage"
            :shown-risk-indicator="shownRiskIndicator"
            @nodeClick="setCompanyHighlighted($event)"
          />
          <div class="vadis-controller-zoom">
            <i
              class="icon-More"
              @click="$refs.d3Graph.zoomIn()"
            />
            <i
              class="icon-Less"
              @click="$refs.d3Graph.zoomOut()"
            />
          </div>
          <div class="vadis-molecule-legend-hierarchy">
            <div class="part">
              <Legend />
            </div>
            <div class="part">
              <i class="icon-User" /><span>{{ ts.ui.legend.people }}</span>
            </div>
            <div class="part">
              <i class="icon-Building" /><span>{{ ts.ui.legend.company }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <CompanyEmptyPane v-else />
</template>

<i18n namespace="components.CompanyNeighborhoodPane">
{
  "ui": {
    "name": "Graphical Explorer Tool",
    "filterCategories": {
      "clustering": "Clustering",
      "participation": "Participation",
      "riskIndicators": "Risk indicator"
    },
    "filters": {
      "showPercentage": "Show percentage",
      "strongLinksOnly": "Only 25% and more",
      "clusterize": "Aggregate similar companies"
    },
    "leftPanel": {
      "setAsCenterNodeBtn": "Set as company focus",
      "companyDetailsBtn": "Company details"
    },
    "legend": {
      "people": "People",
      "company": "Company"
    },
    "d3modes": {
      "label": "Layout",
      "cluster": "Vertical",
      "tree": "Circular"
    }
  },
  "modes": {
    "group": "Group",
    "subsidiaries": "Subsidiaries",
    "shareholders": "Shareholders"
  },
  "labels": {
    "type": "Type",
    "country": "Country",
    "activity": "Activity",
    "europeanVAT": "VAT"
  }
}
</i18n>

<script>
import D3GroupView from '@/components/d3/D3GroupView';
import D3ClusterView from '@/components/d3/D3ClusterView';
import D3TreeView from '@/components/d3/D3TreeView';
import CompanyEmptyPane from '@/components/company/CompanyEmptyPane';
import ItemDetails from '@/components/ItemDetails';
import CountryFlag from '@/components/CountryFlag';
import Loader from '@/components/Loader';
import Legend from '@/components/Legend';
import { BaseGraph } from 'flair-client/src/get';
import { Company } from 'flair-client';
import _ from 'lodash';

const MODES = {
  group: 'D3GroupView',
  subsidiaries: 'D3ClusterView',
  shareholders: 'D3ClusterView',
};

const highlightedItems = [
  {
    label: 'labels.europeanVAT',
    path: 'europeanVAT',
    filter: 'unknown',
  }, {
    label: 'labels.type',
    path: 'companyType',
    filter: 'companyType',
  }, {
    label: 'labels.activity',
    path: 'activity.nace2Code',
    filter: 'nace2code',
  }, {
    label: 'labels.country',
    path: 'address.country',
    component: CountryFlag,
    componentProps: {
      country: (c) => c ? _.get(c, 'address.country') : null,
    },
  },
];

export default {
  name: 'CompanyNeighborhoodPane',
  components: {
    D3GroupView,
    D3ClusterView,
    D3TreeView,
    ItemDetails,
    Loader,
    Legend,
    CompanyEmptyPane,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
    mode: {
      type: String,
      default: 'group',
    },
  },
  data: () => ({
    MODES,
    highlightedItems,
    fullscreen: false,
    graph: BaseGraph.buildFromJson({ attributes: { nodes: [], edges: [] } }),
    dropEdgesLt: null,
    dropEdgeFilter: BaseGraph.transformation((g, partMin) => {
      return g.dropEdges(e => {
        return !e.participation.direct || e.participation.direct < partMin;
      }).then((g2) => g2.keepConnexComponent());
    }),
    showPercentage: false,
    shownRiskIndicator: 'financial',
    reverseEdges: false,
    reverseEdgesT: BaseGraph.transformation((g) => {
      return g.reverseEdges();
    }),
    cluster: false,
    clusterT: BaseGraph.transformation((g) => {
      return g.cluster();
    }),
    currentMode: null,
    highlightedCompany: null,
    d3Layout: 'cluster',
  }),
  computed: {
    companyRisks() {
      return this.$flairClient.ts('companyRisk');
    },
    graphComponent() {
      if (this.currentMode === 'group') {
        return 'D3GroupView';
      }
      return this.d3Layout === 'cluster' ? 'D3ClusterView' : 'D3TreeView';
    },
    layoutCustomisable() {
      return this.currentMode !== 'group';
    },
    riskIndicators() {
      return [
        'financial',
        'sector',
        'geographical',
        'pep',
        'adverse_media',
        'sanction',
      ].map(i => {
        return {
          id: i,
          label: this.companyRisks[i],
        };
      });
    },
    hasNeighborhood() {
      return this.company && !!this.company.primaryGroup;
    },

  },
  watch: {
    company() {
      if (this.hasNeighborhood) {
        this.loadBaseGraph();
      }
    },
    dropEdgesLt(val) {
      if (val) {
        this.graph.transform(this.dropEdgeFilter, this.dropEdgesLt).then(this.setGraph);
      } else {
        this.graph = this.graph.remove(this.dropEdgeFilter);
      }
    },
    reverseEdges(val) {
      if (val) {
        this.graph.transform(this.reverseEdgesT).then(this.setGraph);
      } else {
        this.graph = this.graph.remove(this.reverseEdgesT);
      }
    },
    cluster(val) {
      if (val) {
        this.graph.transform(this.clusterT).then(this.setGraph);
      } else {
        this.graph = this.graph.remove(this.clusterT);
      }
    },
  },
  created() {
    if (this.hasNeighborhood) {
      this.loadBaseGraph()
        .then(() => this.switchMode(this.mode));
    }
  },
  methods: {
    setGraph(graph) {
      this.graph = graph;
    },
    loadBaseGraph() {
      return this.company.relationships.hierarchicalGraph.load()
        .then((res) => {
          const node = _.find(res.document.data.attributes.nodes, (r) => r.company.id === this.company.id);
          this.graph = BaseGraph
            .buildFromJson(res.document.data, {
              centerNodeId: node.id,
            });
          this.switchMode(this.currentMode);
        });
    },
    toggleFullcreen() {
      this.fullscreen = !this.fullscreen;
    },
    toggleFilter(filter, params) {
      if (this[filter]) {
        this[filter] = null;
      } else {
        this[filter] = params;
      }
    },
    switchMode(mode) {
      this.reverseEdges = (mode === 'shareholders');
      this.currentMode = mode;
    },
    setAsCenterNode() {
      if (!this.highlightedCompany) {return;}
      this.graph.withCenterNodeId(this.highlightedCompany.NodeId).then(g => {
        this.graph = g;
      });
    },
    setCompanyHighlighted(node) {
      this.highlightedCompany = this.$flairClient.EntitiesService.getCompanyById({ id: node.companyId });
      this.highlightedCompany.promise.then(() => {

        this.highlightedCompany['companyId'] = node.companyId;
        this.highlightedCompany['NodeId'] = node.id;
      });
    },
    removeHighlightedCompany() {
      this.highlightedCompany = null;
    },
  },
};
</script>
