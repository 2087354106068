<template>
  <div class="vadis-organism-portfolio-empty table-border">
    <img src="../../assets/portfolio_empty.svg">
    <h6>{{ ts.title }}</h6>
    <p>{{ ts.text }}</p>
    <form class="vadis-form-field-search-small">
      <div class="inactive">
        <input
          ref="searchInput"
          v-model="value"
          type="text"
          :placeholder="ts.placeholdder"
          @keydown.enter.prevent="applySearch"
        >
        <div class="options">
          <a class="icon">
            <i
              class="icon-Search"
              @click="applySearch"
            />
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<i18n namespace="components.PortfolioEmpty">
{
  "title": "Your portfolio of companies is empty !",
  "text": "Start searching and then click on the bookmark icon.",
  "placeholder": "Search company..."
}
</i18n>

<script>

export default {
  name: 'PortfolioEmpty',
  data: () => ({
    value: '',
  }),
  methods: {
    applySearch() {
      this.$router.push({
        name: 'search-screen',
        query: { search: this.value, mode: 'Company' },
      });
    },
  },
};
</script>
