<template>
  <NavBar />
</template>

<script>
import NavBar from '@/components/NavBar';

export default {
  name: 'TopBar',
  components: {
    NavBar,
  },
};
</script>
