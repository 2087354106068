<template>
  <div
    class="company-details-view"
  >
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb>
        <li>{{ ts.title }}</li>
      </Breadcrumb>
      <div class="header">
        <h1>{{ ts.title }}</h1>
      </div>
      <TabsComponent
        ref="tabs"
        v-model="currentTab"
        :default-active="defaultActive"
        :tabs="tabs"
        :i18n="ts"
        @input="setTab"
      />
    </div>
    <div class="vadis-organism-padded-area">
      <component
        :is="tabComponent"
      />
    </div>
  </div>
</template>

<i18n namespace="views.Customization">
{
  "title": "Customization",
  "riskIndicators": "Risk Indicators"
}
</i18n>

<script>
import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';
import TabsComponent from '@/components/TabsComponent';
import CustomRiskIndicatorPane from '@/components/custom-risk-indicator/CustomRiskIndicatorPane';

export default {
  name: 'Customization',
  components: {
    TopBar,
    Breadcrumb,
    TabsComponent,
  },
  data: (vm) => {
    return {
      currentTab: null,
      defaultActive: null,
      tabs: [
        {
          id: 'riskIndicators',
          component: CustomRiskIndicatorPane,
          validator: () => vm.$featureEnabled('userManagement'),
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!to.params.tab) {
        return;
      }
      const tab = vm.tabs.find(tab => tab.id === to.params.tab);
      if (tab && vm.$refs.tabs) {
        vm.$refs.tabs.setActive(tab);
      }
    });
  },
  computed: {
    tabComponent() {
      return this.currentTab ? this.currentTab.component : null;
    },
  },
  methods: {
    setTab(tab) {
      this.$router.push({
        name: 'customization-screen',
        params: { tab: tab.id },
      });
    },
  },
};
</script>
