import TransformedGraph from './transformed.graph';

export default class Transformation {
  #transformer;

  constructor(transformer) {
    this.#transformer = transformer;
  }

  apply(decorated, ...args) {
    return this.#transformer(decorated,...args).then(g => {
      return new TransformedGraph(decorated, g, this);
    });
  }

}
