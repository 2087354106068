export default function (Component) {
      Component.options._i18n = {
        namespace: "views.TeamMonthlyConsumption",
        translations: 






































































{
  "title": {
    "teamMonthlyConsumption": "Team monthly consumption"
  },
  "th": {
    "teamName": "Team name",
    "department": "Department",
    "screening": "Screening",
    "monitoring": "Monitoring"
  }
}

      }
    }