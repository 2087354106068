<template>
  <div
    v-if="survey"
    class="survey-questions"
  >
    <div class="nav">
      <button
        id="surveyPrevious"
        href="#"
        @click="prevPage()"
      >
        <i class="icon-Arrow-left-small" />
      </button>
      <p class="progress-bar">
        {{ ts.questionsOf({ a: currentPage + 1, b: survey.pageCount }) }}
      </p>
      <button
        id="surveyNext"
        :class="{'invisible': !showNextArrow} "
        @click="survey.nextPage()"
      >
        <i class="icon-Arrow-right-small" />
      </button>
    </div>
    <survey
      v-if="survey"
      :class="'survey-library'"
      :survey="survey"
    />
    <button
      v-if="showNextButton"
      class="vadis-form-button-main next"
      @click="nextPage()"
    >
      {{ ts.buttons.next }}
    </button>
  </div>
</template>

<i18n namespace="components.SurveyQuestions">
{
  "questionsOf": "Question {a} of {b}",
  "buttons": {
    "next": "Next"
  }
}
</i18n>

<script>
import * as SurveyVue from 'survey-vue';

export default {
  name:'SurveyQuestions',
  components: {
    Survey: SurveyVue.Survey,
  },
  props: {
    survey: {
      type: SurveyVue.Model,
      default: null,
      required: true,
    },
    secretKey: {
      type: String,
      default: null,
      required: true,
    },
  },
  data:() => ({
    showNextButton: false,
    showNextArrow: false,
    currentPage: 0,
  }),
  watch: {
    survey: {
      handler: function(o, n) {
        if (n) {
          this.currentPage = n.currentPageNo;
        }
        this.showNext();
      },
      deep: true,
    },
  },
  mounted() {
    if (window.matchMedia('(min-width: 640px)').matches) {
      (this.survey.getAllQuestions()).forEach(e => {
        e.colCount = 2;
      });
    }
    this.showNext();
  },
  methods: {
    showNext() {
      const questionName = this.survey.currentPage.questions[0].name;
      this.showNextButton = !!this.survey.getQuestionByName(questionName).value;
      this.survey.render();
    },
    nextPage() {
      this.updateSurvey();
    },
    prevPage() {
      if (this.survey.currentPageNo !== 0) {
        this.survey.prevPage();
      } else {
        this.$emit('prev');
      }
    },
    updateSurvey() {
      if (this.survey.currentPage.hasErrors()) {
        return true;
      }
      this.$flairClient.SurveyService.updateSurveySubmission({ secretkey: this.secretKey, result: this.survey.data, submitted: false })
        .promise.then(() => {
          if ((this.survey.currentPageNo + 1) === this.survey.pageCount) {
            this.$emit('next');
          } else {
            this.survey.nextPage();
          }
        }).catch((err) => {
          console.error('#failed', err);
        });
    },
  },
};
</script>
