export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligenceList",
        translations: 

















































































































{
  "startDueDiligenceManually": "Would you like to start a due diligence manually ?",
  "startDueDiligence": "Start due diligence",
  "th": {
    "computedRisk": "Computed risk",
    "revisedRisk": "Revised risk",
    "analyst": "Analyst",
    "manager": "Manager",
    "decision": "Decision",
    "startAt": "Created at"
  },
  "decision": {
    "Rejected": "Rejected",
    "Approved": "Approved",
    "UnderEvaluation": "Under evaluation"
  },
  "buttons": {
    "details": "Details"
  }
}

      }
    }