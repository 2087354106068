export default function (Component) {
      Component.options._i18n = {
        namespace: "components.TeamsPane",
        translations: 





































































































{
  "th": {
    "lastName": "Last name",
    "firstName": "First name",
    "function": "Function",
    "email": "Mail address",
    "lastVisit": "Last visit"
  },
  "members": "members",
  "manager": "Manager",
  "removeUserFromTeam": "Remove user...",
  "remove": "Remove {q} ?",
  "removeSubtitle": "{firstname}’s account will be permanently deleted..",
  "button": {
    "addAnalyst": "Add analyst"
  },
  "link": {
    "send": "Send invitation",
    "resend": "Resend invitation"
  }
}

      }
    }