export default function (Component) {
      Component.options._i18n = {
        namespace: "views.MyPortfolio",
        translations: 

















{
  "title": "My portfolio"
}

      }
    }