export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RequestInfoModal",
        translations: 













































































































































































{
  "titleRequestInfo": "Request info",
  "headers": {
    "surveyLanguage": "Survey language",
    "chooseSurvey": "Choose surveys",
    "sendTo": "Send to",
    "additionalComment": "Additional comments (optional)"
  },
   "errors": {
    "languageRequired": "Please select a language",
    "firstNameRequired": "A first name is required",
    "lastNameRequired": "A last name is required",
    "emailRequired": "A valid email address is required",
    "surveyRequired": "Please select a survey"
  },
  "placeholders": {
    "email": "Email",
    "firstName": "First name",
    "lastName": "Last name"
  },
  "buttons": {
    "send": "Send",
    "cancel": "Cancel"
  }
}

      }
    }