<template>
  <div class="submit-survey">
    <h4 class="submit-survey-title">
      {{ ts.finished }}
    </h4>
    <div class="survey-question-box">
      <p class="survey-text-top">
        {{ ts.surveySubmitted }}
      </p>
      <h5 class="survey-text">
        {{ ts.WouldYouSubmit }}
      </h5>
    </div>
    <div class="buttons">
      <button
        class="vadis-form-button-main"
        @click="updateSurvey()"
      >
        {{ ts.buttons.submit }}
      </button>
      <button
        class="vadis-form-button-standard"
        @click="$emit('review')"
      >
        {{ ts.buttons.review }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.SubmitSurvey">
{
  "finished": "Have you finished the survey?",
  "surveyCompleted": "Your completed survey is ready",
  "WouldYouSubmit": "Would you like to submit it?",
  "buttons": {
    "submit": "Submit definitely",
    "review": "Review from start"
  }
}
</i18n>

<script>
import * as SurveyVue from 'survey-vue';

export default {
  name: 'SubmitSurvey',
  props: {
    survey: {
      type: SurveyVue.Model,
      default: null,
      required: false,
    },
    secretKey: {
      type: String,
      default: null,
      required: false,
    },
  },
  methods: {
    updateSurvey() {
      this.$flairClient.SurveyService.updateSurveySubmission({ secretkey: this.secretKey, result: this.survey.data, submitted: true })
        .promise.then(() => {
          this.$emit('surveySubmitted');
        }).catch((err) => {
          console.error('#failed', err);
        });
    },
  },
};
</script>
