<template>
  <div v-if="isOpen">
    <div class="vadis-style-global-DialogBg" />
    <Loader
      v-if="pdfLoaded"
      :global="true"
      :shown="true"
    />
    <div class="vadis-modal-request-info">
      <Loader
        v-if="!surveys.loaded"
        :global="true"
        :shown="true"
      />
      <div class="title">
        <h6>{{ ts.titleRequestInfo }}</h6>
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>
      <form
        id="request-info-form"
        class="vadis-organism-request-info"
        novalidate
        @submit.prevent="validate"
      >
        <h6>
          {{ ts.headers.surveyLanguage }}
        </h6>
        <div
          :class="{'vadis-form-field-select': true, invalid: $v.form.language.$error}"
        >
          <select
            v-model="form.language"
          >
            <template v-for="(language, k) in getLanguages()">
              <option
                :key="k"
                :value="k"
              >
                <p>
                  {{ language }}
                </p>
              </option>
            </template>
          </select>
          <i class="icon-Dropdown" />
          <p
            v-if="$v.form.language.$error"
            class="validation-error"
          >
            {{ ts.errors.languageRequired }}
          </p>
        </div>

        <div :class="{ 'form-row': true, 'invalid-checkbox': $v.form.dueDiligenceSurveys.$error}">
          <h6>
            {{ ts.headers.chooseSurvey }}
          </h6>
          <div
            v-for="(survey) in surveys.items"
            :key="survey.id"
            class="vadis-molecule-card-document-check"
          >
            <div
              class="file"
            >
              <input
                :id="survey.id"
                v-model="form.dueDiligenceSurveys"
                :value="survey.id"
                type="checkbox"
                class="checkbox"
              >

              <label
                :for="survey.id"
              >
                {{ survey.name }}
              </label>
            </div>
            <div
              class="download-pdf"
              @click="downloadPDF(survey)"
            >
              <i class="icon-Download" />
            </div>
          </div>
          <p
            v-if="$v.form.dueDiligenceSurveys.$error"
            class="validation-error checkbox"
          >
            {{ ts.errors.surveyRequired }}
          </p>
        </div>
        <h6>
          {{ ts.headers.sendTo }}
        </h6>
        <div
          :class="{ 'form-row vadis-form-field-standard': true, invalid: $v.form.email.$error }"
        >
          <input
            id="request-email-input"
            v-model.trim="form.email"
            :placeholder="ts.placeholders.email"
            @input="$v.form.email.$touch"
          >
          <p
            v-if="$v.form.email.$error"
            class="validation-error"
          >
            {{ ts.errors.emailRequired }}
          </p>
        </div>
        <div
          :class="{ 'vadis-form-field-standard': true, invalid: $v.form.firstName.$error }"
        >
          <input
            id="first-name-input"
            v-model.trim="form.firstName"
            :placeholder="ts.placeholders.firstName"
            @input="$v.form.firstName.$touch"
          >
          <p
            v-if="$v.form.firstName.$error"
            class="validation-error"
          >
            {{ ts.errors.firstNameRequired }}
          </p>
        </div>
        <div
          :class="{ 'form-row vadis-form-field-standard ': true, invalid: $v.form.lastName.$error }"
        >
          <input
            id="last-name-input"
            v-model.trim="form.lastName"
            :placeholder="ts.placeholders.lastName"
            @input="$v.form.lastName.$touch"
          >
          <p
            v-if="$v.form.lastName.$error"
            class="validation-error"
          >
            {{ ts.errors.lastNameRequired }}
          </p>
        </div>
        <div class="form-row vadis-form-field-textarea">
          <h6>{{ ts.headers.additionalComment }}</h6>
          <textarea
            v-model="form.comment"
            class="comment"
          />
        </div>
        <div class="form-row buttons">
          <button
            class="vadis-form-button-main"
            type="submit"
          >
            {{ ts.buttons.send }}
          </button>
          <button
            class="vadis-form-button-standard"
            @click="close()"
          >
            {{ ts.buttons.cancel }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<i18n namespace="components.RequestInfoModal">
{
  "titleRequestInfo": "Request info",
  "headers": {
    "surveyLanguage": "Survey language",
    "chooseSurvey": "Choose surveys",
    "sendTo": "Send to",
    "additionalComment": "Additional comments (optional)"
  },
   "errors": {
    "languageRequired": "Please select a language",
    "firstNameRequired": "A first name is required",
    "lastNameRequired": "A last name is required",
    "emailRequired": "A valid email address is required",
    "surveyRequired": "Please select a survey"
  },
  "placeholders": {
    "email": "Email",
    "firstName": "First name",
    "lastName": "Last name"
  },
  "buttons": {
    "send": "Send",
    "cancel": "Cancel"
  }
}
</i18n>

<script>
import _ from 'lodash';
import Loader from '@/components/Loader';
import { required, email } from 'vuelidate/lib/validators';
import { DueDiligence } from 'flair-client';

export default {
  name: 'RequestInfoModal',
  components: {
    Loader,
  },
  props: {
    dueDiligence: {
      type: DueDiligence,
      default: () => {},
      required: true,
    },
  },
  data:() => ({
    selectedSurvey: null,
    pdfLoaded: false,
    isOpen: false,
    surveys: {},
    form: {
      language: '',
      email: '',
      firstName: '',
      lastName: '',
      comment: '',
      dueDiligenceSurveys: [],
    },
  }),
  validations: {
    form: {
      language: {
        required,
      },
      email: {
        required,
        email,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      dueDiligenceSurveys: {
        required,
      },
    },
  },
  created() {
    this.getSurveys();
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.clearForm();
      this.isOpen = false;
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendForm();
      }
    },
    getLanguages() {
      return _.mapKeys(this.$flairClient.ts('locales'), (k, v) => {
        return v;
      });
    },
    getSurveys() {
      this.surveys = this.$flairClient.SurveyService.getSurveys();
    },
    sendForm() {
      const selectedSurveys = this.form.dueDiligenceSurveys.map((e) => ({
        type:'DueDiligence.Survey',
        id: e,
      }));
      const payload = {
        id: this.dueDiligence.id,
        to: {
          email: this.form.email,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
        },
        language: this.form.language,
        comment: this.form.comment,
        surveys: selectedSurveys,
      };
      this.$flairClient.DueDiligenceService.requestInfo(payload)
        .promise.then(() => {
          this.clearForm();
          this.close();
        });
    },
    clearForm() {
      this.$v.$reset();
      this.form.language = '';
      this.form.email = '';
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.comment = '';
      this.form.dueDiligenceSurveys = [];
    },
    downloadPDF(survey) {
      this.pdfLoaded = true;
      this.$flairClient.SurveyService.getSurveyAsPDF({ id: survey.id, lang: this.$flairClient.I18NService.locale }, survey.attributes.name)
        .finally(() => {
          this.pdfLoaded = false;
        });
    },
  },
};
</script>
