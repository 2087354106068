<template>
  <highcharts
    v-if="history"
    :options="chartOptions()"
    class="global-risk-history"
  />
</template>

<script>
import { transformRiskScore } from 'flair-client/src/utils';
import { Chart } from 'highcharts-vue';

export default {
  name: 'GlobalRiskHistory',
  components: {
    highcharts: Chart,
  },
  props: {
    history: {
      type: Array,
      default: () => [],
      required: true,
    },
    last: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data: () => ({
    transformRiskScore,
    ready: false,
  }),
  computed: {
    values: function() {
      const v = this.history.map(v => this.transformRiskScore(v.score));
      v.push(this.transformRiskScore(this.last));
      return v;
    },
    markerSeries: function() {
      const newValues = [];
      this.values.forEach(res => {
        newValues.push({
          y: res,
          className: `point_${res}`,
        });
      });
      return newValues;
    },
    categories: function() {
      return this.history.map(v => v.year);
    },
    xAxisMin: () => 0,
    xAxisMax: function() {
      return this.categories.length;
    },
  },
  methods: {
    chartOptions: function() {
      return {
        chart: {
          backgroundColor: 'transparent',
          type: 'area',
          margin: 0,
        },
        title: {
          text: null,
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          categories: this.categories,
          min: this.xAxisMin,
          max: this.xAxisMax,
          tickInterval: 1,
          minPadding: 0,
          maxPadding: 0,
          tickWidth: 0,
          lineWidth: 0,
          endOnTick: false,
          startOnTick: false,
        },
        yAxis: {
          visible: false,
          max: 11,
          min: -1,
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        series: [{
          type: 'line',
          className: 'outermarker',
          shadow: true,
          dataLabels: {
            enabled: false,
          },
          enableMouseTracking: false,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 8,
            fillColor: 'white',
          },
          data: this.markerSeries,
        }, {
          type: 'line',
          className: 'riskmarker',
          dataLabels: {
            enabled: true,
            y: -5,
          },
          enableMouseTracking: false,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 5,
            fillColor: 'green',
          },
          data: this.markerSeries,
        }, {
          type: 'line',
          className: 'innermarker',
          dataLabels: {
            enabled: false,
          },
          enableMouseTracking: false,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 3,
            fillColor: 'white',
          },
          data: this.markerSeries,
        }],
      };
    },
  },
};

</script>
