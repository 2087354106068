<template>
  <div class="survey-view">
    <SurveyHeader
      :name="name"
      :company="company"
    />
    <AlreadySubmitted
      v-if="alreadySubmitted"
    />
    <SurveyExplanation
      v-if="surveyExplanation && survey"
      :name="name"
      :survey="survey"
      @startSurvey="startSurvey($event)"
    />
    <InvalidSecretKey
      v-if="invalidSecretkey"
    />
    <CheckUser
      v-if="false"
      :company="company"
      @confirmInformation="confirmInformation()"
    />
    <SurveyQuestions
      v-if="informationConfirmed && surveyStarted"
      :survey="survey"
      :secret-key="secretkey"
      @prev="informationConfirmed = false; surveyExplanation = true;"
      @next="toSubmit = true; surveyStarted = false;"
    />
    <SubmitSurvey
      v-if="toSubmit"
      :survey="survey"
      :secret-key="secretkey"
      @review="reviewFromBegining()"
      @surveySubmitted="surveyCompleted()"
    />
    <SurveySubmitted
      v-if="surveySubmitted"
    />
  </div>
</template>

<script>
import * as SurveyVue from 'survey-vue';
import SurveyExplanation from '@/components/surveys/SurveyExplanation.vue';
import CheckUser from '@/components/surveys/CheckUser';
import SurveyQuestions from '@/components/surveys/SurveyQuestions';
import SubmitSurvey from '@/components/surveys/SubmitSurvey.vue';
import InvalidSecretKey from '@/components/surveys/InvalidSecretKey.vue';
import SurveyHeader from '@/components/surveys/SurveyHeader';
import SurveySubmitted from '@/components/surveys/SurveySubmitted';
import AlreadySubmitted from '@/components/surveys/AlreadySubmitted';

export default {
  name: 'Surveys',
  components: {
    SurveyExplanation,
    CheckUser,
    SurveyQuestions,
    SubmitSurvey,
    InvalidSecretKey,
    SurveyHeader,
    SurveySubmitted,
    AlreadySubmitted,
  },
  data:() => ({
    toSubmit: false,
    survey: null,
    invalidSecretkey: null,
    name: null,
    surveyStarted: false,
    surveyExplanation: true,
    informationConfirmed: false,
    company: null,
    surveySubmitted: false,
    secretkey: null,
    alreadySubmitted: false,
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$flairClient.SurveyService.getSurveySubmissionBySecretKey({ secretkey: to.params.secretkey })
        .promise.then((res) => {
          vm.name = res.survey.attributes.name;
          vm.$flairClient.I18NService.locale = res.survey.attributes.language;
          if (res.submitted) {
            vm.alreadySubmitted = true;
            return true;
          }
          vm.secretkey = to.params.secretkey;

          const questions = res.survey.attributes.content;
          vm.survey = new SurveyVue.Model(questions);
          if (res.result) {
            vm.survey.data = res.result;
          }
          vm.invalidSecretKey = false;
        })
        .catch(() => {
          vm.invalidSecretKey = true;
        });
    });
  },
  methods: {
    startSurvey() {
      this.surveyStarted = true;
      this.surveyExplanation = false;
      this.informationConfirmed = true;
    },
    confirmInformation() {
      this.informationConfirmed = true;
    },
    reviewFromBegining() {
      this.survey.currentPageNo = 0;
      this.toSubmit = false;
      this.surveyStarted = true;
    },
    surveyCompleted() {
      this.toSubmit = false;
      this.surveySubmitted = true;
    },
  },
};
</script>

