export default function (Component) {
      Component.options._i18n = {
        namespace: "views.Search",
        translations: 





























































{
  "welcome": "Welcome {who}!",
  "searchResults": "Search results for {q}",
  "companies": "Companies",
  "recentCompanies": "Recent companies",
  "recentPeople": "Recent people"
}

      }
    }