<template>
  <div>
    <Loader
      v-if="loading"
      :global="false"
      :shown="true"
    />
    <div v-else>
      <UsersList
        :users="usersRevoked['false']"
      />
      <UsersRevokedList
        :users="usersRevoked['true']"
      />
    </div>
  </div>
</template>

<i18n namespace="components.UsersPane">
{
  "title": "Users",
  "th": {
    "lastName": "Last name",
    "firstName": "First name",
    "function": "Function",
    "role": "Role",
    "teams": "Teams",
    "departments": "Departments",
    "lastVisit": "Last visit"
  },
  "superadmin": "Super Admin",
  "teammanager": "Team Manager",
  "user": "User"
}
</i18n>

<script>
import _ from 'lodash';
import Loader from '@/components/Loader';
import UsersList from '@/components/user/UsersList';
import UsersRevokedList from '@/components/user/UsersRevokedList';

export default {
  name: 'UsersPane',
  components: {
    Loader,
    UsersList,
    UsersRevokedList,
  },
  data: () => ({
    usersRevoked: [],
    loading: false,
  }),
  created() {
    this.loadUsers();
  },
  events: {
    'admin:user:deleted': (vm) => {
      vm.loadUsers();
    },
  },
  methods: {
    loadUsers() {
      this.loading = true;
      this.$flairClient.AdminService.getUsers({ nopaging: '' }).promise.then(res => {
        this.usersRevoked = _.groupBy(res.items, 'revoked');
        this.loading = false;
      });
    },
  },
};
</script>
