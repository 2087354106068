<template>
  <div>
    <div class="due-diligence-roles-list table-border">
      <div class="title">
        <h6>{{ ts.header }}</h6>
        <div class="search">
          <input
            class="search-tasks"
            type="text"
            placeholder="Search tasks"
          >
          <i class="icon-Search" />
        </div>
      </div>
      <div class="due-diligence-list">
        <table>
          <thead>
            <tr>
              <th>
                {{ ts.tasks }}
              </th>
              <th>
                {{ ts.roles.analyst }}
                <i class="icon-Dropdown" />
              </th>
              <th>
                {{ ts.roles.complianceOfficer }}
                <i class="icon-Dropdown" />
              </th>
              <th>
                {{ ts.roles.manager }}
                <i class="icon-Dropdown" />
              </th>
            </tr>
          </thead>
          <tbody v-if="roles">
            <tr
              v-for="(task) in roles.items"
              :key="task.id"
            >
              <td>
                <div class="tag-role">
                  <p class="name">
                    {{ tsTasks[task.id] }}
                  </p>
                  <!-- <div class="target-tooltip">
                    <i
                      v-if="task.hasInfo"
                      class="icon-Info"
                    />
                    <div class="vadis-molecule-tooltip-info">
                      <div class="tooltiptext">
                        <div class="title">
                          <p>Some info about the role here</p>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </td>
              <td>
                <div
                  :class="[ task.completedByAnalyst ? 'task-completed' : 'task-not-completed']"
                />
              </td>
              <td>
                <div
                  :class="[ task.completedByCO ? 'task-completed' : 'task-not-completed']"
                />
              </td>
              <td>
                <div
                  :class="[ task.completedByManager ? 'task-completed' : 'task-not-completed']"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.DueDiligenceRolesPane">
{
  "header": "Due diligence roles",
  "tasks": "Tasks",
  "roles": {
    "analyst": "Analyst",
    "manager": "Manager",
    "complianceOfficer": "Compliance Officer"
  }
}
</i18n>

<script>

export default {
  name: 'DueDiligenceRolesPane',
  data: () => ({
    roles: null,
  }),
  computed: {
    tsTasks() {
      return this.$flairClient.ts('dueDiligenceRoles');
    },
  },
  created() {
    this.getDDRoles();
  },
  methods: {
    getDDRoles() {
      this.$flairClient.DueDiligenceService.getDueDiligenceRoles()
        .promise.then(roles => this.roles = roles);
    },
  },
};
</script>
