export default function (Component) {
      Component.options._i18n = {
        namespace: "components.UsersList",
        translations: 












































































{
  "title": "Users",
  "th": {
    "lastName": "Last name",
    "firstName": "First name",
    "function": "Function",
    "role": "Role",
    "teams": "Teams",
    "departments": "Departments",
    "lastVisit": "Last visit"
  },
  "superadmin": "Super Admin",
  "teammanager": "Team Manager",
  "user": "User",
  "removeUser": "Remove user",
  "remove": "Remove '{q}'?",
  "removeUserSubtitle": "This account will be suspended."
}

      }
    }