export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DepartmentAccordion",
        translations: 


































































































{
  "button": {
    "createTeam": "Create team"
  },
  "link": {
    "send": "Send invitation",
    "resend": "Resend invitation",
    "addLeader": "Add department leader"
  },
  "members": "members",
  "teams": "teams",
  "renameDepartment":"Rename department",
  "removeDepartment": "Remove department...",
  "renameTeam": "Rename team",
  "editManager": "Edit manager",
  "removeTeam": "Remove team...",
  "remove": "Remove '{q}'?",
  "removeTeamSubtitle": "All accounts of people within team will be suspended.",
  "removeDepartmentSubtitle": "All accounts of people within department will be suspended.",
  "chooseManager": "You must choose a manager for each team",
  "theManagerWill": "The manager will manage their own team of analysts"
}

      }
    }