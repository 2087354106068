<template>
  <div class="person-list table-border">
    <table class="table-hover">
      <thead>
        <tr>
          <th>{{ ts.th.name }}</th>
          <th class="no-open">
            {{ ts.th.dateOfBirth }}
          </th>
          <th class="no-open">
            {{ ts.th.nationality }}
          </th>
        </tr>
      </thead>
      <tbody
        v-if="people.loading"
        class="vadis-molecule-loader"
      >
        <tr>
          <td colspan="5">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-if="people.loaded">
        <tr
          v-for="(person, i) in people.items"
          :key="i"
          :class="{'current': isCurrent(person)}"
          @click="onClick(person)"
        >
          <td>
            <div class="risk-tag-person">
              <a class="name">{{ person.fullName }}</a>
              <RiskTag
                :risks="riskIndicators(person)"
                @click="onClickRisk(person, $event.risk)"
              />
            </div>
          </td>
          <td class="no-open">
            {{ person.birthDate | birthDateWithAge }}
          </td>
          <td class="no-open">
            <CountryFlag :country="person.nationality" />
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="people.loaded && people.hasPagination()"
      :current-page="people.meta.pageNumber"
      :total-items="people.meta.totalItems"
      :number-of-lines="people.meta.pageSize"
      :links="people.links"
      @click="onClickPagination($event)"
    />
  </div>
</template>

<i18n namespace="components.PersonList">
{
  "th": {
    "name": "Name",
    "dateOfBirth": "Date of birth",
    "nationality": "Nationality",
    "affinities": "Affinities",
    "relatedCompanies": "Related Companies"
  }
}
</i18n>

<script>
import _ from 'lodash';
import Loader from '@/components/Loader';
import RiskTag from '@/components/risk/RiskTag';
import CountryFlag from '@/components/CountryFlag';
import Pagination from '@/components/Pagination';

export default {
  name: 'PersonList',
  components: {
    Loader,
    RiskTag,
    CountryFlag,
    Pagination,
  },
  props: {
    people: {
      type: Object,
      default: null,
      required: true,
    },
    current: {
      type: Object,
      default: null,
      required: false,
    },
  },
  methods: {
    onClick(c) {
      this.$emit('click', c);
    },
    isCurrent(c) {
      return c === this.current;
    },
    riskIndicators(person) {
      const risks = _.at(person, 'last.riskProfile.subrisks')[0];
      return _.filter(risks, (r) => r.score > 0);
    },
    onClickRisk(person, risk) {
      this.$emit('onClickRisk', { person, risk });
    },
    onClickPagination(event) {
      this.people[event]();
    },
  },
};
</script>
