export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyEmptyPane",
        translations: 










{
  "noInformation": "There is no information available for this section in the database of {dataProvider}."
}

      }
    }