export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyOverview",
        translations: 



































































{
  "buttons": {
    "close": "Close",
    "companyDetails": "Company Details"
  },
  "labels": {
    "size": "Size",
    "type": "Type",
    "legalForm": "Legal form",
    "country": "Country",
    "activity": "Activity",
    "europeanVAT": "VAT",
    "siren": "Siren",
    "yearOfIncorporation": "Established in",
    "operatingRevenue": "Last turnover",
    "numberOfEmployees": "Number of employees",
    "ultimateBeneficiaryOwner": "Ultimate beneficiary owner",
    "numberOfShareholderCompanies": "Number of shareholders",
    "numberOfSubsidiaryCompanies": "Number of subsidiaries",
    "numberOfRelatedPeople": "Number of managers",
    "primaryGroup": "Group",
    "globalRisk": "Global risk"
  }
}

      }
    }