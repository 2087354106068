export default function (Component) {
      Component.options._i18n = {
        namespace: "components.GeographicalPortfolioRisk",
        translations: 



















































































{
  "subtitle": "You are viewing company level of ",
  "back": "back",
  "lastUpdate": "Last update :",
  "source": "Source",
  "companyInCountry": "Companies in {q}",
  "countries": "Countries",
  "country": "Country",
  "companies": "Companies",
  "company": "Company"
}

      }
    }