<template>
  <div v-if="isOpen">
    <form
      v-if="!emailSent"
      id="login-form"
      class="vadis-organism-change-password"
      novalidate
      @submit.prevent="validateCreds"
    >
      <div class="header">
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>
      <h3>{{ ts.title }}</h3>
      <div
        v-if="errorPasswordSameAsPrevious"
        class="error-messages error-messages-visible"
      >
        <p>{{ ts.errorPasswordSameAsPrevious }}</p>
      </div>
      <div
        v-if="errorPassword"
        class="error-messages error-messages-visible"
      >
        <p>{{ ts.errorPassword }}</p>
      </div>
      <div
        v-if="$v.form.password.$error"
        class="error-messages error-messages-visible"
      >
        <p>{{ ts.errors.passwordRules }}</p>
      </div>

      <div class="form-row">
        <div :class="{ 'vadis-form-field-password-standalone': true, 'invalid-password': $v.form.currentPassword.$error }">
          <input
            id="password-input"
            v-model.trim="form.currentPassword"
            :type="passwordInputType"
            :placeholder="ts.placeholders.currentPassword"
            :disabled="sending"
            @input="$v.form.currentPassword.$touch"
          >
          <i
            :class="showPassword ? 'icon-Visible' : 'icon-Hidden'"
            @click="showPassword = !showPassword"
          />
        </div>
        <p
          v-if="$v.form.currentPassword.$error"
          class="validation-error"
        >
          {{ ts.errors.currentPasswordRequired }}
        </p>
      </div>

      <div class="form-row">
        <div :class="{ 'vadis-form-field-password-standalone': true, 'invalid-password': $v.form.password.$error }">
          <input
            id="new-password-input"
            v-model.trim="form.password"
            :type="passwordInputType"
            :placeholder="ts.placeholders.password"
            :disabled="sending"
            @input="$v.form.password.$touch"
          >
          <i
            :class="showPassword ? 'icon-Visible' : 'icon-Hidden'"
            @click="showPassword = !showPassword"
          />
        </div>
        <p
          v-if="$v.form.password.$error"
          class="validation-error"
        >
          {{ ts.errors.passwordRequired }}
        </p>
      </div>
      <div class="form-row">
        <div :class="{ 'vadis-form-field-password-standalone': true, 'invalid-password': $v.form.repeatPassword.$error }">
          <input
            id="repeatPassword-input"
            v-model.trim="form.repeatPassword"
            :type="passwordInputType"
            :placeholder="ts.placeholders.repeatPassword"
            :disabled="sending"
            @input="$v.form.repeatPassword.$touch"
          >
          <i
            :class="showPassword ? 'icon-Visible' : 'icon-Hidden'"
            @click="showPassword = !showPassword"
          />
        </div>
        <p
          v-if="$v.form.repeatPassword.$error"
          class="validation-error"
        >
          {{ ts.errors.repeatPasswordRequired }}
        </p>
      </div>
      <div class="form-row buttons">
        <button
          type="submit"
          :disabled="$v.form.$invalid || sending"
        >
          {{ ts.buttons.confirm }}
        </button>
      </div>
    </form>
    <PasswordChanged
      v-else
      :mail="form.mail"
      @close="close()"
    />
  </div>
</template>

<i18n namespace="components.PasswordChangeForm">
{
  "title": "Change password",
  "errorPasswordSameAsPrevious": "Your new password should be different as your current one.",
  "errorPassword": "Error: your current password is incorrect or your new password does not respect the security rules.",
  "errors": {
    "currentPasswordRequired": "The current password is required",
    "passwordRequired": "The new password is required",
    "repeatPasswordRequired": "Passwords must be identical",
    "passwordRules": "Your password needs to include both lower and uppercase characters, at least 1 number, and be between 12-24 characters long"
  },
  "buttons": {
    "confirm": "Confirm"
  },
  "placeholders": {
    "currentPassword": "Current password",
    "password": "New password",
    "repeatPassword": "Repeat password"
  }
}
</i18n>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import PasswordChanged from '@/components/password/PasswordChanged';
import validPassword from '@/validators/password-validation';

export default {
  name: 'PasswordChangeForm',
  components: {
    PasswordChanged,
  },
  data: () => ({
    showPassword: false,
    isOpen: false,
    emailSent: false,
    form: {
      currentPassword: '',
      password: '',
      repeatPassword: '',
    },
    sending: false,
    errorPassword: false,
    errorPasswordSameAsPrevious: false,
  }),
  validations: {
    form: {
      currentPassword: {
        required,
      },
      password: {
        required,
        validPassword,
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  computed: {
    passwordInputType() {
      return this.showPassword ? 'text' : 'password';
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    clearForm() {
      this.$v.$reset();
      this.form.password = null;
      this.form.repeatPassword = null;
    },
    changePassword() {
      this.sending = true;
      const payload = {
        type: 'User.Password',
        attributes: {
          oldPassword: this.form.currentPassword,
          newPassword: this.form.password,
        },
      };
      this.$flairClient.MyService.changePassword(payload)
        .promise.then(() => {
          this.sending = false;
          this.clearForm();
          this.emailSent = true;
        }).catch(err => {
          console.error('#failed', err);
          this.sending = false;
          if (err.response.status === 400) {
            this.errorPassword = true;
          } else if (err.response.status === 409) {
            this.errorPasswordSameAsPrevious = true;
          } else {
            throw err;
          }
        });
    },
    validateCreds() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.changePassword();
      } else {
        this.errorPassword = true;
      }
    },
  },
};
</script>
