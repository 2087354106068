<template>
  <div class="vadis-organism-login">
    <div class="invalid-token">
      <i class="icon-Expired" />
      <h2>{{ ts.title }}</h2>
      <p class="error">
        {{ ts.error }}
      </p>
      <div class="link">
        <router-link :to="{ name: 'forgotten-password-screen' }">
          {{ ts.tryAgain }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.PasswordInvalidToken">
{
  "title": "Invalid token",
  "error": "The link has expired",
  "tryAgain": "Try again"
}
</i18n>

<script>
export default {
  name: 'PasswordResetForm',
};
</script>
