export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CommentEvent",
        translations: 



























{
  "analyst": "Analyst",
  "manager": "Manager"
}

      }
    }