export default function (Component) {
      Component.options._i18n = {
        namespace: "views.MyDueDiligenceList",
        translations: 


















{
  "title": "Due Diligence"
}

      }
    }