<template>
  <div class="my-portfolio-view">
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb>
        <li>{{ ts.title }}</li>
      </Breadcrumb>
      <div class="header">
        <h1>{{ ts.title }}</h1>
      </div>
    </div>
    <div class="vadis-organism-padded-area">
      <PortfolioCompanyPane />
    </div>
  </div>
</template>

<i18n namespace="views.MyPortfolio">
{
  "title": "My portfolio"
}
</i18n>

<script>
import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';
import PortfolioCompanyPane from '@/components/portfolio/PortfolioCompanyPane';
export default {
  name: 'MyPortfolio',
  components: {
    TopBar,
    Breadcrumb,
    PortfolioCompanyPane,
  },
};
</script>
