<template>
  <div
    class="ecovadis-weight-indicator"
    :class="'ecovadis-weight-indicator-level-' + risk.score"
  >
    <label>{{ ts.weight }}</label>
    <div class="level" />
    <div class="level" />
    <div class="level" />
    <div class="level" />
  </div>
</template>

<i18n namespace="components.EcoVadisWeightIndicator">
{
  "weight": "Weight"
}
</i18n>

<script>
export default {
  name: 'EcoVadisWeightIndicator',
  props: {
    risk: {
      type: Object,
      default: null,
      required: true,
    },
  },
};
</script>

