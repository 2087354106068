<template>
  <div class="vadis-molecule-d3-graph full-space">
    <svg />
  </div>
</template>

<script>
import * as d3 from 'd3';
import GetUtils from '@/utils/get';

const CHARGE_STRENGTH = -1700;
const RADIUS = 20;

export default {
  name: 'D3GroupView',
  props: {
    graph: {
      type: Object,
      default: null,
      required: true,
    },
    showPercentage: {
      type: Boolean,
      default: false,
      required: false,
    },
    shownRiskIndicator: {
      type: String,
      default: 'financial',
    },
  },
  data: () => ({
    svg: null,
    untouched: true,
  }),
  watch: {
    showPercentage() {
      this.update();
    },
    shownRiskIndicator() {
      this.update();
    },
    graph(val) {
      if (val) {
        this.update();
      }
    },
  },
  mounted() {
    this.createGraph();
    if (this.graph) {
      this.update();
    }
  },
  methods: {
    zoomIn() {
      this.zoomable.scaleBy(this.svg.transition().duration(250), 1.2);
    },
    zoomOut() {
      this.zoomable.scaleBy(this.svg.transition().duration(250), 0.8);
    },
    /**
       * Update function for node & edges
       */
    update() {
      const getUtils = GetUtils(this);
      // Update links
      this.link = this.link.data(this.graph.edges, e => `${e.source}-${e.target}`);
      this.link.exit().remove();

      const newLink = this.link.enter()
        .append('line')
        .attr('class', 'link')
        .attr('marker-end', 'url(#arrow)');
      this.link = this.link.merge(newLink);

      // Update edges
      this.edge = this.edge.data(this.graph.edges, e => `${e.source}-${e.target}`);
      this.edge
        .exit()
        .remove();

      const newEdge = this.edge.enter()
        .append('path')
        .attr('class','edgepath')
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0)
        .attr('fill','blue')
        .attr('stroke','red')
        .attr('id', (d, i) => `edgepath${i}`)
        .style('pointer-events', 'none');
      this.edge = this.edge.merge(newEdge);

      // Update edges label
      const labelsData = this.showPercentage ? this.graph.edges : [];
      this.edgeLabel = this.edgeLabel.data(labelsData, e => `${e.source}-${e.target}`);
      this.edgeLabel.exit().remove();

      const newEdgeLabel = this.edgeLabel.enter()
        .append('text')
        .style('pointer-events', 'none')
        .attr('class', 'edgelabel')
        .attr('dx', 65)
        .attr('dy', 0)
        .attr('font-size', 10)
        .attr('fill', '#aaa');

      newEdgeLabel.append('textPath')
        .attr('xlink:href', (d,i) => `#edgepath${i}`)
        .style('pointer-events', 'none')
        .text(getUtils.edgeLabel);

      this.edgeLabel = this.edgeLabel.merge(newEdgeLabel);

      // Update nodes
      this.node = this.node.data(this.graph.nodes, (n) => n.id);
      this.node.exit().remove();

      const newNode = getUtils.defaultNodeCRUD();
      this.node = this.node.merge(newNode)
        .attr('class', getUtils.nodeClass);

      // Update simulation
      this.simulation.nodes(this.graph.nodes);
      this.simulation.force('linksForce').links(this.graph.edges);
      this.simulation.alpha(1).restart();

      // Make it draggable
      const draggable = d3.drag()
        .on('start', dragStart)
        .on('drag', dragDrag)
        .on('end', dragEnd);
      draggable(this.node);

      getUtils.defaultEventHandlers();

      const that = this;
      // Drag functions
      function dragStart(d) {
        that.untouched = false;
        if (!d3.event.active) {
          that.simulation.alphaTarget(0.3).restart();
        }
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragDrag(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      }

      function dragEnd(d) {
        if (!d3.event.active) {that.simulation.alphaTarget(0);}
        d.fx = null;
        d.fy = null;
      }
    },
    createGraph() {
      this.svg = d3.select(this.$el).select('svg');
      let width, height;
      resize.bind(this)();

      const defs = this.svg.append('svg:defs');

      // Arrow def
      defs.append('svg:marker') // This section adds in the arrows
        .attr('id', 'arrow')
        .attr('class', 'arrow')
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 80)
        .attr('refY', 0)
        .attr('markerWidth', 6)
        .attr('markerHeight', 6)
        .attr('orient', 'auto')
        .append('svg:path')
        .attr('d', 'M0,-5L10,0L0,5');

      this.simulation = d3.forceSimulation();

      const linkForce = d3.forceLink()
        .strength(1)
        .id(d => d.id);
      const chargeForce = d3.forceManyBody()
        .strength(CHARGE_STRENGTH);
      const centerForce = d3.forceCenter(width / 2, height / 2);
      const collideForce = d3.forceCollide(RADIUS);

      this.simulation
        .force('chargeForce', chargeForce)
        .force('centerForce', centerForce)
        .force('linksForce', linkForce)
        .force('collideForce', collideForce);

      this.simulation.on('tick', () => {
        // constrains the nodes to be within a box (if untouched)
        this.node.attr('transform', (d) => {
          // const constrainedX = Math.max(RADIUS, Math.min(width - RADIUS, d.x));
          // const constrainedY = Math.max(RADIUS, Math.min(height - RADIUS, d.y));
          const x = /* this.untouched ? constrainedX : */ d.x;
          const y = /* this.untouched ? constrainedY : */ d.y;
          return `translate(${x},${y})`;
        });

        this.link
          .attr('x1', d => { return d.source.x; })
          .attr('y1', d => { return d.source.y; })
          .attr('x2', d => { return d.target.x; })
          .attr('y2', d => { return d.target.y; });

        this.edge
          .attr('d', (d) => `M${d.source.x} ${d.source.y} L${d.target.x} ${d.target.y}`);

        this.edgeLabel
          .attr('x', (d) => { return d.x; })
          .attr('y', (d) => { return d.y; })
          .attr('transform', function(d) {
            if (d.target.x < d.source.x) {
              const bbox = this.getBBox();
              const rx = bbox.x + bbox.width / 2;
              const ry = bbox.y + bbox.height / 2;
              return `rotate(180 ${rx} ${ry})`;
            } else {
              return 'rotate(0)';
            }
          });
      });

      d3.select(window).on('resize', resize.bind(this));

      const g = this.svg.append('g')
        .attr('class', 'chart');

      this.link = g.append('g')
        .selectAll('.link');

      this.node = g.append('g')
        .selectAll('.node');

      this.edge = g.append('g')
        .selectAll('.edge');

      this.edgeLabel = g.append('g')
        .selectAll('.edge-label');

      /**
       * Resize function
       */
      function resize() {
        width = this.$el.offsetWidth;
        height = this.$el.offsetHeight;

        this.svg.attr('height', height);
        this.svg.attr('width', width);
      }

      // Make it zoomable
      this.zoomable = d3.zoom().on('zoom', zoomActions);
      this.zoomable(this.svg);
      this.svg.on('dblclick.zoom', null);

      // Zoom functions
      function zoomActions() {
        this.untouched = false;
        g.attr('transform', d3.event.transform);
      }
    },
  },
};
</script>
