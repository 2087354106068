<template>
  <div
    v-if="isOpen"
    class="vadis-modal-dueDiligence-invite"
  >
    <Loader
      v-if="isLoading"
      :global="true"
      :shown="true"
    />
    <div class="header">
      <h6>{{ ts.inviteMember }}</h6>
      <p>{{ company.name }}</p>
      <i
        class="icon-Close"
        @click="close()"
      />
    </div>
    <div
      v-if="!teamMembers.items.length"
      class="body"
    >
      <div class="members">
        <h6>{{ ts.noTeamMembers }}</h6>
        <div class="buttons">
          <router-link
            :to="{name: 'my-settings-screen'}"
            tag="button"
            class="vadis-form-button-main-small"
          >
            {{ ts.buttons.inviteMember }}
          </router-link>
        </div>
      </div>
    </div>
    <form
      v-else
      class="body"
      @submit.prevent="sendForm"
    >
      <div class="container">
        <h6>{{ ts.teamMember }}</h6>
        <div
          class="form-row radio"
        >
          <label
            v-for="job in jobs"
            :key="job.title"
            class="label"
          >
            <input
              v-model="role"
              :options="jobs"
              type="radio"
              :value="job.title"
            >
            {{ ts.radioButtons[job.title] }}
          </label>
        </div>
      </div>
      <div
        v-if="role"
        class="explanation"
      >
        <p>{{ ts.explanations[role].will }}</p>

        <ul>
          <li
            v-for="action in ts.explanations[role].actions"
            :key="action"
          >
            {{ action }}
          </li>
        </ul>
      </div>
      <div
        v-if="errorUserAlreadyInvited"
        class="container form-row error-messages error-messages-visible"
      >
        <p> {{ ts.errorUserAlreadyExists }} </p>
      </div>
      <div class="members">
        <h6>{{ ts.manager }}</h6>
        <div class="vadis-form-field-select">
          <select v-model="form.user">
            <template v-for="member in teamMembers.items">
              <option
                v-if="!memberIsInDueDiligence(member)"
                :key="member.id"
                :value="member.id"
              >
                <p>
                  {{ member.firstName }} {{ member.lastName }}
                </p>
              </option>
            </template>
          </select>
          <i class="icon-Dropdown" />
        </div>
      </div>

      <div class="buttons">
        <button
          type="submit"
          class="vadis-form-button-standard-small"
          :disabled="$v.form.$invalid"
        >
          {{ ts.buttons.inviteLink }}
        </button>
        <button
          class="vadis-form-button-standard-small"
          @click="close()"
        >
          {{ ts.buttons.cancel }}
        </button>
      </div>
    </form>
  </div>
</template>

<i18n namespace="components.InviteDueDiligenceMemberModal">
{
  "inviteMember": "Invite a team member",
  "teamMember": "Who would you like to invite to join the team",
  "noTeamMembers": "There is currently nobody in your team, would you like to add a member?",
  "errorUserAlreadyExists": "A user with the same ID already exists with this role in the due diligence.",
  "buttons": {
    "inviteLink": "Invite team member",
    "cancel": "Cancel",
    "inviteMember": "Invite a member"
  },
  "radioButtons": {
      "Manager": "A manager",
      "Analyst": "An analyst",
      "ComplianceOfficer": "A Compliance Officer"
  },
  "explanations": {
    "Manager": {
       "will": "The manager will :",
       "actions": [
          "Monitor your diligence",
          "Give the final recommendation for companies whose risks have been identified as high."
       ]
    },
    "Analyst": {
        "will": "The analyst will :",
        "actions": [
          "Analyse your diligence",
          "Can revise risk"
        ]
    },
    "ComplianceOfficer": {
        "will": "The compliance officer will :",
        "actions": [
          "Help the analyst to analyse the due diligence",
          "Can make the decision for low risk due diligence"
        ]
    }
  }
}
</i18n>

<script>
import _ from 'lodash';
import { Company } from 'flair-client';
import Loader from '@/components/Loader';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'InviteDueDiligenceMemberModal',
  components: {
    Loader,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    teamMembers: null,
    isOpen: false,
    isLoading: false,
    dueDiligence: null,
    errorUserAlreadyInvited: false,
    form: {
      user: null,
    },
    role: null,
    jobs: [
      { name: 'managerRadio', title: 'Manager' },
      { name: 'analystRadio', title: 'Analyst' },
      { name: 'complianceRadio', title: 'ComplianceOfficer' },
    ],
  }),
  validations: {
    form: {
      user: {
        required,
      },
    },
  },
  created() {
    this.loadTeamMembers();
  },
  methods: {
    loadTeamMembers() {
      this.teamMembers = this.$flairClient.MyService.getMyTeamMembers();
    },
    open(dueDiligence, role) {
      this.dueDiligence = dueDiligence;
      this.isOpen = true;
      this.role = role;
    },
    close() {
      this.isOpen = false;
      this.clearForm();
    },
    sendForm() {
      this.isLoading = true;
      const payload = {
        id: this.dueDiligence.id,
        members: [{
          user: {
            type: 'User.Profile',
            id: this.form.user,
          },
          role: this.role,
        }],
      };
      this.$flairClient.DueDiligenceService.inviteMembers(payload)
        .promise.then(() => {
          this.isLoading = false;
          this.clearForm();
          this.close();
        }).catch((err) => {
          if (err.response.status === 400) {
            this.errorUserAlreadyInvited = true;
            this.isLoading = false;
          } else {
            throw err;
          }
        });
    },
    clearForm() {
      this.user = null;
      this.role = null;
      this.form.user = null;
      this.errorUserAlreadyInvited = false;
    },
    memberIsInDueDiligence(member) {
      const groupByRole = _.groupBy(this.dueDiligence.members, 'role');
      if (this.role) {
        return _.find(groupByRole[this.role], e => e.user.id === member.id);
      } else {
        return _.find(this.dueDiligence.members, e => e.user.id === member.id);
      }
    },
  },
};
</script>
