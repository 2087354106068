export default function (Component) {
      Component.options._i18n = {
        namespace: "components.NavBar",
        translations: 








































































































{
  "home": "Home",
  "myportfolio": "My Portfolio",
  "portfolios": "Portfolios",
  "departments": "Departments",
  "settings": "Settings",
  "customization": "Customization",
  "configuration": "Configuration",
  "changePassword": "Change password",
  "analyst": "Analyst",
  "manager": "Manager",
  "logout": "Logout",
  "help": "Help",
  "dueDiligences": "Due Diligence",
  "customization": "Customization"
}

      }
    }