export default function (Component) {
      Component.options._i18n = {
        namespace: "views.CompanyDetails",
        translations: 





























































{
  "exportPDF": "Export PDF",
  "portfolio": "Portfolio"
}

      }
    }