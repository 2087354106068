<template>
  <table class="vadis-organism-table group">
    <thead>
      <tr>
        <th>{{ ts.complianceEntry }}</th>
        <th>{{ ts.subjectName }}</th>
        <th class="text-align-right">
          {{ ts.publishedAt }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(a, i) in analysis"
        :key="i"
      >
        <td><analysis-source-info :analysis="a" /></td>
        <td>{{ a.subjectName }}</td>
        <td class="text-align-right">
          {{ a.publishedAt | humanDate }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<i18n namespace="views.CompanyPDFRiskAnalysisTable">
{
  "year": "Year",
  "complianceEntry": "Entry n°",
  "subjectName": "Subject",
  "publishedAt": "Published at"
}
</i18n>

<script>
import AnalysisSourceInfo from '@/components/AnalysisSourceInfo';

export default {
  name: 'CompanyPDFRiskAnalysisTable',
  components: {
    AnalysisSourceInfo,
  },
  props: {
    analysis: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>
