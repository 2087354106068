<template>
  <div class="accordion">
    <div
      class="intro"
      :class="(isOpen && !disabled) ? 'open' : 'close'"
      @click="toggle()"
    >
      <i
        v-if="isOpen && !disabled"
        :class="{ 'icon-Arrow-down-small': true, 'disabled' : disabled }"
      />
      <i
        v-else
        :class="{ 'icon-Arrow-right-small': true, 'disabled' : disabled }"
      />
      <slot
        name="title"
      />
    </div>
    <slot v-if="isOpen" />
  </div>
</template>

<script>

export default {
  name: 'Accordion',
  props: {
    open:{
      type: Boolean,
      default: true,
    },
    disabled:{
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: null,
  }),
  mounted() {
    this.isOpen = this.open;
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
