export default function (Component) {
      Component.options._i18n = {
        namespace: "components.StartDueDiligenceModal",
        translations: 




















































































{
  "startDueDiligence": "Start Due Diligence",
  "manager": "Who is your manager for this due diligence ?",
  "managerWill": "The manager will :",
  "monitorDuediligence": "Monitor your diligence",
  "finalRecommendation": "Give the final recommendation for companies whose risks have been identified as high.",
  "noTeamMembers": "There is currently nobody in your team, would you like to add a member?",
  "buttons": {
    "start": "Start Due Diligence",
    "cancel": "Cancel",
    "addTeamMember": "Add Team Member"
  }
}

      }
    }