export default function (Component) {
      Component.options._i18n = {
        namespace: "views.MySettings",
        translations: 





























{
  "title": "Settings",
  "departments": "Departments",
  "teams": "Teams",
  "users": "Users",
  "surveys": "Surveys"
}

      }
    }