export default function (Component) {
      Component.options._i18n = {
        namespace: "components.AppVersionInfo",
        translations: 















































{
  "name": "Flair Client",
  "backend": "Backend",
  "frontend": "Frontend",
  "data": "Data",
  "environment": "Environment",
  "server": "Server",
  "financialData": "Financial data",
  "financialDataDate": "Financial data date",
  "complianceData": "Compliance data",
  "complianceDataDate": "Compliance data date",
  "provider": "Provider"
}

      }
    }