import ProxyGraph from './proxy.graph';

export default class ReverseEdgesGraph extends ProxyGraph {

  get edges() {
    return this.decorated.edges.map((e) => {
      return Object.assign({}, e, {
        source: e.target,
        target: e.source,
      });
    });
  }

  remove(transformed) {
    if (this === transformed) {
      return this.decorated;
    } else {
      return new ReverseEdgesGraph(this.decorated.remove(transformed));
    }
  }

}
