export default function (Component) {
      Component.options._i18n = {
        namespace: "components.MakeDecisionModal",
        translations: 





















{
  "makeDecision": "Make decision"
}

      }
    }