export default function (Component) {
      Component.options._i18n = {
        namespace: "components.StartEvent",
        translations: 

















{
  "startedDueDiligenceOn": "started a Due Diligence on {q}",
  "analyst": "Analyst",
  "manager": "Manager"
}

      }
    }