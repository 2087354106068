export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PasswordChangeForm",
        translations: 























































































































{
  "title": "Change password",
  "errorPasswordSameAsPrevious": "Your new password should be different as your current one.",
  "errorPassword": "Error: your current password is incorrect or your new password does not respect the security rules.",
  "errors": {
    "currentPasswordRequired": "The current password is required",
    "passwordRequired": "The new password is required",
    "repeatPasswordRequired": "Passwords must be identical",
    "passwordRules": "Your password needs to include both lower and uppercase characters, at least 1 number, and be between 12-24 characters long"
  },
  "buttons": {
    "confirm": "Confirm"
  },
  "placeholders": {
    "currentPassword": "Current password",
    "password": "New password",
    "repeatPassword": "Repeat password"
  }
}

      }
    }