<template>
  <div class="vadis-organism-login">
    <div class="email-send">
      <div><i class="icon-Check" /></div>
      <h2>{{ ts.title }}</h2>
      <p>{{ ts.messsage }}</p>
      <p>{{ mail }}</p>
      <div class="link">
        <router-link :to="{ name: 'login-screen' }">
          {{ ts.login }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.EmailSend">
{
  "title": "Email sent",
  "messsage": "A recovery mail has been sent to",
  "login": "Login"
}
</i18n>

<script>
export default {
  name: 'EmailSend',
  props: {
    mail: {
      default: '',
      type: String,
    },
  },
};
</script>
