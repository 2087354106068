<template>
  <div>
    <PasswordResetForm
      v-if="!invalidToken"
      :token="token"
    />
    <PasswordInvalidToken v-else />
  </div>
</template>

<style lang="scss">
#reset-password-screen {
  background: url(../assets/login-screen-background.jpg);
  .topbar {
    display: none;
  }
}
</style>

<script>
import PasswordResetForm from '@/components/password/PasswordResetForm';
import PasswordInvalidToken from '@/components/password/PasswordInvalidToken';

export default {
  name: 'ResetPassword',
  components: {
    PasswordResetForm,
    PasswordInvalidToken,
  },
  data: () => ({
    token: null,
    invalidToken: null,
  }),
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      vm.$flairClient.AuthenticationService.getTokenValidity({ token: to.params.token })
        .promise.then(() => {
          vm.token = to.params.token;
          vm.invalidToken = false;
        }) .catch(() => {
          vm.invalidToken = true;
        });
    });
  },
};
</script>
