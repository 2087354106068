export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PasswordForgottenForm",
        translations: 

















































{
  "title": "Password forgotten",
  "errors": {
    "mailRequired": "The mail is required"
  },
  "buttons": {
    "sendMail": "Send reset link"
  },
  "placeholders": {
    "mail": "Mail"
  },
  "login": "Login"
}

      }
    }