import ProxyGraph from './proxy.graph';

export default class CollapsedGraph extends ProxyGraph {

  constructor(decorated) {
    super(decorated);
  }

  get nodes() {
    return this.decorated.nodes.filter((n) => n.id === this.centerNodeId);
  }

  get edges() {
    return [];
  }

  remove(transformed) {
    if (this === transformed) {
      return this.decorated;
    } else {
      return new CollapsedGraph(this.decorated.remove(transformed));
    }
  }

}
