<template>
  <PasswordForgottenForm />
</template>

<style lang="scss">
#forgotten-password-screen {
  background: url(../assets/login-screen-background.jpg);
  .topbar {
    display: none;
  }
}
</style>

<script>
import PasswordForgottenForm from '@/components/password/PasswordForgottenForm';
export default {
  name: 'ForgottenPassword',
  components: {
    PasswordForgottenForm,
  },
};
</script>
