<template>
  <div class="vadis-organism-tab-empty">
    <img
      class=""
      src="../assets/search_empty.svg"
    >
    <h6>{{ ts.noSearch }}</h6>
  </div>
</template>

<i18n namespace="components.EmptySearch">
{
  "noSearch": "No results for your search."
}
</i18n>

<script>
export default {
  name: 'EmptySearch',
};
</script>
