export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SettingsSurveyPane",
        translations: 











































{
  "title": "List of due diligence surveys",
  "error": "You currently have no surveys to view",
  "createSurveyTemplate": "Create survey template",
  "button": {
    "createSurveyTemplate": "Create survey template",
    "saveSurveyTemplate": "Save survey template"
  }
}

      }
    }