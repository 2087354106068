<template>
  <div
    class="vadis-organism-openable-company-list"
    :class="{'vadis-organism-openable-company-list-open': isOpen}"
  >
    <div class="content">
      <CompanyEmptyPane v-if="isEmpty" />
      <SubsidiaryList
        v-else
        :subsidiaries="subsidiaries"
        :current="current"
        @click="setCurrent($event)"
        @onClickRisk="openRisk($event.company, $event.risk)"
      />
      <CompanyOverview
        v-if="current"
        :company="currentCompany"
        @close="clearCurrent()"
        @onClickRisk="openRisk($event.company, $event.risk)"
      />
    </div>
  </div>
</template>

<script>
import { Collection } from 'flair-client';
import SubsidiaryList from '@/components/SubsidiaryList';
import CompanyOverview from '@/components/company/CompanyOverview';
import CompanyEmptyPane from '@/components/company/CompanyEmptyPane';

export default {
  name: 'OpenableSubsidiaryList',
  components: {
    SubsidiaryList,
    CompanyOverview,
    CompanyEmptyPane,
  },
  props: {
    subsidiaries: {
      type: Collection,
      default: null,
      required: true,
    },
  },
  data: () => ({
    current: undefined,
    currentCompany: undefined,
  }),
  computed: {
    isOpen() {
      return !!this.current;
    },
    isEmpty() {
      return this.subsidiaries.data && this.subsidiaries.data.length === 0;
    },
  },
  watch: {
    subsidiaries() {
      this.clearCurrent();
    },
  },
  methods: {
    clearCurrent() {
      this.current = null;
      this.currentCompany = null;
    },
    setCurrent(subsidiary) {
      if (this.$featureEnabled('companyOverview')) {
        const c = this.$flairClient.EntitiesService.getCompanyById({ id: subsidiary.target.id });
        c.promise.then(() => {
          this.current = subsidiary;
          this.currentCompany = c;
        });
      } else {
        this.$router.push({
          name: 'company-details-screen',
          params: { id: subsidiary.target.id, tab: 'summary' },
        });
      }
    },
    clearCurrentRisk() {
      this.currentRisk = null;
    },
  },
};
</script>
