<template>
  <div>
    <OpenableManagementList
      ref="OpenableManagementList"
      :people="people"
    />
  </div>
</template>

<script>
import { Company } from 'flair-client';
import OpenableManagementList from '@/components/openable/OpenableManagementList';

export default {
  name: 'CompanyManagementPane',
  components: {
    OpenableManagementList,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
    paging: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    people: [],
  }),
  watch: {
    company() {
      this.loadPeople();
    },
  },
  created() {
    this.loadPeople();
  },
  methods: {
    loadPeople() {
      const params = {
        id: this.company.id,
      };
      if (!this.paging) {
        params.nopaging = '';
      }
      this.people = this.$flairClient.EntitiesService.getCompanyManagement(params);
    },
  },
};
</script>
