export default function (Component) {
      Component.options._i18n = {
        namespace: "components.EmailSend",
        translations: 
















{
  "title": "Email sent",
  "messsage": "A recovery mail has been sent to",
  "login": "Login"
}

      }
    }