export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PersonList",
        translations: 































































{
  "th": {
    "name": "Name",
    "dateOfBirth": "Date of birth",
    "nationality": "Nationality",
    "affinities": "Affinities",
    "relatedCompanies": "Related Companies"
  }
}

      }
    }