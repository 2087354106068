<template>
  <highcharts
    :options="chartOptions()"
    class="chart-bar-portfolio"
  />
</template>

<i18n namespace="components.PortfolioHealthBarChart">
{
  "lowest": "Lowest risk",
  "low": "Low risk",
  "medium": "Medium risk",
  "high": "High risk",
  "highest": "Highest risk"
}
</i18n>

<script>
import _ from 'lodash';
import { Chart } from 'highcharts-vue';

export default {
  name: 'PortfolioHealthBarChart',
  components: {
    highcharts: Chart,
  },
  props: {
    risks: {
      type: Array,
      default: null,
      required: true,
    },
  },
  computed: {
    values: function() {
      const newValues = _.map(this.ts, (name) => {
        return {
          name: name,
          total: _.compact(_.map(this.risks, (item) => {
            return this.ts[item.riskLevel] === name ? item.numberOfCompanies : null;
          }))[0],
          y: _.compact(_.map(this.risks, (item) => {
            return this.ts[item.riskLevel] === name ? item.riskHealth : null;
          }))[0],
        };
      });
      return newValues;
    },
  },
  methods: {
    chartOptions: function() {
      return {
        chart: {
          type: 'column',
          heigth: '100px',
          width: 570,
          backgroundColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, 'white'],
              [1, '#DCDCDB'],
            ],
          },
        },
        title: {
          text: '',
        },
        subtitle: {
          text: '',
        },
        credits: { enabled: false },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          min: -0.3,
          max: 4.3,
          categories: _.map(this.ts, (n) => n),
          tickmarkPlacement: 'on',
          lineWidth: 0,
        },
        yAxis: {
          title: {
            text: '',
          },
          showFirstLabel: false,
          maxPadding: 0.15,
          endOnTick: true,
          labels: {
            x: -25,
            y: 15,
            formatter: function() {
              return `${this.value}%`;
            },
          },
          opposite: true,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            enableMouseTracking: false,
            borderRadius: 4,
            dataLabels: {
              enabled: true,
              padding: 10,
              style: {
                fontSize: '24px',
              },
            },
          },
        },
        series: [
          {
            dataLabels: [{
              format: '{point.total}',
              style: {
                fontSize: '24px',
              },
            }],
            name: 'Browsers',
            colorByPoint: true,
            colors: ['#008556', '#94a545', '#f6ba39', '#ff6f00', '#d02c2f'],
            data: this.values,
          },
        ],
      };
    },
  },
};
</script>
