<template>
  <div class="check-company">
    <h3 class="check-user-title">
      {{ ts.isYou }}
    </h3>
    <div
      v-if="company"
      class="company-info-box"
    >
      <div class="company-info">
        <h4 class="company-info-name">
          {{ company.name }}
        </h4>
        <p>{{ company.europeanVAT }}</p>
        <p>
          <CountryFlag
            :country="company.address.country"
            :flag-only="false"
          />
        </p>
      </div>
    </div>
    <div class="buttons">
      <button
        class="vadis-form-button-main"
        @click="$emit('confirmInformation')"
      >
        {{ ts.buttons.continue }}
      </button>
      <button
        class="vadis-form-button-standard invisible"
      >
        {{ ts.buttons.reportIssue }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.CheckUser">
{
  "isYou": "Is this you?",
  "buttons": {
    "continue": "Yes, continue to survey",
    "reportIssue": "Report an issue"
  }
}
</i18n>

<script>
import CountryFlag from '@/components/CountryFlag';
import { Company } from 'flair-client';

export default {
  name: 'CheckUser',
  components: {
    CountryFlag,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
};
</script>
