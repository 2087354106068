export default function (Component) {
      Component.options._i18n = {
        namespace: "components.Breadcrumb",
        translations: 














{
  "home": "Home"
}

      }
    }