<template>
  <form
    class="custom-risk-form"
  >
    <div class="form-row vadis-form-field-standard">
      <p class="indicator-label">
        {{ ts.select({ a: minIndicatorSelection, b: maxIndicatorSelection}) }}
      </p>
    </div>
    <div
      v-for="provider in limitedProviders"
      :key="provider.id"
    >
      <CRIAccordion
        v-model="selectedIndicatorsPerProvider[provider.id]"
        :provider="provider"
        :indicators="provider.riskIndicators"
        @input="handleInput"
      />
    </div>
    <div class="form-row indicators-selected">
      <p><strong>{{ selectedIndicatorCount }}/{{ maxIndicatorSelection }}</strong> {{ ts.indicatorsSelected }}</p>
      <p
        v-if="canReset"
        class="reset"
        @click="resetIndicators()"
      >
        {{ ts.reset }}
      </p>
    </div>
  </form>
</template>

<i18n namespace="components.CRIIndicators">
{
  "select": "Select from {a} to {b} existing indicators to create your custom indicator",
  "indicatorsSelected": "indicators selected",
  "reset": "Reset to last saved"
}
</i18n>

<script>

import CRIAccordion from '@/components/custom-risk-indicator/CRIAccordion';
import { Collection, RiskIndicator } from 'flair-client';

export default {
  name: 'CRIIndicators',
  components: {
    CRIAccordion,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    originalRiskIndicator: {
      type: RiskIndicator,
      required: false,
      default: () => {},
    },
    providers: {
      type: Collection,
      required: true,
    },
  },
  data: () => ({
    selectedIndicatorsPerProvider: {},
    existingIndicator: false,
    selectionRange: {
      min: 2,
      max: 4,
    },
    limit: 4,
  }),
  computed: {
    selectedIndicators() {
      return this.selectedIndicatorsPerProvider;
    },
    minIndicatorSelection() {
      return this.selectionRange.min;
    },
    maxIndicatorSelection() {
      return this.selectionRange.max;
    },
    selectedIndicatorCount() {
      return Object.values(this.selectedIndicators).reduce((acc, indicators) => acc + indicators.length, 0);
    },
    limitedProviders() {
      return this.limit ? this.providers.items.slice(0, this.limit) : this.providers.items;
    },
    watchElementsChange() {
      return this.value.elements.length;
    },
    canReset() {
      return this.originalRiskIndicator && this.existingIndicator;
    },
  },

  watch: {
    'value.elements': {
      handler: function(indicators) {
        const indexPerProvider = this.providers.data
          .map(p => p.id)
          .reduce((idx, id) => {
            idx[id] = [];
            return idx;
          }, {});
        this.selectedIndicatorsPerProvider = indicators.reduce((dataProviders, indicator) => {
          dataProviders[indicator.data_provider_id].push(indicator.name);
          return dataProviders;
        }, indexPerProvider);
      },
      immediate: true,
    },
    watchElementsChange() {
      this.existingIndicator = true;
    },
    immediate: true,
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    async isValid() {
      return 2 <= this.selectedIndicatorCount && this.selectedIndicatorCount <= 4;
    },
    handleInput() {
      const elements = Object.entries(this.selectedIndicatorsPerProvider)
        .reduce((elmts, [providerId, riskIndicators]) => {
          return elmts.concat(riskIndicators.map(riskIndicatorId => {
            return {
              data_provider_id: providerId,
              name: riskIndicatorId,
              weight: Math.floor(100.0 / this.selectedIndicatorCount),
            };
          }));
        }, []);
      this.$emit('input', {
        ...this.value,
        elements,
      });
    },
    resetIndicators() {
      this.value.elements = this.originalRiskIndicator.elements;
    },
  },
};
</script>
