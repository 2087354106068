export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RiskWebChart",
        translations: 








{
  "subrisks": {
    "financial": "Financial",
    "sector": "Sector",
    "geographical": "Geographical",
    "adverse_media": "Adverse media",
    "pep": "PEP",
    "sanction": "Sanctions"
  }
}

      }
    }