<template>
  <div class="vadis-organism-login">
    <div class="email-send">
      <div><i class="icon-Check" /></div>
      <h3>{{ ts.title }}</h3>
      <p>{{ ts.messsage }}</p>
      <p>{{ mail }}</p>
      <button
        class="vadis-form-button-standard"
        @click="$emit('close')"
      >
        {{ ts.close }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.PasswordChanged">
{
  "title": "Password changed",
  "messsage": "A confirmation mail has been sent to",
  "close": "Close"
}
</i18n>

<script>
export default {
  name: 'PasswordChanged',
  props: {
    mail: {
      default: '',
      type: String,
    },
  },
};
</script>
