<template>
  <div class="vadis-organism-due-diligence-list">
    <StartDueDiligenceModal
      v-if="$featureEnabled('dueDiligence')"
      ref="startDueDiligenceModal"
    />
    <div
      v-if="dueDiligences.loading"
      class="vadis-molecule-loader"
    >
      <Loader
        :global="false"
        :shown="true"
      />
    </div>
    <div v-else>
      <div
        class="content"
      >
        <div class="due-diligence-list">
          <table>
            <thead>
              <tr>
                <th>{{ ts.th.startAt }}</th>
                <th>{{ ts.th.computedRisk }}</th>
                <th>{{ ts.th.revisedRisk }}</th>
                <th>{{ ts.th.analyst }}</th>
                <th>{{ ts.th.manager }}</th>
                <th>{{ ts.th.decision }}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="d in dueDiligences.items"
                :key="d.id"
              >
                <td> {{ d.startedAt | humanDate }}</td>
                <td>
                  <p :class="`vadis-controller-tag-risk vadis-controller-tag-global-risk-level${d.computedRisk}`">
                    {{ transformRiskScore(d.computedRisk) }}
                  </p>
                </td>
                <td>
                  <p :class="`vadis-controller-tag-risk vadis-controller-tag-revised-risk-level${d.revisedRisk}`">
                    {{ d.revisedRisk }}
                  </p>
                </td>
                <td>
                  <template
                    v-for="member in orderedMembers(d).Analyst"
                  >
                    <div
                      :key="member.id"
                      class="member"
                    >
                      <div class="vadis-molecule-avatar-small">
                        <p>{{ member.initials }}</p>
                      </div> {{ member.fullName }}
                    </div>
                  </template>
                </td>
                <td>
                  <template
                    v-for="member in orderedMembers(d).Manager"
                  >
                    <div
                      :key="member.id"
                      class="member"
                    >
                      <div class="vadis-molecule-avatar-small">
                        <p>{{ member.initials }}</p>
                      </div> {{ member.fullName }}
                    </div>
                  </template>
                </td>
                <td :class="`evaluation-${d.currentStatus}`">
                  {{ ts.decision[d.currentStatus] }} <i
                    :class="`icon-${d.currentStatus}`"
                  />
                </td>
                <td>
                  <router-link
                    tag="button"
                    class="vadis-form-button-standard-small"
                    :to="{ name: 'due-diligence-details-screen', params: { id: d.id } }"
                  >
                    {{ ts.buttons.details }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-if="$featureEnabled('dueDiligence')"
        class="content start"
      >
        <div>
          <h6> {{ ts.startDueDiligenceManually }}</h6>
        </div>
        <button
          class="vadis-form-button-main btn-details"
          @click="start()"
        >
          {{ ts.startDueDiligence }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.DueDiligenceList">
{
  "startDueDiligenceManually": "Would you like to start a due diligence manually ?",
  "startDueDiligence": "Start due diligence",
  "th": {
    "computedRisk": "Computed risk",
    "revisedRisk": "Revised risk",
    "analyst": "Analyst",
    "manager": "Manager",
    "decision": "Decision",
    "startAt": "Created at"
  },
  "decision": {
    "Rejected": "Rejected",
    "Approved": "Approved",
    "UnderEvaluation": "Under evaluation"
  },
  "buttons": {
    "details": "Details"
  }
}
</i18n>

<script>
import { Company } from 'flair-client';
import _ from 'lodash';
import { transformRiskScore } from 'flair-client/src/utils';
import Loader from '@/components/Loader';
import StartDueDiligenceModal from '@/components/due-diligence/StartDueDiligenceModal';

export default {
  name: 'DueDiligenceList',
  components: {
    Loader,
    StartDueDiligenceModal,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
    dueDiligences: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    transformRiskScore,
  }),
  methods: {
    orderedMembers(item) {
      const status = ['Analyst', 'Manager'];
      return _.reduce(status, (dd, s) => {
        dd[s] = _.filter(item.members, (i) => {
          return i.attributes.role === s;
        });
        return dd;
      }, {});
    },
    start() {
      this.$refs.startDueDiligenceModal.open(this.company);
    },
  },
};
</script>
