<template>
  <div>
    <div
      v-if="departments.items && departments.items.length === 0"
      class="department-section table-border"
    >
      <div class="department-section-empty">
        <p class="department-section-empty-title">
          {{ ts.createDeptTitle }}
        </p>
        <div class="text-info">
          <p>
            <i class="icon-Submitted" />
            {{ ts.departmentsCanContain }}
          </p>
        </div>
      </div>
      <button
        v-if="!createDep"
        class="vadis-form-button-main"
        @click="createDep = true;"
      >
        {{ ts.button.createDepartment }}
      </button>
      <DepartmentEditForm
        v-else
        @close="createDep = false;"
      />
    </div>
    <div
      v-else
    >
      <Loader
        v-if="departments.loading"
        :global="false"
        :shown="true"
      />
      <div
        v-for="(dep) in departments.items"
        v-else
        :key="dep.id"
        class="department-section table-border"
      >
        <DepartmentAccordion
          :department="dep"
        />
      </div>
      <div class="department-section table-border">
        <button
          v-if="!createDep"
          class="vadis-form-button-standard"
          @click="createDep = true;"
        >
          {{ ts.button.createDepartment }}
        </button>
        <DepartmentEditForm
          v-else
          @close="createDep = false;"
        />
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.DepartmentsPane">
{
  "createDeptTitle": "Start creating a department and invite team managers",
  "departmentsCanContain": "Departments can contain multpiple teams",
  "errorDepartmentNameCannotBeNull": "A department cannot have an empty name",
  "errorDepartmentNameAlreadyExist": "A department with the same name already exists",
  "button": {
    "createDepartment": "Create department",
    "cancel": "Cancel"
  },
  "link": {
    "addLeader": "Add department leader"
  },
  "placeholder": {
    "department": "Department name..."
  },
  "teams": "teams"
}
</i18n>

<script>
import Loader from '@/components/Loader';
import DepartmentAccordion from '@/components/department/DepartmentAccordion';
import DepartmentEditForm from '@/components/department/DepartmentEditForm';

export default {
  name: 'DepartmentsPane',
  components: {
    DepartmentAccordion,
    DepartmentEditForm,
    Loader,
  },
  data: () => ({
    departments: [],
    createDep: false,
  }),
  events: {
    'admin:*:*': (vm) => {
      vm.reload();
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    reload() {
      this.departments = this.$flairClient.AdminService.getDepartments();
    },
  },
};
</script>
