<template>
  <div>
    <SurveyTranslationFormModal
      ref="surveyTranslationFormModal"
      :context="surveyModalContext"
    />
    <Loader
      v-if="surveys.loading"
      :global="false"
      :shown="true"
    />
    <div
      v-for="survey in surveys.items"
      v-else
      :key="survey.id"
      class="survey-section"
    >
      <SurveyAccordion
        :survey="survey"
      />
      <div
        v-if="error"
        class="vadis-organism-tab-empty"
      >
        <img
          src="../../assets/tab_empty.svg"
        >
        <h6>{{ ts.error }}</h6>
      </div>
    </div>
    <div
      class="survey-section"
    >
      <button
        class="vadis-form-button-standard"
        @click="createSurveyTemplate()"
      >
        {{ ts.button.createSurveyTemplate }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.SettingsSurveyPane">
{
  "title": "List of due diligence surveys",
  "error": "You currently have no surveys to view",
  "createSurveyTemplate": "Create survey template",
  "button": {
    "createSurveyTemplate": "Create survey template",
    "saveSurveyTemplate": "Save survey template"
  }
}
</i18n>

<script>
import Loader from '@/components/Loader';
import SurveyAccordion from '@/components/surveys/SurveyAccordion';
import SurveyTranslationFormModal from '@/components/surveys/SurveyTranslationFormModal';

export default {
  name: 'SettingsSurveyPane',
  components: {
    Loader,
    SurveyAccordion,
    SurveyTranslationFormModal,
  },
  data: () => ({
    surveys: [],
    error: false,
  }),
  events: {
    'survey:*:*': (vm) => {
      vm.loadSurveys();
    },
  },
  computed: {
    surveyModalContext() {
      return {
        title: this.ts.createSurveyTemplate,
        button: this.ts.button.saveSurveyTemplate,
        onClick: this.clickCreateSurvey,
      };
    },
  },
  created() {
    this.loadSurveys();
  },
  methods: {
    loadSurveys() {
      this.surveys = this.$flairClient.SurveyService.getSurveys();
      this.surveys.promise
        .catch(err => {
          if (err.response.status === 404) {
            this.error = true;
          } else {
            throw err;
          }
        });
    },
    createSurveyTemplate() {
      this.$refs.surveyTranslationFormModal.open();
    },
    clickCreateSurvey(payload) {
      this.$flairClient.SurveyService.createSurvey(payload)
        .promise.then(() => {
          this.$refs.surveyTranslationFormModal.close();
        });
    },
  },
};
</script>

