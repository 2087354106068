<template>
  <div class="portfolio-list details table-border">
    <table class="table-hover">
      <thead>
        <tr>
          <th class="vadis-controller-column-filter">
            <div class="label">
              {{ ts.th.name }}
              <div
                class="sort-small"
                @click="$emit('sort', 'name')"
              >
                <i :class="{'icon-Arrow-up-small': true, 'active': sortsBy('name')}" />
                <i :class="{'icon-Arrow-down-small': true, 'active': sortsBy('-name')}" />
              </div>
            </div>
          </th>
          <th />
          <th class="no-open vadis-controller-column-filter">
            <div class="label">
              {{ 'global-score' | companyRisks }}
              <div
                class="sort-small"
                @click="$emit('sort', 'global')"
              >
                <i :class="{'icon-Arrow-up-small': true, 'active': sortsBy('global-score')}" />
                <i :class="{'icon-Arrow-down-small': true, 'active': sortsBy('-global-score')}" />
              </div>
            </div>
          </th>
          <template v-for="(c, i) in getColumnLabels()">
            <th
              :key="i"
              class="no-open vadis-controller-column-filter"
            >
              <div class="label">
                {{ c | companyRisks }}
                <div
                  v-if="c !== 'sector'"
                  class="sort-small"
                  @click="$emit('sort', c)"
                >
                  <i :class="{'icon-Arrow-up-small': true, 'active': sortsBy(c)}" />
                  <i :class="{'icon-Arrow-down-small': true, 'active': sortsBy('-' + c)}" />
                </div>
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody
        v-if="monitoredCompanies.loading"
        class="vadis-molecule-loader"
      >
        <tr>
          <td colspan="9">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="(monitoredCompany, i) in monitoredCompanies.items"
          :key="i"
          :set="c = monitoredCompany.company"
          :class="{'current': isCurrent(monitoredCompany)}"
          @click="onClick(monitoredCompany)"
        >
          <td class="name">
            <div class="vadis-molecule-country-portfolio">
              <span><a>{{ c.name }}</a></span>
              <div>
                <CountryFlag
                  :country="c.address.country"
                  :flag-only="true"
                />
                <span>{{ c.europeanVAT }}</span>
              </div>
            </div>
          </td>
          <td>
            <PortfolioButton :subject="c" />
          </td>
          <td class="no-open">
            <div :class="'vadis-controller-tag-risk vadis-controller-tag-global-risk-level' + c.last.riskProfile.score">
              {{ transformRiskScore(c.last.riskProfile.score) }}
            </div>
          </td>
          <td
            v-for="(risk, j) in getColumnLabels()"
            :key="j"
            class="no-open"
          >
            <RiskIndicator
              :risk="findRisk(risk, c.last.riskProfile.subrisks)"
              :small="true"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="monitoredCompanies.loaded && monitoredCompanies.hasPagination()"
      :current-page="monitoredCompanies.meta.pageNumber"
      :total-items="monitoredCompanies.meta.totalItems"
      :number-of-lines="monitoredCompanies.meta.pageSize"
      :links="monitoredCompanies.links"
      @click="onClickPagination($event)"
    />
  </div>
</template>

<i18n namespace="components.PortfolioDetailsList">
{
  "th": {
    "name": "Name"
  }
}
</i18n>

<script>
import _ from 'lodash';
import { transformRiskScore } from 'flair-client/src/utils';
import CountryFlag from '@/components/CountryFlag';
import Loader from '@/components/Loader';
import RiskIndicator from '@/components/risk/RiskIndicator';
import Pagination from '@/components/Pagination';
import PortfolioButton from '@/components/portfolio/PortfolioButton';

export default {
  name: 'PortfolioDetailsList',
  components: {
    Loader,
    CountryFlag,
    RiskIndicator,
    Pagination,
    PortfolioButton,
  },
  props: {
    monitoredCompanies: {
      type: Object,
      default: null,
      required: true,
    },
    current: {
      type: Object,
      default: null,
      required: false,
    },
    sorts: {
      type: Array,
      default: null,
      required: true,
    },
  },
  data: () => ({
    transformRiskScore,
    classEvolution:'vadis-controller-tag-risk-evolution-',
  }),
  methods: {
    getColumnLabels() {
      return _.map(_.get(this.monitoredCompanies, 'items[0].company.last.riskProfile.subrisks'), (r) => r.name);
    },
    onClick(c) {
      this.$emit('click', c.company);
    },
    isCurrent(c) {
      return c.company === this.current;
    },
    sortsBy(column) {
      return this.sorts.find(e => e === column);
    },
    findRisk(risk, subrisks) {
      return _.find(subrisks, (r) => r.name === risk);
    },
    onClickPagination(event) {
      this.monitoredCompanies[event]();
    },
  },
};
</script>
