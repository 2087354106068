<template>
  <div>
    <div
      v-if="portfolio.items.length || !portfolio.loaded"
      class="portfolio-content"
    >
      <SwitcherBar
        :items="MODES"
        :i18n="ts"
        :active-item="currentMode"
        @click="switchMode($event)"
      />
      <OpenablePortfolioList
        ref="openablePortfolioList"
        :monitored-companies="portfolio"
        :component="portfolioComponent"
        :team="team"
        :sorts="sorts"
        :geographical-presence="geographicalPresence"
        @sort="sort($event)"
      />
    </div>
    <PortfolioEmpty v-else />
  </div>
</template>

<i18n namespace="components.PortfolioCompanyPane">
{
  "detailedRisks": "Detailed risks",
  "globalRisk": "Global risk",
  "syntheticView": "Synthetic view",
  "geographical": "Geographical"
}
</i18n>

<script>
import OpenablePortfolioList from '@/components/openable/OpenablePortfolioList';
import PortfolioEmpty from '@/components/portfolio/PortfolioEmpty';
import SwitcherBar from '@/components/SwitcherBar';

const MODES = [
  {
    components: 'PortfolioDetailsList',
    code: 'detailedRisks',
  },
  {
    components: 'PortfolioList',
    code: 'globalRisk',
  },
  {
    components: 'PortfolioStatistics',
    features: 'portfolioStatistics',
    code: 'syntheticView',
  },
  {
    components: 'GeographicalPortfolioRisk',
    code: 'geographical',
  },
];

export default {
  name: 'PortfolioCompanyPane',
  components: {
    PortfolioEmpty,
    OpenablePortfolioList,
    SwitcherBar,
  },
  props: {
    team: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    MODES,
    portfolio: null,
    geographicalPresence: null,
    currentMode: 'detailedRisks',
    portfolioComponent: 'PortfolioDetailsList',
    sorts: [],
  }),
  events: {
    'portfolio:*:*': (vm) => {
      vm.loadMonitoredCompanies(vm.team, vm.sorts);
    },
  },
  watch: {
    team() {
      this.loadMonitoredCompanies(this.team);
    },
  },
  created() {
    this.loadMonitoredCompanies(this.team);
    this.getGeographicalPresence(this.team);
  },
  methods: {
    loadMonitoredCompanies(team, sorting) {
      this.portfolio = this.$flairClient.PortfolioService.getTeamPortfolioSubjects({ id: this.team.id, sort: sorting });
    },
    getGeographicalPresence(team) {
      return this.$flairClient.MyService.getTeamPortfolioGeographicalPresence({ id: this.team.id }).promise
        .then((res) => {
          this.geographicalPresence = res.data.attributes.geographicalPresence;
        });
    },
    switchMode(mode) {
      this.portfolioComponent = mode.components;
    },
    sortLists(label, sortsList) {
      const list = [];
      if (sortsList.find(e => e === label)) {
        list.push(`-${label}`);
      } else if (!sortsList.find(e => e === `-${label}`)) {
        list.push(label);
      }
      return list;
    },
    sort(label) {
      this.sorts = this.sortLists(label, this.sorts);
      this.portfolio.sortBy(this.sorts);
    },
  },
};
</script>
