import AbstractGraph from './abstract.graph';

export default class ProxyGraph extends AbstractGraph {
  #decorated;

  constructor(decorated) {
    super();
    this.#decorated = decorated;
  }

  get centerNodeId() {
    return this.#decorated.centerNodeId;
  }

  get nodes() {
    return this.#decorated.nodes;
  }

  get edges() {
    return this.#decorated.edges;
  }

  // protected

  get decorated() {
    return this.#decorated;
  }

}
