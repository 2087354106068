<template>
  <div>
    <div class="vadis-molecule-chat-action">
      <div class="vadis-molecule-avatar">
        <p>{{ member.initials }}</p>
      </div>
      <div class="content">
        <div class="name">
          <span>{{ member.fullName }}</span> ({{ ts[member.role.toLowerCase()] }})
          {{ ts.setStatus }} <span :class="'status-' + dueDiligence.currentStatus">{{ setStatusTranslation(dueDiligence.currentStatus) }} <i :class="'icon-' + dueDiligence.currentStatus" /></span>
        </div>
        <div class="date">
          {{ event.createdAt | humanDateHour }}
        </div>
      </div>
    </div>
    <div class="vadis-molecule-chat-message">
      <div class="vadis-molecule-avatar">
        <p>{{ member.initials }}</p>
      </div>
      <div
        class="content"
      >
        <div class="name">
          <span class="bold">{{ member.fullName }}</span> ({{ ts[member.role.toLowerCase()] }})
        </div>
        <div class="date">
          {{ event.createdAt | humanDateHour }}
        </div>
        <div
          class="copy"
        >
          {{ event.comment }}
        </div>
        <DueDiligenceDocuments
          :documents="event.documents"
          :due-diligence="dueDiligence"
        />
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.MakeDecisionEvent">
{
  "setStatus": "set the status to",
  "analyst": "Analyst",
  "manager": "Manager",
  "approved": "Approved",
  "rejected": "Rejected"
}
</i18n>

<script>
import DueDiligenceDocuments from '@/components/due-diligence/DueDiligenceDocuments';

export default {
  name: 'MakeDecisionEvent',
  components: {
    DueDiligenceDocuments,
  },
  props: {
    dueDiligence: {
      type: Object,
      default: null,
      required: true,
    },
    member: {
      type: Object,
      default: null,
      required: true,
    },
    event: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {
    setStatusTranslation(status) {
      return this.ts[status.toLowerCase()];
    },
  },
};
</script>
