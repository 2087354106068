export default function (Component) {
      Component.options._i18n = {
        namespace: "views.Configuration",
        translations: 















{
  "title": "Welcome to Flair configuration"
}

      }
    }