<template>
  <div class="vadis-controller-risk-rating">
    <ul>
      <li
        v-for="(v, k) in [1, 2, 3, 4, 5]"
        :key="k"
        :class="[v === value ? `active-${v}` : '', v === transformRiskScore(computedRisk) && !value? `active-${v}` : '', `risk-${v}`]"
        @click="$emit('input', v)"
      >
        <p :class="`vadis-risk-color-level${v}`">
          {{ v }}
        </p>
        <span v-if="v === transformRiskScore(computedRisk) && transformRiskScore(computedRisk) !== value"> {{ ts.computed }}</span>
        <span
          v-if="v === value"
          class="bold"
        > {{ ts.revisedRisk }}</span>
      </li>
    </ul>
  </div>
</template>

<i18n namespace="components.RiskRating">
{
  "computed": "Computed",
  "revisedRisk": "Revised risk"
}
</i18n>

<script>
import { transformRiskScore } from 'flair-client/src/utils';

export default {
  name: 'RiskRating',
  props: {
    computedRisk: {
      type: Number,
      default: null,
      required: true,
    },
    value: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data: () => ({
    transformRiskScore,
  }),
};
</script>
