<template>
  <div v-if="isOpen">
    <div class="vadis-style-global-DialogBg" />
    <div class="vadis-modal-edit-user">
      <Loader
        v-show="isLoading"
        :global="true"
        :shown="true"
      />
      <div class="title">
        <template v-if="role=='TeamManager'">
          <h6 v-if="!user">
            {{ ts.titleAddManager }}
          </h6>
          <h6 v-else>
            {{ ts.titleUpdateManager }}
          </h6>
        </template>
        <template v-else-if="role=='Analyst'">
          <h6 v-if="!user">
            {{ ts.titleAddAnalyst }}
          </h6>
          <h6 v-else>
            {{ ts.titleUpdateAnalyst }}
          </h6>
        </template>
        <p>{{ team.name }}</p>
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>

      <form
        id="user-edit-form"
        class="vadis-organism-user-edit-form"
        novalidate
        @submit.prevent="validate"
      >
        <div
          v-if="errorUserExists"
          class="form-row error-messages error-messages-visible"
        >
          <p>{{ ts.errors.errorUserExists }}</p>
        </div>
        <div :class="{ 'form-row vadis-form-field-standard': true, invalid: $v.form.firstName.$error }">
          <label>
            {{ ts.labels.firstName }}
          </label>
          <input
            id="firstName-input"
            v-model.trim="form.firstName"
            @input="$v.form.firstName.$touch"
          >
          <p
            v-if="$v.form.firstName.$error"
            class="validation-error"
          >
            {{ ts.errors.firstNameRequired }}
          </p>
        </div>
        <div :class="{ 'form-row vadis-form-field-standard': true, invalid: $v.form.lastName.$error }">
          <label>
            {{ ts.labels.lastName }}
          </label>
          <input
            id="lastName-input"
            v-model.trim="form.lastName"
            @input="$v.form.lastName.$touch"
          >
          <p
            v-if="$v.form.lastName.$error"
            class="validation-error"
          >
            {{ ts.errors.lastNameRequired }}
          </p>
        </div>
        <div :class="{ 'form-row vadis-form-field-standard': true, invalid: $v.form.email.$error, 'vadis-form-field-standard-disabled': user && user.lastVisit }">
          <label>
            {{ ts.labels.email }}
          </label>
          <input
            id="email-input"
            v-model.trim="form.email"
            @input="$v.form.email.$touch"
          >
          <p
            v-if="$v.form.email.$error"
            class="validation-error"
          >
            {{ ts.errors.emailRequired }}
          </p>
        </div>
        <div
          v-if="!user"
          class="form-row radio"
        >
          <label>
            <input
              id="email-input"
              v-model.trim="form.invite"
              value="1"
              type="radio"
            >
            {{ ts.labels.inviteNow }}
          </label>

          <label>
            <input
              id="email-input"
              v-model.trim="form.invite"
              value="0"
              type="radio"
            >
            {{ ts.labels.inviteLater }}
          </label>
        </div>
        <div class="form-row buttons">
          <button
            class="vadis-form-button-main"
            type="submit"
          >
            <template v-if="role=='TeamManager'">
              <template v-if="!user">
                {{ ts.buttons.createManager }}
              </template>
              <template v-else>
                {{ ts.buttons.updateManager }}
              </template>
            </template>
            <template v-else-if="role=='Analyst'">
              <template v-if="!user">
                {{ ts.buttons.createAnalyst }}
              </template>
              <template v-else>
                {{ ts.buttons.updateAnalyst }}
              </template>
            </template>
          </button>
          <button
            class="vadis-form-button-standard"
            @click="close()"
          >
            {{ ts.buttons.cancel }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<i18n namespace="components.UserEditModal">
{
  "titleAddManager": "Add team manager",
  "titleUpdateManager": "Update team manager",
  "titleAddAnalyst": "Add analyst",
  "titleUpdateAnalyst": "Update analyst",
  "errors": {
    "firstNameRequired": "A first name is required",
    "lastNameRequired": "A last name is required",
    "emailRequired": "A valid email address is required",
    "errorUserExists":  "A user with this email exists already"
  },
  "buttons": {
    "createManager": "Create manager",
    "createAnalyst": "Create analyst",
    "cancel": "Cancel",
    "updateManager": "Update manager",
    "updateAnalyst": "Update analyst"
  },
  "labels": {
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email",
    "inviteNow": "Invite now",
    "inviteLater": "Invite later"
  }
}
</i18n>

<script>
import { required, email } from 'vuelidate/lib/validators';
import Loader from '@/components/Loader';

export default {
  name: 'UserEditModal',
  components: {
    Loader,
  },
  props: {
    team: {
      type: Object,
      default: null,
      required: true,
    },
    role: {
      type: String,
      default: null,
      required: true,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isOpen: false,
    form: {
      firstName: '',
      lastName: '',
      email: '',
      invite: '1',
    },
    isLoading: false,
    errorUserExists: false,
  }),
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  mounted() {
    if (this.user) {
      this.form.firstName = this.user.firstName;
      this.form.lastName = this.user.lastName;
      this.form.email = this.user.email;
      this.form.invite = (this.user.invited === false) ? '0' : '1';
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.user) {
          this.updateUser();
        } else {
          this.createUser();
        }
      }
    },
    createUser() {
      this.isLoading = true;
      const payload = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        sendInvitation: this.form.invite === '1' ? true : false,
        teams: [{
          id: this.team.id,
          role: this.role,
        }],
      };
      this.$flairClient.AdminService.createUser(payload)
        .promise.then(() => {
          this.isLoading = true;
          this.close();
        }).catch(err => {
          console.error('#failed', err);
          if (err.response.status === 409) {
            this.errorUserExists = true;
            this.isLoading = false;
          } else {
            throw err;
          }
        });
    },
    updateUser() {
      const payload = {
        id: this.user.id,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
      };
      this.$flairClient.AdminService.updateUser(payload)
        .promise.then(() => this.close());
    },
  },
};
</script>
