export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SurveySubmissionEvent",
        translations: 












































{
  "surveyCompleted": "The survey <span class=\"italic\">{q}</span> has been completed.",
  "completedOn": "Completed on",
  "buttons": {
    "details": "Details"
  }
}

      }
    }