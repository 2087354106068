export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SearchBar",
        translations: 























































{
  "placeholders": {
    "searchInput": {
      "Company": "Search a company by name, VAT, …",
      "Person": "Search a person by name, function, date of birth ..."
    }
  },
  "ui": {
    "recent": "Recently searched :"
  },
  "search": {
    "companies": "Companies",
    "people": "People"
  }
}

      }
    }