<template>
  <div class="risk-question">
    <Accordion
      :open="accordionOpen"
    >
      <template v-slot:title>
        <h6>
          {{ question.question }}
        </h6>
      </template>
      <p
        v-if="question.answer"
        class="risk-description"
      >
        {{ question.answer }}
      </p>
      <div
        v-if="question.points"
        class="risk-description"
      >
        <ul
          v-for="(point, i) in question.points"
          :key="i"
          class="risk-list"
        >
          <li>{{ point }}</li>
        </ul>
      </div>
    </Accordion>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion';

export default {
  name: 'RiskQuestionAccordion',
  components: {
    Accordion,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    accordionOpen: false,
  }),
};
</script>

