<template>
  <div class="vadis-controller-tab">
    <ul>
      <template
        v-for="(tab,i) in tabs"
      >
        <li
          :key="i"
          :class="{ active: isActive(tab) }"
        >
          <a
            @click="setActive(tab)"
          > {{ ts[tab.code] || tab.code }} </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<i18n namespace="components.DueDiligenceListTabs">
{
  "myAssignments": "My assignments"
}
</i18n>

<script>

const tabs = [
  { code: 'myAssignments' },
];

export default {
  name: 'DueDiligenceListTabs',
  props: {
    active: {
      type: String,
      default: 'myAssignments',
    },
  },
  data: () => ({
    tabs: tabs,
  }),
  methods: {
    isActive(tab) {
      return this.active === tab.code;
    },
    setActive(tab) {
      this.$emit('click', tab.code);
    },
  },
};
</script>
