<template>
  <div class="person-list table-border">
    <table class="table-hover">
      <thead>
        <tr>
          <th>{{ ts.th.name }}</th>
          <th class="no-open">
            {{ ts.th.function }}
          </th>
          <th class="no-open vadis-controller-column-filter">
            <div class="label">
              {{ ts.th.status }}
              <div
                class="sort-small"
                @click="sort('status')"
              >
                <i :class="{'icon-Arrow-up-small': true, 'active': sortsBy('status')}" />
                <i :class="{'icon-Arrow-down-small': true, 'active': sortsBy('-status')}" />
              </div>
            </div>
          </th>
          <th class="no-open">
            {{ ts.th.dateOfBirth }}
          </th>
          <th class="no-open">
            {{ ts.th.nationality }}
          </th>
          <th class="no-open">
            {{ ts.th.affinities }}
          </th>
          <th class="no-open">
            {{ ts.th.relatedCompanies }}
          </th>
        </tr>
      </thead>
      <tbody
        v-if="people.loading"
        class="vadis-molecule-loader"
      >
        <tr>
          <td colspan="7">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-if="people.loaded">
        <tr
          v-for="(person, i) in people.items"
          :key="i"
          :class="{'current': isCurrent(person)}"
          @click="onClick(person)"
        >
          <td>
            <div class="risk-tag-person">
              <a class="name">{{ person.source.fullName }}</a>
              <RiskTag
                :risks="riskIndicators(person)"
                @click="onClickRisk(person, $event.risk)"
              />
            </div>
          </td>
          <td class="no-open">
            {{ person.function.stdCode | personFunction }}
          </td>
          <td class="no-open">
            {{ ts[person.function.status] }}
          </td>
          <td class="no-open">
            {{ person.source.birthDate | birthDateWithAge }}
          </td>
          <td class="no-open">
            <CountryFlag :country="person.source.nationality" />
          </td>
          <td class="no-open">
            <a class="vadis-molecule-detail-value vadis-molecule-amount">
              -
            </a>
          </td>
          <td class="no-open">
            <a class="vadis-molecule-detail-value vadis-molecule-amount">
              {{ person.source.last.neighborhood.numberOfRelatedCompanies || '-' }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="people.loaded && people.hasPagination()"
      :current-page="people.meta.pageNumber"
      :total-items="people.meta.totalItems"
      :number-of-lines="people.meta.pageSize"
      :links="people.links"
      @click="onClickPagination($event)"
    />
  </div>
</template>

<i18n namespace="components.ManagementList">
{
  "th": {
    "name": "Name",
    "function": "Function",
    "dateOfBirth": "Date of birth",
    "nationality": "Nationality",
    "affinities": "Affinities",
    "relatedCompanies": "Related Companies",
    "status": "Status"
  },
  "Current": "Current",
  "Previous": "Previous"
}
</i18n>

<script>
import Loader from '@/components/Loader';
import { Collection } from 'flair-client';
import RiskTag from '@/components/risk/RiskTag';
import CountryFlag from '@/components/CountryFlag';
import Pagination from '@/components/Pagination';
import _ from 'lodash';

export default {
  name: 'ManagementList',
  components: {
    Loader,
    RiskTag,
    CountryFlag,
    Pagination,
  },
  props: {
    people: {
      type: Collection,
      default: null,
      required: true,
    },
    current: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    sorts: [],
  }),
  methods: {
    onClick(c) {
      this.$emit('click', c);
    },
    isCurrent(c) {
      if (this.current) {
        return c.source === this.current;
      }
    },
    riskIndicators(person) {
      const risks = _.at(person, 'source.last.riskProfile.subrisks')[0];
      return _.filter(risks, (r) => r.score > 0);
    },
    onClickRisk(person, risk) {
      this.$emit('onClickRisk', { person: person.source, risk });
    },
    onClickPagination(event) {
      this.people[event]();
    },
    sortLists(label, sortsList) {
      const list = [];
      if (sortsList.find(e => e === label)) {
        list.push(`-${label}`);
      } else if (!sortsList.find(e => e === `-${label}`)) {
        list.push(label);
      }
      return list;
    },
    sort(label) {
      this.sorts = this.sortLists(label, this.sorts);
      this.people.sortBy(this.sorts);
    },
    sortsBy(column) {
      return this.sorts.find(e => e === column);
    },
  },
};
</script>
