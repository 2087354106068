<template>
  <div
    class="vadis-organism-openable-portfolio-list"
    :class="{'vadis-organism-openable-company-list-open': isOpen}"
  >
    <div class="content content-portfolio">
      <component
        :is="component"
        v-if="component"
        :monitored-companies="monitoredCompanies"
        :current="current"
        :sorts="sorts"
        :is-home-page="isHomePage"
        :team="team"
        :geographical-presence="geographicalPresence"
        :company="currentCompany"
        @click="setCurrent($event)"
        @sort="$emit('sort', $event)"
      />
      <CompanyOverview
        v-if="current"
        :company="currentCompany"
        class="portfolio"
        @close="clearCurrent()"
        @onClickRisk="openRisk($event.company, $event.risk)"
      />
    </div>
  </div>
</template>

<script>
import PortfolioList from '@/components/portfolio/PortfolioList';
import PortfolioDetailsList from '@/components/portfolio/PortfolioDetailsList';
import PortfolioStatistics from '@/components/portfolio/PortfolioStatistics';
import GeographicalPortfolioRisk from '@/components/geographical/GeographicalPortfolioRisk';
import CompanyOverview from '@/components/company/CompanyOverview';
import { Collection } from 'flair-client';

export default {
  name: 'OpenablePortfolioList',
  components: {
    PortfolioList,
    PortfolioDetailsList,
    PortfolioStatistics,
    CompanyOverview,
    GeographicalPortfolioRisk,
  },
  props: {
    monitoredCompanies: {
      type: Collection,
      default: null,
      required: true,
    },
    component: {
      type: String,
      default: null,
    },
    sorts: {
      type: Array,
      default: null,
    },
    team: {
      type: Object,
      default: null,
    },
    geographicalPresence: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    current: undefined,
    currentCompany: undefined,
    isHomePage: false,
  }),
  computed: {
    isOpen() {
      return !!this.current;
    },
  },
  watch: {
    monitoredCompanies() {
      this.clearCurrent();
    },
  },
  methods: {
    clearCurrent() {
      this.current = null;
      this.currentCompany = null;
    },
    setCurrent(company) {
      if (this.$featureEnabled('companyOverview')) {
        const c = this.$flairClient.EntitiesService.getCompanyById({ id: company.id });
        c.promise.then(() => {
          this.current = company;
          this.currentCompany = c;
        });
      } else {
        this.$router.push({
          name: 'company-details-screen',
          params: { id: company.id, tab: 'summary' },
        });
      }
    },
  },
};
</script>
