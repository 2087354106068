<template>
  <div class="vadis-organism-risk-categories">
    <div class="img-container">
      <img :src="`/img/ecovadis/${risk.name}.png`">
    </div>
    <div class="title">
      <h5>{{ ts.risks[risk.name] }}</h5>
    </div>
    <EcoVadisRiskChart
      :risk="risk"
      :unit="unit"
    />
    <EcoVadisWeightIndicator
      :risk="risk"
    />
  </div>
</template>

<i18n namespace="components.EcoVadisCategories">
{
  "risks": {
    "env_score": "Environment",
    "sup_score": "Sustainable procurement",
    "fbp_score": "Ethics",
    "lab_score": "Labor & Human Rights"
  }
}

</i18n>

<script>
import EcoVadisRiskChart from '@/components/ecovadis/EcoVadisRiskChart';
import EcoVadisWeightIndicator from '@/components/ecovadis/EcoVadisWeightIndicator';

export default {
  name: 'EcoVadisRiskCategories',
  components: {
    EcoVadisRiskChart,
    EcoVadisWeightIndicator,
  },
  props: {
    risk: {
      type: Object,
      default: null,
      required: true,
    },
    unit: {
      type: String,
      default: 'score',
    },
  },
};
</script>

