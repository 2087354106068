import _ from 'lodash';

const RADIUS = 20;

const iconByNodeKind = {
  'company': '\ue919',
  'companyCluster': '\ue931',
  'people': '\ue91a',
};

export default (component, path) => {
  const nodeClass = (ctx) => {
    const n = path ? _.get(ctx, path) : ctx;
    if (n.nodeKind === 'companyCluster') {
      return 'node node-cluster';
    } else {
      const risk = component.graph.nodeRiskScore(n, component.shownRiskIndicator);
      let classes = `node node-level-${risk}`;
      if (n.id === component.graph.centerNodeId) {
        classes += ' node-central';
      }
      return classes;
    }
  };

  const nodeIcon = (ctx) => {
    const n = path ? _.get(ctx, path) : ctx;
    return iconByNodeKind[n.nodeKind];
  };

  const nodeName = (ctx) => {
    const n = path ? _.get(ctx, path) : ctx;
    const parts = n.name.split(' ');
    if (parts.length < 3) {
      return n.name;
    }
    return `${parts.splice(0, 2).join(' ')}…`;
  };

  const edgeLabel = (ctx) => {
    const e = path ? _.get(ctx, path) : ctx;
    const participation = e.participation.direct;
    if (participation) {
      return `${participation.toFixed(2)} %`;
    }
    return '';
  };

  const defaultEventHandlers = () => {
    component.node.on('click', (ctx) => {
      const n = path ? _.get(ctx, path) : ctx;
      component.$emit('nodeClick', n);
    });

    component.node.on('dblclick', (ctx) => {
      const n = path ? _.get(ctx, path) : ctx;
      component.$emit('nodeDblClick', n);
    });
  };

  const defaultNodeCRUD = () => {
    const newNode = component.node.enter()
      .append('g');

    newNode.append('circle')
      .attr('r', RADIUS);

    newNode.append('text')
      .text(nodeName)
      .attr('text-anchor', 'middle')
      .attr('y', (n) => {
        if (n === component.graph.centerNodeId) {
          return RADIUS + 25;
        }
        return RADIUS + 15;
      });

    newNode.append('text')
      .attr('class', 'icon')
      .attr('font-family', 'vadis-font24')
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .attr('font-size', (d) => { return `${d.size}em`; })
      .text(nodeIcon);

    return newNode;
  };

  return {
    defaultNodeCRUD,
    defaultEventHandlers,
    nodeClass,
    nodeIcon,
    nodeName,
    edgeLabel,
  };

};

