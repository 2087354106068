<template>
  <div class="slider-container">
    <input
      v-model="sliderValue"
      class="slider"
      type="range"
      min="1"
      max="99"
      :style="{backgroundSize: backgroundSize}"
      @input="updateSlider()"
    >
  </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    value: {
      validator: () => true,
      required: true,
    },
  },
  data: () => ({
    backgroundSize: '20% 100%',
    sliderValue: null,
  }),
  created() {
    this.backgroundSize = `${this.value}% 100%`;
    this.sliderValue = this.value;
  },
  methods: {
    updateSlider() {
      this.backgroundSize = `${this.sliderValue}% 100%`;
      this.$emit('input', parseInt(this.sliderValue));
    },
  },
};
</script>
