<template>
  <highcharts
    :options="chartOptions()"
    class="chart-web-risk"
  />
</template>

<!-- TODO: are those not the same as the general risk TS? -->
<i18n namespace="components.RiskWebChart">
{
  "subrisks": {
    "financial": "Financial",
    "sector": "Sector",
    "geographical": "Geographical",
    "adverse_media": "Adverse media",
    "pep": "PEP",
    "sanction": "Sanctions"
  }
}
</i18n>

<script>
import { Company } from 'flair-client';
import _ from 'lodash';
import { Chart } from 'highcharts-vue';

export default {
  name: 'RiskWebChart',
  components: {
    highcharts: Chart,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
  methods: {
    chartOptions: function() {
      const data = this.company.getSubrisksHistory();
      return {
        chart: {
          polar: true,
          type: 'line',
        },

        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },

        pane: {
          size: '80%',
        },

        xAxis: {
          categories: _.map(this.ts.subrisks, (n) => n),
          tickmarkPlacement: 'on',
          lineWidth: 0,
        },

        yAxis: {
          gridLineInterpolation: 'polygon',
          tickPositions: [0, 1, 2, 3, 4, 5],
          lineWidth: 0,
          min: 0,
        },

        tooltip: {
          enabled: false,
        },

        legend: {
          enabled: true,
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'vertical',
          itemStyle: {
            'cursor': 'default',
            'color': '#333333',
          },
        },

        series: data.map(year => {
          return {
            name: year.year,
            data: _.map(this.ts.subrisks, (name, id) => {
              if (year.subrisks[id] % 2 === 1) {
                return Math.floor(year.subrisks[id] / 2) + 1;
              } else {
                return Math.floor(year.subrisks[id] / 2);
              }

            }),
            pointPlacement: 'on',
          };
        }),
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                enabled: false,
              },
            },
            marker: {
              enabled: false,
            },
            events: {
              legendItemClick: function() {
                return false;
              },
            },
          },
        },
      };
    },
  },
};
</script>
