<template>
  <span>
    <!-- When full button -->
    <button
      v-if="button"
      :class="buttonClass"
      @click.stop="togglePortfolio()"
    >
      <i
        :class="iconCssClass"
      />
      <span>{{ label }}</span>
    </button>
    <!-- Only star icon-->
    <div
      v-else
      class="icon-Portfolio"
    >
      <span class="vadis-molecule-tooltip">
        <span class="tooltiptext">
          {{ tooltip }}
        </span>
      </span>
      <span
        :class="iconCssClass"
        @click.stop="togglePortfolio()"
      />
    </div>
  </span>
</template>

<i18n namespace="components.PortfolioButton">
{
  "remove": "Remove {q} from portfolio?",
  "addToPortfolio": "Add to portfolio",
  "removeFromPortfolio": "Remove from portfolio",
  "removeTooltip": "Remove from portfolio",
  "addTooltip": "Add to portfolio"
}
</i18n>

<script>
import { Company } from 'flair-client';

export default {
  name: 'PortfolioButton',
  props: {
    subject: {
      type: Company,
      default: () => {},
      required: false,
    },
    button: {
      type: Boolean,
      default: false,
      required: false,
    },
    buttonClass: {
      type: String,
      default: 'vadis-form-button-main',
      required: false,
    },
    text: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      isInPortfolio: false,
    };
  },
  computed: {
    iconCssClass() {
      return this.isInPortfolio ? 'icon-Star-shape' : 'icon-Star-line';
    },
    label() {
      if (this.text) {
        return this.text;
      }
      return this.isInPortfolio ? this.ts.removeFromPortfolio : this.ts.addToPortfolio;
    },
    tooltip() {
      return this.isInPortfolio ? this.ts.removeTooltip : this.ts.addTooltip;
    },
  },
  watch: {
    subject(subject) {
      if (subject) {
        this.isInPortfolio = this.subject.isInMyPortfolio();
      }
    },
  },
  created() {
    if (this.subject) {
      this.isInPortfolio = this.subject.isInMyPortfolio();
    }
  },
  events: {
    'portfolio:subject:removed': (vm, subject) => {
      if (subject.subjectId === vm.subject.id) {
        vm.isInPortfolio = false;
      }
    },
    'portfolio:subject:added': (vm, subject) => {
      if (subject.id === vm.subject.id) {
        vm.isInPortfolio = true;
      }
    },
  },
  methods: {
    togglePortfolio() {
      if (this.isInPortfolio) {
        this.removeFromPortfolio();
      } else {
        const teamId = this.$flairClient.AuthenticationService.me.teams[0].id;
        this.$flairClient.PortfolioService.addTeamPortfolioSubject(teamId, this.subject);
      }
    },
    removeFromPortfolio() {
      this.openConfirmationModal({
        title: this.ts.remove({ q: this.subject.name }),
        onConfirm: (modal) => {
          const teamId = this.$flairClient.AuthenticationService.me.teams[0].id;
          this.$flairClient.PortfolioService.deleteTeamPortfolioSubject({ id: teamId, subjectId: this.subject.id })
            .then(() => modal.close());
        },
      });
    },
  },
};
</script>
