export default function (Component) {
      Component.options._i18n = {
        namespace: "views.ConfigurationPane",
        translations: 


















































































{
  "departmentsTeamsUsers": "Departments, teams & users",
  "subscription": "Subscription information",
  "monitoredCompanies": "Monitored companies",
  "ongoingDueDiligences": "Ongoing due diligence",
  "info": {
    "departments": "Departments",
    "teams": "Teams",
    "users": "Users",
    "plan": "Plan",
    "nextMonth": "Next month"
  },
  "button": {
    "manageSubscription": "Manage subscription",
    "ManageAccess": "Manage access"
  }
}

      }
    }