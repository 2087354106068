<template>
  <div>
    <StartDueDiligenceModal
      v-if="$featureEnabled('dueDiligence')"
      ref="startDueDiligenceModal"
    />
    <RiskProfileModal
      v-if="indicators"
      ref="riskProfileModal"
      :providers="providers"
      :indicators="indicators"
    />
    <div class="vadis-organism-company-summary">
      <div class="column column-left">
        <div
          v-if="riskIndicators"
          class="risk-profile box"
        >
          <div class="vadis-molecule-detail-item">
            <h5>
              {{ ts.indicators }}
            </h5>
            <i
              v-if="$featureEnabled('userManagement')"
              class="icon-Settings"
              @click="openRiskProfileModal()"
            />
          </div>
          <div class="vadis-molecule-detail-item-indicators">
            <div
              class="indicators"
              :class="{active: hover}"
            >
              <div
                class="global-risk"
              >
                <p>{{ ts.globalRisk }}</p>
                <p :class="`vadis-risk-color-level${transformRiskScore(globalRisk)}`">
                  {{ transformRiskScore(globalRisk) }}
                </p>
              </div>
              <RiskIndicator
                v-for="indicator in riskIndicators"
                :key="indicator.id"
                :risk="indicator"
                @over="hover=true;"
                @leave="hover=false;"
                @click="openRisk(company, $event.risk)"
              />
            </div>
            <div class="background-indicators" />
          </div>
        </div>
        <div class="column">
          <ItemDetails
            v-for="(items, title) in detailGroupsLeft"
            :key="title"
            class="box"
            :title="title"
            :items="items"
            :i18n="ts"
            :context="company"
            :title-class="'vadis-molecule-detail-item'"
            :item-class="'vadis-molecule-detail-item'"
            :label-class="'vadis-molecule-detail-label'"
            :value-class="'vadis-molecule-detail-value'"
            :router-link="{name: 'company-details-screen', params: { id: company.id }}"
          />
        </div>
        <div class="column">
          <ItemDetails
            v-for="(items, title) in detailGroupsRight"
            :key="title"
            class="box"
            :title="title"
            :items="items"
            :i18n="ts"
            :context="company"
            :title-class="'vadis-molecule-detail-item'"
            :item-class="'vadis-molecule-detail-item'"
            :label-class="'vadis-molecule-detail-label'"
            :value-class="'vadis-molecule-detail-value'"
            :router-link="{name: 'company-details-screen', params: { id: company.id }}"
          />
          <div class="box">
            <div class="vadis-molecule-detail-item">
              <h5>
                {{ ts.headquarters }}
              </h5>
            </div>
            <div
              v-if="geocoords"
              class="vadis-molecule-detail-item vadis-molecule-detail-map"
            >
              <LeafletMap
                :geocoords="geocoords"
                :tooltip="mapTooltip"
              />
            </div>
            <div class="vadis-molecule-detail-item vadis-molecule-detail-address">
              <CompanyAddress
                :address="companyAddress"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="column column-right"
      >
        <div
          v-if="company && $featureEnabled('portfolio')"
          class="box"
        >
          <h6> {{ isInPortfolio ? ts.inPortfolio : ts.notInPortfolio }}</h6>
          <PortfolioButton
            :subject="company"
            :button="true"
          />
        </div>
        <Loader
          v-if="lastDueDiligenceLoading"
          :global="false"
          :shown="true"
        />
        <template
          v-else
        >
          <DueDiligenceStatusBox
            v-if="lastDueDiligence"
            :title="ts.statusInformation"
            :due-diligence="lastDueDiligence"
            :display-link-to-self="true"
            :status-decision-button="false"
            :invite-link="false"
            :company="company"
          />
          <div
            v-else
            class="box"
          >
            <h6>{{ ts.noDueDiligence }}</h6>
            <button
              v-if="$featureEnabled('dueDiligence')"
              class="vadis-form-button-main"
              @click="startDD()"
            >
              {{ ts.startDueDiligence }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.CompanySummaryPane">
{
  "labels": {
    "size": "Size",
    "type": "Type",
    "legalForm": "Legal form",
    "country": "Country",
    "activity": "Activity",
    "europeanVAT": "VAT",
    "siren": "Siren",
    "group": "Group",
    "yearOfIncorporation": "Established in",
    "operatingRevenue": "Last turnover",
    "numberOfEmployees": "Number of employees",
    "ultimateBeneficiaryOwner": "Ultimate beneficiary owner",
    "numberOfShareholderCompanies": "Number of shareholders",
    "numberOfSubsidiaryCompanies": "Number of subsidiaries",
    "numberOfRelatedPeople": "Number of managers",
    "address": "Address",
    "year": "Year"
  },
  "keyfinancial": "Key financial",
  "keyinformation": "Key information",
  "shareholders": "Shareholders & subsidiaries",
  "headquarters": "Headquarters",
  "indicators": "Risk profile",
  "globalRisk": "Global risk",
  "inPortfolio": "This company is in a portfolio",
  "notInPortfolio": "This company is not in a portfolio",
  "noDueDiligence": "No due diligence has been executed, yet.",
  "startDueDiligence": "Start due diligence",
  "statusInformation": "Last due diligence"
}
</i18n>

<script>
import _ from 'lodash';
import { Company } from 'flair-client';
import RiskIndicator from '@/components/risk/RiskIndicator';
import CompanyAddress from '@/components/company/CompanyAddress';
import LeafletMap from '@/components/LeafletMap';
import GoogleSearchLink from '@/components/GoogleSearchLink';
import Loader from '@/components/Loader';
import CountryFlag from '@/components/CountryFlag';
import ItemDetails from '@/components/ItemDetails';
import PortfolioButton from '@/components/portfolio/PortfolioButton';
import StartDueDiligenceModal from '@/components/due-diligence/StartDueDiligenceModal';
import RiskProfileModal from '@/components/custom-risk-indicator/RiskProfileModal';
import DueDiligenceStatusBox from '@/components/due-diligence/DueDiligenceStatusBox';
import { transformRiskScore } from 'flair-client/src/utils';

const detailGroupsLeft = {
  keyinformation: [{
    label: 'labels.country',
    component: CountryFlag,
    componentProps: {
      country: (c) => c ? _.get(c, 'address.country') : null,
    },
  }, {
    label: 'labels.europeanVAT',
    path: 'europeanVAT',
    filter: 'unknown',
  }, {
    label: 'labels.siren',
    path: 'siren',
    filter: 'unknown',
  }, {
    label: 'labels.legalForm',
    path: 'legalForm',
    filter: 'unknown',
  }, {
    label: 'labels.type',
    path: 'companyType',
    filter: 'companyType',
  }, {
    label: 'labels.size',
    path: 'size',
    filter: 'companySize',
  }, {
    label: 'labels.yearOfIncorporation',
    path: 'establishment.date',
    filter: 'yearOfPseudoDate',
  }, {
    label: 'labels.activity',
    path: 'activity.nace2Code',
    filter: 'nace2code',
  }, {
    component: GoogleSearchLink,
    componentProps: {
      q: (c) => _.get(c, 'name'),
      company: (c) => _.get(c, 'name'),
    },
  }],
  keyfinancial: [{
    label: 'labels.year',
    path: 'last.financial.year',
    filter: 'unknown',
  }, {
    label: 'labels.operatingRevenue',
    path: 'last.financial.turnover',
    filter: 'keuros',
    clickable: 'financial',
  }, {
    label: 'labels.numberOfEmployees',
    path: 'last.financial.numberOfEmployees',
    filter: 'unknown',
    clickable: 'financial',
    valueClass: 'vadis-molecule-amount-link',
  }],
};

const detailGroupsRight = {
  shareholders: [{
    label: 'labels.numberOfShareholderCompanies',
    path: 'last.neighborhood.numberOfShareholderCompanies',
    clickable: 'shareholders',
    filter: 'unknown',
    valueClass: 'vadis-molecule-amount-link',
  }, {
    label: 'labels.numberOfSubsidiaryCompanies',
    path: 'last.neighborhood.numberOfSubsidiaryCompanies',
    clickable: 'subsidiaries',
    filter: 'unknown',
    valueClass: 'vadis-molecule-amount-link',
  }, {
    label: 'labels.numberOfRelatedPeople',
    path: 'last.neighborhood.numberOfRelatedPeople',
    clickable: 'management',
    filter: 'unknown',
    valueClass: 'vadis-molecule-amount-link',
  }, {
    label: 'labels.group',
    path: 'primaryGroup.name',
    filter: 'unknown',
  }],
};

export default {
  name: 'CompanySummaryPane',
  components: {
    RiskIndicator,
    CompanyAddress,
    LeafletMap,
    ItemDetails,
    PortfolioButton,
    StartDueDiligenceModal,
    DueDiligenceStatusBox,
    RiskProfileModal,
    Loader,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    detailGroupsLeft,
    detailGroupsRight,
    hover: false,
    lastDueDiligence: null,
    lastDueDiligenceLoading: false,
    isInPortfolio: null,
    indicators: null,
    providers: null,
  }),
  computed: {
    globalRisk() {
      return _.get(this.company, 'last.riskProfile.score');
    },
    riskIndicators() {
      return _.get(this.company, 'last.riskProfile.subrisks');
    },
    companyAddress() {
      return _.get(this.company, 'address');
    },
    companyName() {
      return _.get(this.company, 'name');
    },
    geocoords() {
      return _.get(this.company, 'address.geocoords');
    },
    mapTooltip() {
      const { street, zip, city } = this.companyAddress;
      let tooltip = '';
      if (street) {
        tooltip = street;
      }
      if (zip || city) {
        tooltip += `<br/>${zip ? `${zip} ` : ''} ${city}`;
      }
      return tooltip;
    },
  },
  created() {
    this.getLastDueDiligence();
    if (this.$featureEnabled('userManagement')) {
      this.getIndicators();
      this.getAllDataProviders();
    }
    if (this.company) {
      this.isInPortfolio = this.company.isInMyPortfolio();
    }
  },
  events: {
    'portfolio:subject:removed': (vm, subject) => {
      if (subject.subjectId === vm.company.id) {
        vm.isInPortfolio = false;
      }
    },
    'portfolio:subject:added': (vm, subject) => {
      if (subject.id === vm.company.id) {
        vm.isInPortfolio = true;
      }
    },
    'dataprovider:riskIndicators:updated': (vm) => {
      vm.getIndicators();
    },
  },
  methods: {
    transformRiskScore,
    attr(path) {
      return _.get(this.company, path);
    },
    dynamicComponentProperties(properties, company) {
      const props = _.reduce(properties, (props, propVal, propName) => {
        let value = propVal;
        if (_.isFunction(propVal)) {
          value = propVal(company);
        }
        props[propName] = value;
        return props;
      }, {});
      return props;
    },
    getLastDueDiligence() {
      this.lastDueDiligenceLoading = true;
      this.$flairClient.DueDiligenceService.getCompanyLastDueDiligence({ id: this.company.id }, false)
        .promise
        .then((dd) => {
          this.lastDueDiligenceLoading = false;
          this.lastDueDiligence = dd;
        })
        .catch(() => {
          // no last duedi for this company
          this.lastDueDiligenceLoading = false;
          this.lastDueDiligence = null;
        });
    },
    getIndicators() {
      this.$flairClient.DataProviderService.getEnabledIndicators().promise.then(
        indicators => this.indicators = indicators);
    },
    getAllDataProviders() {
      this.$flairClient.DataProviderService.getDataProviders().promise
        .then(providers => this.providers = providers);
    },
    startDD() {
      this.$refs.startDueDiligenceModal.open(this.company);
    },
    openRiskProfileModal() {
      this.$refs.riskProfileModal.open(this.company);
    },
  },
};
</script>
