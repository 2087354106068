<template>
  <div class="switcher">
    <div
      class="score-switcher"
      @click="toggleSwitcher()"
    >
      <p
        :class="{ active: value === leftValue }"
        class="value"
      >
        {{ leftLabel }}
      </p>
      <div
        class="switch-handle"
      >
        <div
          class="switch-BG"
          :style="indicatorStyles"
        />
      </div>
      <p
        :class="{ active: value === rightValue }"
        class="value"
      >
        {{ rightLabel }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CRIViewSwitcher',
  props: {
    value: {
      default: null,
      validator: () => true,
    },
    leftLabel: {
      type: String,
      required: true,
    },
    rightLabel: {
      type: String,
      required: true,
    },
    leftValue: {
      required: true,
      validator: () => true,
    },
    rightValue: {
      required: true,
      validator: () => true,
    },
  },
  computed: {
    indicatorStyles() {
      return { transform: this.value === this.leftValue ? 'translateX(0)' : 'translateX(25px)' };
    },
  },
  created() {
    if (this.value === null) {
      this.changeValue(this.leftValue);
    }
  },
  methods: {
    changeValue(val) {
      this.$emit('input', val);
    },
    toggleSwitcher() {
      if (this.value === this.leftValue) {
        this.changeValue(this.rightValue);
      } else {
        this.changeValue(this.leftValue);
      }
    },
  },
};
</script>
