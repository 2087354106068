<template>
  <div class="financial-list table-border">
    <table class="table-hover">
      <thead>
        <tr>
          <th />
          <template
            v-for="(year, i) in years"
          >
            <th
              v-if="company.hasFinancialAttribut(i)"
              :key="i"
            >
              {{ year }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody
        v-if="company.loading"
        class="vadis-molecule-loader"
      >
        <tr>
          <td :colspan="years.length + 1">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-if="company.loaded">
        <tr
          v-for="field in fields"
          :key="field.code"
        >
          <td>{{ ts[field.code] }}</td>
          <template v-for="(year, i) in years">
            <td
              v-if="company.hasFinancialAttribut(i)"
              :key="i"
            >
              <p v-if="field.code === 'consolidatedAccounts'">
                {{ hasConsolidateAccounts(i, field.code) }}
              </p>
              <p v-else>
                {{ applyFilter(field.filter, company.getFinancialField(i, field.code)) }}
              </p>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<i18n namespace="components.FinancialList">
{
  "latestAccount": "Latest account",
  "turnover": "Turnover",
  "ebitda": "EBITDA",
  "workingCapital": "Working capital",
  "shareholderCapital": "Shareholder capital",
  "shareholderFunds": "Shareholder funds",
  "consolidatedAccounts": "Consolidated accounts",
  "numberOfEmployees": "Number of employees",
  "yes": "Yes",
  "no": "No"
}
</i18n>

<script>
import { Company } from 'flair-client';
import Loader from '@/components/Loader';

export default {
  name: 'FinancialList',
  components: {
    Loader,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
    score: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    fields: [
      { code: 'turnover', filter: 'keuros' },
      { code: 'ebitda', filter: 'keuros' },
      { code: 'workingCapital', filter: 'keuros' },
      { code: 'shareholderCapital', filter: 'keuros' },
      { code: 'shareholderFunds', filter: 'keuros' },
      { code: 'consolidatedAccounts' },
      { code: 'numberOfEmployees', filter: 'unknown' },
    ],
  }),
  computed: {
    years() {
      return this.company && this.company.history.map(h => h.year);
    },
  },
  methods: {
    hasConsolidateAccounts(i, value) {
      const r = this.company.getFinancialField(i, value);
      if (r) {
        return this.ts.yes;
      } else if (r === false) {
        return this.ts.no;
      } else {
        return '-';
      }
    },
    applyFilter(filter, value) {
      return this.$options.filters[filter](value);
    },
  },
};
</script>
