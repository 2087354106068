export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligenceDetailsList",
        translations: 






















































































































































{
  "underInvestigation": "Under investigation",
  "closedDueDiligences": "Closed due diligence",
  "th": {
    "companyName": "Company",
    "createdAt": "Created at",
    "computedRisk": "Computed risk",
    "revisedRisk": "Revised risk",
    "analyst": "Analyst",
    "manager": "Manager",
    "decision": "Decision"
  },
  "decision": {
    "Rejected": "Rejected",
    "Approved": "Approved",
    "UnderEvaluation": "Under Evaluation"
  },
  "buttons": {
    "details": "Details"
  }
}

      }
    }