export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CustomRiskCard",
        translations: 

























































{
  "combination": "Combination of {a} indicators",
  "buttons": {
    "edit": "Edit",
    "delete": "Delete",
    "confirmRemoval": "Delete custom indicator"
  },
  "remove": "Remove '{q}'?",
  "removeIndicatorSubtitle": "Are you sure you want to delete the custom indicator {x}?",
  "removeIndicatorNotice": "This change could impact your overall portofolio scores."

}

      }
    }