<template>
  <div
    class="vadis-organism-openable-person-list"
    :class="{'vadis-organism-openable-person-list-open': isOpen}"
  >
    <MatchingPersonModal
      ref="openMatching"
      class="content"
    />
    <div class="content">
      <CompanyEmptyPane v-if="isEmpty" />
      <ManagementList
        v-else
        :people="people"
        :current="current"
        @click="setCurrent($event)"
        @onClickRisk="openRisk($event.person, $event.risk)"
      />
      <PersonOverview
        v-if="current && openOverview"
        :person="person"
        :companies="companies"
        @close="clearCurrent()"
        @onClickRisk="openRisk($event.person, $event.risk)"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ManagementList from '@/components/ManagementList';
import PersonOverview from '@/components/person/PersonOverview';
import CompanyEmptyPane from '@/components/company/CompanyEmptyPane';
import MatchingPersonModal from '@/components/MatchingPersonModal';
import { Collection } from 'flair-client';

export default {
  name: 'OpenableManagementList',
  components: {
    ManagementList,
    PersonOverview,
    CompanyEmptyPane,
    MatchingPersonModal,
  },
  props: {
    people: {
      type: Collection,
      default: null,
      required: true,
    },
  },
  data: () => ({
    current: undefined,
    companies: undefined,
    person: undefined,
    openOverview: false,
  }),
  computed: {
    isOpen() {
      return !!this.current && !!this.openOverview;
    },
    isEmpty() {
      return this.people.data && this.people.data.length === 0;
    },
  },
  watch: {
    people() {
      this.clearCurrent();
    },
  },
  methods: {
    clearCurrent() {
      this.current = null;
      this.person = null;
      this.openOverview = false;
    },
    setCurrent(link) {
      this.current = link.source;
      if (this.$featureEnabled('matching')) {
        this.openOverview = false;
        this.$refs.openMatching.open({
          person: this.current,
          onAssociate: (person, modal) => {
            this.openOverviewPerson(person);
            modal.close();
          },
        });
      } else {
        this.openOverviewPerson(this.current);
      }
    },
    openOverviewPerson(person) {
      this.person = person;
      this.openOverview = true;
      const p = this.$flairClient.EntitiesService.getPersonRelatedCompanies(person);
      p.promise.then(() => {
        this.$flairClient.MyService.addRecentlyBrowsedPeople(person);
        this.companies = p;
      });
    },
  },
};
</script>
