<template>
  <div
    v-click-outside="clickOutside"
    class="vadis-controller-languages"
  >
    <a
      href="#"
      @click="toggleMenu()"
    >
      {{ currentLang() }}
    </a>
    <div>
      <ul v-if="menuOpen">
        <li
          v-for="(name, code) in locales"
          :key="code"
          :class="{active: isCurrentLang(code)}"
          @click="switchLang(code)"
        >
          <a>{{ name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ClickOutside } from '@/directives';

export default {
  name: 'LangSwitcher',
  directives: {
    ClickOutside,
  },
  data: () => {
    return {
      menuOpen: false,
    };
  },
  computed: {
    locales() {
      return this.$flairClient.ts('locales');
    },
  },
  methods: {
    currentLang() {
      return this.locales[this.$flairClient.I18NService.locale];
    },
    clickOutside() {
      this.menuOpen = false;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    switchLang(code) {
      this.$flairClient.I18NService.locale = code;
      this.menuOpen = false;
    },
    isCurrentLang(code) {
      return this.$flairClient.I18NService.locale === code;
    },
  },
};
</script>
