import _ from 'lodash';
import DecoratedPromise from './decorated.promise';
import { Patterns } from './';
import GraphLib from 'graphlib';

export default class AbstractGraph {

  // public

  get nodes() {
    throw new Error('Not implemented: nodes');
  }

  get edges() {
    throw new Error('Not implemented: edges');
  }

  get centerNode() {
    return this.nodes.find((n) => n.id === this.centerNodeId);
  }

  get centerNodeId() {
    throw new Error('Not implemented: centerNodeId');
  }

  // protected

  get graphlib() {
    const g = new GraphLib.Graph();
    this.edges.forEach((e) => g.setEdge(e.source.id, e.target.id, e));
    this.nodes.forEach((n) => g.setNode(n.id, n));
    return g;
  }

  decorate(g) {
    return DecoratedPromise.resolve(g);
  }

  remove(transformed) {
    throw new Error('Not implemented: remove');
  }

  // high level constructions

  async transform(transformation, ...args) {
    return transformation.apply(this, ...args);
  }

  // low-level algebra

  withCenterNodeId(nodeId) {
    return this.decorate(new Patterns.RecenteredGraph(this, nodeId));
  }

  collapseAll() {
    return this.decorate(new Patterns.CollapsedGraph(this));
  }

  keepConnexComponent() {
    return this.decorate(new Patterns.OneconnexGraph(this));
  }

  dropEdges(filter) {
    return this.decorate(new Patterns.DropEdgesGraph(this, filter));
  }

  reverseEdges() {
    return this.decorate(new Patterns.ReverseEdgesGraph(this));
  }

  cluster() {
    return this.decorate(new Patterns.ClusteredGraph(this));
  }

  //

  nodeRiskScore(n, kind = 'financial') {
    const scores = _.at(n, 'last.riskProfile.attributes.subrisks')[0] || [];
    const indicator = scores.find((s) => s.attributes.name === kind) || {};
    return indicator.attributes.score;
  }

  //

  toTree() {
    return this.decorate(new Patterns.TreeGraph(this));
  }

}
