export default function (Component) {
      Component.options._i18n = {
        namespace: "components.ReviseRiskModal",
        translations: 





















{
  "reviseRisk": "Revise risk"
}

      }
    }