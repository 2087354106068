export default function (Component) {
      Component.options._i18n = {
        namespace: "components.MembersList",
        translations: 
























{
  "analyst": "Analyst",
  "manager": "Manager",
  "complianceofficer": "Compliance Officer"
}

      }
    }