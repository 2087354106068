import ProxyGraph from './proxy.graph';
import GraphLib from 'graphlib';

export default class OneconnexGraph extends ProxyGraph {
  #keptNodesIndex;
  #keptEdges;

  constructor(decorated) {
    super(decorated);
  }

  get nodes() {
    return Object.values(this.keptNodesIndex);
  }

  get edges() {
    if (!this.#keptEdges) {
      this.#keptEdges = this.decorated.edges.filter((e) => {
        return this.hasNode(e.source.id) && this.hasNode(e.target.id);
      });
    }
    return this.#keptEdges;
  }

  get keptNodesIndex() {
    if (!this.#keptNodesIndex) {
      const graphlib = this.decorated.graphlib;
      const center = this.decorated.centerNodeId;
      this.#keptNodesIndex = GraphLib.alg.components(graphlib)
        .find((comp) => {
          return comp.indexOf(center) >= 0;
        })
        .reduce((nodes, n) => {
          nodes[n] = graphlib.node(n);
          return nodes;
        }, {});
    }
    return this.#keptNodesIndex;
  }

  hasNode(nodeId) {
    return !!this.keptNodesIndex[nodeId];
  }

  remove(transformed) {
    if (this === transformed) {
      return this.decorated;
    } else {
      return new OneconnexGraph(this.decorated.remove(transformed));
    }
  }

}
