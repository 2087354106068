export default function (Component) {
      Component.options._i18n = {
        namespace: "components.UserEditModal",
        translations: 























































































































































{
  "titleAddManager": "Add team manager",
  "titleUpdateManager": "Update team manager",
  "titleAddAnalyst": "Add analyst",
  "titleUpdateAnalyst": "Update analyst",
  "errors": {
    "firstNameRequired": "A first name is required",
    "lastNameRequired": "A last name is required",
    "emailRequired": "A valid email address is required",
    "errorUserExists":  "A user with this email exists already"
  },
  "buttons": {
    "createManager": "Create manager",
    "createAnalyst": "Create analyst",
    "cancel": "Cancel",
    "updateManager": "Update manager",
    "updateAnalyst": "Update analyst"
  },
  "labels": {
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email",
    "inviteNow": "Invite now",
    "inviteLater": "Invite later"
  }
}

      }
    }