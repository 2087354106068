export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RequestInfoEvent",
        translations: 










































{
  "analyst": "Analyst",
  "manager": "Manager",
  "hasSentSurvey": "has sent a company survey to"
}

      }
    }