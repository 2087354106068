<template>
  <form
    class="custom-risk-form"
  >
    <div class="form-row vadis-form-field-standard form-description">
      <p class="label">
        {{ ts.indicatorName }}
      </p>
      <input
        v-model="customRiskIndicator.name"
        :placeholder="ts.placeholders.customIndicator"
      >
      <p
        v-if="conflictualName"
        class="name-error"
      >
        {{ ts.nameError }}
      </p>
    </div>
    <div class="form-row vadis-form-field-textarea">
      <p class="label">
        {{ ts.indicatorDescription }}
      </p>
      <textarea
        v-model="customRiskIndicator.description"
        class="description"
        :placeholder="ts.placeholders.description"
      />
    </div>
  </form>
</template>

<i18n namespace="components.CRIDescription">
{
  "indicatorName": "Name your custom indicator",
  "indicatorDescription": "Add a short description to your custom indicator (facultative)",
  "display": "Display as",
  "nameError": "Sorry this name has already been used, please choose another one before proceeding.",
  "placeholders": {
    "customIndicator": "My custom indicator",
    "description": "Short description of what 'My custom indicator' is"
  },
  "switcher": {
    "number": "Number",
    "meter": "Meter"
  }
}
</i18n>

<script>
import { CustomRiskIndicator } from 'flair-client';
import MasterViewImage from '@/assets/master-view.png';
import NumberViewImage from '@/assets/number-view.png';

export default {
  name: 'CRIDescription',
  props: {
    value: {
      type: Object,
      required: true,
    },
    originalRiskIndicator: {
      type: CustomRiskIndicator,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      conflictualName: false,
    };
  },
  computed: {
    displayAsImage() {
      return this.customRiskIndicator.displayAsNumber ? NumberViewImage : MasterViewImage;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.customRiskIndicator = value;
      },
      immediate: true,
    },
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    // Todo improve validation
    async isValid() {
      if (this.originalRiskIndicator && this.originalRiskIndicator.name === this.customRiskIndicator.name) {
        return true;
      }

      if (this.customRiskIndicator.name.trim() === '') {
        this.conflictualName = false;
        return false;
      }
      this.conflictualName = await this.$flairClient.DataProviderService.isIndicatorNameTaken(this.customRiskIndicator.name);
      return !this.conflictualName;
    },
  },
};
</script>
