<template>
  <div class="vadis-molecule-chat-message">
    <div class="vadis-molecule-avatar">
      <p>{{ member.initials }}</p>
    </div>
    <div
      class="content"
    >
      <div class="name">
        <span class="bold">{{ member.fullName }}</span> ({{ ts[member.role.toLowerCase()] }})
      </div>
      <div class="date">
        {{ event.createdAt | humanDateHour }}
      </div>
      <div
        class="copy"
      >
        {{ event.comment }}
      </div>
      <DueDiligenceDocuments
        :documents="event.documents"
        :due-diligence="dueDiligence"
      />
    </div>
  </div>
</template>

<i18n namespace="components.CommentEvent">
{
  "analyst": "Analyst",
  "manager": "Manager"
}
</i18n>

<script>
import DueDiligenceDocuments from '@/components/due-diligence/DueDiligenceDocuments';

export default {
  name: 'CommentEvent',
  components: {
    DueDiligenceDocuments,
  },
  props: {
    dueDiligence: {
      type: Object,
      default: null,
      required: true,
    },
    member: {
      type: Object,
      default: null,
      required: true,
    },
    event: {
      type: Object,
      default: null,
      required: true,
    },
  },
};
</script>
