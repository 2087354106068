export default function (Component) {
      Component.options._i18n = {
        namespace: "components.OpenableCompanyList",
        translations: 
































{
  "companies": "Companies"
}

      }
    }