import ProxyGraph from './proxy.graph';

export default class CollapsedGraph extends ProxyGraph {
  #centerNodeId;

  constructor(decorated, centerNodeId) {
    super(decorated);
    this.#centerNodeId = centerNodeId;
  }

  get centerNodeId() {
    return this.#centerNodeId;
  }

  remove(transformed) {
    if (this === transformed) {
      return this.decorated;
    } else {
      return new CollapsedGraph(this.decorated.remove(transformed), this.#centerNodeId);
    }
  }

}
