<template>
  <div class="shareholder-list table-border">
    <table class="table-hover">
      <thead>
        <tr>
          <th>{{ ts.th.name }}</th>
          <th>{{ ts.th.city }} ({{ ts.th.country }})</th>
          <th class="text-align-right">
            {{ ts.th.directParticipation }}
          </th>
          <th class="no-open">
            {{ ts.th.identifier }}
          </th>
          <th class="no-open">
            {{ ts.th.companyType }}
          </th>
          <th class="no-open">
            {{ ts.th.companySize }}
          </th>
          <th class="no-open">
            {{ ts.th.group }}
          </th>
        </tr>
      </thead>
      <tbody
        v-if="shareholders.loading"
        class="vadis-molecule-loader"
      >
        <tr>
          <td colspan="7">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-if="shareholders.loaded">
        <tr
          v-for="(shareholder, i) in shareholders.items"
          :key="i"
          :class="{'current': isCurrent(shareholder)}"
          :disabled="!shareholder.id"
          @click="onClick(shareholder)"
        >
          <td>
            <div class="risk-tag-company">
              <a class="name">{{ shareholder.source.name }}</a>
              <RiskTag
                :risks="riskIndicators(shareholder)"
                @click="onClickRisk(shareholder, $event.risk)"
              />
            </div>
          </td>
          <td>
            <CompanyLocation :company="shareholder.source" />
          </td>
          <td class="text-align-right">
            {{ shareholder.participation.direct|percent }}
          </td>
          <td class="no-open">
            {{ shareholder.source.europeanVAT }}
          </td>
          <td class="no-open">
            {{ shareholder.source.companyType|companyType }}
          </td>
          <td class="no-open">
            {{ shareholder.source.size|companySize }}
          </td>
          <td class="no-open">
            <p v-if="shareholder.source.primaryGroup">
              {{ shareholder.source.primaryGroup.name }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="shareholders.loaded && shareholders.hasPagination()"
      :current-page="shareholders.meta.pageNumber"
      :total-items="shareholders.meta.totalItems"
      :number-of-lines="shareholders.meta.pageSize"
      :links="shareholders.links"
      @click="onClickPagination($event)"
    />
  </div>
</template>

<i18n namespace="components.ShareholderList">
{
  "th": {
    "name": "Name",
    "city": "City",
    "country": "Country",
    "identifier": "ID",
    "group": "Group",
    "companyType": "Type",
    "companySize": "Size",
    "directParticipation": "Direct part. (%)",
    "totalParticipation": "Total part. (%)"
  }
}
</i18n>

<script>
import _ from 'lodash';
import { Collection } from 'flair-client';
import Loader from '@/components/Loader';
import CompanyLocation from '@/components/company/CompanyLocation';
import Pagination from '@/components/Pagination';
import RiskTag from '@/components/risk/RiskTag';

export default {
  name: 'ShareholderList',
  components: {
    Loader,
    CompanyLocation,
    Pagination,
    RiskTag,
  },
  props: {
    shareholders: {
      type: Collection,
      default: null,
      required: true,
    },
    current: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onClick(c) {
      this.$emit('click', c);
    },
    isCurrent(c) {
      return c === this.current;
    },
    onClickPagination(event) {
      this.shareholders[event]();
    },
    riskIndicators(ss) {
      const risks = _.at(ss, 'source.last.riskProfile.subrisks')[0];
      return _.filter(risks, (r) => r.score > 0);
    },
    onClickRisk(ss, risk) {
      this.$emit('onClickRisk', { company: ss.source, risk });
    },
  },
};
</script>
