<template>
  <div class="company-overview">
    <header>
      <div class="title">
        <button
          class="vadis-form-button-standard-icon btn-close"
          @click="$emit('close')"
        >
          <i class="icon-Close" />&nbsp;{{ ts.buttons.close }}
        </button>
        <div>
          <h4>
            {{ companyFullName }}
          </h4>
          <div class="subtitle">
            {{ company.identifiers.bvdId }}
            <GoogleSearchLink :q="companyFullName" />
          </div>
        </div>
        <router-link
          tag="button"
          :to="{ name: 'company-details-screen', params: {id: company.id, tab: 'summary'} }"
          class="vadis-form-button-main btn-details"
        >
          {{ ts.buttons.companyDetails }}
        </router-link>
      </div>
      <div
        v-if="riskIndicators"
        class="indicators"
        :class="{active: hover}"
      >
        <div
          class="global-risk"
        >
          <p>{{ ts.labels.globalRisk }}</p>
          <p :class="`vadis-risk-color-level${transformRiskScore(globalRisk)}`">
            {{ transformRiskScore(globalRisk) }}
          </p>
        </div>
        <RiskIndicator
          v-for="indicator in riskIndicators"
          :key="indicator.id"
          :risk="indicator"
          @over="hover=true;"
          @leave="hover=false;"
          @click="$emit('onClickRisk', { company: company, risk: $event.risk })"
        />
      </div>
    </header>
    <section>
      <ItemDetails
        v-for="(items, i) in detailGroups"
        :key="i"
        class="vadis-organism-detail-group"
        :items="items"
        :i18n="ts"
        :context="company"
        :item-class="'vadis-molecule-detail-item'"
        :label-class="'vadis-molecule-detail-label'"
        :value-class="'vadis-molecule-detail-value'"
        :router-link="{name: 'company-details-screen', params: { id: company.id }}"
      />
    </section>
  </div>
</template>

<i18n namespace="components.CompanyOverview">
{
  "buttons": {
    "close": "Close",
    "companyDetails": "Company Details"
  },
  "labels": {
    "size": "Size",
    "type": "Type",
    "legalForm": "Legal form",
    "country": "Country",
    "activity": "Activity",
    "europeanVAT": "VAT",
    "siren": "Siren",
    "yearOfIncorporation": "Established in",
    "operatingRevenue": "Last turnover",
    "numberOfEmployees": "Number of employees",
    "ultimateBeneficiaryOwner": "Ultimate beneficiary owner",
    "numberOfShareholderCompanies": "Number of shareholders",
    "numberOfSubsidiaryCompanies": "Number of subsidiaries",
    "numberOfRelatedPeople": "Number of managers",
    "primaryGroup": "Group",
    "globalRisk": "Global risk"
  }
}
</i18n>

<script>
import { Company } from 'flair-client';
import { transformRiskScore } from 'flair-client/src/utils';
import RiskIndicator from '@/components/risk/RiskIndicator';
import GoogleSearchLink from '@/components/GoogleSearchLink';
import CountryFlag from '@/components/CountryFlag';
import ItemDetails from '@/components/ItemDetails';
import _ from 'lodash';

const detailGroups = [
  [{
    label: 'labels.europeanVAT',
    path: 'europeanVAT',
    filter: 'unknown',
  }, {
    label: 'labels.siren',
    path: 'siren',
    filter: 'unknown',
  }, {
    label: 'labels.type',
    path: 'companyType',
    filter: 'companyType',
  }, {
    label: 'labels.activity',
    path: 'activity.nace2Code',
    filter: 'nace2code',
  }, {
    label: 'labels.country',
    path: 'address.country',
    component: CountryFlag,
    componentProps: {
      country: (c) => c ? _.get(c, 'address.country') : null,
    },
  }, {
    label: 'labels.size',
    path: 'size',
    filter: 'companySize',
  }, {
    label: 'labels.legalForm',
    path: 'legalForm',
    filter: 'unknown',
  }, {
    label: 'labels.yearOfIncorporation',
    path: 'establishment.date',
    filter: 'yearOfPseudoDate',
  }],
  [{
    label: 'labels.operatingRevenue',
    path: 'last.financial.turnover',
    filter: 'keuros',
    clickable: 'financial',
  }, {
    label: 'labels.numberOfEmployees',
    path: 'last.financial.numberOfEmployees',
    filter: 'unknown',
    clickable: 'financial',
    valueClass: 'vadis-molecule-amount-link',
  }],
  [{
    label: 'labels.numberOfShareholderCompanies',
    path: 'last.neighborhood.numberOfShareholderCompanies',
    filter: 'unknown',
    clickable: 'shareholders',
    valueClass: 'vadis-molecule-amount-link',
  }, {
    label: 'labels.numberOfSubsidiaryCompanies',
    path: 'last.neighborhood.numberOfSubsidiaryCompanies',
    filter: 'unknown',
    clickable: 'subsidiaries',
    valueClass: 'vadis-molecule-amount-link',
  }, {
    label: 'labels.numberOfRelatedPeople',
    path: 'last.neighborhood.numberOfRelatedPeople',
    filter: 'unknown',
    clickable: 'management',
    valueClass: 'vadis-molecule-amount-link',
  }, {
    label: 'labels.primaryGroup',
    path: 'primaryGroup.name',
    filter: 'unknown',
  }],
];

export default {
  name: 'CompanyOverview',
  components: {
    RiskIndicator,
    GoogleSearchLink,
    ItemDetails,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    transformRiskScore,
    detailGroups,
    hover: false,
  }),
  computed: {
    riskIndicators() {
      return _.get(this.company, 'last.riskProfile.subrisks');
    },
    globalRisk() {
      return _.get(this.company, 'last.riskProfile.score');
    },
    companyFullName() {
      const name = _.at(this.company, 'name');
      return `${name}`;
    },
  },
  methods: {
    attr(path) {
      return _.at(this.company, path)[0];
    },
    dynamicComponentProperties(propName, propValue) {
      const props = {};
      props[propName] = propValue;
      return props;
    },
  },
};
</script>
