export default function (Component) {
      Component.options._i18n = {
        namespace: "components.WarningCOEvent",
        translations: 





































{
  "vadisFlair": "Vadis Flair",
  "addingCo": "The global risk is high, so well done for adding a compliance officer to help you with the due diligence",
  "highRisk": "The global risk is high,  We recommend you involve a compliance officer in the current due diligence, so they can advise you.",
  "buttons": {
    "inviteComplianceOfficer": "Invite compliance officer"
  }
}

      }
    }