export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DepartmentsPane",
        translations: 
































































{
  "createDeptTitle": "Start creating a department and invite team managers",
  "departmentsCanContain": "Departments can contain multpiple teams",
  "errorDepartmentNameCannotBeNull": "A department cannot have an empty name",
  "errorDepartmentNameAlreadyExist": "A department with the same name already exists",
  "button": {
    "createDepartment": "Create department",
    "cancel": "Cancel"
  },
  "link": {
    "addLeader": "Add department leader"
  },
  "placeholder": {
    "department": "Department name..."
  },
  "teams": "teams"
}

      }
    }