<template>
  <div>
    <div
      v-if="errorDepartmentNameAlreadyExist"
      class="error-messages"
    >
      <p>{{ ts.errorDepartmentNameAlreadyExist }}</p>
    </div>
    <div
      v-if="errorDepartmentNameCannotBeNull"
      class="error-messages"
    >
      <p>{{ ts.errorDepartmentNameCannotBeNull }}</p>
    </div>
    <form @submit.prevent="createUpdateDepartment()">
      <div class="vadis-form-field-standard">
        <input
          ref="input"
          v-model="departmentName"
          type="text"
          :placeholder="ts.placeholder.department"
        >
      </div>
      <button
        v-if="!department"
        class="vadis-form-button-main"
        type="submit"
      >
        {{ ts.button.createDepartment }}
      </button>
      <button
        v-else
        class="vadis-form-button-main"
        type="submit"
      >
        {{ ts.button.renameDepartment }}
      </button>
      <button
        class="vadis-form-button-standard"
        @click="$emit('close');"
      >
        {{ ts.button.cancel }}
      </button>
    </form>
  </div>
</template>

<i18n namespace="components.DepartmentEditForm">
{
  "errorDepartmentNameCannotBeNull": "A department cannot have an empty name",
  "errorDepartmentNameAlreadyExist": "A department with the same name already exists",
  "button": {
    "createDepartment": "Create department",
    "renameDepartment": "Rename department",
    "cancel": "Cancel"
  },
  "placeholder": {
    "department": "Department name..."
  }
}
</i18n>

<script>

export default {
  name: 'DepartmentEditForm',
  props: {
    rename: {
      default: false,
      type: Boolean,
      required: true,
    },
    department: {
      default: null,
      type: Object,
      required: true,
    },
  },
  data: () => ({
    departmentName: '',
    errorDepartmentNameAlreadyExist: false,
    errorDepartmentNameCannotBeNull: false,
  }),
  mounted() {
    this.$refs.input.focus();
    if (this.department) {
      this.departmentName = this.department.name;
    }
  },
  methods: {
    cleanError() {
      this.errorDepartmentNameCannotBeNull = false;
      this.errorDepartmentNameAlreadyExist = false;
    },
    createUpdateDepartment() {
      this.cleanError();
      if (this.departmentName === '') {
        this.errorDepartmentNameCannotBeNull = true;
        return;
      }
      if (this.rename) {
        this.renameDepartment();
      } else {
        this.createDepartment();
      }
    },
    createDepartment() {
      this.$flairClient.AdminService.createDepartment({ name: this.departmentName })
        .promise.then(() => {
          this.$emit('close');
        }).catch((err) => {
          console.error('#failed', err);
          if (err.response.status === 409) {
            this.errorDepartmentNameAlreadyExist = true;
          } else {
            throw err;
          }
        });
    },
    renameDepartment() {
      if (this.departmentName === this.department.name) {
        this.$emit('close');
        return;
      }
      this.$flairClient.AdminService.updateDepartment({ id: this.department.id, name: this.departmentName })
        .promise.then(() => {
          this.$emit('close');
        }).catch((err) => {
          console.error('#failed', err);
          if (err.response.status === 409) {
            this.errorDepartmentNameAlreadyExist = true;
          } else {
            throw err;
          }
        });
    },
  },
};
</script>
