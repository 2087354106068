export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RiskGlobalBox",
        translations: 






































































{
  "establishIn": "Est. in",
  "revisor": "Revisor:",
  "computedRisk": "Computed risk",
  "revisedRisk": "Revised risk",
  "buttons": {
    "reviseRisk": "Revise risk"
  }
}

      }
    }