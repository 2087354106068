import ProxyGraph from './proxy.graph';

export default class TransformedGraph extends ProxyGraph {
  #transformed;
  #transformation;

  constructor(decorated, transformed, transformation) {
    super(decorated);
    this.#transformed = transformed;
    this.#transformation = transformation;
  }

  get centerNodeId() {
    return this.#transformed.centerNodeId;
  }  

  get nodes() {
    return this.#transformed.nodes;
  }  

  get edges() {
    return this.#transformed.edges;
  }  

  remove(t) {
    if (this === t || this.#transformation === t) {
      return this.decorated;
    } else {
      return new TransformedGraph(this.decorated.remove(t), this.#transformed.remove(t), this.#transformation);
    }
  }

}
