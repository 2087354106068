export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyRiskModal",
        translations: 























{
  "companyDetails": "Company details"
}

      }
    }