<template>
  <div class="leaflet-map" />
</template>

<script>
export default {
  name: 'LeafletMap',
  props: {
    geocoords: {
      type: Object,
      default: null,
      required: true,
    },
    tooltip: {
      type: String,
      default: null,
      required: true,
    },
  },
  data: () => ({
    map: null,
    Leaflet: null,
  }),
  watch: {
    geocoords: function(newval, oldval) {
      if (newval && newval !== oldval) {
        this.map.panTo(new this.Leaflet.LatLng(this.geocoords.latitude, this.geocoords.longitude));
        const marker = this.Leaflet.marker([this.geocoords.latitude, this.geocoords.longitude]).addTo(this.map);
        marker.bindTooltip(this.tooltip, { direction: 'bottom', permanent: true });
      }
    },
  },
  created() {
    // We load the leaflet library here instead of importing it in a more classical way
    // because Leaflet requires to be running in a browser (it decorates the window object)
    // By loading the library only when one LeafletMap component is created we ensure that
    // parent components can import this one without crashing because of the window variable
    // Parent components should implement the necessary logic to avoid creating an instance if not
    // running in a browser environment (SSR for instance)
    this.Leaflet = require('leaflet');
  },
  mounted() {
    if (this.geocoords) {
      this.init();
    }
  },
  methods: {
    init() {
      this.map = this.Leaflet.map(this.$el).setView([this.geocoords.latitude, this.geocoords.longitude], 13);

      // add the OpenStreetMap tiles
      this.Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }).addTo(this.map);

      // show the scale bar on the lower left corner
      this.Leaflet.control.scale().addTo(this.map);

      // create the icons for markers
      delete this.Leaflet.Icon.Default.prototype._getIconUrl;

      this.Leaflet.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      });

      const marker = this.Leaflet.marker([this.geocoords.latitude, this.geocoords.longitude]).addTo(this.map);

      if (this.tooltip !== '' && this.tooltip !== null) {
        marker.bindTooltip(this.tooltip, { direction: 'bottom', permanent: true });
      }
    },
  },
};

</script>
