export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CRIAccordion",
        translations: 







































{
  "selected": "selected"
}

      }
    }