import _ from 'lodash';
import ProxyGraph from './proxy.graph';

export default class ClusteredGraph extends ProxyGraph {
  #nodes;
  #edges;

  constructor(decorated) {
    super(decorated);
  }

  get nodes() {
    if (!this.#nodes) {this._build();}
    return this.#nodes;
  }

  get edges() {
    if (!this.#edges) {this._build();}
    return this.#edges;
  }

  remove(transformed) {
    if (this === transformed) {
      return this.decorated;
    } else {
      return new ClusteredGraph(this.decorated.remove(transformed));
    }
  }

  _build() {
    const index = {};
    this.decorated.nodes.forEach((node) => {
      index[node.id] = [[],[]];
    });
    this.decorated.edges.forEach((edge) => {
      index[edge.target.id][0].push(edge.source.id);
      index[edge.source.id][1].push(edge.target.id);
    });
    this.decorated.nodes.forEach((node) => {
      index[node.id][0] = _.uniq(index[node.id][0]).sort();
      index[node.id][1] = _.uniq(index[node.id][1]).sort();
      index[node.id] = `${_.join(index[node.id][0], '.')}->${_.join(index[node.id][1], '.')}`;
    });
    const rindex = this.decorated.nodes.reduce((acc,n) => {
      acc[index[n.id]] = acc[index[n.id]] || [n.id, 0];
      acc[index[n.id]][1] += 1;
      return acc;
    }, {});
    const newNodeIndex = this.decorated.nodes.filter((n) => {
      return rindex[index[n.id]][0] === n.id;
    }).reduce((idx, n) => {
      const size = rindex[index[n.id]][1];
      if (size > 1) {
        idx[n.id] = Object.assign({}, n, {
          id: `cluster-${n.id}`,
          nodeKind: 'companyCluster',
          name: `${size} companies`,
        });
      } else {
        idx[n.id] = n;
      }
      return idx;
    }, {});

    this.#nodes = Object.values(newNodeIndex);
    this.#edges = this.decorated.edges.filter((e) => {
      return rindex[index[e.source.id]][0] === e.source.id
          && rindex[index[e.target.id]][0] === e.target.id;
    }).map((e) => {
      return Object.assign({}, e, {
        source: newNodeIndex[e.source.id],
        target: newNodeIndex[e.target.id],
      });
    });
  }

}
