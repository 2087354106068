export default function (Component) {
      Component.options._i18n = {
        namespace: "components.MatchingPersonModal",
        translations: 
















































































































{
  "people": "people",
  "matchingDetected": "Matching people detected",
  "providedBy": "Provided by",
  "associateData": "Associate your data",
  "labels": {
    "name": "Name",
    "country": "Country",
    "birthDate": "Date of birth",
    "risks": "Risks",
    "matchingScore": "Matching Score"
  },
  "buttons": {
    "associate": "Associate",
    "cancel": "Cancel"
  },
  "noMatch": "No match found"
}

      }
    }