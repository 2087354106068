<template>
  <div>
    <div class="documents">
      <div
        v-for="(document) in documents"
        :key="document.id"
        :class="documentClass"
      >
        <div
          class="file"
          @click="download(document)"
        >
          <i class="icon-Document" />
          <div
            class="document-info"
          >
            <p class="document-name">
              {{ document.name }}
            </p> <p class="document-size">
              ({{ document.size }} kb)
            </p>
          </div>
        </div>
        <div
          class="options"
          @click="$emit('remove', document)"
        >
          <i class="icon-Trash" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const DOC_VIEW_ONLY = 'vadis-molecule-card-document-view-only';
const DOC_STRUCTURE = 'vadis-molecule-card-document-structure';

export default {
  name: 'DocumentList',
  props: {
    documents: {
      type: Array,
      default: null,
      required: true,
    },
  },
  computed: {
    documentClass() {
      return this.readOnly ? DOC_VIEW_ONLY : DOC_STRUCTURE;
    },
  },
  methods: {
    download(doc) {
      return this.$flairClient.DownloadService.download(doc);
    },
  },
};
</script>
