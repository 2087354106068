<template>
  <div>
    <Loader
      v-if="!providers"
      :global="true"
      :shown="true"
    />
    <template v-else>
      <CustomRiskIndicatorModal
        ref="customRiskIndicatorModal"
        :providers="providers"
      />
      <DataProviderCard
        v-for="provider in dataProviders"
        :key="provider.id"
        :provider="provider"
      />
      <CustomRiskCard
        v-for="cri of customRiskIndicators"
        :key="cri.id"
        :indicator="cri"
        :providers="providers"
      />
      <div class="department-section table-border">
        <button
          class="vadis-form-button-standard"
          @click="createCustomIndicator()"
        >
          {{ ts.createIndicator }}
        </button>
      </div>
    </template>
  </div>
</template>

<i18n namespace="components.CustomRiskIndicatorPane">
{
  "createIndicator": "Create custom indicator"
}
</i18n>

<script>
import CustomRiskIndicatorModal from '@/components/custom-risk-indicator/CustomRiskIndicatorModal';
import CustomRiskCard from '@/components/custom-risk-indicator/CustomRiskCard';
import DataProviderCard from '@/components/custom-risk-indicator/DataProviderCard';
import Loader from '@/components/Loader';

export default {
  name: 'CustomRiskIndicatorPane',
  components: {
    CustomRiskIndicatorModal,
    CustomRiskCard,
    DataProviderCard,
    Loader,
  },
  data: () => ({
    providers: null,
  }),
  computed: {
    customRiskIndicators() {
      const customDataProvider = this.providers.items.find(p => p.id === 'Custom');
      return customDataProvider ? customDataProvider.riskIndicators : {};
    },
    dataProviders() {
      const dataProviders = this.providers.items.filter(provider => provider.id !== 'Custom');
      return dataProviders;
    },
  },
  created() {
    this.getAllDataProviders();
  },
  events: {
    'dataprovider:*:*': (vm) => {
      vm.getAllDataProviders();
    },
  },
  methods: {
    createCustomIndicator() {
      this.$refs.customRiskIndicatorModal.open();
    },
    getAllDataProviders() {
      this.$flairClient.DataProviderService.getDataProviders().promise
        .then(providers => this.providers = providers);
    },
  },
};
</script>

