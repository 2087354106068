<template>
  <div class="survey-explanation">
    <div class="survey-title">
      <h1>{{ name }}</h1>
    </div>
    <div class="lists">
      <ul class="bullets">
        <li v-if="Object.keys(survey.data).length === 0">
          {{ ts.surveyExplanation.surveyContains }} {{ survey.PageCount }} {{ ts.surveyExplanation.questions }}
        </li>
        <li v-else>
          {{ ts.surveyExplanation.completedQuestions({ a: resultsLength, b: survey.pageCount }) }}
        </li>
        <li>{{ ts.surveyExplanation.answersSaved }}</li>
        <li>{{ ts.surveyExplanation.mandatoryAnswers }}</li>
        <li>{{ ts.surveyExplanation.submissionDetails }}</li>
      </ul>
    </div>
    <div
      v-if="Object.keys(survey.data).length === 0"
      class="buttons"
    >
      <button
        class="vadis-form-button-main"
        @click="$emit('startSurvey')"
      >
        {{ ts.buttons.start }}
      </button>
      <button
        class="vadis-form-button-standard invisible"
      >
        {{ ts.buttons.previewPDF }}
      </button>
    </div>
    <div
      v-else
      class="buttons"
    >
      <button
        class="vadis-form-button-standard"
        @click="$emit('startSurvey')"
      >
        {{ ts.buttons.reviewAndContinue }}
      </button>
      <button
        class="vadis-form-button-standard"
        @click="survey.currentPageNo = resultsLength(); $emit('startSurvey')"
      >
        {{ ts.buttons.continueAnswer }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.SurveyExplanation">
{
  "surveyExplanation": {
    "surveyContains": "This survey contains",
    "questions": "questions",
    "completedQuestions": "You have completed {a} / {b} questions",
    "answersSaved": "Answers are auto-saved so you can return to the form at any time using the link you received by mail",
    "mandatoryAnswers": "All questions with an asterisk (*) next to them are mandatory and must be answered to be able to submit the survey",
    "submissionDetails": "Answers will not be submitted until you confirm the submission of the questionnaire on the last screen"
  },
  "buttons": {
    "start": "Start",
    "previewPDF": "Preview in PDF",
    "reviewAndContinue": "Review answers and continue",
    "continueAnswer": "Answer remaining questions"
  }
}
</i18n>

<script>
import * as SurveyVue from 'survey-vue';

export default {
  name: 'SurveyExplanation',
  props: {
    name: {
      default: null,
      type: String,
      required: true,
    },
    survey: {
      default: null,
      type: SurveyVue.Model,
      required: true,
    },
  },
  methods: {
    resultsLength() {
      const arr = Object.keys(this.survey.data);
      return (arr[arr.length - 1]).split('-')[0];
    },
  },
};
</script>
