<template>
  <div class="teams-list table-border">
    <UserEditModal
      ref="userEditModal"
      :team="team"
      :role="'Analyst'"
      :user="user"
    />
    <div class="title">
      <h6>{{ team.name }}</h6>
      <span> {{ team.nbrOfMembers }} {{ ts.members }}</span>
    </div>
    <table>
      <thead>
        <tr>
          <th>{{ ts.th.lastName }}</th>
          <th>{{ ts.th.firstName }}</th>
          <th>
            {{ ts.th.function }}
          </th>
          <th>
            {{ ts.th.email }}
          </th>
          <th>
            {{ ts.th.lastVisit }}
          </th>
          <th />
        </tr>
      </thead>
      <tbody
        v-if="team.loading"
        class="vadis-molecule-loader"
      >
        <tr>
          <td colspan="6">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-if="team.loaded">
        <tr
          v-for="(user) in team.users"
          :key="user.id"
        >
          <td>
            <div class="tag-role">
              <p class="name">
                {{ user.lastName }}
              </p>
              <span
                v-if="user.id === team.teamManager.id"
                class="vadis-molecule-tag"
              >
                <P>{{ ts.manager }}</P>
              </span>
            </div>
          </td>
          <td>
            {{ user.firstName }}
          </td>
          <td>
            {{ user.function }}
          </td>
          <td>
            {{ user.email }}
          </td>
          <td>
            {{ user.lastVisit | humanDateHour }}
            <a
              v-if="!user.lastVisit && !user.invited"
              @click="sendEmail(user)"
            >{{ ts.link.send }}</a>
            <a
              v-if="!user.lastVisit && user.invited"
              @click="sendEmail(user)"
            > {{ ts.link.resend }}</a>
          </td>
          <td>
            <ContextualMenu
              v-if="user.id !== team.teamManager.id"
              :context="user"
              :items="contextualMenuItems"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="footer-section">
      <button
        class="vadis-form-button-main-small"
        @click="$refs.userEditModal.open()"
      >
        {{ ts.button.addAnalyst }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.TeamsPane">
{
  "th": {
    "lastName": "Last name",
    "firstName": "First name",
    "function": "Function",
    "email": "Mail address",
    "lastVisit": "Last visit"
  },
  "members": "members",
  "manager": "Manager",
  "removeUserFromTeam": "Remove user...",
  "remove": "Remove {q} ?",
  "removeSubtitle": "{firstname}’s account will be permanently deleted..",
  "button": {
    "addAnalyst": "Add analyst"
  },
  "link": {
    "send": "Send invitation",
    "resend": "Resend invitation"
  }
}
</i18n>

<script>
import Loader from '@/components/Loader';
import ContextualMenu from '@/components/ContextualMenu';
import UserEditModal from '@/components/UserEditModal';

export default {
  name: 'TeamsPane',
  components: {
    Loader,
    ContextualMenu,
    UserEditModal,
  },
  props: {
    team: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    addAnalyst: false,
    user: null,
  }),
  computed: {
    contextualMenuItems() {
      return {
        removeUserFromTeam: {
          label: this.ts.removeUserFromTeam,
          onClick: this.onRemoveUserClick,
        },
      };
    },
  },
  methods: {
    userName(user) {
      return `${user.firstName} ${user.lastName}`;
    },
    onRemoveUserClick(user) {
      this.openConfirmationModal({
        title: this.ts.remove({ q: this.userName(user) }),
        subtitle: this.ts.removeSubtitle({ firstname: user.firstName }),
        onConfirm: (modal) => {
          this.removeUserFromTeam(user)
            .promise.then(() => modal.close());
        },
      });
    },
    removeUserFromTeam(user) {
      const params = {
        userId: user.id,
        teamId: this.team.id,
      };
      return this.$flairClient.AdminService.removeUserFromTeam(params);
    },
    sendEmail(item) {
      this.$flairClient.AdminService.sendUserInvite({ id: item.id });
    },
  },
};
</script>
