export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PortfolioHealthBarChart",
        translations: 







{
  "lowest": "Lowest risk",
  "low": "Low risk",
  "medium": "Medium risk",
  "high": "High risk",
  "highest": "Highest risk"
}

      }
    }