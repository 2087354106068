export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PortfolioButton",
        translations: 































{
  "remove": "Remove {q} from portfolio?",
  "addToPortfolio": "Add to portfolio",
  "removeFromPortfolio": "Remove from portfolio",
  "removeTooltip": "Remove from portfolio",
  "addTooltip": "Add to portfolio"
}

      }
    }