<template>
  <div class="vadis-molecule-chat-action">
    <div class="vadis-molecule-avatar">
      <p>{{ member.initials }}</p>
    </div>
    <div class="content">
      <div class="name">
        <span>{{ member.fullName }}</span> ({{ ts[member.role.toLowerCase()] }})
        {{ ts.invitedAs({ fullName: dueDiligence.getMember(event.members[0].id).fullName, role: getRoleTs }) }}
      </div>
      <div class="date">
        {{ event.createdAt | humanDateHour }}
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.InviteMemberEvent">
{
  "invitedAs": "invited {fullName} as {role}",
  "analyst": "Analyst",
  "manager": "Manager",
  "complianceofficer": "Compliance Officer"
}
</i18n>

<script>

export default {
  name: 'InviteMemberEvent',
  props: {
    dueDiligence: {
      type: Object,
      default: null,
      required: true,
    },
    member: {
      type: Object,
      default: null,
      required: true,
    },
    event: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    getRoleTs() {
      return this.ts[this.dueDiligence.getMember(this.event.members[0].id).role.toLowerCase()];
    },
  },
};
</script>
