export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RiskProfileModal",
        translations: 




















































































{
  "sampleData": "Sample data",
  "riskProfile": "Risk Profile",
  "indicatorsSelected": "indicators selected",
  "reset": "Reset to default",
  "buttons": {
    "save": "Save changes",
    "cancel": "Cancel"
  }
}

      }
    }