export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CRIDescription",
        translations: 
































{
  "indicatorName": "Name your custom indicator",
  "indicatorDescription": "Add a short description to your custom indicator (facultative)",
  "display": "Display as",
  "nameError": "Sorry this name has already been used, please choose another one before proceeding.",
  "placeholders": {
    "customIndicator": "My custom indicator",
    "description": "Short description of what 'My custom indicator' is"
  },
  "switcher": {
    "number": "Number",
    "meter": "Meter"
  }
}

      }
    }