<template>
  <div class="vadis-controller-tab">
    <ul>
      <template v-for="(tab,i) in getTabs()">
        <li
          v-if="!tab.features || $featureEnabled(tab.features)"
          :key="i"
          :class="{ active: isActive(tab) }"
        >
          <router-link :to="{ name: 'company-details-screen', params: { id: company.id, tab: tab.code } }">
            {{ ts[tab.code] || tab.code }}
          </router-link>
        </li>
      </template>
    </ul>
  </div>
</template>

<i18n namespace="components.CompanyDetailsTabs">
{
  "summary": "Summary",
  "financial": "Financial",
  "management": "Management",
  "shareholders": "Shareholders",
  "subsidiaries": "Subsidiaries",
  "neighborhood": "Neighborhood",
  "due-diligence": "Due diligence",
  "ecovadis": "Ecovadis"
}
</i18n>

<script>
import { Company } from 'flair-client';

const tabs = [
  { code: 'summary' },
  { code: 'financial' },
  { code: 'management' },
  { code: 'shareholders' },
  { code: 'subsidiaries' },
  { code: 'neighborhood',
    features: 'companyNeighborhood',
  },
  { code: 'due-diligence' },
  { code: 'ecovadis' },
];

export default {
  name: 'CompanyDetailsTabs',
  props: {
    active: {
      type: String,
      default: 'summary',
      required: false,
    },
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    tabs: tabs,
  }),
  methods: {
    isActive(tab) {
      return this.active === tab.code;
    },
    getTabs() {
      return this.tabs;
    },
  },
};
</script>
