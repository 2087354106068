<template>
  <div class="no-open">
    <template
      v-for="(risk, j) in risks"
    >
      <span
        v-if="risk.score > 4"
        :key="j"
        class="vadis-molecule-risk-tag"
        :class="'vadis-molecule-risk-tag-level-' + risk.score"
        @click.stop="$emit('click', { risk: risk.name })"
      >
        <span
          v-if="ts.tooltips[risk.name]"
          class="vadis-molecule-tooltip"
        ><span class="tooltiptext">{{ ts.tooltips[risk.name] }}</span></span>
        <a>{{ risk.name | companyRisks | capitalize }}</a>
      </span>
    </template>
  </div>
</template>

<i18n namespace="components.RiskTag">
{
  "tooltips": {
    "pep": "Politically exposed person",
    "adverse_media": "Adverse media"
  }
}
</i18n>

<script>
export default {
  name: 'RiskTag',
  props: {
    risks: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>
