<template>
  <div class="due-diligence-view">
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb>
        <li>{{ ts.title }}</li>
      </Breadcrumb>
      <div class="header">
        <h1>{{ ts.title }}</h1>
      </div>
      <DueDiligenceListTabs />
    </div>
    <div class="vadis-organism-padded-area">
      <DueDiligencePane />
    </div>
  </div>
</template>

<i18n namespace="views.MyDueDiligenceList">
{
  "title": "Due Diligence"
}
</i18n>

<script>
import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';
import DueDiligenceListTabs from '@/components/due-diligence/DueDiligenceListTabs.vue';
import DueDiligencePane from '@/components/due-diligence/DueDiligencePane.vue';

export default {
  name: 'MyDueDiligenceList',
  components: {
    TopBar,
    Breadcrumb,
    DueDiligenceListTabs,
    DueDiligencePane,
  },
  data: () => ({
    activeTab: null,
  }),
};

</script>
