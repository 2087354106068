export default function (Component) {
      Component.options._i18n = {
        namespace: "components.EmptySearch",
        translations: 










{
  "noSearch": "No results for your search."
}

      }
    }