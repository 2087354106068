export default function (Component) {
      Component.options._i18n = {
        namespace: "views.DataconsumptionWidget",
        translations: 

















































{
  "title": {
    "dataConsumption": "Data consumption"
  },
  "year": {
    "currentYear": "Current year",
    "previousYear": "Y - 1"
  },
  "screeningSpent": "Screening spent this year",
  "monitoringSpent": "Monitoring spent this year",
  "remaining": "Remaining"
}

      }
    }