export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SurveyTranslationFormModal",
        translations: 


























































































































































{
  "dragFile": "Drag files to upload, or click to ",
  "browse": "browse...",
  "optionalContent": "(optional)",
  "headers": {
    "language": "Language",
    "name": "Name",
    "description": "Description",
    "surveyContent": "Survey Content"
  },
   "errors": {
    "languageRequired": "Please select a language",
    "nameRequired": "A first name is required",
    "surveyContentRequired": "Survey content is required",
    "errorFile": "The file type you have tried to add is not supported"
  },
  "placeholders": {
    "name": "Name",
    "description": "Description"
  },
  "buttons": {
    "cancel": "Cancel"
  }
}

      }
    }