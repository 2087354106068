export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyList",
        translations: 

























































































































































































{
  "th": {
    "name": "Name",
    "country": "Country",
    "identifier": "VAT number",
    "group": "Group",
    "companyType": "Type"
  },
  "removeFromRecent": "Remove from recent"
}

      }
    }