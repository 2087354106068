<template>
  <highcharts
    :options="gaugeOptions()"
    class="ecovadis-chart"
  />
</template>

<script>

import { Chart } from 'highcharts-vue';
import { transformRiskScore } from 'flair-client/src/utils';

export default {
  name: 'EcoVadisRiskChart',
  components: {
    highcharts: Chart,
  },
  props: {
    risk: {
      type: Object,
      default: null,
      required: true,
    },
    unit: {
      type: String,
      default: 'score',
    },
  },
  methods: {
    transformRiskScore,
    prepareDataForGraph() {
      const data = this.risk;
      data.y = this.risk.percentage;
      data.value = this.risk[this.unit];
      return data;
    },
    gaugeOptions: function() {
      return {
        chart: {
          type: 'solidgauge',
          spacingTop: 0,
          spacingLeft: 0,
          spacingRight: 0,
          spacingBottom: 0,
        },

        title: null,
        pane: {
          center: ['50%', '50%'],
          size: '100%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: '#EEE',
            innerRadius: '90%',
            outerRadius: '100%',
            shape: 'arc',
            borderWidth: 0,
          },
        },
        exporting: {
          enabled: false,
        },

        tooltip: {
          enabled: false,
        },

        credits: {
          enabled: false,
        },

        // the value axis
        yAxis: {
          min: 0,
          max: 100,
          labels: {
            enabled: false,
          },
          stops: [
            [0.19, '#DF5353'], // red
            [0.20, '#ff6f00'], // orange
            [0.39, '#ff6f00'], // orange
            [0.40, '#f6ba39'], // yellow
            [0.59, '#f6ba39'], // yellow
            [0.60, '#94a545'], // green
            [0.79, '#94a545'], // green
            [0.80, '#008556'], // green
            [0.99, '#008556'], // green
          ],
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 0,
        },
        plotOptions: {
          gauge: {
            pivot: {
              radius: 0,
            },
          },
          series: {
            enableMouseTracking: false,
          },
          solidgauge: {
            dataLabels: {
              borderWidth: 0,
              useHTML: true,
              y: -30,
            },
          },
        },
        series: [{
          name: 'ecovadis',
          colorByPoint: true,
          data: [this.prepareDataForGraph()],
          innerRadius: '90%',
          dataLabels: {
            format:
            '<div class="score-container">' +
            // '<span class="status">{point.status}</span>' +
            '<span class="ecovadis-color-{point.score} value">{point.value}</span>' +
            '</div>',
          },
        },
        // dial series (gauge)
        {
          data: [this.risk.average],
          type: 'gauge',
          dataLabels: { enabled: false },
          dial: {
            backgroundColor: '#c3c3c3',
            baseLength: '100%',
            radius: '110%',
            baseWidth: 10,
            rearLength: '-75%',
            borderColor: '#ffffff',
            borderWidth: 4,
          },
        }],
      };
    },
  },
};
</script>
