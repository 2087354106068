export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CustomRiskIndicatorModal",
        translations: 












































































































{
  "header": "Create custom indicator",
  "namedHeader": "{name}",
  "editInformation": "You are modifying a custom indicator that is used in the company's global score.  By modifying '{name}',  you will impact the global score of all portfolios.",
  "stages": {
    "description": "Description",
    "indicators": "Indicators",
    "composition": "Composition",
    "confirmation": "Confirmation"
  },
  "buttons": {
    "next": "Next",
    "save": "Save",
    "update": "Update indicator",
    "cancel": "Cancel",
    "close": "Close"
  }
}

      }
    }