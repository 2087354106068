<template>
  <div>
    <Loader
      v-if="loading"
      :global="false"
      :shown="true"
      class="pdf-loader"
    />
    <CompanyPDFReport
      v-else
      :report="reportData"
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import CompanyPDFReport from '@/components/company/CompanyPDFReport';

export default {
  name: 'CompanyPDF',
  components: {
    CompanyPDFReport,
    Loader,
  },
  data: () => ({
    reportData: null,
    loading: true,
  }),
  created() {
    const instance = this.$flairClient.AppService.instance;
    const id = this.$router.currentRoute.params.id;
    this.$flairClient.PdfReportService.getCompanyPdfData(id, instance.defaultYear).then((data) => {
      this.reportData = data;
      this.loading = false;
    });
  },
};

</script>
