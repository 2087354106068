<template>
  <div>
    <div class="documents">
      <div
        v-for="(document) in dueDiligenceDocuments"
        :key="document.id"
        :class="documentClass"
      >
        <div
          class="file"
          @click="download(document)"
        >
          <i class="icon-Document" />
          <div
            class="document-info"
          >
            <p class="document-name">
              {{ document.name }}
            </p> <p class="document-size">
              ({{ document.size }} kb)
            </p>
          </div>
        </div>
        <div
          class="options"
          @click="$emit('remove', document)"
        >
          <i class="icon-Trash" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DueDiligence, Document } from 'flair-client';

const DOC_VIEW_ONLY = 'vadis-molecule-card-document-view-only';
const DOC_STRUCTURE = 'vadis-molecule-card-document-structure';

export default {
  name: 'DueDiligenceDocuments',
  props: {
    documents: {
      validator: (docs) => {
        return Array.isArray(docs) && docs.every(d => d instanceof Document);
      },
      default: null,
      required: false,
    },
    dueDiligence: {
      type: DueDiligence,
      default: () => {},
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    documentClass() {
      return this.readOnly ? DOC_VIEW_ONLY : DOC_STRUCTURE;
    },
    /**
     * Because this component can be passed an array of
     * DueDiligence.Document references (only with ids)
     * we ensure that we always iterate on an array of
     * full DueDiligence.Documents objects
     */
    dueDiligenceDocuments() {
      if (!this.documents) {
        return [];
      }
      if (this.dueDiligence) {
        return this.documents.map((d) => this.dueDiligence.getDocument(d.id));
      }
      return this.documents;
    },
  },
  methods: {
    download(doc) {
      return this.$flairClient.DownloadService.download(doc);
    },
  },
};
</script>
