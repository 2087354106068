export default function (Component) {
      Component.options._i18n = {
        namespace: "components.ReviseRiskEvent",
        translations: 





































{
  "revisedRisk": "Revised risk",
  "analyst": "Analyst",
  "manager": "Manager"
}

      }
    }