<template>
  <div
    class="target-tooltip"
    :class="{active: hover}"
    @mouseover="mouseOver()"
    @mouseleave="mouseLeave()"
  >
    <div
      :class="[small ? 'vadis-molecule-risk-indicator-small' : 'vadis-molecule-risk-indicator',
               'vadis-molecule-risk-indicator-level-' + risk.score]"
      @click="onClick(risk.name, risk.score)"
    >
      <div class="circle" />
      <ul>
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
      <label v-if="!small">
        {{ risk.name | providerRiskIndicator | companyRisks | capitalize }}
      </label>
    </div>
    <div
      v-if="!small"
      class="vadis-molecule-tooltip-info"
    >
      <div class="tooltiptext">
        <div
          class="title"
          :class="'title-' + risk.score"
        >
          <p>{{ risk.name | providerRiskIndicator | companyRisks | capitalize }}</p>
        </div>
        <div class="line">
          <span>{{ ts.lastUpdate }}</span><span>{{ risk.lastUpdate | shortDate }}</span>
        </div>
        <div class="line">
          <span v-if="!risk.score && risk.score!=0">{{ ts.nodata }}</span>
          <span v-if="risk.score==0">{{ noRiskMessage(risk.name) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.RiskIndicator">
{
  "lastUpdate": "Last update",
  "nodata": "No data found",
  "norisk": {
    "pep": "No PEP identified",
    "adverse_media": "No adverse media identified",
    "default": "No risk"
  }
}
</i18n>

<script>
export default {
  name: 'RiskIndicator',
  props: {
    risk: {
      type: Object,
      default: null,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    class: '',
    hover: false,
  }),
  methods: {
    noRiskMessage(risk) {
      return this.ts.norisk[risk] || this.ts.norisk.default;
    },
    mouseOver() {
      this.hover = true;
      this.$emit('over');
    },
    mouseLeave() {
      this.hover = false;
      this.$emit('leave');
    },
    onClick(risk, score) {
      if (score !== null) {
        if (!(score <= 1 && (risk === 'pep' || risk === 'sanction' || risk === 'adverse_media'))) {
          this.$emit('click', { risk });
        }
      }
    },
  },
};
</script>
