<template>
  <div>
    <Loader
      v-if="loading"
      :global="false"
      :shown="true"
    />
    <article
      v-else
      class="company-details-view"
    >
      <header>
        <h1>{{ ts.myPortfolio }}</h1>
        <p>{{ ts.printedOn }} {{ printingDate|humanDate }}</p>
        <button
          class="vadis-form-button-standard-icon"
          @click="printIt();"
        >
          <i class="icon-Import" />
          {{ ts.savePDF }}
        </button>
      </header>
      <section class="vadis-organism-padded-area">
        <h2> {{ ts.summary }} </h2>
        <PortfolioList
          :monitored-companies="portfolio.relationships.subjects"
        />
      </section>
    </article>
  </div>
</template>

<i18n namespace="views.MyPortfolioPDF">
{
  "printedOn": "Printed on",
  "myPortfolio": "My portfolio",
  "savePDF": "Save PDF"
}
</i18n>

<script>
import PortfolioList from '@/components/portfolio/PortfolioList';
import Loader from '@/components/Loader';

import moment from 'moment';

export default {
  name: 'PortfolioPDF',
  components: {
    PortfolioList,
    Loader,
  },
  data: () => ({
    loading: true,
    portfolio: null,
  }),
  computed: {
    printingDate() {
      return moment();
    },
  },
  watch: {
  },
  created() {
    this.loadPortfolio()
      .then((portfolio) => {
        this.portfolio = portfolio;
        this.loading = false;
      });
  },
  methods: {
    loadPortfolio() {
      // TODO Doesn't work with current team portfolio api (portfolio belongs to team and no user)
      /* const portfolio = this.$flairClient.PortfolioService.getTeamPortfolioSubjects();
      return portfolio.ensureRelationships({
        subjects: true
      }).then(() => {
        return portfolio;
      }); */
    },
    printIt() {
      this.loading = true;
      const attachment = 'my-portfolio.pdf';
      this.$flairClient.PrintService.print(attachment).then((pdf) => {
        this.loading = false;
        this.pdf = pdf.download();
      });
    },

  },
};

</script>
