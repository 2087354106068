<template>
  <div>
    <Loader
      v-if="teams.loading"
      :global="false"
      :shown="true"
    />
    <div
      v-for="(team, i) in teams.items"
      v-else
      :key="i"
    >
      <TeamsPane
        :team="team"
      />
    </div>
    <div
      v-if="error"
      class="vadis-organism-tab-empty"
    >
      <img
        class=""
        src="../../assets/tab_empty.svg"
      >
      <h6>You don't have a team to manage yet</h6>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import TeamsPane from '@/components/team/TeamsPane';

export default {
  name: 'SettingsTeamPane',
  components: {
    TeamsPane,
    Loader,
  },
  events: {
    'admin:*:*': (vm) => {
      vm.loadteams();
    },
  },
  data: () => ({
    teams: [],
    error: false,
  }),
  created() {
    this.loadteams();
  },
  methods: {
    loadteams() {
      this.teams = this.$flairClient.AdminService.getTeams();
      this.teams.promise
        .catch(err => {
          if (err.response.status === 404) {
            this.error = true;
          } else {
            throw err;
          }
        });
    },
  },
};
</script>
