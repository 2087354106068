import BaseGraph from './base.graph';
import CollapsedGraph from './collapsed.graph';
import RecenteredGraph from './recentered.graph';
import OneconnexGraph from './oneconnex.graph';
import DropEdgesGraph from './drop.edges.graph';
import ReverseEdgesGraph from './reverse.edges.graph';
import TreeGraph from './tree.graph';
import ClusteredGraph from './clustered.graph';
import DecoratedPromise from './decorated.promise';

const Patterns = {
  CollapsedGraph,
  RecenteredGraph,
  OneconnexGraph,
  DropEdgesGraph,
  ReverseEdgesGraph,
  TreeGraph,
  ClusteredGraph,
};

export {
  BaseGraph,
  Patterns,
  DecoratedPromise,
};
