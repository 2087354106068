<template>
  <div class="vadis-organism-error404">
    <div class="container">
      <div class="copy">
        <p>{{ ts.error }} <span>404</span></p>
        <p>{{ ts.explanation }}</p>
        <router-link
          tag="button"
          :to="{ name: 'search-screen' }"
          class="vadis-form-button-main btn-details"
        >
          {{ ts.backToHome }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<i18n namespace="views.Error404">
{
  "error": "ERROR",
  "explanation": "We are sorry for the inconvience. It looks like you’re trying to access a page that either has been deleted or never even existed.",
  "backToHome": "Back to home"
}
</i18n>

<script>

export default {
  name: 'Error404',
  methods: {
    backHome() {
      this.$router.push('/');
    },
  },
};
</script>
