<template>
  <div>
    <SurveyTranslationFormModal
      ref="surveyTranslationFormModal"
      :survey-information="translation"
      :optional-survey-content="true"
      :context="surveyModalContext"
      :survey-translations="survey.translations"
    />
    <Loader
      v-if="pdfLoaded"
      :global="true"
      :shown="true"
    />
    <div
      class="title"
    >
      <div class="left">
        <h6>{{ translation.name }}</h6>
        <div class="target-tooltip">
          <i class="icon-Info" />
          <div class="vadis-molecule-tooltip-info">
            <div class="tooltiptext">
              <div
                class="title"
              >
                {{ translation.name }}
              </div>
              <div class="line">
                {{ translation.description || ts.noDescription }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div>
          <p>{{ getLanguages() }}</p>
          <p>
            {{ ts.lastUpdated }}  {{ translation.updatedAt | humanDateHour }}
          </p>
          <i
            class="icon-Download"
            @click="downloadPDF(translation)"
          />
        </div>
        <ContextualMenu
          :items="contextualMenuItems"
        />
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.SurveyTranslationAccordionItem">

{
  "lastUpdated": "Last updated on",
  "removeSurveyTranslation": "Remove survey",
  "removeTranslationSubtitle": "This language will be removed from the survey",
  "editSurveyTemplate": "Edit survey",
  "remove": "Remove {q} language from {s}?",
  "noDescription": "No description",
  "modifySurveyTranslation": "Modify survey translation",
  "saveModification": "Save modification"
}
</i18n>

<script>
import _ from 'lodash';
import ContextualMenu from '@/components/ContextualMenu';
import Loader from '@/components/Loader';
import SurveyTranslationFormModal from '@/components/surveys/SurveyTranslationFormModal';
import { Survey, SurveyTranslation } from 'flair-client';

export default {
  name: 'SurveyTranslationAccordionItem',
  components: {
    ContextualMenu,
    SurveyTranslationFormModal,
    Loader,
  },
  props: {
    translation: {
      type: SurveyTranslation,
      default: null,
      required: true,
    },
    survey: {
      type: Survey,
      default: null,
      required: true,
    },
  },
  data: () => ({
    pdfLoaded: false,
  }),
  computed: {
    contextualMenuItems() {
      return {
        editSurveyTemplate: {
          label: this.ts.editSurveyTemplate,
          onClick: this.clickEditSurvey,
        },
        removeSurveyTranslation: {
          label: this.ts.removeSurveyTranslation,
          onClick: this.clickRemoveSurveyTranslation,
        },
      };
    },
    surveyModalContext() {
      return {
        title: this.ts.modifySurveyTranslation,
        button: this.ts.saveModification,
        onClick: this.clickUpdateSurvey,
      };
    },
  },
  methods: {
    getLanguages() {
      return _.find(this.$flairClient.ts('locales'), (k, v) => this.translation.language === v);
    },
    downloadPDF(translation) {
      this.pdfLoaded = true;
      this.$flairClient.SurveyService.getSurveyTranslationAsPDF({ id: translation.id }, translation.name)
        .finally(() => {
          this.pdfLoaded = false;
        });
    },
    clickRemoveSurveyTranslation() {
      this.openConfirmationModal({
        title: this.ts.remove({ q: this.$flairClient.ts('locales')[this.translation.language], s: this.translation.name }),
        subtitle: this.ts.removeTranslationSubtitle,
        onConfirm: (modal) => {
          this.$flairClient.SurveyService.removeSurveyTranslation({ id: this.translation.id })
            .promise.then(() => modal.close());
        },
      });
    },
    editSurveyTranslation() {
      this.$flairClient.SurveyService.getSurveyTranslation({ id: this.translation.id });
    },
    clickEditSurvey() {
      this.$refs.surveyTranslationFormModal.open();
      this.editSurveyTranslation();
    },
    clickUpdateSurvey(payload) {
      payload.id = this.translation.id;
      this.$flairClient.SurveyService.updateSurveyTranslation(payload)
        .promise.then(() => {
          this.$refs.surveyTranslationFormModal.close();
        });
    },
  },
};
</script>

