export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PortfolioCompanyPane",
        translations: 


























{
  "detailedRisks": "Detailed risks",
  "globalRisk": "Global risk",
  "syntheticView": "Synthetic view",
  "geographical": "Geographical"
}

      }
    }