<template>
  <div
    v-if="isOpen"
    @dragenter="dragEnter"
    @drop.prevent="addFile"
    @dragover.prevent
  >
    <div class="vadis-style-global-DialogBg" />
    <div class="vadis-modal-create-survey">
      <div class="header">
        <h6>
          {{ context.title }}
        </h6>
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>
      <form
        id="create-survey-template-form"
        class="vadis-organism-survey-template"
        novalidate
        @submit.prevent="validate"
      >
        <div class="form-row">
          <p class="label">
            {{ ts.headers.language }}
          </p>
          <div
            :class="{'vadis-form-field-select': true, invalid: $v.form.language.$error}"
          >
            <select
              v-model="form.language"
            >
              <template v-for="(language, k) in getLanguages()">
                <option
                  :key="k"
                  :value="k"
                  :disabled="language.exists"
                >
                  <p>
                    {{ language.name }}
                  </p>
                </option>
              </template>
            </select>
            <i class="icon-Dropdown" />
            <p
              v-if="$v.form.language.$error"
              class="validation-error"
            >
              {{ ts.errors.languageRequired }}
            </p>
          </div>
        </div>

        <div
          :class="{ 'form-row vadis-form-field-standard': true, invalid: $v.form.name.$error }"
        >
          <p class="label">
            {{ ts.headers.name }}
          </p>
          <input
            v-model.trim="form.name"
          >
          <p
            v-if="$v.form.name.$error"
            class="validation-error"
          >
            {{ ts.errors.nameRequired }}
          </p>
        </div>

        <div class="form-row vadis-form-field-textarea">
          <p class="label">
            {{ ts.headers.description }}
          </p>
          <textarea
            v-model="form.description"
            class="description"
          />
        </div>

        <div
          :class="{ 'form-row': true, invalid: fileRequired}"
        >
          <p class="label">
            {{ ts.headers.surveyContent }}
            <span v-if="optionalSurveyContent">
              {{ ts.optionalContent }}
            </span>
          </p>
          <div class="document">
            <DocumentList
              v-show="form.file"
              :documents="[form.file]"
              :read-only="false"
              @remove="removeFile($event)"
            />
            <p
              v-if="incorrectFile"
              class="validation-error"
            >
              {{ ts.errors.errorFile }}
            </p>
            <div
              v-if="!form.file"
              :class="['vadis-molecule-upload-file', dragHover ? 'drop' : '']"
            >
              <input
                id="file"
                type="file"
                name="file"
                class="inputfile"
                accept="application/json"
                :required="!optionalSurveyContent"
                @change="addFile"
              >
              <label
                ref="insideDrag"
                for="file"
              >
                <i class="icon-Upload" />
                <p>{{ ts.dragFile }} <a>{{ ts.browse }}</a></p>
              </label>
            </div>
          </div>
          <p
            v-if="fileRequired"
            class="validation-error"
          >
            {{ ts.errors.surveyContentRequired }}
          </p>
        </div>

        <div class="form-row buttons">
          <button
            class="vadis-form-button-main"
            type="submit"
          >
            {{ context.button }}
          </button>
          <button
            class="vadis-form-button-standard"
            @click="close()"
          >
            {{ ts.buttons.cancel }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<i18n namespace="components.SurveyTranslationFormModal">
{
  "dragFile": "Drag files to upload, or click to ",
  "browse": "browse...",
  "optionalContent": "(optional)",
  "headers": {
    "language": "Language",
    "name": "Name",
    "description": "Description",
    "surveyContent": "Survey Content"
  },
   "errors": {
    "languageRequired": "Please select a language",
    "nameRequired": "A first name is required",
    "surveyContentRequired": "Survey content is required",
    "errorFile": "The file type you have tried to add is not supported"
  },
  "placeholders": {
    "name": "Name",
    "description": "Description"
  },
  "buttons": {
    "cancel": "Cancel"
  }
}
</i18n>

<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { SurveyTranslation } from 'flair-client';
import DocumentList from '@/components/DocumentList';

export default {
  name: 'SurveyTranslationFormModal',
  components: {
    DocumentList,
  },
  props: {
    context: {
      type: Object,
      default: null,
      required: true,
    },
    optionalSurveyContent: {
      type: Boolean,
      default: null,
    },
    surveyInformation: {
      type: SurveyTranslation,
      default: null,
    },
    surveyTranslations: {
      type: Array,
      default: null,
    },
  },
  data:() => ({
    isOpen: false,
    incorrectFile: false,
    fileRequired: false,
    form: {
      language: '',
      name: '',
      description: '',
      file: '',
    },
    dragHover: false,
  }),
  mounted() {
    if (this.surveyInformation) {
      this.form.language = this.surveyInformation.language;
      this.form.name = this.surveyInformation.name;
      this.form.description = this.surveyInformation.description;
    }
  },
  validations: {
    form: {
      language: {
        required,
      },
      name: {
        required,
      },
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      if (!this.surveyInformation) {
        this.clearForm();
      }
      this.isOpen = false;
    },
    validate() {
      this.$v.$touch();
      if (!this.optionalSurveyContent && !this.form.file) {
        this.fileRequired = true;
      }
      if (!this.$v.$invalid) {
        this.sendForm();
      }
    },
    getLanguages() {
      const existingTranslation = _.map(this.surveyTranslations, e => e.language);
      return _.reduce(this.$flairClient.ts('locales'), (res, v, k) => {
        let exists = _.indexOf(existingTranslation, k) >= 0;
        if (this.surveyInformation) {
          exists = exists && k !== this.surveyInformation.language;
        }
        res[k] = {
          name: v,
          exists: exists,
        };
        return res;
      }, {});
    },
    dragEnter() {
      this.dragHover = true;
    },
    removeFile(file) {
      this.form.file = '';
    },
    addFile(e) {
      this.incorrectFile = false;
      this.dragHover = false;
      let droppedFiles;
      if (e.dataTransfer) {
        droppedFiles = e.dataTransfer.files;
      } else if (e.target) {
        droppedFiles = e.target.files;
      }
      if (!droppedFiles) {return;}
      ([...droppedFiles]).forEach(f => {
        if (f.type.match('application/json')) {
          this.form.file = f;
        } else {
          this.incorrectFile = true;
        }
      });
    },
    sendForm() {
      const payload = {
        language: this.form.language,
        name: this.form.name,
        description: this.form.description,
        file: this.form.file,
      };
      this.context.onClick(payload);
    },
    clearForm() {
      this.$v.$reset();
      this.form.language = '';
      this.form.name = '';
      this.form.description = '';
      this.form.file = '';
    },
  },
};
</script>

