export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SurveyTranslationAccordionItem",
        translations: 























































{
  "lastUpdated": "Last updated on",
  "removeSurveyTranslation": "Remove survey",
  "removeTranslationSubtitle": "This language will be removed from the survey",
  "editSurveyTemplate": "Edit survey",
  "remove": "Remove {q} language from {s}?",
  "noDescription": "No description",
  "modifySurveyTranslation": "Modify survey translation",
  "saveModification": "Save modification"
}

      }
    }