export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PasswordChanged",
        translations: 

















{
  "title": "Password changed",
  "messsage": "A confirmation mail has been sent to",
  "close": "Close"
}

      }
    }