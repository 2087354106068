export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DataProviderCard",
        translations: 































































{
  "contains": "Contains {number} indicators",
  "active": "active",
  "buttons": {
    "disable": "Disable",
    "enable": "Enable data provider"
  }
}

      }
    }