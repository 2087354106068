export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanySummaryPane",
        translations: 




























































































































































{
  "labels": {
    "size": "Size",
    "type": "Type",
    "legalForm": "Legal form",
    "country": "Country",
    "activity": "Activity",
    "europeanVAT": "VAT",
    "siren": "Siren",
    "group": "Group",
    "yearOfIncorporation": "Established in",
    "operatingRevenue": "Last turnover",
    "numberOfEmployees": "Number of employees",
    "ultimateBeneficiaryOwner": "Ultimate beneficiary owner",
    "numberOfShareholderCompanies": "Number of shareholders",
    "numberOfSubsidiaryCompanies": "Number of subsidiaries",
    "numberOfRelatedPeople": "Number of managers",
    "address": "Address",
    "year": "Year"
  },
  "keyfinancial": "Key financial",
  "keyinformation": "Key information",
  "shareholders": "Shareholders & subsidiaries",
  "headquarters": "Headquarters",
  "indicators": "Risk profile",
  "globalRisk": "Global risk",
  "inPortfolio": "This company is in a portfolio",
  "notInPortfolio": "This company is not in a portfolio",
  "noDueDiligence": "No due diligence has been executed, yet.",
  "startDueDiligence": "Start due diligence",
  "statusInformation": "Last due diligence"
}

      }
    }