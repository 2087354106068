<template>
  <div
    :class="cssClass"
  >
    <div class="title">
      <div class="left">
        <h5>Creditsafe</h5>
      </div>
      <div class="right">
        <p>{{ ts.provideBy }}</p>
        <img
          src="/img/creditsafe.png"
          alt="creditsafe"
        >
      </div>
    </div>
    <div
      v-if="creditRisk.loaded"
      class="graph"
    >
      <div class="cursor">
        <p>{{ creditRisk.ranking }}</p>
        <p v-if="creditRisk.score">
          {{ creditRisk.score }} / 100
        </p>
        <p v-else>
          {{ ts.notRated }}
        </p>
      </div>
      <div class="line">
        <ul>
          <li /><li /><li /><li /><li />
        </ul>
      </div>
      <div class="legend">
        <p>
          <span>{{ ts.creditSafe[creditRisk.ranking].result }}</span>
          {{ ts.creditSafe[creditRisk.ranking].explanation }}
        </p>
      </div>
    </div>
    <Loader
      v-else
      :global="false"
      :shown="true"
    />
  </div>
</template>

<i18n namespace="components.CreditSafeWidget">
{
  "notRated": "not rated",
  "provideBy": "Provided by",
  "creditSafe": {
    "A": {
      "result": "Very low risk : ",
      "explanation": "Proceed with transaction – offer extended terms if required. "
    },
    "B": {
      "result": "Low risk : ",
      "explanation": "Proceed with transaction."
    },
    "C": {
      "result": "Moderate risk : ",
      "explanation": "Proceed with caution and monitor closely."
    },
    "D": {
      "result": "High Risk : ",
      "explanation": "Request assurances before extending credit in the form of personal guarantees."
    },
    "E": {
      "result": "Not rated : ",
      "explanation": "No information is available to indicate the company is trading or further investigation is needed. "
    }
  }
}
</i18n>

<script>
import Loader from '@/components/Loader';

export default {
  name: 'CreditSafeWidget',
  components: {
    Loader,
  },
  props: {
    companyId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data: () => ({
    creditRisk: {},
  }),
  computed: {
    cssClass() {
      return `vadis-organism-widgets-creditsafe vadis-organism-widgets-creditsafe-${this.creditRisk.ranking}`;
    },
  },
  watch: {
    'companyId': {
      handler: 'loadCreditRisk',
      immediate: true,
    },
  },
  methods: {
    loadCreditRisk() {
      const instance = this.$flairClient.AppService.instance;
      this.creditRisk = this.$flairClient.EntitiesService.getCompanyHistoricalCreditRisk({
        id: this.companyId,
        year: instance.defaultYear,
      });
    },
  },
};
</script>
