<template>
  <div
    v-if="isOpen"
    class="vadis-modal-dueDiligence-start"
  >
    <Loader
      v-if="isLoading"
      :global="true"
      :shown="true"
    />
    <div class="header">
      <h6>{{ ts.startDueDiligence }}</h6>
      <p>{{ company.name }}</p>
      <i
        class="icon-Close"
        @click="close()"
      />
    </div>
    <div
      v-if="!teamMembers.items.length"
      class="body"
    >
      <div class="members">
        <h6>{{ ts.noTeamMembers }}</h6>
        <div class="buttons">
          <router-link
            :to="{name: 'my-settings-screen'}"
            tag="button"
            class="vadis-form-button-main-small"
          >
            {{ ts.buttons.addTeamMember }}
          </router-link>
        </div>
      </div>
    </div>
    <form
      v-else
      class="body"
      @submit.prevent="sendForm"
    >
      <div class="members">
        <h6>{{ ts.manager }}</h6>
        <div class="vadis-form-field-select">
          <select v-model="form.manager">
            <option
              v-for="member in teamMembers.items"
              :key="member.id"
              :value="member.id"
            >
              {{ member.firstName }} {{ member.lastName }}
            </option>
          </select>
          <i class="icon-Dropdown" />
        </div>
      </div>
      <div
        class="explanation"
      >
        <p>{{ ts.managerWill }} </p>
        <ul>
          <li>{{ ts.monitorDuediligence }} </li>
          <li>{{ ts.finalRecommendation }} </li>
        </ul>
      </div>

      <div class="buttons">
        <button
          type="submit"
          class="vadis-form-button-main-small"
          :disabled="$v.form.$invalid"
        >
          {{ ts.buttons.start }}
        </button>
        <button
          class="vadis-form-button-standard-small"
          @click="close()"
        >
          {{ ts.buttons.cancel }}
        </button>
      </div>
    </form>
  </div>
</template>

<i18n namespace="components.StartDueDiligenceModal">
{
  "startDueDiligence": "Start Due Diligence",
  "manager": "Who is your manager for this due diligence ?",
  "managerWill": "The manager will :",
  "monitorDuediligence": "Monitor your diligence",
  "finalRecommendation": "Give the final recommendation for companies whose risks have been identified as high.",
  "noTeamMembers": "There is currently nobody in your team, would you like to add a member?",
  "buttons": {
    "start": "Start Due Diligence",
    "cancel": "Cancel",
    "addTeamMember": "Add Team Member"
  }
}
</i18n>

<script>
import { required } from 'vuelidate/lib/validators';
import Loader from '@/components/Loader';

export default {
  name: 'StartDueDiligenceModal',
  components: {
    Loader,
  },
  data: () => ({
    teamMembers: null,
    isOpen: false,
    company: null,
    form: {
      manager: null,
    },
    isLoading: false,
  }),
  validations: {
    form: {
      manager: {
        required,
      },
    },
  },
  created() {
    this.loadTeamMembers();
  },
  methods: {
    loadTeamMembers() {
      this.teamMembers = this.$flairClient.MyService.getMyTeamMembers();
    },
    open(company) {
      this.company = company;
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    sendForm() {
      this.isLoading = true;
      const payload = {
        id: this.company.id,
        role: 'Analyst',
        members: [{
          user: {
            id: this.form.manager,
            type: 'User.Profile',
          },
          role: 'Manager',
        }],
      };
      this.$flairClient.DueDiligenceService.startDueDiligence(payload)
        .promise.then((res) => {
          this.$router.push({
            name: 'due-diligence-details-screen',
            params: { id: res.id },
          });
          this.isLoading = false;
        });
    },
  },
};
</script>
