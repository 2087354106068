export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RelatedCompanyList",
        translations: 






























































{
  "th": {
    "name": "Name",
    "country": "Country",
    "role": "Role",
    "affinities": "Affinities"
  }
}

      }
    }