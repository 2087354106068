export default function (Component) {
      Component.options._i18n = {
        namespace: "components.EcoVadisCategories",
        translations: 


















{
  "risks": {
    "env_score": "Environment",
    "sup_score": "Sustainable procurement",
    "fbp_score": "Ethics",
    "lab_score": "Labor & Human Rights"
  }
}


      }
    }