<template>
  <highcharts
    :options="chartOptions()"
    class="chart-pie-portfolio"
  />
</template>

<i18n namespace="components.PortfolioHealthPieChart">
{
    "lowest": "Lowest risk",
    "low": "Low risk",
    "medium": "Medium risk",
    "high": "High risk",
    "highest": "Highest risk"
}
</i18n>

<script>
import _ from 'lodash';
import { Chart } from 'highcharts-vue';

export default {
  name: 'PortfolioHealthPieChart',
  components: {
    highcharts: Chart,
  },
  props: {
    risks: {
      type: Array,
      default: null,
      required: true,
    },
    teamName: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    values: function() {
      const newValues = _.map(this.ts, (name) => {
        return {
          name: name,
          y: _.compact(_.map(this.risks, (item) => {
            return this.ts[item.riskLevel] === name ? item.numberOfCompanies : null;
          }))[0] || 0,
        };
      });
      return newValues;
    },
  },
  methods: {
    chartOptions: function() {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          type: 'pie',
        },
        title: {
          verticalAlign: 'middle',
          floating: true,
          text: this.trimText(this.teamName),
        },
        credits: { enabled: false },
        tooltip: {
          animation: false,
          enabled: false,
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            dataLabels: { enabled: false },
            colors: ['#008556', '#94a545', '#f6ba39', '#ff6f00', '#d02c2f'],
            showInLegend: false,
          },
          series: {
            enableMouseTracking: false,
          },
        },
        series: [{
          name: 'Brands',
          innerSize: '85%',
          data: this.values,
        }],
      };
    },
    trimText(text, threshold = 18) {
      if (text.length <= threshold) {
        return text;
      }
      return text.substr(0, threshold - 3).concat('…');
    },
  },
};
</script>
