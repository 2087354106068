<template>
  <highcharts
    :options="chartOptions()"
    class="chart-bar-consumption"
  />
</template>

<i18n namespace="components.DataConsumptionGraph">
{
  "planStartOn": "Yearly plan starts on {date}",
  "monthly": "Monthly",
  "yearlyAggregate": "Yearly aggregate",
  "aggregate": "aggregate",
  "screening": "Screening",
  "monitoring": "Monitoring"
}
</i18n>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'DataConsumptionGraph',
  components: {
    highcharts: Chart,
  },
  props: {
    consumption: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    monitoring: function() {
      return this.consumption.consumption.map((d) => d.monthlyMonitoring);
    },
    screening: function() {
      return this.consumption.consumption.map((d) => d.monthlyScreening);
    },
    cumulativeMonitoring: function() {
      return this.consumption.consumption.map((d) => d.cumulativeMonitoring);
    },
    cumulativeScreening: function() {
      return this.consumption.consumption.map((d) => d.cumulativeScreening);
    },
    categories: function() {
      return this.consumption.consumption.map((d) => d.monthPivotDate);
    },
    title: function() {
      return this.$options.filters.humanDate(this.consumption.startDate);
    },
  },
  methods: {
    chartOptions: function() {
      return {
        chart: {
          height: '400px',
          margin: [80,0,46,0],
          style: {
            fontFamily: 'Source Sans Pro',
            letterSpacing: '0.3px',
            lineHeight: '1.67',
          },
          backgroundColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, 'white'],
              [1, '#DCDCDB'],
            ],
          },
        },
        title: {
          text: this.ts.planStartOn({ date: this.title }),
          align: 'right',
          y: 20,
          x: -25,
        },
        credits: { enabled: false },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginTop: 5,
          itemMarginBottom: 25,
          x: 15,
          y: -35,
        },
        xAxis: {
          min: -0.3,
          max: 11.3,
          labels: {
            formatter: (args) => {
              return this.$options.filters.humanDateMonth(args.value);
            },
          },
          categories: this.categories,
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              x: 25,
              y: 15,
            },
            showFirstLabel: false,
            title: {
              align: 'high',
              textAlign: 'left',
              rotation: 0,
              y: -15,
              x: 15,
              text: this.ts.monthly,
            },
          }, { // Secondary yAxis
            labels: {
              format: '{value}',
              x: -25,
              y: 15,
            },
            showFirstLabel: false,
            title: {
              align: 'high',
              textAlign: 'left',
              rotation: 0,
              y: -15,
              x: -105,
              text: this.ts.yearlyAggregate,
            },
            opposite: true,
          },
        ],
        plotOptions: {
          series: {
            lineWidth: 1.5,
            marker: {
              enabled: false,
              symbol: 'circle',
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
            events: {
              legendItemClick: function() {
                return false;
              },
            },
          },
          column: {
            states: {
              hover: {
                enabled: false,
                halo: false,
              },
            },
          },
          line: {
            showInLegend: false,
            states: {
              hover: {
                enabled: true,
                halo: false,
                lineWidth: 1.5,
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
          borderWidth: 0,
          backgroundColor: null,
          padding: 0,
          formatter: (graph) => {
            const currentPoint = graph.chart.hoverPoint;
            let txt = `
              <div class="tooltip">
                <div class="header">
            `;
            const x = currentPoint.x;
            const series = currentPoint.series.chart.series;
            if (currentPoint.series.userOptions.shared) {
              txt += `${this.$options.filters.humanDateMonthYear(currentPoint.category)} </div>`;
              series.forEach(e => {
                if (e.userOptions.shared) {
                  (e.data).forEach(i => {
                    if (i.x === x) {
                      txt += `
                        <div class="content ${e.userOptions.className}">
                          <div>
                            <span class="bullets">\u25CF</span>
                            <span>${e.name}</span>
                          </div>
                          <div class="value">${i.y}</div>
                        </div>
                      `;
                    }
                  });
                }
              });
            } else {
              txt += `${this.$options.filters.humanDateMonth(currentPoint.category)} (${this.ts.aggregate})
                </div>
                <div class="content ${currentPoint.series.userOptions.className} ">
                  <div>
                    <span class="bullets">\u25CF</span>
                    <span>${currentPoint.series.name}</span>
                  </div>
                  <div class="value">${currentPoint.y}</div>
                </div>
              `;
            }
            return `${txt}</div>`;
          },
        },
        series: [{
          type: 'column',
          name: this.ts.screening,
          data: this.screening,
          className: 'column-screening',
          pointWidth: 15,
          shared: true,
        }, {
          type: 'column',
          name: this.ts.monitoring,
          data: this.monitoring,
          className: 'column-monitoring',
          pointWidth: 15,
          shared: true,
        },{
          yAxis:1,
          type: 'line',
          name: this.ts.screening,
          className: 'line-screening',
          data: this.cumulativeScreening,
          stickyTracking: false,
        },
        {
          yAxis:1,
          type: 'line',
          name: this.ts.monitoring,
          className: 'line-monitoring',
          data: this.cumulativeMonitoring,
          stickyTracking: false,
        }],
      };
    },
  },
};
</script>
