<template>
  <div
    @dragenter="dragEnter"
    @drop.prevent="addFile"
    @dragover.prevent
  >
    <form
      @submit.prevent="sendForm"
    >
      <div class="vadis-controller-make-decision">
        <ul>
          <li
            :class="['Approved' === form.makeDecision ? `active` : '', 'approve']"
            @click="form.makeDecision = 'Approved'"
          >
            <p>
              <i class="icon-Check" />
            </p>
            <span>
              {{ ts.approve }}
            </span>
          </li>
          <li
            :class="['Rejected' === form.makeDecision ? `active` : '', 'reject']"
            @click="form.makeDecision = 'Rejected'"
          >
            <p>
              <i class="icon-Close" />
            </p>
            <span>
              {{ ts.reject }}
            </span>
          </li>
        </ul>
      </div>
      <template v-if="form.makeDecision">
        <div class="motivation">
          <h6>{{ ts.motivation }}</h6>
          <div class="vadis-form-field-textarea">
            <textarea
              ref="textarea-motivation"
              v-model="form.comment"
              :class="!form.comment ? 'warning' : ''"
              :placeholder="ts.placeholder"
            />
          </div>
        </div>
        <div class="document">
          <h6>{{ ts.documents }}</h6>
          <DueDiligenceDocuments
            :documents="form.files"
            :read-only="false"
            @remove="removeFile($event)"
          />
          <div
            :class="['vadis-molecule-upload-file', dragHover ? 'drop' : '']"
          >
            <input
              id="file"
              type="file"
              name="file"
              class="inputfile"
              multiple
              @change="addFile"
            >
            <label
              ref="insideDrag"
              for="file"
            >
              <i class="icon-Upload" />
              <p>{{ ts.dragFile }} <a>{{ ts.browse }}</a></p>
            </label>
          </div>
        </div>
        <div class="buttons">
          <button
            type="submit"
            class="vadis-form-button-main-small"
            :disabled="$v.form.$invalid"
          >
            {{ ts.buttons.save }}
          </button>
          <button
            class="vadis-form-button-standard-small"
            @click="$emit('cancel')"
          >
            {{ ts.buttons.cancel }}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>

<i18n namespace="components.MakeDecisionForm">
{
  "motivation": "Motivation",
  "placeholder": "Write your motivation here...",
  "documents": "Documents that support the revision",
  "dragFile": "Drag files to upload, or click to ",
  "browse": "browse...",
  "reject": "Reject",
  "approve": "Approve",
   "buttons": {
    "save": "Save",
    "cancel": "Cancel"
  }
}
</i18n>

<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { DueDiligence } from 'flair-client';
import { Document } from 'flair-client/src/entities';
import DueDiligenceDocuments from '@/components/due-diligence/DueDiligenceDocuments';

export default {
  name: 'MakeDecisionForm',
  components: {
    DueDiligenceDocuments,
  },
  props: {
    dueDiligence: {
      type: DueDiligence,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    form: {
      makeDecision: null,
      comment: '',
      files: [],
    },
    dragHover: false,
  }),
  validations: {
    form: {
      makeDecision: {
        required,
      },
      comment: {
        required,
      },
    },
  },
  watch: {
    'form.motivation'() {
      this.resizeTextarea();
    },
  },
  methods: {
    dragEnter() {
      this.dragHover = true;
    },
    addFile(e) {
      this.dragHover = false;
      let droppedFiles;
      if (e.dataTransfer) {
        droppedFiles = e.dataTransfer.files;
      } else if (e.target) {
        droppedFiles = e.target.files;
      }
      if (!droppedFiles) {return;}
      ([...droppedFiles]).forEach(f => {
        this.addAttachments(f);
      });

    },
    removeFile(file) {
      document.getElementById('file').value = '';
      const payload = {
        guid: file.id,
      };
      this.$flairClient.DueDiligenceService.removeAttachments(payload)
        .promise.then(() => {
          this.form.files = this.form.files.filter(f => {
            return f !== file;
          });
        });
    },
    resizeTextarea() {
      if (this.form.comment.trim().length > (40 * 4)) {
        this.$refs['textarea-motivation'].style.height = '120px';
      } else if (this.form.comment.trim().length > (40 * 2)) {
        this.$refs['textarea-motivation'].style.height = '90px';
      } else {
        this.$refs['textarea-motivation'].style.height = '60px';
      }
    },
    addAttachments(file) {
      this.$flairClient.DueDiligenceService.addAttachments({ file })
        .promise.then(attachment => {
          const document = Document.fromFileAndAttachment(file, attachment);
          this.form.files.push(document);
        });
    },
    sendForm() {
      const documents = [];
      _.forEach(this.form.files, (e) => {
        const item = {
          type: 'DueDiligence.Document',
          id: e.ref,
        };
        documents.push(item);
      });
      const payload = {
        id: this.dueDiligence.id,
        decision: this.form.makeDecision,
        comment: this.form.comment,
        documents: documents || [],
      };
      this.$flairClient.DueDiligenceService.makeDecision(payload)
        .promise.then(() => {
          this.$emit('close');
        });
    },
  },
};

</script>
