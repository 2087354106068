<template>
  <Accordion
    :open="accordionOpen"
    class="form-row vadis-form-field-standard"
  >
    <template v-slot:title>
      <p>{{ provider.id }}</p>
      <div
        v-if="selectedIndicators.length"
        class="selected"
      >
        <span>{{ selectedIndicators.length }} {{ ts.selected }}</span>
      </div>
    </template>
    <div
      v-for="indicator in indicators"
      :key="indicator.id"
      class="checkbox-container"
    >
      <input
        :id="`${indicator.id}-${provider.id}`"
        v-model="selectedIndicators"
        :disabled="maxSelection"
        :value="indicator.name"
        :class="{disabled: maxSelection}"
        type="checkbox"
        @change="handleChange()"
      >
      <label
        :for="`${indicator.id}-${provider.id}`"
        class="indicator-name"
        :class="{disabled: maxSelection}"
      >
        {{ indicator.name | providerRiskIndicator }}
      </label>
    </div>
  </Accordion>
</template>

<i18n namespace="components.CRIAccordion">
{
  "selected": "selected"
}
</i18n>

<script>
import Accordion from '@/components/Accordion';

export default {
  name: 'CRIAccordion',
  components: {
    Accordion,
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    provider: {
      type: Object,
      required: true,
    },
    indicators: {
      type: Array,
      required: true,
    },
    maxSelection: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    accordionOpen: false,
    selectedIndicators: [],
  }),
  watch: {
    'value': {
      handler: function(value) {
        this.selectedIndicators = value;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleChange() {
      this.$emit('input', this.selectedIndicators);
    },
  },
};
</script>
