<template>
  <div class="company-list table-border">
    <table class="table-hover">
      <thead>
        <tr>
          <th>
            {{ ts.th.name }}
            <form
              v-if="hasFiltersEnabled"
              class="vadis-form-field-search-small-disabled"
            >
              <div class="field">
                <input
                  placeholder="Name..."
                >
                <div class="options">
                  <a class="icon">
                    <i class="icon-Search" />
                  </a>
                </div>
              </div>
            </form>
          </th>
          <th />
          <th>
            {{ ts.th.country }}
            <form
              v-if="hasFiltersEnabled"
              class="vadis-form-field-select"
            >
              <div class="field">
                <FilterDropdown
                  v-model="form.country"
                  :values="tsCountries"
                  :placeholder="'Country...'"
                >
                  <template v-slot="slotProps">
                    <CountryFlag
                      :country="slotProps.value.k"
                      :flag-only="true"
                    />
                    <a>{{ slotProps.value.v }}</a>
                  </template>
                </FilterDropdown>
              </div>
            </form>
          </th>
          <th class="no-open">
            {{ ts.th.identifier }}
            <form
              v-if="hasFiltersEnabled"
              class="vadis-form-field-search-small"
            >
              <div class="field">
                <input
                  v-model="form.europeanVAT"
                  placeholder="VAT..."
                >
                <div class="options">
                  <a class="icon">
                    <i class="icon-Search" />
                  </a>
                </div>
              </div>
            </form>
          </th>
          <th class="no-open">
            {{ ts.th.companyType }}
            <form
              v-if="hasFiltersEnabled"
              class="vadis-form-field-select"
            >
              <div class="field">
                <FilterDropdown
                  v-model="form.type"
                  :values="tsType"
                  :placeholder="'Type...'"
                >
                  <template v-slot="slotProps">
                    <a>{{ slotProps.value.v }}</a>
                  </template>
                </FilterDropdown>
              </div>
            </form>
          </th>
          <th class="no-open">
            {{ ts.th.group }}
            <form
              v-if="hasFiltersEnabled"
              class="vadis-form-field-search-small"
            >
              <div class="field">
                <input
                  v-model="form.group"
                  placeholder="Group..."
                >
                <div class="options">
                  <a class="icon">
                    <i class="icon-Search" />
                  </a>
                </div>
              </div>
            </form>
          </th>
          <th class="no-open" />
        </tr>
      </thead>
      <tbody
        v-if="companies.loading"
        class="vadis-molecule-loader"
      >
        <tr>
          <td colspan="7">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody
        v-if="companies.loaded && !companies.items.length"
      >
        <tr>
          <td colspan="7">
            <EmptySearch />
          </td>
        </tr>
      </tbody>
      <tbody v-if="companies.loaded && companies.items.length">
        <tr
          v-for="(company, i) in companies.items"
          :key="i"
          :class="{'current': isCurrent(company)}"
          @click="onClick(company)"
        >
          <td>
            <div class="risk-tag-company">
              <a class="name">{{ company.name }}</a>
              <RiskTag
                :risks="riskIndicators(company)"
                @click="onClickRisk(company, $event.risk)"
              />
            </div>
          </td>
          <td>
            <PortfolioButton
              v-if="$featureEnabled('portfolio')"
              :subject="company"
            />
          </td>
          <td>
            <CompanyLocation :company="company" />
          </td>
          <td class="no-open">
            {{ company.europeanVAT }}
          </td>
          <td class="no-open">
            {{ company.companyType|companyType }}
          </td>
          <td class="no-open">
            <p v-if="company.primaryGroup">
              {{ company.primaryGroup.name }}
            </p>
          </td>
          <td class="no-open">
            <ContextualMenu
              :items="contextualMenuItems"
              :context="company"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="companies.loaded && companies.hasPagination()"
      :current-page="companies.meta.pageNumber"
      :total-items="companies.meta.totalItems"
      :number-of-lines="companies.meta.pageSize"
      :links="companies.links"
      @click="onClickPagination($event)"
    />
  </div>
</template>

<i18n namespace="components.CompanyList">
{
  "th": {
    "name": "Name",
    "country": "Country",
    "identifier": "VAT number",
    "group": "Group",
    "companyType": "Type"
  },
  "removeFromRecent": "Remove from recent"
}
</i18n>

<script>
import _ from 'lodash';
import { Collection, SearchState } from 'flair-client';
import Loader from '@/components/Loader';
import CompanyLocation from '@/components/company/CompanyLocation';
import RiskTag from '@/components/risk/RiskTag';
import Pagination from '@/components/Pagination';
import FilterDropdown from '@/components/FilterDropdown';
import CountryFlag from '@/components/CountryFlag';
import EmptySearch from '@/components/EmptySearch';
import ContextualMenu from '@/components/ContextualMenu';
import PortfolioButton from '@/components/portfolio/PortfolioButton';

export default {
  name: 'CompanyList',
  components: {
    Loader,
    CompanyLocation,
    RiskTag,
    Pagination,
    FilterDropdown,
    CountryFlag,
    EmptySearch,
    ContextualMenu,
    PortfolioButton,
  },
  props: {
    search: {
      type: SearchState,
      default: null,
    },
    companies: {
      type: Collection,
      default: null,
      required: true,
    },
    current: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data: () => ({
    company: undefined,
    form: {
      country: undefined,
      europeanVAT: undefined,
      type: undefined,
      group: undefined,
    },
    tsType: null,
  }),
  computed: {
    hasFiltersEnabled() {
      return !!this.search;
    },
    tsTypes() {
      return this.$flairClient.ts('companyTypes');
    },
    tsTypesBvD() {
      return this.$flairClient.ts('companyTypesBvD');
    },
    tsCountries() {
      return this.$flairClient.ts('countries');
    },
    contextualMenuItems() {
      const items = {
        removeFromRecent: {
          label: this.ts.removeFromRecent,
          onClick: this.removeFromRecent,
        },
      };
      return items;
    },
  },
  watch: {
    form: {
      handler() {
        this.search.setFilters(this.form);
      },
      deep: true,
    },
    'search.current': {
      handler() {
        this.resetSearchForm();
      },
      deep: true,
    },
  },
  created() {
    if (this.search) {
      this.resetSearchForm();
    }
    this.tsType = this.$featureEnabled('creditSafe') ? this.tsTypes : this.tsTypesBvD;
  },
  methods: {
    removeFromRecent(company) {
      this.$flairClient.MyService.removeFromBrowseHistory({ id: company.id });
    },
    onClick(c) {
      this.$emit('click', c);
    },
    isCurrent(c) {
      return c === this.current;
    },
    riskIndicators(company) {
      const risks = _.get(company, 'last.riskProfile.subrisks');
      return _.filter(risks, (r) => r.score > 0);
    },
    onClickRisk(company, risk) {
      this.$emit('onClickRisk', { company, risk });
    },
    onClickPagination(event) {
      this.companies[event]();
    },
    resetSearchForm() {
      const search = this.search.current.attributes;
      Object.entries(this.form).forEach(([filter, oldValue]) => {
        const newValue = search[filter];
        if (newValue !== oldValue) {
          this.form[filter] = newValue;
        }
      });
    },
  },
};
</script>
