<template>
  <div>
    <div
      v-if="title"
      :class="classes(titleClass)"
    >
      <h5>{{ value(title) }}</h5>
    </div>
    <div
      v-for="(item, j) in items"
      :key="j"
      :class="classes(itemClass, item.class)"
    >
      <span
        v-if="item.label"
        :class="classes(labelClass, item.labelClass)"
      >
        {{ value(item.label) }}
      </span>
      <router-link
        v-if="item.clickable"
        :class="classes(valueClass, item.valueClass)"
        :to="addParamsToRouterLink(item.clickable)"
      >
        <component
          :is="item.component"
          v-if="item.component"
          v-bind="componentProperties(item.componentProps)"
        />
        <template v-else>
          {{ itemValue(item) }}
        </template>
      </router-link>
      <span
        v-else
        :class="classes(valueClass, item.valueClass)"
      >
        <component
          :is="item.component"
          v-if="item.component"
          v-bind="componentProperties(item.componentProps)"
        />
        <template v-else>
          {{ itemValue(item) }}
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { TranslationGroup } from 'flair-client';

export default {
  name: 'ItemDetails',
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    i18n: {
      type: TranslationGroup,
      default: null,
    },
    context: {
      type: Object,
      default: undefined,
    },
    titleClass: {
      type: String,
      default: 'item-details-title',
    },
    itemClass: {
      type: String,
      default: 'item-details-item',
    },
    labelClass: {
      type: String,
      default: 'item-details-item-label',
    },
    valueClass: {
      type: String,
      default: 'item-details-item-value',
    },
    routerLink: {
      type: Object,
      default: null,
    },
  },
  methods: {
    itemValue(item) {
      const value = _.get(this.context, item.path);
      if (item.filter) {
        const vueFilter = this.$options.filters[item.filter];
        if (!vueFilter) {
          console.warn('Item refers to an unknown filter:', item.filter);
        }
        return vueFilter(value);
      }
      return value;
    },
    value(v) {
      if (this.i18n) {
        return _.get(this.i18n, v);
      } else {
        return v;
      }
    },
    classes: function() {
      return Array.prototype.join.call(arguments, ' ');
    },
    componentProperties(properties) {
      return _.reduce(properties, (props, propVal, propName) => {
        let value = propVal;
        if (_.isFunction(propVal)) {
          value = propVal(this.context);
        }
        props[propName] = value;
        return props;
      }, {});
    },
    addParamsToRouterLink(tab) {
      if (typeof tab !== 'string') {
        return this.routerLink;
      }
      const router = { ...this.routerLink, params: { ...this.routerLink.params } };
      router.params.tab = tab;
      return router;

    },
  },
};
</script>
