export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligenceOverview",
        translations: 

































































































































































{
  "duediligence": "Due Diligence",
  "documents": "Documents",
  "dragFile": "Drag files to upload",
  "placeholder": "Write a message here ....",
  "button": {
    "post": "Post",
    "cancel": "Cancel",
    "hideDetails": "Hide details",
    "importDocument": "Import document",
    "reviseRisk": "Revise risk",
    "addDecision": "Add decision"
  }
}

      }
    }