<template>
  <div class="vadis-molecule-chat-message">
    <div class="vadis-molecule-avatar">
      <img src="../../../assets/flair.svg">
    </div>
    <div
      class="content"
    >
      <div class="name">
        <span class="bold">Vadis Flair</span>
      </div>
      <div class="date">
        {{ event.createdAt | humanDateHour }}
      </div>
      <div
        class="copy"
      >
        <p v-html="ts.surveyCompleted({ q: getSurveysName(event.extraParameters.surveySubmissionRef) })" />
        <div class="survey-info">
          <div class="text">
            <p class="name">
              {{ getSurveysName(event.extraParameters.surveySubmissionRef) }}
            </p>
            <p class="completed">
              {{ ts.completedOn }} {{ event.createdAt | humanDateHour }}
            </p>
          </div>
          <div
            v-if="!print"
            class="button"
          >
            <button
              class="vadis-form-button-standard-small"
              @click="$emit('details', event.extraParameters.surveySubmissionRef)"
            >
              {{ ts.buttons.details }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.SurveySubmissionEvent">
{
  "surveyCompleted": "The survey <span class=\"italic\">{q}</span> has been completed.",
  "completedOn": "Completed on",
  "buttons": {
    "details": "Details"
  }
}
</i18n>

<script>
import _ from 'lodash';

export default {
  name: 'SurveySubmissionEvent',
  props: {
    dueDiligence: {
      type: Object,
      default: null,
      required: true,
    },
    event: {
      type: Object,
      default: null,
      required: true,
    },
    print: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    getSurveysName(surveyId) {
      return _.get(this.dueDiligence.getSurvey(surveyId.toString()), 'name');
    },
  },
};
</script>
