export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CustomRiskIndicatorPane",
        translations: 



































{
  "createIndicator": "Create custom indicator"
}

      }
    }