<template>
  <div>
    <form
      id="login-form"
      class="vadis-organism-login"
      novalidate
      @submit.prevent="validateCreds"
    >
      <h3>{{ ts.title }}</h3>
      <div
        :class="[$v.form.password.$error ? 'error-messages error-messages-visible' : 'info-message']"
      >
        <p>{{ ts.errors.passwordRules }}</p>
      </div>
      <div class="form-row">
        <div :class="{ 'vadis-form-field-password-standalone': true, 'invalid-password': $v.form.password.$error }">
          <input
            id="password-input"
            v-model.trim="form.password"
            :type="passwordInputType"
            :placeholder="ts.placeholders.password"
            :disabled="sending"
            @input="$v.form.password.$touch"
          >
          <i
            :class="showPassword ? 'icon-Visible' : 'icon-Hidden'"
            @click="showPassword = !showPassword"
          />
        </div>
        <p
          v-if="$v.form.password.$error"
          class="validation-error"
        >
          {{ ts.errors.passwordRequired }}
        </p>
      </div>
      <div class="form-row">
        <div :class="{ 'vadis-form-field-password-standalone': true, 'invalid-password': $v.form.repeatPassword.$error }">
          <input
            id="repeatPassword-input"
            v-model.trim="form.repeatPassword"
            :type="passwordInputType"
            :placeholder="ts.placeholders.repeatPassword"
            :disabled="sending"
            @input="$v.form.repeatPassword.$touch"
          >
          <i
            :class="showPassword ? 'icon-Visible' : 'icon-Hidden'"
            @click="showPassword = !showPassword"
          />
        </div>
        <p
          v-if="$v.form.repeatPassword.$error"
          class="validation-error"
        >
          {{ ts.errors.repeatPasswordRequired }}
        </p>
      </div>
      <div class="form-row buttons">
        <button
          type="submit"
          :disabled="$v.form.$invalid || sending"
        >
          {{ ts.buttons.confirm }}
        </button>
      </div>
    </form>
  </div>
</template>

<i18n namespace="components.PasswordResetForm">
{
  "title": "Reset password",
  "errors": {
    "passwordRequired": "The new password is required",
    "repeatPasswordRequired": "Passwords must be identical",
    "passwordRules": "Your password needs to include both lower and uppercase characters, at least 1 number, and be between 12-24 characters long"
  },
  "buttons": {
    "confirm": "Confirm"
  },
  "placeholders": {
    "password": "Password",
    "repeatPassword": "Repeat password"
  }
}
</i18n>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import validPassword from '@/validators/password-validation';

export default {
  name: 'PasswordResetForm',
  props: {
    token: {
      type: String,
      default: null,
      required: true,
    },
  },
  data: () => ({
    showPassword: false,
    form: {
      password: '',
      repeatPassword: '',
    },
    sending: false,
    resetPasswordSuccess: null,
  }),
  validations: {
    form: {
      password: {
        required,
        validPassword,
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  computed: {
    passwordInputType() {
      return this.showPassword ? 'text' : 'password';
    },
    errorMessagesClass() {
      return {
        'form-row': true,
        'error-messages': true,
        'error-messages-visible': (this.resetPasswordSuccess === false),
      };
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.password = null;
      this.form.repeatPassword = null;
    },
    resetPassword() {
      this.sending = true;
      this.$flairClient.AuthenticationService.resetPassword({ password: this.form.password, token: this.token })
        .promise.then(() => {
          this.sending = false;
          this.clearForm();
          this.$router.push('/');
        }).catch((err) => {
          console.error('PasswordResetForm#password reset failed', err);
          this.sending = false;
        });
    },
    validateCreds() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.resetPassword();
      }
    },
  },
};
</script>
