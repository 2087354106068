export default function (Component) {
      Component.options._i18n = {
        namespace: "components.InvalidSecretKey",
        translations: 


















{
  "apologies": "Apologies, you do not have the correct credentials to access this area.",
  "buttons": {
    "backHome": "Baske home"
  }
}

      }
    }