<template>
  <div class="person-overview">
    <header>
      <div class="title">
        <button
          class="vadis-form-button-standard-icon btn-close"
          @click="$emit('close')"
        >
          <i class="icon-Close" />&nbsp;{{ ts.buttons.close }}
        </button>
        <div class="info">
          <h4>
            {{ person.fullName }}
          </h4>
          <div class="subtitle">
            <CountryFlag :country="person.nationality" />
            <p>
              {{ person.birthDate | birthDate }}
              <span
                v-if="person.deathDate"
                class="death-icon"
              />
              {{ person.deathDate | birthDate }}
              <GoogleSearchLink :q="person.fullName" />
            </p>
          </div>
        </div>
        <router-link
          tag="button"
          :to="{ name: 'person-details-screen', params: { id: person.id, tab: 'summary' } }"
          class="vadis-form-button-main btn-details"
        >
          {{ ts.buttons.personDetails }}
        </router-link>
      </div>
      <div
        v-if="riskIndicators"
        class="indicators"
        :class="{active: hover}"
      >
        <RiskIndicator
          v-for="indicator in riskIndicators"
          :key="indicator.id"
          :risk="indicator"
          @over="hover=true;"
          @leave="hover=false;"
          @click="$emit('onClickRisk', { person: person, risk: $event.risk })"
        />
      </div>
    </header>
    <section>
      <h6>{{ ts.labels.relatedCompanies }}</h6>
      <CompanyEmptyPane v-if="isEmpty" />
      <RelatedCompanyList
        v-else
        :companies="companies"
      />
    </section>
  </div>
</template>

<i18n namespace="components.PersonOverview">
{
  "buttons": {
    "close": "Close",
    "personDetails": "Person Details"
  },
  "labels": {
    "relatedCompanies": "Related companies"
  }
}
</i18n>

<script>
import RiskIndicator from '@/components/risk/RiskIndicator';
import CompanyEmptyPane from '@/components/company/CompanyEmptyPane';
import GoogleSearchLink from '@/components/GoogleSearchLink';
import RelatedCompanyList from '@/components/RelatedCompanyList';
import CountryFlag from '@/components/CountryFlag';
import { Collection } from 'flair-client';
import _ from 'lodash';

export default {
  name: 'PersonOverview',
  components: {
    RiskIndicator,
    GoogleSearchLink,
    RelatedCompanyList,
    CountryFlag,
    CompanyEmptyPane,
  },
  props: {
    person: {
      type: Object,
      default: null,
      required: true,
    },
    companies: {
      type: Collection,
      default: null,
      required: false,
    },
  },
  data: () => ({
    hover: false,
    activeNav: undefined,
  }),
  computed: {
    riskIndicators() {
      return _.at(this.person, 'last.riskProfile.subrisks')[0];
    },
    isEmpty() {
      return this.companies && this.companies.items && this.companies.items.length === 0;
    },
  },
};
</script>
