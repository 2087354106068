<template>
  <div>
    <Accordion
      :open="accordionOpen"
    >
      <template v-slot:title>
        <h5>{{ risk.title }}</h5>
      </template>

      <RiskQuestionAccordion
        v-for="question in risk.questions"
        :key="question.question"
        :question="question"
      />
    </Accordion>
  </div>
</template>

<script>
import RiskQuestionAccordion from '@/components/help/RiskQuestionAccordion';
import Accordion from '@/components/Accordion';

export default {
  name: 'RiskIndicatorAccordion',
  components: {
    Accordion,
    RiskQuestionAccordion,
  },
  props: {
    risk: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    accordionOpen: false,
  }),
};
</script>
