<template>
  <div
    v-click-outside="clickOutside"
    class="vadis-controller-dropdown"
  >
    <div class="select">
      <input
        :value="inputValue"
        :placeholder="placeholder"
        autocomplete="off"
        @click="toggleMenu()"
        @input="filterList"
        @keydown.enter.prevent="filterList"
      >
      <div
        class="options"
        @click="toggleMenu()"
      >
        <div class="sort">
          <i class="icon-Arrow-up-small" /><i class="icon-Arrow-down-small" />
        </div>
      </div>
    </div>
    <div>
      <ul v-if="isOpen">
        <li
          v-if="!required"
          @click="chooseOption(null)"
        />
        <li
          v-for="(item, i) in listValues"
          :key="i"
          :class="{active: isCurrent(item.k)}"
          @click="chooseOption(item.k, item.v)"
        >
          <slot :value="{ v: item.v, k: item.k }">
            <a>{{ item.v }}</a>
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { ClickOutside } from '@/directives';

export default {
  name: 'FilterDropdown',
  directives: {
    ClickOutside,
  },
  props: {
    values: {
      type: Object,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: undefined,
    },
    required: {
      default: false,
      type: Boolean,
    },
    sorted: {
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    isOpen: false,
    listValues: null,
    inputValue: '',
  }),
  computed: {
  },
  watch: {
    value:{
      handler(newVal) {
        this.inputValue = newVal ? this.values[newVal] : newVal;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.sorted) {
      this.listValues = this.orderList(this.values);
    } else {
      this.listValues = _(this.values)
        .map((v, k) => {
          return { k, v };
        })
        .value();
    }
  },
  methods: {
    clickOutside() {
      this.isOpen = false;
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    isCurrent(code) {
      return this.value === code;
    },
    chooseOption(key, value) {
      this.inputValue = value;
      this.$emit('input', key);
      this.isOpen = false;
    },
    orderList(list) {
      return _(list)
        .map((v, k) => {
          return { k, v };
        })
        .sortBy('v')
        .value();
    },
    filterList: _.debounce(function(event) {
      this.inputValue = event.target.value;
      const v = _.reduce(this.values, (o, acc, k) => {
        if (acc.toLowerCase().includes(event.target.value.toLowerCase())) {
          o[k] = acc;
        }
        return o;
      }, {});
      this.listValues = this.orderList(v);
    }, 500),
  },
};
</script>
