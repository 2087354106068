export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyNotFound",
        translations: 








{
  "notFound": "Company not found",
  "companyRemoved": "This company cannot be displayed or has been removed from our database."
}

      }
    }