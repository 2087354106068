<template>
  <div>
    <StartDueDiligenceModal
      v-if="$featureEnabled('dueDiligence')"
      ref="startDueDiligenceModal"
    />
    <div class="vadis-organism-tab-empty">
      <img
        class=""
        src="../../assets/due-diligence_empty.svg"
      >
      <h6>{{ ts.noDueDiligence }}</h6>
      <button
        v-if="$featureEnabled('dueDiligence')"
        class="vadis-form-button-main"
        @click="start()"
      >
        {{ ts.startDueDiligence }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.DueDiligenceEmpty">
{
  "noDueDiligence": "No due diligence has been executed, yet.",
  "startDueDiligence": "Start due diligence"
}
</i18n>

<script>
import { Company } from 'flair-client';
import StartDueDiligenceModal from '@/components/due-diligence/StartDueDiligenceModal';

export default {
  name: 'DueDiligenceEmpty',
  components: {
    StartDueDiligenceModal,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
  methods: {
    start() {
      this.$refs.startDueDiligenceModal.open(this.company);
    },
  },
};
</script>
