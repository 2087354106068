export default function (Component) {
      Component.options._i18n = {
        namespace: "components.ManagementList",
        translations: 




































































































{
  "th": {
    "name": "Name",
    "function": "Function",
    "dateOfBirth": "Date of birth",
    "nationality": "Nationality",
    "affinities": "Affinities",
    "relatedCompanies": "Related Companies",
    "status": "Status"
  },
  "Current": "Current",
  "Previous": "Previous"
}

      }
    }