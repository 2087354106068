import _ from 'lodash';
import ProxyGraph from './proxy.graph';
import GraphLib from 'graphlib';

export default class TreeGraph extends ProxyGraph {
  #keptNodesIndex;
  #keptEdges;
  #treedata;

  get nodes() {
    return Object.values(this.keptNodesIndex);
  }

  get edges() {
    if (!this.#keptEdges) {
      this.#keptEdges = this.decorated.edges.filter((e) => {
        return this.hasNode(e.source.id) && this.hasNode(e.target.id);
      });
    }
    return this.#keptEdges;
  }

  hasNode(nodeId) {
    return !!this.keptNodesIndex[nodeId];
  }

  get keptNodesIndex() {
    if (!this.#keptNodesIndex) {
      this._buildIndexes();
    }
    return this.#keptNodesIndex;
  }

  toTreeData() {
    if (!this.#treedata) {
      this._buildIndexes();
    }
    return this.#treedata;
  }

  _buildIndexes() {
    const glib = this.decorated.graphlib;
    const weight = (e) => {
      return glib.edge(e).participation.total || 0;
    };
    const root = this.decorated.centerNodeId;
    const tree = GraphLib.alg.dijkstra(glib, root, weight);

    this.#keptNodesIndex = this._buildNodeIndex(glib, tree);
    this.#treedata = this._buildTreeData(glib, tree);
  }

  _buildNodeIndex(glib, tree) {
    return Object.keys(tree).filter((n) => {
      return tree[n].distance !== Infinity;
    }).reduce((t, n) => {
      t[n] = glib.node(n);
      return t;
    }, {});
  }

  _buildTreeData(glib, tree) {
    const ensureNode = (idx, nodeId) => {
      idx[nodeId] = idx[nodeId] || {
        node: glib.node(nodeId),
        children: [],
      };
      return idx[nodeId];
    };

    const edgeIndex = this.decorated.edges.reduce((idx, e) => {
      idx[e.target.id] = e;
      return idx;
    }, {});

    const treeIndex = Object.keys(tree).reduce((idx, nodeId) => {
      if (tree[nodeId].distance === Infinity) {return idx;}
      const node = ensureNode(idx, nodeId);
      const parent = ensureNode(idx, tree[nodeId].predecessor);
      parent.children.push(Object.assign({}, node, {
        participation: _.get(edgeIndex, `${nodeId}.participation`) || {},
      }));
      return idx;
    }, {});

    return treeIndex[this.centerNodeId];
  }

  remove(transformed) {
    if (this === transformed) {
      return this.decorated;
    } else {
      return new TreeGraph(this.decorated.remove(transformed));
    }
  }
}
