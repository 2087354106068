<template>
  <div>
    <SurveyTranslationFormModal
      ref="surveyTranslationFormModal"
      :context="surveyModalContext"
      :survey-translations="survey.translations"
    />
    <Accordion
      :open="accordionOpen"
      :disabled="!surveysPresent"
    >
      <template v-slot:title>
        <div
          v-if="!renamingSurvey"
          class="title"
        >
          <div class="left">
            <h5>{{ survey.name }}</h5>
            <p>{{ survey.translations.length }} {{ ts.language }}</p>
          </div>
          <div class="right">
            <ContextualMenu
              :items="contextualMenuItems"
            />
          </div>
        </div>
        <SurveyRenameForm
          v-else
          :rename="true"
          :survey="survey"
          @close="renamingSurvey = false"
        />
      </template>
      <template v-if="accordionOpen && surveysPresent">
        <div
          v-for="(translation) in survey.translations"
          :key="translation.id"
          class="individual-survey-section"
        >
          <SurveyTranslationAccordionItem
            :translation="translation"
            :survey="survey"
          />
        </div>
      </template>
    </Accordion>
    <div
      class="individual-survey-section"
    >
      <button
        class="vadis-form-button-main"
        @click="addLanguageToSurvey()"
      >
        {{ ts.button.addLanguage }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.SurveyAccordion">

{
  "language": "language(s)",
  "renameSurveyTemplate": "Rename survey template",
  "removeSurvey": "Remove survey",
  "removeSurveyTemplate": "All the survey versions (languages) contained will be lost",
  "remove": "Remove '{q}'?",
  "addNewLanguage": "Add new language survey",
  "button": {
    "addLanguage": "Add language"
  }
}
</i18n>

<script>
import _ from 'lodash';
import Accordion from '@/components/Accordion';
import ContextualMenu from '@/components/ContextualMenu';
import SurveyTranslationFormModal from '@/components/surveys/SurveyTranslationFormModal';
import SurveyRenameForm from '@/components/surveys/SurveyRenameForm';
import SurveyTranslationAccordionItem from '@/components/surveys/SurveyTranslationAccordionItem';
import { Survey } from 'flair-client';

export default {
  name: 'SurveyAccordion',
  components: {
    Accordion,
    ContextualMenu,
    SurveyTranslationFormModal,
    SurveyRenameForm,
    SurveyTranslationAccordionItem,
  },
  props: {
    survey: {
      type: Survey,
      default: null,
      required: true,
    },
  },
  data: () => ({
    renamingSurvey: false,
    accordionOpen: true,
    surveysPresent: true,
  }),
  computed: {
    contextualMenuItems() {
      return {
        renameSurveyTemplate: {
          label: this.ts.renameSurveyTemplate,
          onClick: this.clickRenameSurvey,
        },
        removeSurvey: {
          label: this.ts.removeSurvey,
          onClick: this.clickRemoveSurvey,
        },
      };
    },
    surveyModalContext() {
      return {
        title: this.ts.addNewLanguage,
        button: this.ts.button.addLanguage,
        onClick: this.clickAddLanguage,
      };
    },
  },
  mounted() {
    if (!this.survey.translations.length) {
      this.surveysPresent = false;
    }
  },
  methods: {
    getLanguages() {
      return _.find(this.$flairClient.ts('locales'), (k, v) => this.translation.language === v);
    },
    clickRemoveSurvey() {
      this.openConfirmationModal({
        title: this.ts.remove({ q: this.survey.name }),
        subtitle: this.ts.removeSurveyTemplate,
        onConfirm: (modal) => {
          this.$flairClient.SurveyService.removeSurvey({ id: this.survey.id })
            .promise.then(() => modal.close());
        },
      });
    },
    clickRenameSurvey() {
      this.renamingSurvey = true;
      this.newSurveyName = true;
    },
    addLanguageToSurvey() {
      this.$refs.surveyTranslationFormModal.open();
    },
    clickAddLanguage(payload) {
      payload.id = this.survey.id;
      this.$flairClient.SurveyService.addSurveyTranslation(payload)
        .promise.then(() => {
          this.$refs.surveyTranslationFormModal.close();
        });
    },
  },
};
</script>

