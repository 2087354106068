export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SurveyDetails",
        translations: 
























































{
  "completedBy": "Completed by",
  "on": "on",
  "questionsTo": "Question {a} of {b}",
  "downloadPDF": "Download PDF"
}

      }
    }