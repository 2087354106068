<template>
  <div
    v-if="isOpen"
    class="vadis-modal-assessment"
  >
    <div class="header">
      <h6>{{ ts.makeDecision }}</h6>
      <p>{{ dueDiligence.relationships.company.name }}</p>
      <i
        class="icon-Close"
        @click="close()"
      />
    </div>
    <MakeDecisionForm
      :due-diligence="dueDiligence"
      @cancel="close()"
      @close="close()"
    />
  </div>
</template>

<i18n namespace="components.MakeDecisionModal">
{
  "makeDecision": "Make decision"
}
</i18n>

<script>
import MakeDecisionForm from '@/components/due-diligence/MakeDecisionForm';

// The instance of our Singleton component
let _instance;

export default {
  name: 'MakeDecisionModal',
  components: {
    MakeDecisionForm,
  },
  data: () => ({
    isOpen: false,
    dueDiligence: null,
  }),
  created() {
    if (_instance) {
      throw new Error('MakeDecisionModal is not supposed to be included more than once');
    }
    _instance = this;
  },
  destroyed() {
    _instance = null;
  },
  /** PUBLIC API */
  open(dueDiligence) {
    return _instance.open(dueDiligence);
  },
  close() {
    return _instance.close();
  },
  /** PRIVATE API */
  methods: {
    open(dueDiligence) {
      this.dueDiligence = dueDiligence;
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
