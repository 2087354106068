<template>
  <div class="due-diligence-container">
    <div
      class="due-diligences-list"
    >
      <Accordion
        :open="accordionOpen"
      >
        <template v-slot:title>
          <h6>{{ tableTitle }}</h6>
          <p
            class="dd-items vadis-controller-badge"
          >
            {{ dueDiligences.items.length }}
          </p>
        </template>
        <table>
          <thead>
            <tr>
              <th>
                {{ ts.th.companyName }}
              </th>
              <th>
                {{ ts.th.createdAt }}
              </th>
              <th>
                {{ ts.th.computedRisk }}
              </th>
              <th>
                {{ ts.th.revisedRisk }}
              </th>
              <th>
                {{ ts.th.analyst }}
              </th>
              <th>
                {{ ts.th.manager }}
              </th>
              <th
                v-if="$vnode.key === 'closed'"
              >
                {{ ts.th.decision }}
              </th>
              <th />
            </tr>
          </thead>
          <tbody
            v-if="!dueDiligences || dueDiligences.loading"
            class="vadis-molecule-loader"
          >
            <tr>
              <td colspan="7">
                <Loader
                  :global="false"
                  :shown="true"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="dd in dueDiligences.items"
              :key="dd.id"
              @click="goToDueDiligence(dd)"
            >
              <td class="name">
                <div class="vadis-molecule-country-due-diligence">
                  <span><a>{{ dd.relationships.company.name }}</a></span>
                  <div>
                    <CountryFlag
                      :country="dd.relationships.company.address.country"
                      :flag-only="true"
                    />
                    <span>{{ dd.relationships.company.europeanVAT }}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="tag-role">
                  <p class="name">
                    {{ dd.startedAt | humanDate }}
                  </p>
                </div>
              </td>
              <td>
                <p :class="`vadis-controller-tag-risk vadis-controller-tag-global-risk-level${dd.computedRisk}`">
                  {{ transformRiskScore(dd.computedRisk) }}
                </p>
              </td>
              <td>
                <p :class="`vadis-controller-tag-risk vadis-controller-tag-revised-risk-level${dd.revisedRisk}`">
                  {{ dd.revisedRisk }}
                </p>
              </td>
              <td>
                <template
                  v-for="member in orderedMembers(dd).Analyst"
                >
                  <div
                    :key="member.id"
                    class="member"
                  >
                    <div class="vadis-molecule-avatar-small">
                      <p>{{ member.initials }}</p>
                    </div>
                    {{ member.fullName }}
                  </div>
                </template>
              </td>
              <td>
                <template
                  v-for="member in orderedMembers(dd).Manager"
                >
                  <div
                    :key="member.id"
                    class="member"
                  >
                    <div class="vadis-molecule-avatar-small">
                      <p>{{ member.initials }}</p>
                    </div>
                    {{ member.fullName }}
                  </div>
                </template>
              </td>
              <td
                v-if="$vnode.key === 'closed'"
                :class="`evaluation-${dd.currentStatus}`"
              >
                {{ ts.decision[dd.currentStatus] }}
                <i
                  :class="`icon-${dd.currentStatus}`"
                />
              </td>
              <td>
                <router-link
                  tag="button"
                  class="vadis-form-button-standard-small"
                  :to="{ name: 'due-diligence-details-screen', params: { id: dd.id } }"
                >
                  {{ ts.buttons.details }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </Accordion>
    <!-- Pagination to go here -->
    </div>
  </div>
</template>

<i18n namespace="components.DueDiligenceDetailsList">
{
  "underInvestigation": "Under investigation",
  "closedDueDiligences": "Closed due diligence",
  "th": {
    "companyName": "Company",
    "createdAt": "Created at",
    "computedRisk": "Computed risk",
    "revisedRisk": "Revised risk",
    "analyst": "Analyst",
    "manager": "Manager",
    "decision": "Decision"
  },
  "decision": {
    "Rejected": "Rejected",
    "Approved": "Approved",
    "UnderEvaluation": "Under Evaluation"
  },
  "buttons": {
    "details": "Details"
  }
}
</i18n>

<script>
import _ from 'lodash';
import Loader from '@/components/Loader';
import { Collection } from 'flair-client';
import { transformRiskScore } from 'flair-client/src/utils';
import CountryFlag from '@/components/CountryFlag';
import Accordion from '@/components/Accordion';

export default {
  name: 'DueDiligenceDetailsList',
  components: {
    Accordion,
    CountryFlag,
    Loader,
  },
  props: {
    dueDiligences: {
      type: Collection,
      default: null,
      required: true,
    },
    title: {
      type: String,
      default: null,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    transformRiskScore,
    accordionOpen: true,
  }),
  computed: {
    tableTitle() {
      return this.title || this.ts.status;
    },
  },
  methods: {
    orderedMembers(item) {
      const status = ['Analyst', 'Manager'];
      return _.reduce(status, (dd, s) => {
        dd[s] = _.filter(item.members, (i) => {
          return i.attributes.role === s;
        });
        return dd;
      }, {});
    },
    goToDueDiligence(dd) {
      this.$router.push({
        name: 'due-diligence-details-screen',
        params: { id: dd.id },
      });
    },
  },
};
</script>

