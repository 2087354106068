<template>
  <div class="vadis-organism-related-company">
    <MatchingCompanyModal
      ref="openMatching"
      class="content"
    />
    <table class="vadis-organism-table">
      <thead>
        <tr>
          <th>{{ ts.th.name }}</th>
          <th>{{ ts.th.country }}</th>
          <th>{{ ts.th.role }}</th>
          <th>{{ ts.th.affinities }}</th>
        </tr>
      </thead>
      <tbody
        v-if="!companies || (!!companies && companies.loading)"
        class="vadis-molecule-loader"
      >
        <tr>
          <td colspan="4">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-if="companies && companies.loaded">
        <tr
          v-for="(company, i) in companies.items"
          :key="i"
          @click="setCurrent(company)"
        >
          <td>
            <a>{{ company.target.name }}</a>
          </td>
          <td>
            <CompanyLocation :company="company.target" />
          </td>
          <td>
            {{ company.function.stdCode | personFunction }}
          </td>
          <td>
            <a class="vadis-molecule-amount">
              {{ company.target.last.neighborhood.numberOfRelatedPeople | unknown }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="companies && companies.loaded && companies.hasPagination()"
      :current-page="companies.meta.pageNumber"
      :total-items="companies.meta.totalItems"
      :number-of-lines="companies.meta.pageSize"
      :links="companies.links"
      @click="onClickPagination($event)"
    />
  </div>
</template>

<i18n namespace="components.RelatedCompanyList">
{
  "th": {
    "name": "Name",
    "country": "Country",
    "role": "Role",
    "affinities": "Affinities"
  }
}
</i18n>

<script>
import MatchingCompanyModal from '@/components/MatchingCompanyModal';
import CompanyLocation from '@/components/company/CompanyLocation';
import Pagination from '@/components/Pagination';
import Loader from '@/components/Loader';
import { Collection } from 'flair-client';

export default {
  name: 'RelatedCompanyList',
  components: {
    CompanyLocation,
    Loader,
    Pagination,
    MatchingCompanyModal,
  },
  props: {
    companies: {
      type: Collection,
      default: null,
      required: false,
    },
  },
  data: () => ({
    currentCompany: null,
  }),
  methods: {
    onClickPagination(event) {
      this.companies[event]();
    },
    setCurrent(company) {
      this.currentCompany = company.target;
      if (this.$featureEnabled('matching')) {
        this.$refs.openMatching.open(this.currentCompany);
      } else {
        this.$router.push({
          name: 'company-details-screen',
          params: { id: company.target.id, tab: 'summary' },
        });
      }
    },
  },
};
</script>
