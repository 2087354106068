<template>
  <div>
    <div class="logo">
      <img src="/img/flair.a830dfec.svg">
    </div>
    <div class="invalid-secret-key">
      <h2>{{ ts.apologies }}</h2>
    </div>
    <router-link
      tag="button"
      :to="{ name: 'search-screen' }"
      class="vadis-form-button-main btn-details"
    >
      {{ ts.buttons.backHome }}
    </router-link>
  </div>
</template>

<i18n namespace="components.InvalidSecretKey">
{
  "apologies": "Apologies, you do not have the correct credentials to access this area.",
  "buttons": {
    "backHome": "Baske home"
  }
}
</i18n>

<script>

export default {
  name: 'InvalidSecretKey',
};
</script>

<style>
.invalid-secret-key {
  display: flex;
  margin-top: 20%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20%;
}
</style>
