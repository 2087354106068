<template>
  <div
    class="vadis-organism-openable-company-list"
    :class="{'vadis-organism-openable-company-list-open': isOpen}"
  >
    <div class="content">
      <CompanyEmptyPane v-if="isEmpty" />
      <ShareholderList
        v-else
        :shareholders="shareholders"
        :current="current"
        @click="setCurrent($event)"
        @onClickRisk="openRisk($event.company, $event.risk)"
      />
      <CompanyOverview
        v-if="current"
        :company="currentCompany"
        @close="clearCurrent()"
        @onClickRisk="openRisk($event.company, $event.risk)"
      />
    </div>
  </div>
</template>

<script>
import ShareholderList from '@/components/ShareholderList';
import CompanyOverview from '@/components/company/CompanyOverview';
import CompanyEmptyPane from '@/components/company/CompanyEmptyPane';

import { Collection } from 'flair-client';

export default {
  name: 'OpenableShareholderList',
  components: {
    ShareholderList,
    CompanyOverview,
    CompanyEmptyPane,
  },
  props: {
    shareholders: {
      type: Collection,
      default: null,
      required: true,
    },
  },
  data: () => ({
    current: undefined,
    currentCompany: undefined,
  }),
  computed: {
    isOpen() {
      return !!this.current;
    },
    isEmpty() {
      return this.shareholders.data && this.shareholders.data.length === 0;
    },
  },
  watch: {
    shareholders() {
      this.clearCurrent();
    },
  },
  methods: {
    clearCurrent() {
      this.current = null;
      this.currentCompany = null;
    },
    setCurrent(shareholder) {
      if (this.$featureEnabled('companyOverview')) {
        const c = this.$flairClient.EntitiesService.getCompanyById({ id: shareholder.source.id });
        c.promise.then(() => {
          this.current = shareholder;
          this.currentCompany = c;
        });
      } else {
        this.$router.push({
          name: 'company-details-screen',
          params: { id: shareholder.source.id, tab: 'summary' },
        });
      }
    },
  },
};
</script>
