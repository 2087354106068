import AbstractGraph from './abstract.graph';

export default class DecoratedPromise extends Promise {

  constructor(executor) {
    super(executor);
  }

  withCenterNodeId(nodeId) {
    return this.then((graph) => graph.withCenterNodeId(nodeId));
  }

  collapseAll() {
    return this.then((graph) => graph.collapseAll());
  }

  keepConnexComponent() {
    return this.then((graph) => graph.keepConnexComponent());
  }

  dropEdges(filter) {
    return this.then((graph) => graph.dropEdges(filter));
  }

  static resolve(g) {
    if (!(g instanceof AbstractGraph)) {
      const cstr = g ? g.constructor : typeof(g);
      throw new Error(`AbstractGraph expected, got ${cstr}`);
    }
    return new DecoratedPromise((resolve) => resolve(g));
  }

  static reject(g) {
    if (!(g instanceof AbstractGraph)) {
      const cstr = g ? g.constructor : typeof(g);
      throw new Error(`AbstractGraph expected, got ${cstr}`);
    }
    return new DecoratedPromise((_, reject) => reject(g));
  }

}
