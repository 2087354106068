export default function (Component) {
      Component.options._i18n = {
        namespace: "components.EcoVadisWeightIndicator",
        translations: 













{
  "weight": "Weight"
}

      }
    }