<template>
  <div class="help-view">
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb @home="goHome()">
        <li>{{ ts.title }}</li>
      </Breadcrumb>
      <div class="header">
        <h1>{{ ts.title }}</h1>
      </div>
      <TabsComponent
        ref="tabs"
        v-model="currentTab"
        :default-active="defaultActive"
        :tabs="tabs"
        :i18n="ts"
        @input="setTab"
      />
    </div>
    <div class="vadis-organism-padded-area">
      <component
        :is="tabComponent"
      />
    </div>
  </div>
</template>

<i18n namespace="views.Help">
{
  "title": "Help",
  "flairRiskIndicators": "Flair risk indicators",
  "global": "Global",
  "dueDiligenceRoles": "Due diligence roles"
}
</i18n>

<script>

import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';
import TabsComponent from '@/components/TabsComponent';
import FlairRiskIndicatorPane from '@/components/help/FlairRiskIndicatorPane';
import DueDiligenceRolesTable from '@/components/help/DueDiligenceRolesTable';
import GlobalHelpPane from '@/components/help/GlobalHelpPane';

export default {
  name: 'Help',
  components: {
    TopBar,
    Breadcrumb,
    TabsComponent,
    GlobalHelpPane,
    FlairRiskIndicatorPane,
    DueDiligenceRolesTable,
  },
  data: (vm) => ({
    currentTab: null,
    defaultActive: null,
    tabs: [
      {
        id: 'global',
        component: GlobalHelpPane,
      },
      {
        id: 'flairRiskIndicators',
        component: FlairRiskIndicatorPane,
        validator: () => {
          return vm.$featureEnabled('flairData');
        },
      },
      {
        id: 'dueDiligenceRoles',
        component: DueDiligenceRolesTable,
      },
    ],
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!to.params.tab) {
        return;
      }
      const tab = vm.tabs.find(tab => tab.id === to.params.tab);
      if (tab && vm.$refs.tabs) {
        vm.$refs.tabs.setActive(tab);
      }
    });
  },
  computed: {
    tabComponent() {
      return this.currentTab ? this.currentTab.component : null;
    },
  },
  methods: {
    setTab(tab) {
      this.$router.push({
        name: 'help-screen',
        params: { tab: tab.id },
      });
    },
  },
};
</script>

