<template>
  <div class="vadis-controller-tab">
    <ul>
      <template
        v-for="tab in visibleTabs"
      >
        <li
          :key="tab.id"
          :class="{ active: isActive(tab) }"
        >
          <a
            @click="setActive(tab)"
          >{{ labelFor(tab) }}</a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'TabsComponent',
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    defaultActive: {
      type: String,
      default: null,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    i18n: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    currentTab: null,
  }),
  computed: {
    visibleTabs() {
      return this.tabs.filter(t => !t.validator || t.validator(t));
    },
  },
  watch: {
    tabs: {
      handler() {
        if (this.currentTab) {
          return;
        }
        if (this.defaultActive) {
          this.currentTab = this.getTabById(this.defaultActive);
        } else if (this.tabs && this.tabs.length) {
          this.currentTab = this.visibleTabs[0];
        }
        this.$emit('input', this.currentTab);
      },
      immediate: true,
    },
  },
  methods: {
    getTabById(tabId) {
      return this.tabs.find(t => t.id === tabId);
    },
    isActive(tab) {
      return this.currentTab === tab;
    },
    setActive(tab) {
      this.$emit('input', tab);
      this.currentTab = tab;
    },
    getActive() {
      return this.currentTab;
    },
    labelFor(tab) {
      if (this.i18n) {
        return this.i18n[tab.id];
      }
      return tab.label || tab.id;
    },
  },

};
</script>

