export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligenceStatusBox",
        translations: 























































































































{
  "dueDiligenceStart": "Due diligence start date",
  "statusInformation": "Status information",
  "underEvaluation": "Under evaluation",
  "decisionHighRisk": "Decision on high risk diligences must be taken by managers.",
  "decisionComplianceOfficer": "Decision must be taken by an analyst or a manager.",
  "approved": "Approved",
   "rejected": "Rejected",
  "updatedOn": "Updated on",
  "decisionMaker": "Decision maker",
  "expiration": "Expiration",
  "buttons": {
    "addDecision": "Add decision",
    "notifyManager": "Notify manager",
    "notifyAnalyst": "Notify analyst",
    "manageDueDiligence": "Manage due diligence",
    "seeDueDiligence": "See due diligence"
  },
  "link": "Invite"
}

      }
    }