<template>
  <div
    class="vadis-organism-due-diligence-overview"
    @dragenter="dragEnter"
    @drop.prevent="addFile"
    @dragover.prevent
  >
    <SurveyDetails ref="surveyDetails" />
    <div
      class="flex"
    >
      <div
        :class="{'left-box': true, transi: hasOldValue}"
      >
        <div
          v-due-diligence-action:writeComment
          class="write-comment"
        >
          <form
            v-if="dueDiligence.isUnderEvaluation()"
            class="comment"
            @submit.prevent="addComment"
          >
            <div
              v-if="dragHover"
              class="vadis-molecule-upload-file drop"
            >
              <label
                ref="insideDrag"
                for="file"
              >
                <i class="icon-Upload" />
                <p> {{ ts.dragFile }}</p>
              </label>
            </div>
            <div
              v-else
              class="vadis-form-field-textarea"
            >
              <textarea
                v-model="form.comment"
                :placeholder="ts.placeholder"
                class="textarea"
              />
              <DueDiligenceDocuments
                :documents="form.files"
                :read-only="false"
                @remove="removeFile($event)"
              />
            </div>
            <div
              v-if="form.comment || form.files.length"
              class="buttons"
            >
              <button
                type="submit"
                :disabled="$v.form.$invalid"
                class="vadis-form-button-main"
              >
                {{ ts.button.post }}
              </button>
              <button
                type="cancel"
                class="vadis-form-button-standard"
                @click="cancel()"
              >
                {{ ts.button.cancel }}
              </button>
            </div>
          </form>
          <div class="buttons">
            <input
              id="file"
              ref="myFile"
              type="file"
              name="file"
              class="inputfile"
              multiple
              @change="addFile"
            >
            <label
              ref="insideDrag"
              for="file"
            >
              <p
                v-if="dueDiligence.isUnderEvaluation()"
                class="vadis-form-button-standard"
              >
                <i class="icon-Import" />{{ ts.button.importDocument }}
              </p>
            </label>
            <template v-if="dueDiligence.isUnderEvaluation()">
              <button
                v-due-diligence-action:reviseRisk
                class="vadis-form-button-standard"
                @click="reviseRisk()"
              >
                {{ ts.button.reviseRisk }}
              </button>
              <button
                v-due-diligence-action:makeDecision.disable
                class="vadis-form-button-standard"
                @click="makeDecision()"
              >
                {{ ts.button.addDecision }}
              </button>
            </template>
          </div>
        </div>
        <transition-group
          name="list"
        >
          <div
            v-for="(event) in dueDiligence.timeline"
            :id="event.id"
            :key="event.id"
            :set="member = dueDiligence.getMember(event.createdBy.id)"
            class="timeline-event"
          >
            <component
              :is="componentEvent(event.type)"
              v-if="event.type"
              :due-diligence="dueDiligence"
              :event="event"
              :company="company"
              :member="member"
              @details="onClickSurveyDetails($event)"
            />
          </div>
        </transition-group>
      </div>
      <div class="right-box">
        <GlobalRiskBox
          v-if="dueDiligence.relationships"
          :due-diligence="dueDiligence"
        />
        <DueDiligenceStatusBox
          :due-diligence="dueDiligence"
          :status-decision-button="true"
          :company="company"
          :invite-link="true"
        />
        <DueDiligenceRequestInfo
          v-if="dueDiligence.isUnderEvaluation()"
          v-due-diligence-action:requestInfo
          :due-diligence="dueDiligence"
          @details="onClickSurveyDetails($event)"
        />
        <h4
          v-if="dueDiligence.documents && dueDiligence.documents.length"
        >
          {{ ts.documents }}
        </h4>
        <DueDiligenceDocuments
          :documents="dueDiligence.documents"
        />
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.DueDiligenceOverview">
{
  "duediligence": "Due Diligence",
  "documents": "Documents",
  "dragFile": "Drag files to upload",
  "placeholder": "Write a message here ....",
  "button": {
    "post": "Post",
    "cancel": "Cancel",
    "hideDetails": "Hide details",
    "importDocument": "Import document",
    "reviseRisk": "Revise risk",
    "addDecision": "Add decision"
  }
}
</i18n>

<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import DueDiligenceDocuments from '@/components/due-diligence/DueDiligenceDocuments';
import DueDiligenceStatusBox from '@/components/due-diligence/DueDiligenceStatusBox';
import DueDiligenceRequestInfo from '@/components/due-diligence/DueDiligenceRequestInfo';
import GlobalRiskBox from '@/components/due-diligence/GlobalRiskBox';
import ReviseRiskModal from '@/components/due-diligence/ReviseRiskModal';
import MakeDecisionModal from '@/components/due-diligence/MakeDecisionModal';
import SurveySubmissionEvent from '@/components/due-diligence/chat/SurveySubmissionEvent';
import InviteEvent from '@/components/due-diligence/chat/InviteEvent';
import CommentEvent from '@/components/due-diligence/chat/CommentEvent';
import ReviseRiskEvent from '@/components/due-diligence/chat/ReviseRiskEvent';
import RequestInfoEvent from '@/components/due-diligence/chat/RequestInfoEvent';
import StartEvent from '@/components/due-diligence/chat/StartEvent';
import MakeDecisionEvent from '@/components/due-diligence/chat/MakeDecisionEvent';
import WarningCOEvent from '@/components/due-diligence/chat/WarningCOEvent';
import SurveyDetails from '@/components/due-diligence/SurveyDetails';
import { Document } from 'flair-client/src/entities';
import { transformRiskScore } from 'flair-client/src/utils';
import { DueDiligence } from 'flair-client';

export default {
  name: 'DueDiligenceOverview',
  components: {
    DueDiligenceDocuments,
    GlobalRiskBox,
    DueDiligenceStatusBox,
    DueDiligenceRequestInfo,
    SurveySubmissionEvent,
    InviteEvent,
    CommentEvent,
    ReviseRiskEvent,
    RequestInfoEvent,
    StartEvent,
    MakeDecisionEvent,
    WarningCOEvent,
    SurveyDetails,
  },
  props: {
    dueDiligence: {
      type: DueDiligence,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    hasOldValue: false,
    form: {
      comment: '',
      files: [],
    },
    dragHover: false,
    surveyOpen: true,
  }),
  computed: {
    company() {
      return this.dueDiligence.relationships.company;
    },
    membersByRole() {
      return _.groupBy(this.dueDiligence.members, 'role');
    },
  },
  watch: {
    dueDiligence(newV, oldV) {
      if (oldV.data) {
        this.hasOldValue = true;
      }
    },
  },
  validations: {
    form: {
      comment: {
        required,
      },
    },
  },
  methods: {
    componentEvent(type) {
      return `${type.split('.')[2]}Event`;
    },
    canMakeDecision() {
      if (transformRiskScore(this.dueDiligence.computedRisk) > 3) {
        return false;
      } else {
        return true;
      }
    },
    dragEnter() {
      this.dragHover = true;
    },
    removeFile(file) {
      document.getElementById('file').value = '';
      const payload = {
        guid: file.id,
      };
      this.$flairClient.DueDiligenceService.removeAttachments(payload)
        .promise.then(() => {
          this.form.files = this.form.files.filter(f => {
            return f !== file;
          });
        });
    },
    reviseRisk() {
      ReviseRiskModal.open(this.dueDiligence);
    },
    makeDecision() {
      MakeDecisionModal.open(this.dueDiligence);
    },
    addFile(e) {
      let droppedFiles;
      if (e.dataTransfer) {
        droppedFiles = e.dataTransfer.files;
      } else if (e.target) {
        droppedFiles = e.target.files;
      }
      if (!droppedFiles) {return;}
      ([...droppedFiles]).forEach(f => {
        this.addAttachments(f);
      });
      this.dragHover = false;
    },
    addAttachments(file) {
      this.$flairClient.DueDiligenceService.addAttachments({ file })
        .promise.then(attachment => {
          const document = Document.fromFileAndAttachment(file, attachment);
          this.form.files.push(document);
        });
    },
    addComment() {
      const payload = {
        id: this.dueDiligence.id,
        comment: this.form.comment,
        documents: this.form.files.map(d => d.toRef()),
      };
      this.$flairClient.DueDiligenceService.postComment(payload)
        .promise.then(() => {
          this.cancel();
        });
    },
    cancel() {
      this.form.comment = '';
      this.form.files = [];
    },
    onClickSurveyDetails(surveyId) {
      this.$flairClient.SurveyService.getSurveySubmissionById({ id: surveyId })
        .promise.then(res => {
          this.$refs.surveyDetails.open(res);
        });
    },
  },
};
</script>
