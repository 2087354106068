<template>
  <div class="portfolio-list table-border">
    <CompanyRiskModal
      ref="companyRiskModal"
    />
    <table class="table-hover">
      <thead>
        <tr>
          <th class="vadis-controller-column-filter">
            <div class="label">
              {{ ts.th.name }}
              <div
                class="sort-small"
                @click="$emit('sort', 'name')"
              >
                <i :class="{'icon-Arrow-up-small': true, 'active': sortsBy('name')}" />
                <i :class="{'icon-Arrow-down-small': true, 'active': sortsBy('-name')}" />
              </div>
            </div>
          </th>
          <th />
          <th class="no-open">
            {{ ts.th['global-risk'] }}
          </th>
          <th class="no-open">
            {{ ts.th['risk-evolution'] }}
          </th>
          <th class="no-open">
            {{ ts.th['last-update'] }}
          </th>
          <th />
        </tr>
      </thead>
      <tbody
        v-if="monitoredCompanies.loading"
        class="vadis-molecule-loader"
      >
        <tr>
          <td colspan="9">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="(monitoredCompany, i) in monitoredCompanies.items"
          :key="i"
          :set="c = monitoredCompany.company"
          :class="{'current': isCurrent(monitoredCompany)}"
          @click="onClick(monitoredCompany)"
        >
          <td class="name">
            <div class="vadis-molecule-country-portfolio">
              <span><a>{{ c.name }}</a></span>
              <div>
                <CountryFlag
                  :country="c.address.country"
                  :flag-only="true"
                />
                <span>{{ c.europeanVAT }}</span>
              </div>
            </div>
          </td>
          <td>
            <PortfolioButton :subject="c" />
          </td>
          <td class="no-open global-score">
            <GlobalRiskHistory
              :history="monitoredCompany.getRiskGlobal()"
              :last="c.last.riskProfile.score"
            />
            <div :class="'vadis-controller-tag-risk vadis-controller-tag-global-risk-level' + c.last.riskProfile.score">
              {{ transformRiskScore(c.last.riskProfile.score) }}
            </div>
          </td>
          <td class="no-open risk-evolution">
            <div
              v-if="positive(monitoredCompany.riskEvolution)==0"
              :class="classEvolution + 'none'"
            >
              <span> {{ ts.stable }}</span>
              <i
                class="icon-Info"
                @click.stop="onClickDetails(monitoredCompany)"
              />
            </div>
            <div
              v-else
              :class="[positive(monitoredCompany.riskEvolution)==-1 ? classEvolution + 'success' : classEvolution + 'dangerous']"
            >
              <i :class="[positive(monitoredCompany.riskEvolution)==1 ? 'icon-Arrow-up' : 'icon-Arrow-down']" />
              <span v-if="positive(monitoredCompany.riskEvolution)==1"> + </span>
              {{ monitoredCompany.riskEvolution }}
              <i
                class="icon-Info"
                @click.stop="onClickDetails(monitoredCompany)"
              />
            </div>
          </td>
          <td class="no-open">
            <span>{{ monitoredCompany.created | humanDate }}</span>
          </td>
          <td>
            <!--
              // TODO: @llambeau @mdeserable
              <i
              class="icon-Contextuel"
              @click.stop="removePortfolio(monitoredCompany.company)"
            /> -->
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="monitoredCompanies.loaded && monitoredCompanies.hasPagination()"
      :current-page="monitoredCompanies.meta.pageNumber"
      :total-items="monitoredCompanies.meta.totalItems"
      :number-of-lines="monitoredCompanies.meta.pageSize"
      :links="monitoredCompanies.links"
      @click="onClickPagination($event)"
    />
  </div>
</template>

<i18n namespace="components.PortfolioList">
{
  "th": {
    "name": "Name",
    "global-risk": "Global risk",
    "risk-evolution": "Risk evolution",
    "last-update": "Last update"
  },
  "stable": "stable"
}
</i18n>

<script>
import { transformRiskScore } from 'flair-client/src/utils';
import CountryFlag from '@/components/CountryFlag';
import Loader from '@/components/Loader';
import GlobalRiskHistory from '@/components/GlobalRiskHistory';
import CompanyRiskModal from '@/components/company/CompanyRiskModal';
import Pagination from '@/components/Pagination';
import PortfolioButton from '@/components/portfolio/PortfolioButton';

export default {
  name: 'PortfolioList',
  components: {
    Loader,
    GlobalRiskHistory,
    CompanyRiskModal,
    CountryFlag,
    Pagination,
    PortfolioButton,
  },
  props: {
    monitoredCompanies: {
      type: Object,
      default: null,
      required: true,
    },
    current: {
      type: Object,
      default: null,
      required: false,
    },
    sorts: {
      type: Array,
      default: null,
      required: false,
    },
  },
  data: () => ({
    transformRiskScore,
    showRiskModal: false,
    currentCompany: undefined,
    classEvolution:'vadis-controller-tag-risk-evolution-',
  }),
  mounted() {
  },
  methods: {
    onClick(c) {
      this.$emit('click', c.company);
    },
    onClickDetails(c) {
      const company = this.$flairClient.EntitiesService.getCompanyById({ id: c.company.id });
      company.promise.then((res) => {
        if (res.hasRiskHistory()) {
          this.$refs.companyRiskModal.open(res);
        }
      });
    },
    isCurrent(c) {
      return c.company === this.current;
    },
    positive(value) {
      return Math.sign(value);
    },
    sortsBy(column) {
      return this.sorts.find(e => e === column);
    },
    onClickPagination(event) {
      this.monitoredCompanies[event]();
    },
  },
};
</script>
