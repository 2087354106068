<template>
  <div
    class="vadis-organism-openable-company-list"
    :class="{'vadis-organism-openable-company-list-open': isOpen}"
  >
    <div>
      <i
        v-if="title"
        class="icon-Building"
      />
      <h4 v-if="title">
        {{ title }}
      </h4>
    </div>
    <div class="content">
      <CompanyList
        :companies="companies"
        :current="current"
        :search="search"
        @click="setCurrent($event)"
        @onClickRisk="openRisk($event.company, $event.risk)"
      />
      <CompanyOverview
        v-if="current"
        :company="current"
        @close="clearCurrent()"
        @onClickRisk="openRisk($event.company, $event.risk)"
      />
    </div>
  </div>
</template>

<i18n namespace="components.OpenableCompanyList">
{
  "companies": "Companies"
}
</i18n>

<script>
import CompanyList from '@/components/company/CompanyList';
import CompanyOverview from '@/components/company/CompanyOverview';
import { Collection, SearchState } from 'flair-client';

export default {
  name: 'OpenableCompanyList',
  components: {
    CompanyList,
    CompanyOverview,
  },
  props: {
    search: {
      type: SearchState,
      default: null,
    },
    companies: {
      type: Collection,
      default: null,
      required: true,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
  },
  data: () => ({
    current: undefined,
  }),
  computed: {
    isOpen() {
      return !!this.current;
    },
  },
  methods: {
    clearCurrent() {
      this.current = null;
    },
    setCurrent(company) {
      if (this.$featureEnabled('companyOverview')) {
        this.current = company;
      } else {
        this.$router.push({
          name: 'company-details-screen',
          params: { id: company.id, tab: 'summary' },
        });
      }
    },
  },
};
</script>
