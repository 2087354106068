export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CRIConfirmation",
        translations: 





























































{
  "createdIndicator": "Your custom indicator has been created",
  "updatedIndicator": "Your custom indicator has been updated",
  "name": "Name",
  "description": "Description",
  "composition": "Composition",
  "displayAs": "Display as",
  "noDescription": "No description provided"
}

      }
    }