<template>
  <div
    v-if="isOpen"
  >
    <div class="vadis-style-global-DialogBg" />
    <div class="vadis-modal-risk-profile">
      <div class="header">
        <h6>{{ ts.riskProfile }}</h6>
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>
      <div class="risk-indicator-container dummy-data">
        <div class="overlay">
          <h1 class="sample-text">
            {{ ts.sampleData }}
          </h1>
        </div>
        <div class="risk-indicator-list">
          <RiskIndicator
            v-for="risk in limitedIndicators"
            :key="risk.id"
            :risk="risk"
            @over="hover=true;"
            @leave="hover=false;"
          />
        </div>
        <div class="selected-indicators">
          <p
            class="indicator-selection"
            :class="{ error: maxSelection }"
          >
            {{ riskIndicators.length }}/{{ maxIndicators }}
          </p>
          <p>
            {{ ts.indicatorsSelected }}
          </p>
          <p
            class="default"
            @click="modalInitialization()"
          >
            {{ ts.reset }}
          </p>
        </div>
        <div class="risk-modal-divider" />
      </div>
      <div class="body">
        <form class="custom-risk-form">
          <div
            v-for="(provider, i) in filteredProviders"
            :key="i"
          >
            <CRIAccordion
              v-model="selectedIndicatorsPerProvider[provider.id]"
              :provider="provider"
              :indicators="provider.riskIndicators"
              :max-selection="maxSelection"
              @input="handleInput"
            />
          </div>
        </form>
      </div>
      <div class="footer">
        <div class="form-buttons">
          <button
            :disabled="maxSelection"
            class="vadis-form-button-main save"
            @click="updateEnabledRiskIndicators()"
          >
            {{ ts.buttons.save }}
          </button>
          <button
            class="vadis-form-button-standard cancel"
            @click="close()"
          >
            {{ ts.buttons.cancel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.RiskProfileModal">
{
  "sampleData": "Sample data",
  "riskProfile": "Risk Profile",
  "indicatorsSelected": "indicators selected",
  "reset": "Reset to default",
  "buttons": {
    "save": "Save changes",
    "cancel": "Cancel"
  }
}
</i18n>

<script>

import CRIAccordion from '@/components/custom-risk-indicator/CRIAccordion';
import RiskIndicator from '@/components/risk/RiskIndicator';
import { Collection, Resource } from 'flair-client';

export default {
  name: 'RiskProfileModal',
  components: {
    RiskIndicator,
    CRIAccordion,
  },
  props: {
    providers: {
      type: Collection,
      required: false,
      default: () => {},
    },
    indicators: {
      type: Resource,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    isOpen: false,
    company: null,
    selectedIndicatorsPerProvider: {},
    riskIndicators: null,
    maxIndicators: 7,
    limit: 7,
  }),
  computed: {
    filteredProviders() {
      return this.providers.items.filter(p => p.id !== 'DisabledTestProvider');
    },
    maxSelection() {
      return this.riskIndicators.length > this.maxIndicators;
    },
    limitedIndicators() {
      return this.limit ? this.riskIndicators.slice(0, this.limit) : this.riskIndicators;
    },
  },
  created() {
    this.modalInitialization();
  },
  methods: {
    open(company) {
      this.company = company;
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    randomScore() {
      const randomScore = Math.floor(Math.random() * 10);
      return randomScore;
    },
    modalInitialization() {
      this.riskIndicators = this.indicators.riskProfile.map(indicator => {
        return {
          data_provider_id: indicator.dataProviderId,
          name: indicator.riskId,
          score: this.randomScore(),
        };
      });
      const indexPerProvider = this.riskIndicators
        .map(p => p.data_provider_id)
        .reduce((idx, id) => {
          idx[id] = [];
          return idx;
        }, {});
      this.selectedIndicatorsPerProvider = this.riskIndicators.reduce((dataProviders, indicator) => {
        dataProviders[indicator.data_provider_id].push(indicator.name);
        return dataProviders;
      }, indexPerProvider);
    },
    handleInput() {
      const elements = Object.entries(this.selectedIndicatorsPerProvider)
        .reduce((elmts, [providerId, riskIndicators]) => {
          return elmts.concat(riskIndicators.map(riskIndicatorId => {
            return {
              data_provider_id: providerId,
              name: riskIndicatorId,
              // TODO This score should be sent from the backend and then evaluated here
              // for now we just pass null for all indicators
              score: this.randomScore(),
            };
          }));
        }, []);
      this.riskIndicators = elements;
      return this.riskIndicators;
    },
    updateEnabledRiskIndicators() {
      const risks = this.riskIndicators.map(risk => {
        return {
          dataProviderId: risk.data_provider_id,
          riskId: risk.name,
        };
      });
      this.$flairClient.DataProviderService.updateEnabledIndicators({
        type: 'RiskProfile',
        risks,
      },
      ).promise.then(() => {
        this.close();
      });
    },
  },
};
</script>
