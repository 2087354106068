export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyPDFReport",
        translations: 





















































































































{
  "summary": "Summary",
  "financial": "Financial",
  "management": "Management",
  "shareholders": "Shareholders",
  "subsidiaries": "Subsidiaries",
  "printedOn": "Printed on",
  "geographicalRisk": "Geographical risk for {q}",
  "geographicalGroupRisk": "Geographical risk for the group {q}",
  "pepRisk": "PEP risk",
  "sanctionRisk": "Sanctions risk",
  "adverseMediaRisk": "Adverse media risk",
  "country": "Country",
  "score": "Score",
  "presence": "Presence",
  "year": "Year",
  "savePDF": "Save PDF"
}

      }
    }