<template>
  <div class="vadis-organism-geographical-risk-portfolio">
    <Loader
      v-if="!ready"
      :global="false"
      :shown="true"
    />
    <div class="content">
      <div class="left">
        <div
          v-if="detailsOfCountry"
          class="company-group-list"
        >
          <GeographicalRiskInfo
            class="info"
            :risk-indicator="riskIndicator"
            :back="true"
            @close="goBack()"
            @back="goBack()"
          />
          <div
            class="vadis-molecule-detail-item title"
          >
            <span
              v-if="companies"
              class="vadis-molecule-detail-label"
            > {{ companies.length }} {{ companyCount }} </span>
          </div>
          <div
            v-for="(company, i) in companies"
            :key="i"
            class="vadis-molecule-detail-item"
          >
            <router-link
              class="vadis-molecule-detail-value"
              :to="{ name: 'company-details-screen', params: { id: company.id, tab: 'summary' } }"
            >
              <a>{{ company.attributes.name }}</a>
            </router-link>
          </div>
        </div>
        <div
          v-else
          class="company-group-list"
        >
          <div class="title">
            <p v-if="geographicalPresence">
              {{ geographicalPresence.length }} {{ countryCount }}
            </p>
            <!-- <i class="icon-Close" /> -->
          </div>
          <div
            v-for="(c, i) in geographicalPresence"
            :key="i"
            class="vadis-molecule-detail-item"
            @click="fetchCountryDetails(c.country)"
          >
            <span class="vadis-molecule-detail-label">
              <CountryFlag
                :country="c.country"
              />
            </span>
            <span
              class="vadis-molecule-detail-value"
            > {{ c.numberOfCompanies }} </span>
          </div>
        </div>
      </div>
      <GeographicalMap
        v-if="ready"
        :risks="countries"
        :current="currentCountry"
        @click="fetchCountryDetails($event)"
      />
      <div class="vadis-molecule-legend-geographical">
        <div class="part">
          <Legend />
        </div>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.GeographicalPortfolioRisk">
{
  "subtitle": "You are viewing company level of ",
  "back": "back",
  "lastUpdate": "Last update :",
  "source": "Source",
  "companyInCountry": "Companies in {q}",
  "countries": "Countries",
  "country": "Country",
  "companies": "Companies",
  "company": "Company"
}
</i18n>

<script>
import _ from 'lodash';
import { Collection } from 'flair-client';
import GeographicalMap from '@/components/geographical/GeographicalMap';
import GeographicalRiskInfo from '@/components/geographical/GeographicalRiskInfo';
import CountryFlag from '@/components/CountryFlag';
import Legend from '@/components/Legend';
import Loader from '@/components/Loader';

export default {
  name: 'GeographicalPortfolioRisk',
  components: {
    GeographicalMap,
    GeographicalRiskInfo,
    CountryFlag,
    Loader,
    Legend,
  },
  props: {
    team: {
      type: Object,
      required: false,
      default: null,
    },
    geographicalPresence: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    ready: false,
    presentCountries: null,
    detailsOfCountry: false,
    currentCountry: '',
    countries: null,
    companies: null,
    riskIndicator: null,
  }),
  computed: {
    companyCount() {
      return this.companies.length === 1 ? this.ts.company : this.ts.companies;
    },
    countryCount() {
      return this.geographicalPresence.length === 1 ? this.ts.country : this.ts.countries;
    },
  },
  created() {
    this.fetchCountries();
    this.mapPresentCountries();
  },
  methods: {
    // TODO: Refactor copy/paste from GroupGeogrpahicalRisk
    fetchCountries() {
      return this.$flairClient.TransparencyService.getGeographicalRisk().promise
        .then(res => {
          const c = res.data.values;
          this.countries = _.reduce(this.presentCountries, (o, acc) => { o[acc] = c[acc]; return o; }, {});
          this.ready = true;
        });
    },
    mapPresentCountries() {
      this.presentCountries = _.map(this.geographicalPresence, gp => gp.country);
    },
    // TODO: Refactor copy/paste from GroupGeogrpahicalRisk
    fetchCountryDetails(country) {
      if (!(_.find(this.presentCountries, (e) => e === country))) {
        return;
      }
      this.currentCountry = country;
      this.getGeographicalCountryPresence();
      this.$flairClient.TransparencyService.getGeographicalRiskHistory({ country }).promise
        .then(res => {
          this.riskIndicator = res;
          this.riskIndicator.history = this.riskIndicator.getRiskHistory();
          this.detailsOfCountry = true;
        });
    },
    getGeographicalCountryPresence() {
      return this.$flairClient.MyService.getTeamPortfolioGeographicalCountryPresence({
        id: this.team.id,
        country: this.currentCountry,
      }).promise.then((res) => {
        this.companies = res.data;
      });
    },
    goBack() {
      this.detailsOfCountry = false;
      this.currentCountry = '';
    },
  },
};

</script>
