<template>
  <div class="vadis-molecule-chat-message">
    <Loader
      v-if="pdfLoaded"
      :global="true"
      :shown="true"
    />
    <div class="vadis-molecule-avatar">
      <p>{{ member.initials }}</p>
    </div>
    <div
      class="content"
    >
      <div class="name">
        <span class="bold">{{ member.fullName }}</span> ({{ ts[member.role.toLowerCase()] }})
        <span>
          {{ ts.hasSentSurvey }}
          {{ event.extraParameters.supplier.firstname }}
          {{ event.extraParameters.supplier.lastname }}
          (<a
            v-for="survey in event.surveys"
            :key="survey.id"
            @click="downloadPDF(survey)"
          >{{ getSurveysName(survey) }}</a>)
        </span>
      </div>
      <div class="date">
        {{ event.createdAt | humanDateHour }}
      </div>
      <div
        class="copy italic"
      >
        {{ event.comment }}
      </div>
      <DueDiligenceDocuments
        :documents="event.documents"
        :due-diligence="dueDiligence"
      />
    </div>
  </div>
</template>

<i18n namespace="components.RequestInfoEvent">
{
  "analyst": "Analyst",
  "manager": "Manager",
  "hasSentSurvey": "has sent a company survey to"
}
</i18n>

<script>
import _ from 'lodash';
import Loader from '@/components/Loader';
import DueDiligenceDocuments from '@/components/due-diligence/DueDiligenceDocuments';

export default {
  name: 'RequestInfoEvent',
  components: {
    Loader,
    DueDiligenceDocuments,
  },
  props: {
    dueDiligence: {
      type: Object,
      default: null,
      required: true,
    },
    member: {
      type: Object,
      default: null,
      required: true,
    },
    event: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    pdfLoaded: false,
  }),
  methods: {
    getSurveysName(survey) {
      return _.get(this.dueDiligence.getSurvey(survey.id), 'name');
    },
    downloadPDF(survey) {
      this.pdfLoaded = true;
      const s = this.dueDiligence.getSurvey(survey.id);
      this.$flairClient.SurveyService.getSurveyTranslationAsPDF({ id: s.reference }, s.name)
        .finally(() => {
          this.pdfLoaded = false;
        });
    },
  },
};
</script>
