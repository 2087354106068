export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DataConsumptionGraph",
        translations: 







{
  "planStartOn": "Yearly plan starts on {date}",
  "monthly": "Monthly",
  "yearlyAggregate": "Yearly aggregate",
  "aggregate": "aggregate",
  "screening": "Screening",
  "monitoring": "Monitoring"
}

      }
    }