<template>
  <div class="configuration-view">
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb />
      <div class="header">
        <h1>{{ ts.title }}</h1>
      </div>
    </div>
    <div class="vadis-organism-padded-area">
      <ConfigurationPane />
    </div>
  </div>
</template>

<i18n namespace="views.Configuration">
{
  "title": "Welcome to Flair configuration"
}
</i18n>

<script>
import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';
import ConfigurationPane from '@/components/ConfigurationPane';

export default {
  name: 'Configuration',
  components: {
    TopBar,
    Breadcrumb,
    ConfigurationPane,
  },
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      if (!vm.$featureEnabled('userManagement')) {
        vm.$router.push('/404');
      }
    });
  },
};
</script>
