// Regex for validating password

const validPassword = (pwd) => {
  if (!pwd || pwd.length < 12 || pwd.length > 24) {
    return false;
  }
  // at least 1 lower case letter
  if (!/[a-z]+/.test(pwd)) {
    return false;
  }
  // at least 1 upper case letter
  if (!/[A-Z]+/.test(pwd)) {
    return false;
  }
  // at least 1 number
  if (!/[0-9]+/.test(pwd)) {
    return false;
  }
  return true;
};

export default validPassword;
