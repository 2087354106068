<template>
  <div>
    <div
      v-if="errorSurveyNameCannotBeNull"
      class="error-messages"
    >
      <p>{{ ts.errorSurveyNameCannotBeNull }}</p>
    </div>
    <form @submit.prevent="updateSurveyName()">
      <div class="vadis-form-field-standard">
        <input
          ref="input"
          v-model="surveyName"
          type="text"
          :placeholder="ts.placeholder.survey"
        >
      </div>
      <button
        class="vadis-form-button-main"
        type="submit"
      >
        {{ ts.button.renameSurvey }}
      </button>
      <button
        class="vadis-form-button-standard"
        @click="$emit('close');"
      >
        {{ ts.button.cancel }}
      </button>
    </form>
  </div>
</template>

<i18n namespace="components.SurveyRenameForm">
{
  "errorSurveyNameCannotBeNull": "A survey cannot have an empty name",
  "button": {
    "renameSurvey": "Rename Survey",
    "cancel": "Cancel"
  },
  "placeholder": {
    "survey": "Survey name..."
  }
}
</i18n>

<script>
import { Survey } from 'flair-client';

export default {
  name: 'SurveyRenameForm',
  props: {
    survey: {
      type: Survey,
      default: null,
      required: true,
    },
  },
  data: () => ({
    surveyName: '',
    errorSurveyNameCannotBeNull: false,
  }),
  mounted() {
    this.$refs.input.focus();
    this.surveyName = this.survey.name;
  },
  methods: {
    cleanError() {
      this.errorSurveyNameCannotBeNull = false;
    },
    updateSurveyName() {
      this.cleanError();
      if (this.surveyName === '') {
        this.errorSurveyNameCannotBeNull = true;
        return;
      }
      if (this.surveyName === this.survey.name) {
        this.$emit('close');
        return;
      }
      this.$flairClient.SurveyService.updateSurvey({ id: this.survey.id, name: this.surveyName })
        .promise.then(() => {
          this.$emit('close');
        }).catch((err) => {
          console.error('#failed', err);
          throw err;
        });
    },
  },
};
</script>
