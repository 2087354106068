<template>
  <div class="vadis-molecule-due-diligence-status-box">
    <InviteDueDiligenceMemberModal
      v-if="$featureEnabled('dueDiligence')"
      ref="inviteDueDiligenceMemberModal"
      :company="company"
    />
    <div class="vadis-molecule-detail-item-status">
      <h5 class="title">
        {{ componentTitle }}
      </h5>
    </div>
    <div
      v-if="dueDiligence.isUnderEvaluation()"
      class="evaluation under-evaluation"
    >
      <div class="item">
        <span class="label">
          <p>{{ ts.underEvaluation }}</p>
          <span
            v-due-diligence-action:notifyManager
          >
            {{ ts.decisionHighRisk }}
          </span>
          <span v-due-diligence-action:notifyAnalyst>
            {{ ts.decisionComplianceOfficer }}
          </span>
        </span>
        <span
          v-if="statusDecisionButton"
          class="value"
        >
          <button
            v-due-diligence-action:makeDecision.hide
            class="vadis-form-button-standard-small"
            @click="makeDecision()"
          >
            {{ ts.buttons.addDecision }}
          </button>
          <button
            v-due-diligence-action:notifyManager
            class="vadis-form-button-standard-disabled-small"
          >
            {{ ts.buttons.notifyManager }}
          </button>
          <button
            v-due-diligence-action:notifyAnalyst
            class="vadis-form-button-standard-disabled-small"
          >
            {{ ts.buttons.notifyAnalyst }}
          </button>
        </span>
      </div>
    </div>
    <div
      v-if="dueDiligence.isRejected() || dueDiligence.isApproved()"
      class="evaluation"
    >
      <div class="item">
        <span
          v-if="dueDiligence.isApproved()"
          class="label evaluation-accepted"
        >{{ ts.approved }} <i class="icon-Approved" /></span>
        <span
          v-else
          class="label evaluation-rejected"
        >{{ ts.rejected }} <i class="icon-Rejected" /></span>
        <span class="value">{{ ts.updatedOn }} {{ dueDiligence.getDecisionDate() | humanDate }}</span>
      </div>
      <div class="item">
        <span class="label">{{ ts.decisionMaker }}</span>
        <span class="value">
          <div class="vadis-molecule-avatar-small">
            <p>{{ dueDiligence.getDecisionMaker().initials }}</p>
          </div>
          {{ dueDiligence.getDecisionMaker().fullName }}
        </span>
      </div>
      <div class="item">
        <span class="label">{{ ts.expiration }}</span>
        <span class="value">{{ dueDiligence.validUntil | humanDate }}</span>
      </div>
    </div>
    <div class="members">
      <div class="item">
        <span class="label">{{ ts.dueDiligenceStart }}</span>
        <span class="value">{{ dueDiligence.startedAt | humanDate }}</span>
      </div>
      <MembersList :members-by-role="membersByRole" />
      <div
        v-show="dueDiligence.isUnderEvaluation()"
        class="item"
      >
        <span
          class="label"
        />
        <div class="list" />
        <a
          v-if="inviteLink && $featureEnabled('dueDiligence')"
          @click="inviteMember"
        >{{ ts.link }}
        </a>
      </div>
    </div>
    <div
      v-if="displayLinkToSelf"
      class="action"
    >
      <router-link
        :to="{name: 'due-diligence-details-screen', params: { id: dueDiligence.id }}"
        tag="button"
        class="vadis-form-button-main"
      >
        {{ hasCorrectRole ? ts.buttons.manageDueDiligence : ts.buttons.seeDueDiligence }}
      </router-link>
    </div>
  </div>
</template>

<i18n namespace="components.DueDiligenceStatusBox">
{
  "dueDiligenceStart": "Due diligence start date",
  "statusInformation": "Status information",
  "underEvaluation": "Under evaluation",
  "decisionHighRisk": "Decision on high risk diligences must be taken by managers.",
  "decisionComplianceOfficer": "Decision must be taken by an analyst or a manager.",
  "approved": "Approved",
   "rejected": "Rejected",
  "updatedOn": "Updated on",
  "decisionMaker": "Decision maker",
  "expiration": "Expiration",
  "buttons": {
    "addDecision": "Add decision",
    "notifyManager": "Notify manager",
    "notifyAnalyst": "Notify analyst",
    "manageDueDiligence": "Manage due diligence",
    "seeDueDiligence": "See due diligence"
  },
  "link": "Invite"
}
</i18n>

<script>

import _ from 'lodash';
import MembersList from '@/components/due-diligence/MembersList';
import MakeDecisionModal from '@/components/due-diligence/MakeDecisionModal';
import InviteDueDiligenceMemberModal from '@/components/due-diligence/InviteDueDiligenceMemberModal';
import { transformRiskScore } from 'flair-client/src/utils';
import { DueDiligence, Company } from 'flair-client';

export default {
  name: 'DueDiligenceStatusBox',
  components: {
    MembersList,
    InviteDueDiligenceMemberModal,
  },
  props: {
    dueDiligence: {
      type: DueDiligence,
      default: () => {},
      required: true,
    },
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
    statusDecisionButton: {
      type: Boolean,
      default: false,
    },
    inviteLink: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    displayLinkToSelf: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    membersByRole() {
      return _.groupBy(this.dueDiligence.members, 'role');
    },
    componentTitle() {
      return this.title || this.ts.statusInformation;
    },
    hasCorrectRole() {
      return _.find(this.dueDiligence.members, (e) => e.user.id === this.$flairClient.AuthenticationService.me.id);
    },
  },
  methods: {
    canMakeDecision() {
      if (transformRiskScore(this.dueDiligence.computedRisk) > 3) {
        return false;
      } else {
        return true;
      }
    },
    makeDecision() {
      MakeDecisionModal.open(this.dueDiligence);
    },
    inviteMember() {
      this.$refs.inviteDueDiligenceMemberModal.open(this.dueDiligence);
    },
  },
};
</script>
