<template>
  <div class="vadis-organism-company-ecovadis">
    <div class="header">
      <h5>Ecovadis</h5>
      <a v-if="riskRating.link">
        {{ ts.completeScorecard }}
      </a>
      <div class="vadis-form-field-select">
        <p>{{ ts.unit }}</p>
        <select v-model="unit">
          <option
            v-for="i in riskIndicators"
            :key="i.id"
            :value="i.label"
          >
            {{ ts.filterCategories[i.label] }}
          </option>
        </select>
        <i class="icon-Dropdown" />
      </div>
      <div class="widget">
        <p>{{ ts.providedBy }}</p>
        <a
          href="https://ecovadis.com/"
          target="_blank"
        >
          <img
            src="/img/ecovadis/ecovadis.png"
            alt="ecovadis"
          >
        </a>
      </div>
    </div>
    <p class="information">
      {{ ts.information }}
    </p>
    <div class="dummy-data">
      <div class="overlay">
        <h1 class="sample-text">
          {{ ts.sampleData }}
        </h1>
      </div>
      <div class="body">
        <div
          class="overall-score-graph"
          :class="overallScoreClass"
        >
          <div class="title">
            <h5>{{ ts.overAllScore }}</h5>
          </div>
          <EcoVadisRiskChart
            :risk="riskRating"
          />
          <div class="percentile-score">
            <p>{{ riskRating.rank }}{{ ts.th }}</p>
            <p>{{ ts.percentile }}</p>
          <!-- <img class="icon-Info"> -->
          </div>
        </div>
        <EcoVadisRiskCategories
          v-for="risk in riskRating.risks"
          :key="risk.name"
          :risk="risk"
          :unit="unit"
        />
      </div>
    </div>
    <div class="footer">
      <div class="vadis-molecule-legend-hierarchy">
        <div class="part">
          <Legend />
          <div class="rectangle" />
        </div>
        <p>{{ ts.averageScore }}</p>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.CompanyEcovadisPane">
{
  "sampleData": "Sample data",
  "information": "This view is an example (with fake data) of what Ecovadis data might look like if you connect to this provider",
  "completeScorecard": "Complete scorecard",
  "overAllScore": "Overall score",
  "providedBy": "Provided by",
  "unit": "Unit",
  "th": "th",
  "percentile": "percentile",
  "filterCategories": {
    "percentage": "Percentage",
    "score": "Risk indicator"
  },
  "averageScore": "Average Industry Score"
}

</i18n>

<script>

import EcoVadisRiskCategories from '@/components/ecovadis/EcoVadisRiskCategories';
import EcoVadisRiskChart from '@/components/ecovadis/EcoVadisRiskChart';
import Legend from '@/components/Legend';

export default {
  name: 'CompanyEcovadisPane',
  components: {
    EcoVadisRiskCategories,
    EcoVadisRiskChart,
    Legend,
  },
  data: () => ({
    riskRating: {},
    unit: 'score',
  }),
  computed: {
    overallScoreClass() {
      return `overall-score ecovadis-color-${this.riskRating.score}`;
    },
    riskIndicators() {
      return [
        'score',
        'percentage',
      ].map(i => {
        return {
          id: i,
          label: i,
        };
      });
    },
  },
  created() {
    this.getRiskRating();
  },
  methods: {
    getRiskRating() {
      return this.$flairClient.EntitiesService.getEcovadisRiskRatingById({
        id: '2385',
        siret: '1',
      }).promise.then((res) => {
        this.riskRating = res.data.attributes;
      });
    },
  },
};
</script>
