<template>
  <div>
    <OpenableShareholderList
      ref="openableShareholderList"
      :shareholders="shareholders"
    />
  </div>
</template>

<script>
import { Company } from 'flair-client';
import OpenableShareholderList from '@/components/openable/OpenableShareholderList';

export default {
  name: 'CompanyShareholdersPane',
  components: {
    OpenableShareholderList,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
    paging: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: () => ({
    shareholders: [],
  }),
  watch: {
    company() {
      this.loadShareholders();
    },
  },
  created() {
    this.loadShareholders();
  },
  methods: {
    loadShareholders() {
      const params = {
        id: this.company.id,
      };
      if (!this.paging) {
        params.nopaging = '';
      }
      this.shareholders = this.$flairClient.EntitiesService.getCompanyShareholders(params);
    },
  },
};
</script>
