export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyDetailsTabs",
        translations: 


















{
  "summary": "Summary",
  "financial": "Financial",
  "management": "Management",
  "shareholders": "Shareholders",
  "subsidiaries": "Subsidiaries",
  "neighborhood": "Neighborhood",
  "due-diligence": "Due diligence",
  "ecovadis": "Ecovadis"
}

      }
    }