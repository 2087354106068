<template>
  <div class="vadis-organism-openable-financial-list">
    <div class="visual">
      <div class="left">
        <div
          class="financial-score"
        >
          <div class="vadis-molecule-detail-item">
            <h5>
              {{ ts.indicators }}
            </h5>
          </div>
          <Loader
            v-if="!financial.loaded"
            class="financial-score-loader"
            :global="false"
            :shown="true"
          />
          <div
            v-else
            class="vadis-molecule-detail-item-indicators"
          >
            <div class="indicators">
              <RiskIndicator
                :risk="financial"
              />
              <h1 :class="`label-risk-${transformRiskScore(financial.score)}`">
                {{ transformRiskScore(financial.score) }}
              </h1>
            </div>
            <div class="background-indicators" />
          </div>
        </div>
      </div>
      <div
        v-if="$featureEnabled('creditSafe')"
        class="right"
      >
        <CreditSafeWidget
          :company-id="company.id"
        />
      </div>
    </div>
    <div class="content">
      <FinancialList
        v-if="company.hasFinancialHistory()"
        :company="company"
        :score="financial"
      />
      <CompanyEmptyPane v-else />
    </div>
  </div>
</template>

<i18n namespace="components.CompanyFinancialPane">
{
  "indicators": "Financial score"
}
</i18n>

<script>
import { Company } from 'flair-client';
import { transformRiskScore } from 'flair-client/src/utils';
import Loader from '@/components/Loader';
import FinancialList from '@/components/FinancialList';
import RiskIndicator from '@/components/risk/RiskIndicator';
import CompanyEmptyPane from '@/components/company/CompanyEmptyPane';
import CreditSafeWidget from '@/components/CreditSafeWidget';

export default {
  name: 'CompanyFinancialPane',
  components: {
    Loader,
    FinancialList,
    RiskIndicator,
    CompanyEmptyPane,
    CreditSafeWidget,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    transformRiskScore,
    financial: {},
  }),
  watch: {
    company() {
      this.loadFinancial();
    },
  },
  created() {
    this.loadFinancial();
  },
  methods: {
    loadFinancial() {
      const instance = this.$flairClient.AppService.instance;
      this.financial = this.$flairClient.EntitiesService.getCompanyHistoricalRiskIndicatorById({
        id: this.company.id,
        year: instance.defaultYear,
        risk: 'financial',
      });
    },
  },
};
</script>
