export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SurveyQuestions",
        translations: 







































{
  "questionsOf": "Question {a} of {b}",
  "buttons": {
    "next": "Next"
  }
}

      }
    }