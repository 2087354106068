<template>
  <div
    class="vadis-organism-openable-person-list"
    :class="{'vadis-organism-openable-person-list-open': isOpen}"
  >
    <div>
      <i
        v-if="title"
        class="icon-User"
      />
      <h4 v-if="title">
        {{ title }}
      </h4>
    </div>
    <div class="content">
      <PersonList
        :people="people"
        :current="current"
        @click="setCurrent($event)"
        @onClickRisk="openRisk($event.person, $event.risk)"
      />
      <PersonOverview
        v-if="current"
        :person="current"
        :companies="companies"
        @close="clearCurrent()"
        @onClickRisk="openRisk($event.person, $event.risk)"
      />
    </div>
  </div>
</template>

<script>
import PersonList from '@/components/person/PersonList';
import PersonOverview from '@/components/person/PersonOverview';

export default {
  name: 'OpenablePersonList',
  components: {
    PersonList,
    PersonOverview,
  },
  props: {
    people: {
      type: Object,
      default: null,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    current: undefined,
    companies: undefined,
  }),
  computed: {
    isOpen() {
      return !!this.current;
    },
  },
  watch: {
    people() {
      this.clearCurrent();
    },
  },
  methods: {
    clearCurrent() {
      this.current = null;
    },
    setCurrent(person) {
      this.current = person;
      this.companies = this.$flairClient.EntitiesService.getPersonRelatedCompanies(person);
      this.companies.promise.then(() => {
        this.$flairClient.MyService.addRecentlyBrowsedPeople(person);
      });
    },
  },
};
</script>
