<template>
  <div>
    <div
      v-if="errorTeamNameAlreadyExist"
      class="error-messages"
    >
      <p>{{ ts.errorTeamNameAlreadyExist }}</p>
    </div>
    <div
      v-if="errorTeamNameCannotBeNull"
      class="error-messages"
    >
      <p>{{ ts.errorTeamNameCannotBeNull }}</p>
    </div>
    <form @submit.prevent="createUpdateTeam()">
      <div class="vadis-form-field-standard">
        <input
          ref="input"
          v-model="teamName"
          type="text"
          :placeholder="ts.placeholder.team"
        >
      </div>
      <button
        v-if="!team"
        class="vadis-form-button-main"
        type="submit"
      >
        {{ ts.button.createTeam }}
      </button>
      <button
        v-else
        class="vadis-form-button-main"
        type="submit"
      >
        {{ ts.button.renameTeam }}
      </button>
      <button
        class="vadis-form-button-standard"
        @click="$emit('close');"
      >
        {{ ts.button.cancel }}
      </button>
    </form>
  </div>
</template>

<i18n namespace="components.TeamEditForm">
{
  "errorTeamNameCannotBeNull": "A team cannot have an empty name",
  "errorTeamNameAlreadyExists": "A team with the same name already exists",
  "button": {
    "createTeam": "Create team",
    "renameTeam": "Rename team",
    "cancel": "Cancel"
  },
  "placeholder": {
    "team": "Department name..."
  }
}
</i18n>

<script>
export default {
  name: 'TeamEditForm',
  props: {
    departmentId: {
      default: '',
      type: String,
    },
    rename: {
      default: false,
      type: Boolean,
    },
    team: {
      default: null,
      type: Object,
      required: true,
    },
  },
  data: () => ({
    teamName: '',
    errorTeamNameAlreadyExist: false,
    errorTeamNameCannotBeNull: false,
  }),
  mounted() {
    this.$refs.input.focus();
    if (this.team) {
      this.teamName = this.team.name;
    }
  },
  methods: {
    cleanError() {
      this.errorTeamNameCannotBeNull = false;
      this.errorTeamNameAlreadyExist = false;
    },
    createUpdateTeam() {
      this.cleanError();
      if (this.teamName === '') {
        this.errorTeamNameCannotBeNull = true;
        return;
      }
      if (this.rename) {
        this.renameTeam();
      } else {
        this.createTeam();
      }
    },
    createTeam() {
      this.$flairClient.AdminService.createTeam({ name: this.teamName, id: this.departmentId })
        .promise.then(() => {
          this.$emit('close');
        }).catch((err) => {
          console.error('#failed', err);
          if (err.response.status === 409) {
            this.errorTeamNameAlreadyExist = true;
          } else {
            throw err;
          }
        });
    },
    renameTeam() {
      if (this.teamName === this.team.name) {
        this.$emit('close');
        return;
      }
      this.$flairClient.AdminService.updateTeam({ id: this.team.id, name: this.teamName })
        .promise.then(() => {
          this.$emit('close');
        }).catch((err) => {
          console.error('#failed', err);
          if (err.response.status === 409) {
            this.errorTeamNameAlreadyExist = true;
          } else {
            throw err;
          }
        });
    },
  },
};
</script>
