export default function (Component) {
      Component.options._i18n = {
        namespace: "views.Error404",
        translations: 


















{
  "error": "ERROR",
  "explanation": "We are sorry for the inconvience. It looks like you’re trying to access a page that either has been deleted or never even existed.",
  "backToHome": "Back to home"
}

      }
    }