export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CreditSafeWidget",
        translations: 

















































{
  "notRated": "not rated",
  "provideBy": "Provided by",
  "creditSafe": {
    "A": {
      "result": "Very low risk : ",
      "explanation": "Proceed with transaction – offer extended terms if required. "
    },
    "B": {
      "result": "Low risk : ",
      "explanation": "Proceed with transaction."
    },
    "C": {
      "result": "Moderate risk : ",
      "explanation": "Proceed with caution and monitor closely."
    },
    "D": {
      "result": "High Risk : ",
      "explanation": "Request assurances before extending credit in the form of personal guarantees."
    },
    "E": {
      "result": "Not rated : ",
      "explanation": "No information is available to indicate the company is trading or further investigation is needed. "
    }
  }
}

      }
    }