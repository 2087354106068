<template>
  <div v-if="isOpen">
    <div class="vadis-style-global-DialogBg" />
    <div class="vadis-modal-risk">
      <div class="header">
        <h6>{{ company.name }}</h6>
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>
      <RiskWebChart :company="company" />
      <router-link
        tag="button"
        :to="{ name: 'company-details-screen', params: { id: company.id, tab: 'summary' } }"
        class="vadis-form-button-main btn-details"
      >
        {{ ts.companyDetails }}
      </router-link>
    </div>
  </div>
</template>

<i18n namespace="components.CompanyRiskModal">
{
  "companyDetails": "Company details"
}
</i18n>

<script>
import RiskWebChart from '@/components/risk/RiskWebChart';

export default {
  name: 'CompanyRiskModal',
  components: {
    RiskWebChart,
  },
  data() {
    return {
      isOpen: false,
      company: null,
    };
  },
  methods: {
    open(company) {
      this.company = company;
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
