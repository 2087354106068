export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CRIIndicators",
        translations: 

































{
  "select": "Select from {a} to {b} existing indicators to create your custom indicator",
  "indicatorsSelected": "indicators selected",
  "reset": "Reset to last saved"
}

      }
    }