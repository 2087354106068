<template>
  <div>
    <div
      v-for="(data, group) in faq"
      :key="group"
      class="department-section table-border"
    >
      <RiskIndicatorAccordion
        :risk="data"
      />
    </div>
  </div>
</template>

<script>

import RiskIndicatorAccordion from '@/components/help/RiskIndicatorAccordion';

export default {
  name: 'FlairRiskIndicatorPane',
  components: {
    RiskIndicatorAccordion,
  },
  computed: {
    faq() {
      return this.$flairClient.ts('riskIndicatorFAQ');
    },
  },
};
</script>
