export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PortfolioEmpty",
        translations: 



























{
  "title": "Your portfolio of companies is empty !",
  "text": "Start searching and then click on the bookmark icon.",
  "placeholder": "Search company..."
}

      }
    }