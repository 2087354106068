<template>
  <div class="vadis-organism-configuration-pane">
    <div class="column column-left">
      <div class="box">
        <DataConsumptionWidget />
      </div>
      <div class="box">
        <TeamMonthlyConsumption />
      </div>
    </div>
    <div class="column column-right">
      <div class="box small">
        <p>{{ ts.monitoredCompanies }}</p>
        <h1 v-if="info">
          {{ info.attributes.numberOfMonitoredCompanies }}
        </h1>
        <Loader
          v-else
          :global="false"
          :shown="true"
        />
      </div>
      <div class="box small">
        <p>{{ ts.ongoingDueDiligences }}</p>
        <h1 v-if="info">
          {{ info.attributes.numberOfOngoingDueDiligences }}
        </h1>
        <Loader
          v-else
          :global="false"
          :shown="true"
        />
      </div>
      <div
        v-if="$featureEnabled('subscription')"
        class="box column-space-2"
      >
        <ItemDetails
          v-for="(items, title) in detailGroupsSubscription"
          :key="title"
          :title="title"
          :items="items"
          :i18n="ts"
          :context="subscription"
          :title-class="'vadis-molecule-detail-item'"
          :item-class="'vadis-molecule-detail-item'"
          :label-class="'vadis-molecule-detail-label'"
          :value-class="'vadis-molecule-detail-value'"
        />
        <button class="vadis-form-button-main">
          {{ ts.button.manageSubscription }}
        </button>
      </div>
      <div class="box column-space-2">
        <div v-if="info">
          <ItemDetails
            v-for="(items, title) in detailGroupsInfo"
            :key="title"
            :title="title"
            :items="items"
            :i18n="ts"
            :context="info"
            :title-class="'vadis-molecule-detail-item'"
            :item-class="'vadis-molecule-detail-item'"
            :label-class="'vadis-molecule-detail-label'"
            :value-class="'vadis-molecule-detail-value'"
            :router-link="{ name: 'my-settings-screen' }"
          />
        </div>
        <Loader
          v-else
          :global="false"
          :shown="true"
        />
        <button class="vadis-form-button-main">
          {{ ts.button.ManageAccess }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n namespace="views.ConfigurationPane">
{
  "departmentsTeamsUsers": "Departments, teams & users",
  "subscription": "Subscription information",
  "monitoredCompanies": "Monitored companies",
  "ongoingDueDiligences": "Ongoing due diligence",
  "info": {
    "departments": "Departments",
    "teams": "Teams",
    "users": "Users",
    "plan": "Plan",
    "nextMonth": "Next month"
  },
  "button": {
    "manageSubscription": "Manage subscription",
    "ManageAccess": "Manage access"
  }
}
</i18n>

<script>
import DataConsumptionWidget from '@/components/data-consumption/DataConsumptionWidget';
import TeamMonthlyConsumption from '@/components/team/TeamMonthlyConsumption';
import ItemDetails from '@/components/ItemDetails';
import Loader from '@/components/Loader';

const detailGroupsInfo = {
  departmentsTeamsUsers: [{
    label: 'info.departments',
    path: 'attributes.numberOfDepartments',
    filter: 'unknown',
    clickable: true,
    valueClass: 'vadis-molecule-amount-link',
  }, {
    label: 'info.teams',
    path: 'attributes.numberOfTeams',
    filter: 'unknown',
    clickable: true,
    valueClass: 'vadis-molecule-amount-link',
  }, {
    label: 'info.users',
    path: 'attributes.numberOfUsers',
    filter: 'unknown',
    clickable: true,
    valueClass: 'vadis-molecule-amount-link',
  }],
};

const detailGroupsSubscription = {
  subscription: [{
    label: 'info.plan',
    path: 'attributes.plan',
    filter: 'unknown',
  }, {
    label: 'info.nextMonth',
    path: 'attributes.numberOfTeams',
    filter: 'unknown',
  }],
};

export default {
  name: 'ConfigurationPane',
  components: {
    DataConsumptionWidget,
    TeamMonthlyConsumption,
    Loader,
    ItemDetails,
  },
  data: () => ({
    detailGroupsInfo,
    detailGroupsSubscription,
    info: null,
    subscription: null,
  }),
  mounted() {
    this.$flairClient.AdminService.getOrganizationInfo().promise.then((res) => {
      this.info = res;
    });
  },
};
</script>
