<template>
  <div class="survey-submitted">
    <i class="icon-Check" />
    <div class="survey-explanation">
      <h5>
        {{ ts.alreadySubmitted }}
      </h5>
    </div>
  </div>
</template>

<i18n namespace="components.AlreadySubmitted">
{
  "alreadySubmitted": "Your survey has already been submitted.  If you'd like more information about the results, please contact the company you submitted the survey to."
}
</i18n>

<script>
export default {
  name: 'AlreadySubmitted',
};
</script>
