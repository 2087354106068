<template>
  <div
    v-if="isOpen"
  >
    <div class="vadis-style-global-DialogBg" />
    <div class="vadis-modal-risk-indicator">
      <div class="header">
        <h6 v-if="editingExistingRisk">
          {{ riskName }}
        </h6>
        <h6 v-else>
          {{ ts.header }}
        </h6>
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>
      <div
        v-if="editingExistingRisk"
        class="edit-warning"
      >
        <p>{{ ts.editInformation({ name: riskName }) }}</p>
      </div>
      <div
        class="sub-header"
      >
        <div
          v-for="(stage, index) in stages"
          :key="index"
          class="modal-stages"
          :class="{ disabled: page < index || isFinalStage}"
          @click="setActive(index)"
        >
          <label
            :class="[stage.name === currentStageName ? 'active' : '', page > stage.id -1 ? 'completed' : '']"
          >
            {{ stage.id }}
          </label>
          <p
            :class="[stage.name === currentStageName ? 'active' : '', page > stage.id -1 ? 'completed' : '']"
          >
            {{ ts.stages[stage.name] }}
          </p>
        </div>
      </div>
      <div class="body">
        <component
          :is="currentComponent"
          ref="currentStageComponent"
          v-model="customRiskIndicator"
          :providers="providers"
          :original-risk-indicator="originalRiskIndicator"
          @mounted="checkIsValid"
        />
      </div>
      <div class="footer">
        <div
          v-if="!isFinalStage"
          class="form-buttons"
        >
          <button
            v-if="!isComposition"
            class="vadis-form-button-main save"
            :disabled="hasIncorrectValidation"
            @click="nextPage();"
          >
            {{ ts.buttons.next }}
          </button>
          <button
            v-else-if="editingExistingRisk"
            :disabled="hasIncorrectValidation"
            class="vadis-form-button-main save"
            @click="updateRiskIndicator()"
          >
            {{ ts.buttons.update }}
          </button>
          <button
            v-else
            class="vadis-form-button-main save"
            :disabled="hasIncorrectValidation"
            @click="createRiskIndicator()"
          >
            {{ ts.buttons.save }}
          </button>
          <button
            class="vadis-form-button-standard cancel"
            @click="close()"
          >
            {{ ts.buttons.cancel }}
          </button>
        </div>
        <div
          v-else
          class="form-buttons"
        >
          <button
            class="vadis-form-button-main close"
            @click="close()"
          >
            {{ ts.buttons.close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.CustomRiskIndicatorModal">
{
  "header": "Create custom indicator",
  "namedHeader": "{name}",
  "editInformation": "You are modifying a custom indicator that is used in the company's global score.  By modifying '{name}',  you will impact the global score of all portfolios.",
  "stages": {
    "description": "Description",
    "indicators": "Indicators",
    "composition": "Composition",
    "confirmation": "Confirmation"
  },
  "buttons": {
    "next": "Next",
    "save": "Save",
    "update": "Update indicator",
    "cancel": "Cancel",
    "close": "Close"
  }
}
</i18n>

<script>

import CRIDescription from '@/components/custom-risk-indicator/CRIDescription';
import CRIIndicators from '@/components/custom-risk-indicator/CRIIndicators';
import CRIConfirmation from '@/components/custom-risk-indicator/CRIConfirmation';
import CRIComposition from '@/components/custom-risk-indicator/CRIComposition';
import { Collection, RiskIndicator } from 'flair-client';

// The instance of our Singleton component
let _instance;

const getInstance = () => {
  if (!_instance) {
    throw new Error('CustomRiskIndicatorModal has not been included yet');
  }
  return _instance;
};
export { getInstance };

export default {
  name: 'CustomRiskIndicatorModal',
  components: {
    CRIDescription,
    CRIIndicators,
    CRIComposition,
    CRIConfirmation,
  },
  props: {
    providers: {
      type: Collection,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    originalRiskIndicator: null,
    customRiskIndicator: null,
    isOpen: false,
    page: 0,
    isValid: false,
    stages: [{
      id: 1,
      name: 'description',
      component: CRIDescription,
    },{
      id: 2,
      name: 'indicators',
      component: CRIIndicators,
    },{
      id: 3,
      name: 'composition',
      component: CRIComposition,
    },{
      id: 4,
      name: 'confirmation',
      component: CRIConfirmation,
    }],
  }),
  computed: {
    currentStage() {
      return this.stages[this.page];
    },
    currentStageName() {
      return this.currentStage.name;
    },
    currentComponent() {
      return this.currentStage.component;
    },
    currentComponentName() {
      return this.currentComponent.name;
    },
    editingExistingRisk() {
      return this.originalRiskIndicator !== null;
    },
    isComposition() {
      return this.currentComponent === CRIComposition;
    },
    isFinalStage() {
      return this.currentComponent === CRIConfirmation;
    },
    hasIncorrectValidation() {
      return !this.isValid;
    },
    riskName() {
      return this.originalRiskIndicator
        ? this.originalRiskIndicator.name
        : this.customRiskIndicator.name;
    },
  },
  watch: {
    customRiskIndicator: {
      handler() {
        this.checkIsValid();
      },
      deep: true,
    },
    page: {
      handler() {
        this.checkIsValid();
      },
      deep: true,
    },
  },
  created() {
    if (_instance) {
      throw new Error('CustomRiskIndicatorModal is not supposed to be included more than once');
    }
    _instance = this;
  },
  methods: {
    async checkIsValid() {
      if (!this.$refs.currentStageComponent) {
        return;
      }
      this.isValid = await this.$refs.currentStageComponent.isValid();
    },
    open(riskIndicator = null) {
      // TODO: @llambeau make entities clonable or toFormData or something
      this.customRiskIndicator = riskIndicator
        ? { ...riskIndicator.attributes }
        : { ...RiskIndicator.Template };
      this.originalRiskIndicator = riskIndicator;
      this.isOpen = true;
    },
    close() {
      this.customRiskIndicator = null;
      this.page = 0;
      this.isOpen = false;
    },
    nextPage() {
      this.page += 1;
    },
    setActive(index) {
      this.page = index;
    },
    createRiskIndicator() {
      this.$flairClient.DataProviderService.createCustomRiskIndicator(
        {
          name: this.customRiskIndicator.name,
          description: this.customRiskIndicator.description,
          displayAsNumber: true,
          elements: this.customRiskIndicator.elements,
        },
      ).promise.then(() => {
        this.nextPage();
      });
    },
    updateRiskIndicator() {
      this.$flairClient.DataProviderService.updateCustomRiskIndicator({
        id: this.originalRiskIndicator.name,
        customRisk: this.customRiskIndicator,
      }).promise.then(() => {
        this.nextPage();
      });
    },
  },
};
</script>

