<template>
  <div>
    <div
      class="vadis-molecule-global-risk-box"
      :class="dueDiligence.revisedRisk ? `tag-risk-${dueDiligence.revisedRisk}` : `tag-risk-${transformRiskScore(dueDiligence.computedRisk)}`"
    >
      <div class="flex">
        <div>
          <div class="company-name">
            <router-link
              tag="span"
              :to="{ name: 'company-details-screen', params: {id: dueDiligence.relationships.company.id, tab: 'summary'} }"
            >
              {{ dueDiligence.relationships.company.name }}
            </router-link>
            <PortfolioButton
              v-if="$featureEnabled('portfolio')"
              :subject="dueDiligence.relationships.company"
            />
          </div>
          <p>{{ dueDiligence.relationships.company.europeanVAT }}</p>
          <p>
            <CountryFlag
              :country="dueDiligence.relationships.company.address.country"
              :country-only="true"
            />
          </p>
          <p>{{ ts.establishIn }} {{ dueDiligence.relationships.company.establishment.date | humanDateYear }} <GoogleSearchLink :q="dueDiligence.relationships.company.name" /></p>
        </div>
        <div>
          <div class="revised-risk">
            <p>{{ dueDiligence.revisedRisk ? ts.revisedRisk : ts.computedRisk }}</p>
            <p :class="dueDiligence.revisedRisk ? `vadis-risk-color-level${dueDiligence.revisedRisk}` : `vadis-risk-color-level${transformRiskScore(dueDiligence.computedRisk)}`">
              {{ dueDiligence.revisedRisk || transformRiskScore(dueDiligence.computedRisk) || "?" }}
            </p>
          </div>
          <template
            v-if="dueDiligence.isUnderEvaluation() && !print"
          >
            <button
              v-due-diligence-action:reviseRisk
              class="vadis-form-button-standard-small"
              @click="reviseRisk()"
            >
              {{ ts.buttons.reviseRisk }}
            </button>
          </template>
        </div>
      </div>
      <div
        v-if="dueDiligence.revisedRisk"
        class="flex"
      >
        <div>
          <p>{{ ts.revisor }} {{ dueDiligence.getRevisorName() }} </p>
        </div>
        <div>
          <p>
            {{ ts.computedRisk }} :
            <span
              class="revised-risk-small"
              :class="`vadis-risk-color-level${transformRiskScore(dueDiligence.computedRisk)}`"
            > {{ transformRiskScore(dueDiligence.computedRisk) || "?" }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.RiskGlobalBox">
{
  "establishIn": "Est. in",
  "revisor": "Revisor:",
  "computedRisk": "Computed risk",
  "revisedRisk": "Revised risk",
  "buttons": {
    "reviseRisk": "Revise risk"
  }
}
</i18n>

<script>
import GoogleSearchLink from '@/components/GoogleSearchLink';
import ReviseRiskModal from '@/components/due-diligence/ReviseRiskModal';
import PortfolioButton from '@/components/portfolio/PortfolioButton';
import CountryFlag from '@/components/CountryFlag';
import { transformRiskScore } from 'flair-client/src/utils';
import { DueDiligence } from 'flair-client';

export default {
  name: 'GlobalRiskBox',
  inherit: true,
  components: {
    GoogleSearchLink,
    CountryFlag,
    PortfolioButton,
  },
  props: {
    dueDiligence: {
      type: DueDiligence,
      default: () => {},
      required: true,
    },
    print: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    transformRiskScore,
  }),
  methods: {
    reviseRisk() {
      ReviseRiskModal.open(this.dueDiligence);
    },
  },
};
</script>
