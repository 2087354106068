export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligencePane",
        translations: 



















{
  "emptyMessage": "You don't have any due diligence pending",
  "stateTitles": {
    "ongoing": "Under investigation",
    "closed": "Closed due diligence"
  }
}

      }
    }