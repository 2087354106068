export default function (Component) {
      Component.options._i18n = {
        namespace: "components.GlobalHelpPane",
        translations: 
























{
  "inProgressMessage": "The help section is currently in progress",
   "buttons": {
     "downloadDueDiligenceManual": "Download due diligence manual",
     "downloadUserManual": "Download user manual"
   }
}

      }
    }