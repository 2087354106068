<template>
  <div
    class="custom-risk-card"
  >
    <div class="department-section table-border">
      <div class="risk-indicator-container">
        <h6 class="risk-indicator-name">
          {{ indicator.name }}
        </h6>
        <p class="risk-indicator-description">
          {{ indicator.description }}
        </p>
        <Accordion
          :open="accordionOpen"
        >
          <template v-slot:title>
            <p>
              {{ ts.combination({ a: elementsCount}) }}
            </p>
          </template>
          <div
            v-for="(provider, i) in groupedProviders"
            :key="i"
            class="element-data"
          >
            <p id="element-name">
              {{ i }}
            </p>
            <div
              v-for="(indicatorVal, j) in provider"
              :key="j"
            >
              <p>
                {{ indicatorVal.name | providerRiskIndicator }}: {{ indicatorVal.weight }}%
              </p>
            </div>
          </div>
        </Accordion>
      </div>
      <div class="buttons">
        <button
          class="vadis-form-button-standard"
          @click="editCustomIndicator()"
        >
          {{ ts.buttons.edit }}
        </button>
        <button
          class="vadis-form-button-standard"
          @click="removeCustomIndicator()"
        >
          {{ ts.buttons.delete }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.CustomRiskCard">
{
  "combination": "Combination of {a} indicators",
  "buttons": {
    "edit": "Edit",
    "delete": "Delete",
    "confirmRemoval": "Delete custom indicator"
  },
  "remove": "Remove '{q}'?",
  "removeIndicatorSubtitle": "Are you sure you want to delete the custom indicator {x}?",
  "removeIndicatorNotice": "This change could impact your overall portofolio scores."

}
</i18n>

<script>
import { Collection, RiskIndicator } from 'flair-client';
import { getInstance as getModalInstance } from '@/components/custom-risk-indicator/CustomRiskIndicatorModal';
import Accordion from '@/components/Accordion';

export default {
  name: 'CustomRiskcard',
  components: {
    Accordion,
  },
  props: {
    indicator: {
      type: RiskIndicator,
      required: true,
    },
    providers: {
      type: Collection,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    accordionOpen: false,
  }),
  computed: {
    elementsCount() {
      return this.indicator.elements ? this.indicator.elements.length : '0';
    },
    editModal() {
      return getModalInstance();
    },
    groupedProviders() {
      return this.indicator.elements.reduce((els, indicator) => {
        els[indicator.data_provider_id] ||= [];
        els[[indicator.data_provider_id]]
          .push(indicator);
        return els;
      }, {});
    },
  },
  methods: {
    editCustomIndicator() {
      this.editModal.open(this.indicator);
    },
    removeCustomIndicator() {
      this.openConfirmationModal({
        title: this.ts.remove({ q: this.indicator.name }),
        subtitle: this.ts.removeIndicatorSubtitle({ x: this.indicator.name }),
        information: this.ts.removeIndicatorNotice,
        confirmLabel: this.ts.buttons.confirmRemoval,
        onConfirm: (modal) => {
          this.$flairClient.DataProviderService.removeCustomRiskIndicator({ id: this.indicator.id })
            .promise.then(() => modal.close());
        },
      });
    },
  },
};
</script>
