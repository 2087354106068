<template>
  <div :class="cssClass">
    <InviteDueDiligenceMemberModal
      v-if="$featureEnabled('dueDiligence')"
      ref="inviteDueDiligenceMemberModal"
      :company="company"
    />
    <div class="vadis-molecule-avatar">
      <img src="../../../assets/flair.svg">
    </div>
    <div class="content">
      <p>{{ ts.vadisFlair }}</p>
      <div class="date">
        {{ event.createdAt | humanDateHour }}
      </div>
      <div class="name">
        <span
          v-if="!hasComplianceOfficer"
        >
          {{ ts.highRisk }}
        </span>
        <span
          v-else
        >
          {{ ts.addingCO }}
        </span>
      </div>
      <button
        v-if="!hasComplianceOfficer"
        @click="inviteComplianceOfficer()"
      >
        {{ ts.buttons.inviteComplianceOfficer }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.WarningCOEvent">
{
  "vadisFlair": "Vadis Flair",
  "addingCo": "The global risk is high, so well done for adding a compliance officer to help you with the due diligence",
  "highRisk": "The global risk is high,  We recommend you involve a compliance officer in the current due diligence, so they can advise you.",
  "buttons": {
    "inviteComplianceOfficer": "Invite compliance officer"
  }
}
</i18n>

<script>
import InviteDueDiligenceMemberModal from '@/components/due-diligence/InviteDueDiligenceMemberModal';

export default {
  name: 'WarningCOEvent',
  components: {
    InviteDueDiligenceMemberModal,
  },
  props: {
    dueDiligence: {
      type: Object,
      default: null,
      required: true,
    },
    member: {
      type: Object,
      default: null,
      required: true,
    },
    event: {
      type: Object,
      default: null,
      required: true,
    },
    company: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    hasComplianceOfficer() {
      return this.dueDiligence.members.map(m => m.role).includes('ComplianceOfficer');
    },
    cssClass() {
      return this.hasComplianceOfficer ? 'vadis-molecule-chat-action-advice' : 'vadis-molecule-chat-action-warning';
    },
  },
  methods: {
    inviteComplianceOfficer() {
      this.$refs.inviteDueDiligenceMemberModal.open(this.dueDiligence, 'ComplianceOfficer');
    },
  },
};
</script>
