<template>
  <div class="content">
    <img src="../../assets/company-not-found.svg">
    <h6>{{ ts.notFound }}</h6>
    <p>{{ ts.companyRemoved }}</p>
  </div>
</template>

<i18n namespace="components.CompanyNotFound">
{
  "notFound": "Company not found",
  "companyRemoved": "This company cannot be displayed or has been removed from our database."
}
</i18n>

<script>

export default {
  name: 'CompanyNotFound',
};

</script>
