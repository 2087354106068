export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SurveySubmitted",
        translations: 










{
  "thankyou": "Thank you!",
  "submit": "Your answers have been submitted"
}

      }
    }