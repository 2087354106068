<template>
  <div v-if="isOpen">
    <div class="vadis-style-global-DialogBg" />
    <div class="vadis-modal-matching-person-company">
      <div class="title">
        <h5 v-if="results.items.length">
          {{ results.meta.totalItems }} {{ ts.matchingDetected }}
        </h5>
        <h5 v-else>
          {{ ts.noMatch }}
        </h5>
        <p>
          {{ ts.providedBy }}
          <img
            src="/img/acuris.png"
            alt="acuris"
          >
        </p>
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>
      <div class="content">
        <h6> {{ ts.associateData }} </h6>
        <div class="matching-list">
          <table>
            <thead>
              <tr>
                <th />
                <th> {{ ts.labels.name }}</th>
                <th> {{ ts.labels.country }} </th>
                <th> {{ ts.labels.birthDate }} </th>
                <th> {{ ts.labels.risks }} </th>
                <th> {{ ts.labels.matchingScore }} </th>
              </tr>
            </thead>
            <tbody
              v-if="results.loading"
              class="vadis-molecule-loader"
            >
              <tr>
                <td colspan="6">
                  <Loader
                    :global="false"
                    :shown="true"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-if="results.loaded">
              <tr
                v-for="(r, i) in results.items"
                :key="i"
                :class="{'selected': matching == r}"
                @click="matching = r"
              >
                <td>
                  <input
                    :id="r"
                    v-model="matching"
                    :value="r"
                    type="radio"
                  >
                </td>
                <td> {{ r.fullName }} </td>
                <td>
                  <CountryFlag
                    :country="r.nationality"
                    :flag-only="false"
                  />
                </td>
                <td> {{ r.birthDate }} </td>
                <td>
                  <RiskTag
                    :risks="r.last.riskProfile.subrisks"
                  />
                </td>
                <td> {{ r.document.data.meta.matchingScore }} </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            v-if="results.loaded && results.hasPagination()"
            :current-page="results.meta.pageNumber"
            :total-items="results.meta.totalItems"
            :number-of-lines="results.meta.pageSize"
            :links="results.links"
            @click="onClickPagination($event)"
          />
        </div>
      </div>
      <div class="form-row buttons">
        <button
          class="vadis-form-button-main"
          :disabled="!matching"
          type="submit"
          @click="associate()"
        >
          {{ ts.buttons.associate }}
        </button>
        <button
          class="vadis-form-button-standard"
          @click="close()"
        >
          {{ ts.buttons.cancel }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.MatchingPersonModal">
{
  "people": "people",
  "matchingDetected": "Matching people detected",
  "providedBy": "Provided by",
  "associateData": "Associate your data",
  "labels": {
    "name": "Name",
    "country": "Country",
    "birthDate": "Date of birth",
    "risks": "Risks",
    "matchingScore": "Matching Score"
  },
  "buttons": {
    "associate": "Associate",
    "cancel": "Cancel"
  },
  "noMatch": "No match found"
}
</i18n>

<script>
import _ from 'lodash';
import Loader from '@/components/Loader';
import CountryFlag from '@/components/CountryFlag';
import RiskTag from '@/components/risk/RiskTag';
import Pagination from '@/components/Pagination';

export default {
  name: 'MatchingPersonModal',
  components: {
    CountryFlag,
    Loader,
    Pagination,
    RiskTag,
  },
  data: () => ({
    isOpen: false,
    matching: null,
    currentPerson: null,
    results: null,
    params: null,
  }),
  methods: {
    open(params) {
      this.isOpen = true;
      this.currentPerson = params.person;
      this.params = params;
      this.findMatch();
    },
    close() {
      this.isOpen = false;
      this.matching = null;
    },
    onClickPagination(event) {
      this.results[event]();
    },
    findMatch() {
      const payload = {
        attributes: {
          q: this.currentPerson.fullName,
          'page[size]': 5,
        },
      };
      this.results = this.$flairClient.SearchService.searchPeople(payload);
      this.results.promise.then(res => {
        if (res.items.length === 0) {
          this.matching = this.currentPerson;
          this.associate();
        } else {
          this.matching = res.items[0];
        }
      });
    },
    associate() {
      if (this.params.onAssociate) {
        this.params.onAssociate(this.matching, this);
      }
    },
  },
};
</script>
