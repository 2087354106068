<template>
  <div class="user-list table-border">
    <Accordion :open="false">
      <template v-slot:title>
        <div class="title">
          <h6> {{ ts.title }} </h6>
        </div>
      </template>
      <table>
        <thead>
          <tr>
            <th> {{ ts.th.lastName }} </th>
            <th> {{ ts.th.firstName }} </th>
            <th> {{ ts.th.role }} </th>
            <th> {{ ts.th.function }} </th>
            <th> {{ ts.th.departments }} </th>
            <th> {{ ts.th.teams }} </th>
            <th> {{ ts.th.lastVisit }} </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user) in users"
            :key="user.id"
          >
            <td>
              <div class="tag-role">
                <p class="name">
                  {{ user.lastName }}
                </p>
              </div>
            </td>
            <td>
              {{ user.firstName }}
            </td>
            <td>
              <span :class="[user.isSuperadmin ? 'vadis-molecule-tag' : '']">
                <p>{{ user.roles ? ts[user.roles.toLowerCase()] : ts.user }}</p>
              </span>
            </td>
            <td>
              {{ user.function }}
            </td>
            <td>
              <p
                v-for="dep in user.departments"
                :key="dep.id"
              >
                {{ dep.attributes.name }},
              </p>
            </td>
            <td>
              <p
                v-for="team in user.teams"
                :key="team.id"
              >
                {{ team.attributes.name }},
              </p>
            </td>
            <td>
              {{ user.lastVisit | humanDateHour }}
            </td>
            <td>
            <!-- <ContextualMenu
                :context="user"
                :items="contextualMenuItems"
              /> -->
            </td>
          </tr>
        </tbody>
      </table>
    </Accordion>
  </div>
</template>

<i18n namespace="components.UsersRevokedList">
{
  "title": "Users revoked",
  "th": {
    "lastName": "Last name",
    "firstName": "First name",
    "function": "Function",
    "role": "Role",
    "teams": "Teams",
    "departments": "Departments",
    "lastVisit": "Last visit"
  },
  "superadmin": "Super Admin",
  "teammanager": "Team Manager",
  "user": "User"
}
</i18n>

<script>
import Accordion from '@/components/Accordion';

export default {
  name: 'UsersRevokedList',
  components: {
    Accordion,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
};
</script>
