<template>
  <div>
    <CreateAccountForm
      v-if="!invalidToken"
      :user="user"
    />
    <InvalidToken v-else />
  </div>
</template>

<style lang="scss">
#subscribe-screen {
  background: url(../assets/login-screen-background.jpg);
  .topbar {
    display: none;
  }
}
</style>

<script>
import jwt from 'jsonwebtoken';
import CreateAccountForm from '@/components/CreateAccountForm';
import InvalidToken from '@/components/InvalidToken';

export default {
  name: 'ResetPassword',
  components: {
    CreateAccountForm,
    InvalidToken,
  },
  data: () => ({
    user: null,
    invalidToken: null,
  }),
  beforeRouteEnter: function(to, from, next) {
    const decoded = jwt.decode(to.params.token, { complete: true });
    next(vm => {
      if (!decoded || Date.now() >= decoded.payload.exp * 1000) {
        vm.invalidToken = true;
      } else {
        vm.user = {
          firstname: decoded.payload.firstname,
          lastname: decoded.payload.lastname,
          email: decoded.payload.email,
          token: to.params.token,
        };
        vm.invalidToken = false;
      }

    });
  },
};
</script>
