<template>
  <div>
    <form
      v-if="!emailSent"
      id="login-form"
      class="vadis-organism-login"
      novalidate
      @submit.prevent="validateCreds"
    >
      <h3>{{ ts.title }}</h3>
      <div
        :class="{ 'form-row vadis-form-field-standalone': true, invalid: $v.form.mail.$error }"
        class="password-forgotten-form"
      >
        <input
          id="mail-input"
          v-model.trim="form.mail"
          :placeholder="ts.placeholders.mail"
          :disabled="sending"
          @input="$v.form.mail.$touch"
        >
        <p
          v-if="$v.form.mail.$error"
          class="validation-error"
        >
          {{ ts.errors.mailRequired }}
        </p>
      </div>
      <div class="form-row buttons">
        <button
          type="submit"
          :disabled="$v.form.$invalid || sending"
        >
          {{ ts.buttons.sendMail }}
        </button>
      </div>
      <div class="link">
        <router-link :to="{ name: 'login-screen' }">
          {{ ts.login }}
        </router-link>
      </div>
    </form>
    <EmailSend
      v-else
      :mail="form.mail"
    />
  </div>
</template>

<i18n namespace="components.PasswordForgottenForm">
{
  "title": "Password forgotten",
  "errors": {
    "mailRequired": "The mail is required"
  },
  "buttons": {
    "sendMail": "Send reset link"
  },
  "placeholders": {
    "mail": "Mail"
  },
  "login": "Login"
}
</i18n>

<script>
import { required, email } from 'vuelidate/lib/validators';
import EmailSend from '@/components/EmailSend';

export default {
  name: 'PasswordForgottenForm',
  components: {
    EmailSend,
  },
  data: () => ({
    form: {
      mail: '',
    },
    sending: false,
    emailSent: false,
  }),
  validations: {
    form: {
      mail: {
        required,
        email,
      },
    },
  },
  methods: {
    sendMail() {
      this.sending = true;
      this.$flairClient.AuthenticationService.forgottenPassword({ email: this.form.mail })
        .promise
        .then(() => {
          this.sending = false;
          this.emailSent = true;
        }).catch((err) => {
          console.error('PasswordForgottenForm#password forgotten failed', err);
          this.sending = false;
        });
    },
    validateCreds() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendMail();
      }
    },
  },
};
</script>
