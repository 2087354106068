export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CreateAccountForm",
        translations: 












































































































{
  "title": "Join Flair",
  "name": "Hi {firstname} {lastname},",
  "joinFlair": "Please fill in your information to join Flair.",
  "information": "Your username will be {mail}",
  "passwordRules": "Your password needs to include both lower and uppercase characters, at least 1 number, 1 symbol, and be between 12-24 characters long",
  "loginInformation": "Thanks for subscribing to Flair, please enter your login information to start your session",
  "errors": {
    "passwordRequired": "A password is required",
    "repeatPasswordRequired": "Passwords must be identical",
    "functionRequired": "A function is required and must be between 1-64 characters in length",
    "phoneNumberRequired": "Your phone number can only contain numeric characters"
  },
  "buttons": {
    "subscribe": "Subscribe"
  },
  "placeholders": {
    "password": "Password",
    "repeatPassword": "Repeat password",
    "function": "Function",
    "phoneNumber": "Phone number"
  }
}

      }
    }