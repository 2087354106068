export default function (Component) {
      Component.options._i18n = {
        namespace: "views.MyPortfolioPDF",
        translations: 
































{
  "printedOn": "Printed on",
  "myPortfolio": "My portfolio",
  "savePDF": "Save PDF"
}

      }
    }