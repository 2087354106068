export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligenceEmpty",
        translations: 























{
  "noDueDiligence": "No due diligence has been executed, yet.",
  "startDueDiligence": "Start due diligence"
}

      }
    }