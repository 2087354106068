<template>
  <a @click="searchGoogle()"><img
    class="icon-Google"
    src="../assets/google.svg"
  >{{ company }}</a>
</template>

<script>
export default {
  name: 'GoogleSearchLink',
  props: {
    q: {
      type: String,
      default: null,
      required: true,
    },
    company: {
      type: String,
      default: null,
    },
  },
  methods: {
    searchGoogle() {
      window.open(`http://google.com/search?q=${this.q}`);
    },
  },
};
</script>
