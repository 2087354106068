<template>
  <div>
    <div>
      <DueDiligenceDetailsList
        v-for="(dueDiligences, state) in groupedDueDiligences"
        :key="state"
        :due-diligences="dueDiligences"
        :title="ts.stateTitles[state]"
      />
    </div>
    <div
      v-if="error"
      class="vadis-organism-tab-empty"
    >
      <h6>{{ ts.emptyMessage }}</h6>
    </div>
  </div>
</template>

<i18n namespace="components.DueDiligencePane">
{
  "emptyMessage": "You don't have any due diligence pending",
  "stateTitles": {
    "ongoing": "Under investigation",
    "closed": "Closed due diligence"
  }
}
</i18n>

<script>
import DueDiligenceDetailsList from '@/components/due-diligence/DueDiligenceDetailsList.vue';

const state = {
  ongoing: 'ongoing',
  closed: 'closed',
};

export default {
  name: 'DueDiligencePane',
  components: {
    DueDiligenceDetailsList,
  },
  data: () => ({
    error: false,
    state,
    groupedDueDiligences: {
      ongoing: {},
      closed: {},
    },
  }),
  created() {
    this.loadDueDiligences();
  },
  methods: {
    loadDueDiligences() {
      this.groupedDueDiligences.closed = this.$flairClient.MyService.getMyDueDiligences({ state: state.closed });
      this.groupedDueDiligences.ongoing = this.$flairClient.MyService.getMyDueDiligences({ state: state.ongoing });
    },
  },
};
</script>
