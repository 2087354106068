<template>
  <div class="data-provider-card">
    <div class="department-section table-border">
      <div class="left">
        <img :src="getLogoForDataProvider(provider.id)">
      </div>
      <div class="data-provider-details">
        <div class="header">
          <h6 class="provider-name">
            {{ provider.id }}
          </h6>
          <div
            v-if="isActivated"
            class="active-btn"
          />
          <p
            v-if="isActivated"
            class="active"
          >
            {{ ts.active }}
          </p>
        </div>
        <p>
          {{ tsDataProviderDescription[provider.id] }}
        </p>
        <Accordion
          :open="accordionOpen"
        >
          <template v-slot:title>
            <p>{{ ts.contains({ number: indicatorCount}) }}</p>
          </template>
          <div class="list-indicators">
            <div
              v-for="(risk, i) in provider.riskIndicators"
              :key="i"
              class="risk-indicator-data"
            >
              <p id="risk-indicator-name">
                {{ risk.name | providerRiskIndicator }}
              </p>
            </div>
          </div>
        </Accordion>
      </div>
      <div class="buttons">
        <button
          v-if="isActivated"
          class="vadis-form-button-standard"
          :disabled="true"
        >
          {{ ts.buttons.disable }}
        </button>
        <button
          v-else
          class="vadis-form-button-main"
        >
          {{ ts.buttons.enable }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.DataProviderCard">
  {
    "contains": "Contains {number} indicators",
    "active": "active",
    "buttons": {
      "disable": "Disable",
      "enable": "Enable data provider"
    }
  }
</i18n>

<script>

import AcurisLogo from '@/assets/data-providers/acuris.png';
import CreditSafeLogo from '@/assets/data-providers/creditsafe.png';
import EcoVadisLogo from '@/assets/data-providers/ecovadis.png';
import FlairDataLogo from '@/assets/data-providers/flairData.png';
import { DataProvider } from 'flair-client';
import Accordion from '@/components/Accordion';

export default {
  name: 'DataProviderCard',
  components: {
    Accordion,
  },
  props: {
    provider: {
      type: DataProvider,
      required: true,
    },
  },
  data: () => ({
    isEnabled: false,
    accordionOpen: false,
    logos: {
      acuris: AcurisLogo,
      creditsafe: CreditSafeLogo,
      ecovadis: EcoVadisLogo,
      flairdata: FlairDataLogo,
    },

  }),
  computed: {
    indicatorCount() {
      return this.provider.riskIndicators.length;
    },
    isActivated() {
      return this.provider.activated;
    },
    tsDataProviderDescription() {
      return this.$flairClient.ts('dataProviderDescription');
    },
  },
  methods: {
    getLogoForDataProvider(providerId) {
      return this.logos[providerId.toLowerCase()];
    },
  },
};
</script>
