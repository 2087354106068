<template>
  <div
    v-if="isOpen"
    class="vadis-modal-change-provider"
  >
    <div class="title">
      <h6>{{ ts.changeProvider }}</h6>
      <i
        class="icon-Close"
        @click="close()"
      />
    </div>
    <form
      @submit.prevent="sendForm"
    >
      <div class="providers">
        <div
          v-for="provider in providers"
          :key="provider.name"
        >
          <label>
            <input
              :id="provider.name"
              v-model="form.provider"
              type="radio"
              :value="provider.name"
              :disabled="!provider.isAllowed"
            >
            {{ provider.name }}</label>
        </div>
      </div>
      <div class="buttons">
        <button
          type="submit"
          class="vadis-form-button-main-small"
        >
          {{ ts.buttons.change }}
        </button>
        <button
          class="vadis-form-button-standard-small"
          @click="close()"
        >
          {{ ts.buttons.cancel }}
        </button>
      </div>
    </form>
  </div>
</template>

<i18n namespace="components.ChangeProviderModal">
{
  "changeProvider": "Change provider",
  "buttons": {
    "change": "Change provider",
    "cancel": "Cancel"
  }
}
</i18n>

<script>
import _ from 'lodash';

export default {
  name: 'ChangeProviderModal',
  components: {
  },
  data: () => ({
    isOpen: false,
    providers: null,
    form: {
      provider: null,
    },
  }),
  methods: {
    open(providers) {
      this.providers = providers;
      this.form.provider = _.get(_.find(providers, p => p.isCurrent), 'name');
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    sendForm() {
      localStorage.setItem('provider', this.form.provider);
      this.$router.go();
    },
  },
};
</script>
