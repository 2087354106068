<template>
  <div class="my-portfolio-view">
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb>
        <li v-if="currentTeam">
          {{ ts.title({team: currentTeam.name}) }}
        </li>
      </Breadcrumb>
      <div
        v-if="currentTeam"
        class="header"
      >
        <h1>{{ ts.title({team: currentTeam.name}) }}</h1>
      </div>
      <TabsComponent
        ref="tabs"
        v-model="currentTab"
        :tabs="tabs"
      />
    </div>
    <div class="vadis-organism-padded-area">
      <PortfolioCompanyPane
        v-if="currentTeam"
        :team="currentTeam"
      />
    </div>
  </div>
</template>

<i18n namespace="views.TeamPortfolio">
{
  "title": "{team} team portfolio"
}
</i18n>

<script>
import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';
import PortfolioCompanyPane from '@/components/portfolio/PortfolioCompanyPane';
import TabsComponent from '@/components/TabsComponent';

export default {
  name: 'TeamPortfolio',
  components: {
    TopBar,
    Breadcrumb,
    PortfolioCompanyPane,
    TabsComponent,
  },
  data: () => ({
    currentTab: null,
    tabs: [],
  }),
  computed: {
    currentTeam() {
      return this.currentTab ? this.currentTab.team : null;
    },
  },
  created() {
    this.fetchTeams();
  },
  methods: {
    fetchTeams() {
      return this.$flairClient.MyService.getMyTeams().promise
        .then(res => {
          // Create list of tabs from list of teams
          // we decorate the tab with the team object for ease of access
          // (see currentTeam())
          this.tabs = res.items.map((team) => {
            return {
              id: team.id,
              label: team.name,
              team,
            };
          });
        });
    },
  },
};

</script>
