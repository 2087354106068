export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RiskIndicator",
        translations: 















































{
  "lastUpdate": "Last update",
  "nodata": "No data found",
  "norisk": {
    "pep": "No PEP identified",
    "adverse_media": "No adverse media identified",
    "default": "No risk"
  }
}

      }
    }