export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligenceRequestInfo",
        translations: 



















































































































{
  "link": "View & manage forms",
  "nothingRequested": {
    "title": "Would you like some info from the company before making an informed decision?",
    "information": "Send a survey form to a manager of the company"
  },
  "requested": {
    "title": "Information requests",
    "surveyName": "Survey name",
    "preview": "preview",
    "sent": "Sent",
    "completedOn": "Completed on",
    "viewRequest": "view request"
  },
  "buttons": {
    "requestInfo": "Request info",
    "details": "Details",
    "remindSupplier": "Remind supplier"
  }
}

      }
    }