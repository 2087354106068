<template>
  <span
    v-if="company.address"
    class="vadis-molecule-country"
  >
    <CountryFlag

      :country="company.address.country"
      :flag-only="true"
    />
    <span>{{ company.address.city }}</span>
    <span v-if="company.address.country">({{ company.address.country }})</span>
  </span>
  <span v-else>
    -
  </span>
</template>

<script>
import { Company } from 'flair-client';
import CountryFlag from '@/components/CountryFlag';

export default {
  name: 'CompanyLocation',
  components: {
    CountryFlag,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
};
</script>
