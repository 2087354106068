<template>
  <div class="container">
    <img
      class=""
      src="../../assets/user_guide.svg"
    >
    <p>{{ ts.inProgressMessage }}</p>
    <div class="buttons">
      <button
        class="vadis-form-button-main"
        @click="downloadUserManual()"
      >
        {{ ts.buttons.downloadUserManual }}
      </button>
      <button
        class="vadis-form-button-main"
        @click="downloadDueDiligenceManual()"
      >
        {{ ts.buttons.downloadDueDiligenceManual }}
      </button>
    </div>
  </div>
</template>

<i18n namespace="components.GlobalHelpPane">
{
  "inProgressMessage": "The help section is currently in progress",
   "buttons": {
     "downloadDueDiligenceManual": "Download due diligence manual",
     "downloadUserManual": "Download user manual"
   }
}
</i18n>

<script>

import _ from 'lodash';
import { downloadFile, fileExists } from 'flair-client/src/utils';
import { USER_GUIDE_FILE, DUE_DILIGENCE_MANUAL, DEFAULT_USER_GUIDE_FILE, DEFAULT_DUE_DILIGENCE_MANUAL } from '@/constants';

export default {
  namne: 'GlobalHelpPane',
  methods: {
    downloadUserManual() {
      const newUrl = USER_GUIDE_FILE.replace('$LANG$', this.getCorrectLang());
      fileExists(newUrl)
        .then(exists => {
          if (exists) {
            downloadFile(newUrl);
          } else {
            downloadFile(DEFAULT_USER_GUIDE_FILE);
          }
        });
    },
    downloadDueDiligenceManual() {
      const newUrl = DUE_DILIGENCE_MANUAL.replace('$LANG$', this.getCorrectLang());
      fileExists(newUrl)
        .then(exists => {
          if (exists) {
            downloadFile(newUrl);
          } else {
            downloadFile(DEFAULT_DUE_DILIGENCE_MANUAL);
          }
        });
    },
    getCorrectLang() {
      const res = _.split(this.$flairClient.I18NService.locale, '-');
      const correctLang = _.toUpper(res[0]);
      return correctLang;
    },
  },
};
</script>
