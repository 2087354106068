export default function (Component) {
      Component.options._i18n = {
        namespace: "components.AlreadySubmitted",
        translations: 











{
  "alreadySubmitted": "Your survey has already been submitted.  If you'd like more information about the results, please contact the company you submitted the survey to."
}

      }
    }