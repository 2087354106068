export default function (Component) {
      Component.options._i18n = {
        namespace: "components.MakeDecisionEvent",
        translations: 











































{
  "setStatus": "set the status to",
  "analyst": "Analyst",
  "manager": "Manager",
  "approved": "Approved",
  "rejected": "Rejected"
}

      }
    }