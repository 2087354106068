<template>
  <div
    v-if="isOpen"
    class="vadis-molecule-survey-details"
  >
    <Loader
      v-if="pdfLoaded"
      :global="true"
      :shown="true"
    />
    <div
      class="header"
    >
      <h5>
        {{ result.survey.attributes.name }}
      </h5>
      <i
        class="icon-Close"
        @click="close"
      />
    </div>
    <div
      class="vadis-organism-table-subheader-nav"
    >
      <p>
        {{ ts.completedBy }}
        <span class="company-name"> {{ result.company.attributes.name }}</span>
        <span class="survey-date">{{ ts.on }} {{ result.data.attributes.updatedAt | humanDate }}</span>
      </p>
      <div class="navigation">
        <i
          class="icon-Arrow-left"
          @click="surveySub.prevPage()"
        />
        <h5>{{ ts.questionsTo({ a: currentPage + 1, b: surveySub.pageCount}) }}</h5>
        <i
          class="icon-Arrow-right"
          @click="surveySub.nextPage()"
        />
      </div>
      <div
        class="download-pdf"
        @click="downloadPDF()"
      >
        <i class="icon-Download" />
        <a>{{ ts.downloadPDF }}</a>
      </div>
    </div>
    <survey
      v-if="surveySub"
      class="survey-library"
      :survey="surveySub"
    />
  </div>
</template>

<i18n namespace="components.SurveyDetails">
{
  "completedBy": "Completed by",
  "on": "on",
  "questionsTo": "Question {a} of {b}",
  "downloadPDF": "Download PDF"
}
</i18n>

<script>
import * as SurveyVue from 'survey-vue';
import Loader from '@/components/Loader';

const Survey = SurveyVue.Survey;

export default {
  name: 'SurveyDetails',
  components: {
    Survey,
    Loader,
  },
  data() {
    return {
      isOpen: false,
      surveySub: null,
      result: null,
      currentPage: null,
      pdfLoaded: false,
    };
  },
  watch: {
    surveySub: {
      handler: function(o, n) {
        if (n) {
          this.currentPage = n.currentPageNo;
        }
      },
      deep: true,
    },
  },
  methods: {
    open(surveySub) {
      this.result = surveySub;
      this.surveySub = new SurveyVue.Model(surveySub.survey.attributes.content);
      (this.surveySub.getAllQuestions()).forEach(e => {
        e.colCount = 2;
      });
      this.surveySub.data = surveySub.data.attributes.result;
      this.surveySub.mode = 'display';
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    downloadPDF() {
      this.pdfLoaded = true;
      this.$flairClient.SurveyService.getSurveySubmissionAsPDF({ id: this.result.id }, this.result.survey.attributes.name)
        .finally(() => {
          this.pdfLoaded = false;
        });
    },
  },
};

</script>
