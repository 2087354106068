export default function (Component) {
      Component.options._i18n = {
        namespace: "components.ReviseRiskForm",
        translations: 









































































{
  "motivation": "Motivation",
  "placeholder": "Write your motivation here...",
  "documents": "Documents that support the revision",
  "dragFile": "Drag files to upload, or click to ",
  "browse": "browse...",
   "buttons": {
    "save": "Save report",
    "cancel": "Cancel"
  }
}

      }
    }