export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligenceRolesPane",
        translations: 



















































































{
  "header": "Due diligence roles",
  "tasks": "Tasks",
  "roles": {
    "analyst": "Analyst",
    "manager": "Manager",
    "complianceOfficer": "Compliance Officer"
  }
}

      }
    }