export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyFinancialPane",
        translations: 






















































{
  "indicators": "Financial score"
}

      }
    }