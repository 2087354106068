export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SurveyAccordion",
        translations: 




























































{
  "language": "language(s)",
  "renameSurveyTemplate": "Rename survey template",
  "removeSurvey": "Remove survey",
  "removeSurveyTemplate": "All the survey versions (languages) contained will be lost",
  "remove": "Remove '{q}'?",
  "addNewLanguage": "Add new language survey",
  "button": {
    "addLanguage": "Add language"
  }
}

      }
    }