export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DueDiligenceNotFound",
        translations: 










{
  "notFound": "Due Diligence not found",
  "noDueDiligence": "This due diligence cannot be found or has been removed from our database"
}

      }
    }