export default function (Component) {
      Component.options._i18n = {
        namespace: "views.TeamPortfolio",
        translations: 






























{
  "title": "{team} team portfolio"
}

      }
    }