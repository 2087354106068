export default function (Component) {
      Component.options._i18n = {
        namespace: "components.Pagination",
        translations: 






















{
  "lineOf": "{line} of {total} lines"
}

      }
    }