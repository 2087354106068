export default function (Component) {
      Component.options._i18n = {
        namespace: "components.TeamAccordionItem",
        translations: 


















































{
  "button": {
    "renameTeam": "Rename team",
    "addManager": "Add manager"
  },
  "link": {
    "send": "Send invitation",
    "resend": "Resend invitation"
  },
  "members": "members",
  "renameTeam": "Rename team",
  "editManager": "Edit manager",
  "remove": "Remove '{q}'?",
  "removeTeam": "Remove team...",
  "removeTeamSubtitle": "All accounts of people within team will be suspended."
}

      }
    }