<template>
  <nav class="vadis-organism-navigation">
    <div class="left">
      <ul>
        <li>
          <router-link :to="{ name: 'search-screen' }">
            <img src="../assets/flair.svg">
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'search-screen' }">
            {{ ts.home }}
          </router-link>
        </li>
        <li v-if="$featureEnabled('teamPortfolio') && $featureEnabled('portfolio')">
          <router-link :to="{ name: 'my-portfolio-screen'}">
            {{ ts.portfolios }}
          </router-link>
        </li>
        <li v-if="!$featureEnabled('teamPortfolio')">
          <router-link :to="{ name: 'my-portfolio-screen'}">
            {{ ts.myportfolio }}
          </router-link>
        </li>
        <li v-if="$featureEnabled('dueDiligence')">
          <router-link :to="{ name: 'my-due-diligence-list-screen' }">
            {{ ts.dueDiligences }}
          </router-link>
        </li>
        <li v-if="$featureEnabled('configuration') && $featureEnabled('userManagement')">
          <router-link :to="{ name: 'configuration-screen' }">
            {{ ts.configuration }}
          </router-link>
        </li>
        <li v-if="$featureEnabled('userManagement') || $featureEnabled('teamManagement')">
          <router-link :to="{ name: 'my-settings-screen' }">
            {{ ts.settings }}
          </router-link>
        </li>
        <li v-if="$featureEnabled('userManagement') && $featureEnabled('customization')">
          <router-link :to="{ name: 'customization-screen' }">
            {{ ts.customization }}
          </router-link>
        </li>
        <li>
          <router-link to="/help">
            {{ ts.help }}
          </router-link>
        </li>
      </ul>
    </div>
    <PasswordChangeForm ref="passwordChangeForm" />
    <ChangeProviderModal ref="changeProviderModal" />
    <div class="right">
      <ul>
        <li>
          <AppVersionInfo @change-provider="changeProvider($event)" />
        </li>
        <li>
          <div class="vadis-molecule-info-user">
            <div class="vadis-molecule-avatar-medium">
              <p>{{ me.initials }}</p>
            </div>
            <div class="vadis-molecule-info">
              <div class="content">
                <div class="section user">
                  <LangSwitcher />
                  <div class="vadis-molecule-avatar-big">
                    <p>{{ me.initials }}</p>
                  </div>
                  <p class="title">
                    {{ me.firstName }} {{ me.lastName }}
                  </p>
                  <p
                    v-for="team in me.teams"
                    :key="team.name"
                  >
                    {{ team.isManager ? ts.manager : ts.analyst }} •  {{ team.name }}
                  </p>
                </div>
                <div class="section">
                  <p>{{ me.email }}</p>
                  <a
                    v-if="$featureEnabled('internalAuthentication')"
                    @click="changePassword()"
                  >{{ ts.changePassword }}</a>
                </div>
                <div class="section">
                  <button
                    class="vadis-form-button-standard-icon"
                    @click="logout"
                  >
                    <i class="icon-Logout" />{{ ts.logout }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<i18n namespace="components.NavBar">
{
  "home": "Home",
  "myportfolio": "My Portfolio",
  "portfolios": "Portfolios",
  "departments": "Departments",
  "settings": "Settings",
  "customization": "Customization",
  "configuration": "Configuration",
  "changePassword": "Change password",
  "analyst": "Analyst",
  "manager": "Manager",
  "logout": "Logout",
  "help": "Help",
  "dueDiligences": "Due Diligence",
  "customization": "Customization"
}
</i18n>

<script>
import LangSwitcher from './LangSwitcher.vue';
import AppVersionInfo from './AppVersionInfo.vue';
import PasswordChangeForm from '@/components/password/PasswordChangeForm';
import ChangeProviderModal from '@/components/ChangeProviderModal';

export default {
  name: 'NavBar',
  components: {
    LangSwitcher,
    AppVersionInfo,
    PasswordChangeForm,
    ChangeProviderModal,
  },
  computed: {
    me() {
      return this.$flairClient.AuthenticationService.me;
    },
  },
  methods: {
    logout() {
      this.$flairClient.AuthenticationService.logout();
    },
    changePassword() {
      this.$refs.passwordChangeForm.open();
    },
    changeProvider($event) {
      this.$refs.changeProviderModal.open($event);
    },
  },
};
</script>
