<template>
  <div class="vadis-molecule-info-app">
    <p>
      {{ ts.name }} v{{ version }} <i class="icon-Info" />
    </p>
    <div
      v-if="instance"
      class="vadis-molecule-info"
    >
      <div class="content">
        <div class="section">
          <div class="vadis-molecule-detail-item">
            <span class="vadis-molecule-detail-label">
              {{ ts.frontend }}
            </span>
            <span class="vadis-molecule-detail-value"> {{ version }}</span>
          </div>
          <div
            v-if="$featureEnabled('devEnvironment')"
            class="vadis-molecule-detail-item"
          >
            <span class="vadis-molecule-detail-label">
              {{ ts.environment }}
            </span>
            <span class="vadis-molecule-detail-value"> {{ instance.environment | unknown }} </span>
          </div>
          <div
            v-if="$featureEnabled('devEnvironment')"
            class="vadis-molecule-detail-item"
          >
            <span class="vadis-molecule-detail-label">
              {{ ts.server }}
            </span>
            <span class="vadis-molecule-detail-value"> {{ instance.server | unknown }} </span>
          </div>
          <div class="vadis-molecule-detail-item">
            <span class="vadis-molecule-detail-label">
              {{ ts.provider }}
            </span>
            <span class="vadis-molecule-detail-value"><a @click="$emit('change-provider', instance.providers)"> {{ getCurrentProvider }} </a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.AppVersionInfo">
{
  "name": "Flair Client",
  "backend": "Backend",
  "frontend": "Frontend",
  "data": "Data",
  "environment": "Environment",
  "server": "Server",
  "financialData": "Financial data",
  "financialDataDate": "Financial data date",
  "complianceData": "Compliance data",
  "complianceDataDate": "Compliance data date",
  "provider": "Provider"
}
</i18n>

<script>
import _ from 'lodash';
import { version } from '@/constants';

export default {
  name: 'AppVersionInfo',
  data: () => ({
    version,
    apiVersion: null,
    instance: {},
    isPRoviderModalOpen: false,
  }),
  events: {
    'app:instance:updated': (vm) => {
      vm.refreshVersions();
    },
  },
  computed: {
    getCurrentProvider: function() {
      return localStorage.getItem('provider') || this.instance.currentProvider;
    },
  },
  created() {
    this.refreshVersions();
  },
  methods: {
    refreshVersions() {
      this.instance = this.$flairClient.AppService.instance;
    },
  },
};
</script>
