export default function (Component) {
      Component.options._i18n = {
        namespace: "components.DepartmentEditForm",
        translations: 















































{
  "errorDepartmentNameCannotBeNull": "A department cannot have an empty name",
  "errorDepartmentNameAlreadyExist": "A department with the same name already exists",
  "button": {
    "createDepartment": "Create department",
    "renameDepartment": "Rename department",
    "cancel": "Cancel"
  },
  "placeholder": {
    "department": "Department name..."
  }
}

      }
    }