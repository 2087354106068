export default function (Component) {
      Component.options._i18n = {
        namespace: "views.Customization",
        translations: 





























{
  "title": "Customization",
  "riskIndicators": "Risk Indicators"
}

      }
    }