export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RiskTag",
        translations: 






















{
  "tooltips": {
    "pep": "Politically exposed person",
    "adverse_media": "Adverse media"
  }
}

      }
    }