export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CompanyEcovadisPane",
        translations: 















































































{
  "sampleData": "Sample data",
  "information": "This view is an example (with fake data) of what Ecovadis data might look like if you connect to this provider",
  "completeScorecard": "Complete scorecard",
  "overAllScore": "Overall score",
  "providedBy": "Provided by",
  "unit": "Unit",
  "th": "th",
  "percentile": "percentile",
  "filterCategories": {
    "percentage": "Percentage",
    "score": "Risk indicator"
  },
  "averageScore": "Average Industry Score"
}


      }
    }