<template>
  <div class="vadis-organism-not-found">
    <img
      src="@/assets/due-diligence-not-found.svg"
    >
    <h6> {{ ts.notFound }}</h6>
    <p>{{ ts.noDueDiligence }}</p>
  </div>
</template>

<i18n namespace="components.DueDiligenceNotFound">
{
  "notFound": "Due Diligence not found",
  "noDueDiligence": "This due diligence cannot be found or has been removed from our database"
}
</i18n>

<script>
export default {
  name:'DueDiligenceNotFound',
};
</script>
