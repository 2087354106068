<template>
  <div class="vadis-molecule-d3-graph full-space">
    <svg
      width="100%"
      height="100%"
    />
  </div>
</template>

<script>
import * as d3 from 'd3';
import GetUtils from '@/utils/get';

const RADIUS = 20;

export default {
  name: 'D3ClusterView',
  props: {
    graph: {
      type: Object,
      default: null,
      required: true,
    },
    showPercentage: {
      type: Boolean,
      default: false,
      required: false,
    },
    shownRiskIndicator: {
      type: String,
      default: 'financial',
    },
  },
  data: () => ({
    svg: null,
    untouched: true,
    treeGraph: null,
    treeGraphData: null,
  }),
  watch: {
    shownRiskIndicator() {
      this.update();
    },
    showPercentage() {
      this.update();
    },
    graph() {
      this.buildTree();
    },
  },
  mounted() {
    this.createSvgGraph();
    if (this.graph) {
      this.buildTree();
    }
  },
  methods: {
    zoomIn() {
      this.zoomable.scaleBy(this.svg.transition().duration(250), 1.2);
    },
    zoomOut() {
      this.zoomable.scaleBy(this.svg.transition().duration(250), 0.8);
    },
    buildTree() {
      this.graph.toTree().then(g => {
        this.treeGraph = g;
        this.treeGraphData = g.toTreeData();
        this.update();
      });
    },
    update() {
      const getUtils = GetUtils(this, 'data.node');
      const cluster = d3.cluster()
        .nodeSize([10 * RADIUS, 6 * RADIUS])
        .separation((a, b) => {
          return (a.parent === b.parent ? 2 : 2) / a.depth;
        });
      const root = d3.hierarchy(this.treeGraphData, (d) => {
        return d.children;
      });

      // Update links
      this.link = this.link.data(cluster(root).links());
      this.link.exit().remove();

      const newLink = this.link.enter()
        .append('path')
        .attr('class', 'link')
        .attr('marker-end', 'url(#arrow)');

      this.link = this.link.merge(newLink)
        .attr('d', (d) => {
          return `M${d.source.x},${d.source.y
          }C${d.source.x},${d.source.y
          } ${d.target.x},${d.source.y
          } ${d.target.x},${d.target.y - 1.5 * RADIUS}`;
        })
        .attr('id', (d, i) => `edgepath${i}`);

      // Update edges label
      const labelsData = this.showPercentage ? cluster(root).links() : [];
      this.edgeLabel = this.edgeLabel.data(labelsData);
      this.edgeLabel.exit().remove();

      const newEdgeLabel = this.edgeLabel.enter()
        .append('text')
        .style('pointer-events', 'none')
        .attr('class', 'edgelabel')
        .attr('dx', 65)
        .attr('dy', 0)
        .attr('font-size', 10)
        .attr('fill', '#aaa');

      newEdgeLabel.append('textPath')
        .attr('xlink:href', (d,i) => `#edgepath${i}`)
        .style('pointer-events', 'none')
        .text(GetUtils(this, 'target.data').edgeLabel);

      this.edgeLabel = this.edgeLabel.merge(newEdgeLabel);

      // Update nodes
      this.node = this.node.data(root.descendants(), (n) => n.data.node.id);
      this.node.exit().remove();

      const newNode = getUtils.defaultNodeCRUD();

      this.node = this.node.merge(newNode)
        .attr('class', getUtils.nodeClass)
        .attr('transform', (d) => { return `translate(${d.x},${d.y})`; });

      getUtils.defaultEventHandlers();
    },
    createSvgGraph() {
      this.svg = d3.select(this.$el).select('svg');
      let width = this.$el.offsetWidth;
      let height = this.$el.offsetHeight;

      const g = this.svg.append('g')
        .attr('class', 'chart');

      const defs = this.svg.append('svg:defs');
      // Arrow def
      defs.append('svg:marker') // This section adds in the arrows
        .attr('id', 'arrow')
        .attr('class', 'arrow')
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 0)
        .attr('refY', 0)
        .attr('markerWidth', 6)
        .attr('markerHeight', 6)
        .attr('orient', 'auto')
        .append('svg:path')
        .attr('d', 'M0,-5L10,0L0,5');

      const zoom = d3.zoom().on('zoom', () => {
        g.attr('transform', d3.event.transform);
      });

      const transform = d3.zoomIdentity.translate(width / 2, height / 2 + 20);
      zoom(this.svg);

      this.svg.call(zoom.transform, transform);

      resize.bind(this)();
      d3.select(window).on('resize', resize.bind(this));

      /**
       * Resize function
       */
      function resize() {
        width = this.$el.offsetWidth;
        height = this.$el.offsetHeight;

        this.svg.attr('height', height);
        this.svg.attr('width', width);
      }

      // Make it zoomable
      this.zoomable = d3.zoom().on('zoom', zoomActions);
      this.zoomable(this.svg);
      this.svg.on('dblclick.zoom', null);

      // Zoom functions
      function zoomActions() {
        this.untouched = false;
        g.attr('transform', d3.event.transform);
      }

      this.link = g.append('g')
        .selectAll('.link');
      this.node = g.append('g')
        .selectAll('.node');
      this.edgeLabel = g.append('g')
        .selectAll('.edge-label');
    },
  },
};
</script>
