<template>
  <div>
    <DueDiligenceList
      v-if="dueDiligences.items.length || !dueDiligences.loaded"
      :due-diligences="dueDiligences"
      :company="company"
    />
    <DueDiligenceEmpty
      v-else
      :company="company"
    />
  </div>
</template>

<script>
import { Company } from 'flair-client';
import DueDiligenceList from '@/components/due-diligence/DueDiligenceList';
import DueDiligenceEmpty from '@/components/due-diligence/DueDiligenceEmpty';

export default {
  name: 'CompanyDueDiligencePane',
  components: {
    DueDiligenceList,
    DueDiligenceEmpty,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    dueDiligences: {},
  }),
  watch: {
    company() {
      this.loadDueDiligences();
    },
  },
  created() {
    this.loadDueDiligences();
  },
  methods: {
    loadDueDiligences() {
      this.dueDiligences = this.$flairClient.EntitiesService.getCompanyDueDiligences({ id: this.company.id });
    },
  },
};
</script>
