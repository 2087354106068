<template>
  <div class="breadcrumb">
    <ul>
      <router-link
        tag="li"
        :to="{ name: 'search-screen' }"
      >
        <a>{{ ts.home }}</a>
      </router-link>
      <slot />
    </ul>
  </div>
</template>

<i18n namespace="components.Breadcrumb">
{
  "home": "Home"
}
</i18n>

<script>
export default {
  name: 'Breadcrumb',
};
</script>
