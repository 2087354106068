export default function (Component) {
      Component.options._i18n = {
        namespace: "components.UsersRevokedList",
        translations: 












































































{
  "title": "Users revoked",
  "th": {
    "lastName": "Last name",
    "firstName": "First name",
    "function": "Function",
    "role": "Role",
    "teams": "Teams",
    "departments": "Departments",
    "lastVisit": "Last visit"
  },
  "superadmin": "Super Admin",
  "teammanager": "Team Manager",
  "user": "User"
}

      }
    }