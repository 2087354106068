<template>
  <div class="survey-header">
    <div class="logo">
      <img src="/img/flair.a830dfec.svg">
    </div>
    <div class="subheader">
      <p class="survey-name">
        {{ name }}
      </p>
      <p
        v-if="company"
        class="survey-company"
      >
        {{ company.name }}
      </p>
    </div>
  </div>
</template>

<script>
import { Company } from 'flair-client';

export default {
  name: 'SurveyHeader',
  props: {
    name: {
      type: String,
      default: null,
      required: false,
    },
    company: {
      type: Company,
      default: () => {},
      required: false,
    },
  },
};
</script>
