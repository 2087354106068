<template>
  <div class="due-diligence-view">
    <Loader
      v-if="pdfLoaded"
      :global="true"
      :shown="true"
    />
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb>
        <router-link
          v-if="dueDiligence.loaded"
          tag="li"
          :to="{ name: 'my-due-diligence-list-screen'}"
        >
          <a>{{ ts.title }}</a>
        </router-link>
        <router-link
          v-if="dueDiligence.loaded"
          tag="li"
          :to="{ name: 'company-details-screen', params: { id: dueDiligence.relationships.company.id, tab: 'summary' } }"
        >
          <a>{{ dueDiligence.relationships.company.name }}</a>
        </router-link>
      </Breadcrumb>
      <div class="header">
        <h1
          v-if="!notFound"
        >
          {{ ts.title }} {{ dueDiligence.startedAt | humanDate }}
        </h1>
        <h1
          v-else
        >
          {{ ts.title }}
        </h1>
        <div class="buttons">
          <button
            v-if="this.$featureEnabled('printpdf')"
            class="vadis-form-button-line-icon"
            @click="printPDF();"
          >
            <i class="icon-Import" />
            {{ ts.printPDF }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!notFound"
      class="vadis-organism-padded-area"
    >
      <ReviseRiskModal />
      <MakeDecisionModal />
      <DueDiligenceOverview
        v-if="dueDiligence.loaded"
        :due-diligence="dueDiligence"
      />
      <Loader
        v-else
        :global="false"
        :shown="true"
      />
    </div>
    <DueDiligenceNotFound v-else />
  </div>
</template>

<i18n namespace="views.DueDiligenceDetails">
{
  "title": "Due Diligence",
  "printPDF": "Print PDF"
}
</i18n>

<script>
import { DueDiligence } from 'flair-client';
import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';
import Loader from '@/components/Loader';
import ReviseRiskModal from '@/components/due-diligence/ReviseRiskModal';
import MakeDecisionModal from '@/components/due-diligence/MakeDecisionModal';
import DueDiligenceOverview from '@/components/due-diligence/DueDiligenceOverview';
import DueDiligenceNotFound from '@/components/due-diligence/DueDiligenceNotFound';

export default {
  name: 'DueDiligenceDetails',
  components: {
    TopBar,
    Breadcrumb,
    Loader,
    DueDiligenceOverview,
    ReviseRiskModal,
    MakeDecisionModal,
    DueDiligenceNotFound,
  },
  events: {
    'duediligence:*:*': (vm, payload) => {
      if (payload instanceof DueDiligence) {
        payload.ensureRelationships({
          company: true,
        }).then(() => {
          vm.dueDiligence = payload;
        });
      }
    },
  },
  data: () => ({
    dueDiligence: {},
    notFound: false,
    pdfLoaded: false,
  }),
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      const dueDiligence = vm.$flairClient.DueDiligenceService.getDueDiligenceById({ id: to.params.id });
      dueDiligence.ensureRelationships({
        company: true,
      }).then(() => {
        vm.dueDiligence = dueDiligence;
      }).catch(err => {
        if (err.response.status === 404) {
          vm.notFound = true;
        } else {
          throw err;
        }
      });
    });
  },
  methods: {
    printPDF() {
      this.pdfLoaded = true;
      this.$flairClient.DueDiligenceService.getDueDiligenceAsPDF({ id: this.dueDiligence.id }, `Due-diligence-${this.dueDiligence.relationships.company.name}`)
        .finally(() => {
          this.pdfLoaded = false;
        });
    },
  },
};

</script>
