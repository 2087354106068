export default function (Component) {
      Component.options._i18n = {
        namespace: "views.PortfolioStatistics",
        translations: 







































































{
  "MonitoredCompanies": "Monitored companies ({name})",
  "GlobalRiskHealth": "Global risk health",
  "global": "Global risk",
  "geographical": "Geographical",
  "pep": "PEP",
  "adverse_media": "Adverse media",
  "financial": "Financial",
  "sanction": "Sanctions",
  "title": "This portfolio is empty"
}

      }
    }