<template>
  <div>
    <form
      v-if="user"
      id="login-form"
      class="vadis-organism-login"
      novalidate
      @submit.prevent="validateCreds"
    >
      <h3>{{ ts.title }}</h3>
      <div class="info-header">
        <p>{{ ts.name({ firstname: user.firstname, lastname: user.lastname }) }}</p>
        <p> {{ ts.joinFlair }} </p>
      </div>
      <div
        :class="[$v.form.password.$error ? 'error-messages error-messages-visible' : 'info-message']"
      >
        <p>{{ ts.passwordRules }}</p>
      </div>
      <div class="form-row">
        <div :class="{ 'vadis-form-field-password-standalone': true, 'invalid-password': $v.form.password.$error }">
          <input
            id="password-input"
            v-model.trim="form.password"
            :type="passwordInputType"
            :placeholder="ts.placeholders.password"
            :disabled="sending"
            @input="$v.form.password.$touch"
          >
          <i
            :class="showPassword ? 'icon-Visible' : 'icon-Hidden'"
            @click="showPassword = !showPassword"
          />
        </div>
        <p
          v-if="!$v.form.password.required && $v.form.password.$dirty"
          class="error-messages error-messages-visible"
        >
          {{ ts.errors.passwordRequired }}
        </p>
      </div>
      <div class="form-row">
        <div :class="{ 'vadis-form-field-password-standalone': true, 'invalid-password': $v.form.repeatPassword.$error }">
          <input
            id="repeat-password-input"
            v-model.trim="form.repeatPassword"
            :type="passwordInputType"
            :placeholder="ts.placeholders.repeatPassword"
            :disabled="sending"
            @input="$v.form.repeatPassword.$touch"
          >
          <i
            :class="showPassword ? 'icon-Visible' : 'icon-Hidden'"
            @click="showPassword = !showPassword"
          />
        </div>
        <p
          v-if="$v.form.repeatPassword.$error && $v.form.repeatPassword.$dirty"
          class="error-messages error-messages-visible"
        >
          {{ ts.errors.repeatPasswordRequired }}
        </p>
      </div>
      <div :class="{ 'form-row vadis-form-field-standalone': true, invalid: $v.form.function.$error }">
        <input
          id="function-input"
          v-model.trim="form.function"
          :placeholder="ts.placeholders.function"
          :disabled="sending"
          @input="$v.form.function.$touch"
        >
        <p
          v-if="$v.form.function.$error"
          class="error-messages error-messages-visible"
        >
          {{ ts.errors.functionRequired }}
        </p>
      </div>
      <div :class="{ 'form-row vadis-form-field-standalone': true, invalid: $v.form.phoneNumber.$error }">
        <input
          id="phoneNumber-input"
          v-model.trim="form.phoneNumber"
          :placeholder="ts.placeholders.phoneNumber"
          :disabled="sending"
          @input="$v.form.phoneNumber.$touch"
        >
        <p
          v-if="$v.form.phoneNumber.$error"
          class="error-messages error-messages-visible"
        >
          {{ ts.errors.phoneNumberRequired }}
        </p>
      </div>
      <div class="info-footer">
        <p>{{ ts.information({ mail: user.email }) }}</p>
      </div>
      <div class="form-row buttons">
        <button
          type="submit"
          :disabled="$v.form.$anyError || sending"
        >
          {{ ts.buttons.subscribe }}
        </button>
      </div>
    </form>
  </div>
</template>

<i18n namespace="components.CreateAccountForm">
{
  "title": "Join Flair",
  "name": "Hi {firstname} {lastname},",
  "joinFlair": "Please fill in your information to join Flair.",
  "information": "Your username will be {mail}",
  "passwordRules": "Your password needs to include both lower and uppercase characters, at least 1 number, 1 symbol, and be between 12-24 characters long",
  "loginInformation": "Thanks for subscribing to Flair, please enter your login information to start your session",
  "errors": {
    "passwordRequired": "A password is required",
    "repeatPasswordRequired": "Passwords must be identical",
    "functionRequired": "A function is required and must be between 1-64 characters in length",
    "phoneNumberRequired": "Your phone number can only contain numeric characters"
  },
  "buttons": {
    "subscribe": "Subscribe"
  },
  "placeholders": {
    "password": "Password",
    "repeatPassword": "Repeat password",
    "function": "Function",
    "phoneNumber": "Phone number"
  }
}
</i18n>

<script>
import { required, sameAs, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import validPassword from '@/validators/password-validation';

export default {
  name: 'CreateAccountForm',
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showPassword: false,
    form: {
      password: '',
      repeatPassword: '',
      function: '',
      phoneNumber: '',
    },
    sending: false,
    createAccountSuccess: true,
    token: null,
  }),
  validations: {
    form: {
      password: {
        required,
        validPassword,
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
      function: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(64),
      },
      phoneNumber: {
        numeric,
      },
    },
  },
  computed: {
    passwordInputType() {
      return this.showPassword ? 'text' : 'password';
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.password = null;
      this.form.repeatPassword = null;
      this.form.function = null;
      this.form.phoneNumber = null;
    },
    createAccount() {
      this.sending = true;
      const payload = {
        password: this.form.password,
        function: this.form.function,
        phoneNumber: this.form.phoneNumber,
        token: this.user.token,
      };
      this.$flairClient.AuthenticationService.activateUser(payload)
        .promise.then(() => {
          this.sending = false;
          this.clearForm();
          this.$router.push({ path: '/login', query: { loginInformation: this.ts.loginInformation } });
        }).catch((err) => {
          console.error('CreateAccount#submission failed', err);
          this.sending = false;
          this.AuthenticationService = false;
        });
    },
    validateCreds() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.createAccount();
      }
    },
  },
};
</script>
