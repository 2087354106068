import ProxyGraph from './proxy.graph';

export default class DropEdgesGraph extends ProxyGraph {
  #edgesDropper;

  constructor(decorated, edgesDropper) {
    super(decorated);
    this.#edgesDropper = edgesDropper;
  }

  get edges() {
    return this.decorated.edges.filter(e => !this.#edgesDropper(e));
  }

  remove(transformed) {
    if (this === transformed) {
      return this.decorated;
    } else {
      return new DropEdgesGraph(this.decorated.remove(transformed), this.#edgesDropper);
    }
  }

}
