<template>
  <div>
    <UserEditModal
      ref="userEditModal"
      :team="team"
      :role="'TeamManager'"
      :user="team.teamManager"
    />
    <div
      v-if="!rename"
      class="items"
    >
      <div class="left">
        <h6>{{ team.name }}</h6>
        <p> {{ team.nbrOfMembers }} {{ ts.members }}</p>
      </div>
      <div class="right">
        <div v-if="team.teamManager">
          {{ team.teamManager.firstName }} {{ team.teamManager.lastName }}
          <a
            v-if="!team.teamManager.lastVisit && !team.teamManager.invited"
            @click="sendEmail(team)"
          >{{ ts.link.send }}</a>
          <a
            v-if="!team.teamManager.lastVisit && team.teamManager.invited"
            @click="sendEmail(team)"
          > {{ ts.link.resend }}</a>
        </div>
        <div v-else>
          <button
            class="vadis-form-button-main btn-details"
            @click="$refs.userEditModal.open()"
          >
            {{ ts.button.addManager }}
          </button>
        </div>
        <ContextualMenu
          :items="showItemsMenu"
        />
      </div>
    </div>
    <TeamEditForm
      v-else
      :team="team"
      :rename="true"
      @close="rename = false;"
    />
  </div>
</template>

<i18n namespace="components.TeamAccordionItem">
{
  "button": {
    "renameTeam": "Rename team",
    "addManager": "Add manager"
  },
  "link": {
    "send": "Send invitation",
    "resend": "Resend invitation"
  },
  "members": "members",
  "renameTeam": "Rename team",
  "editManager": "Edit manager",
  "remove": "Remove '{q}'?",
  "removeTeam": "Remove team...",
  "removeTeamSubtitle": "All accounts of people within team will be suspended."
}
</i18n>

<script>
import ContextualMenu from '@/components/ContextualMenu';
import UserEditModal from '@/components/UserEditModal';
import TeamEditForm from '@/components/team/TeamEditForm';

export default {
  name: 'TeamAccordionItem',
  components: {
    ContextualMenu,
    UserEditModal,
    TeamEditForm,
  },
  props: {
    team: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    rename: false,
  }),
  computed: {
    showItemsMenu() {
      const items = {
        renameTeam: {
          label: this.ts.renameTeam,
          onClick: () => {
            this.rename = true;
          },
        },
        removeTeam: {
          label: this.ts.removeTeam,
          onClick: this.removeTeam,
        },
        editManager: {
          label: this.ts.editManager,
          onClick: () => {
            this.$refs.userEditModal.open();
          },
        },
      };
      if (!this.team.teamManager) {
        delete items.editManager;
      }
      return items;
    },
  },
  methods: {
    sendEmail(team) {
      this.$flairClient.AdminService.sendUserInvite({ id: team.teamManager.id });
    },
    removeTeam() {
      this.openConfirmationModal({
        title: this.ts.remove({ q: this.team.name }),
        subtitle: this.ts.removeTeamSubtitle,
        onConfirm: (modal) => {
          this.$flairClient.AdminService.removeTeam({ id: this.team.id })
            .promise.then(() => modal.close());
        },
      });
    },
  },
};
</script>
