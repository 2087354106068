<template>
  <div>
    <OpenableSubsidiaryList
      ref="openableSubsidiaryList"
      :subsidiaries="subsidiaries"
    />
  </div>
</template>

<script>
import { Company } from 'flair-client';
import OpenableSubsidiaryList from '@/components/openable/OpenableSubsidiaryList';

export default {
  name: 'CompanySubsidairiesPane',
  components: {
    OpenableSubsidiaryList,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
    paging: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    subsidiaries: null,
  }),
  watch: {
    company() {
      this.loadSubsidairies();
    },
  },
  created() {
    this.loadSubsidairies();
  },
  methods: {
    loadSubsidairies() {
      const params = {
        id: this.company.id,
      };
      if (!this.paging) {
        params.nopaging = '';
      }
      this.subsidiaries = this.$flairClient.EntitiesService.getCompanySubsidiaries(params);
    },
  },
};
</script>
