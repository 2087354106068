<template>
  <form
    class="custom-risk-form"
  >
    <div class="indicator-created-container">
      <i class="icon-Submitted" />
      <h5 class="title">
        {{ ts.createdIndicator }}
      </h5>
    </div>
    <div class="summary">
      <div class="heading-container">
        <p class="heading">
          {{ ts.name }}:
        </p>
        <p class="heading">
          {{ ts.description }}:
        </p>
        <p class="heading">
          {{ ts.composition }}
        </p>
      </div>
      <div class="results-container">
        <p class="results">
          {{ value.name }}
        </p>
        <p
          v-if="noDescriptionProvided"
          class="results"
        >
          {{ ts.noDescription }}
        </p>
        <p
          v-else
          class="results"
        >
          {{ value.description }}
        </p>
        <div
          v-for="(indicator, i) in groupedIndicators"
          :key="i"
          class="data-providers"
        >
          <p id="provider-name">
            {{ i }}
          </p>
          <div
            v-for="(indVal, j) in indicator"
            :key="j"
            class="risk-indicators"
          >
            <p>
              {{ indVal.name | providerRiskIndicator }} ({{ indVal.weight }}%),
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<i18n namespace="components.CRIConfirmation">
{
  "createdIndicator": "Your custom indicator has been created",
  "updatedIndicator": "Your custom indicator has been updated",
  "name": "Name",
  "description": "Description",
  "composition": "Composition",
  "displayAs": "Display as",
  "noDescription": "No description provided"
}
</i18n>

<script>
export default {
  name: 'CRIConfirmation',
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    noDescriptionProvided() {
      return this.value.description === '';
    },

    groupedIndicators() {
      return this.value.elements.reduce((els, indicator) => {
        els[indicator.data_provider_id] ||= [];
        els[[indicator.data_provider_id]]
          .push(indicator);
        return els;
      }, {});
    },
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    async isValid() {
      return true;
    },
  },
};
</script>
