<template>
  <div class="vadis-controller-pagination">
    <span>{{ ((currentPage - 1 )*numberOfLines) + 1 }} - {{ ts.lineOf({ line: nbrOfLines, total: totalItems }) }}</span>
    <a
      :class="{disabled: !links.first || currentPage === 1}"
      @click="onClick('first', $event)"
    ><i class="icon-Go-to-beginning" /></a>
    <a
      :class="{disabled: !links.prev || currentPage === 1}"
      @click="onClick('prev', $event)"
    ><i class="icon-Arrow-left" /></a>
    <a
      :class="{disabled: !links.next || totalPages === currentPage}"
      @click="onClick('next', $event)"
    ><i class="icon-Arrow-right" /></a>
    <a
      :class="{disabled: !links.last || totalPages === currentPage}"
      @click="onClick('last', $event)"
    ><i class="icon-Go-to-end" /></a>
  </div>
</template>

<i18n namespace="components.Pagination">
{
  "lineOf": "{line} of {total} lines"
}
</i18n>

<script>
export default {
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 1,
    },
    numberOfLines: {
      type: Number,
      default: 15,
    },
    links:{
      type: Object,
      default: null,
    },
  },
  computed: {
    nbrOfLines: function() {
      if (this.totalItems < (this.currentPage) * this.numberOfLines) {
        return this.totalItems;
      } else {
        return (this.currentPage) * this.numberOfLines;
      }
    },
    totalPages: function() {
      return Math.ceil(this.totalItems / this.numberOfLines);
    },
  },
  methods: {
    onClick(value, event) {
      if (event.target.parentNode.className !== 'disabled') {
        this.$emit('click', value);
      }
    },
  },
};
</script>
