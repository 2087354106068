<template>
  <div>
    <RequestInfoModal
      v-if="$featureEnabled('dueDiligence')"
      ref="requestInfoModal"
      :due-diligence="dueDiligence"
    />
    <div
      v-if="!dueDiligence.surveys.length"
      class="request-info-container"
    >
      <div class="vadis-molecule-due-diligence-information-request">
        <div class="title">
          <h5>{{ ts.nothingRequested.title }}</h5>
        </div>
        <p
          class="paragraph"
        >
          {{ ts.nothingRequested.information }}
        </p>
        <div class="buttons">
          <button
            class="vadis-form-button-main"
            @click="requestInfo()"
          >
            {{ ts.buttons.requestInfo }}
          </button>
        </div>
        <div
          v-if="$featureEnabled('userManagement') || $featureEnabled('teamManagement')"
          class="manage-form-tag-below"
        >
          <router-link
            :to="{name: 'my-settings-screen', params: {tab: 'surveys'}}"
          >
            {{ ts.link }}
          </router-link>
        </div>
      </div>
    </div>
    <div
      v-else
      class="request-info-container"
    >
      <div class="vadis-molecule-due-diligence-information-request">
        <div class="title">
          <h5>{{ ts.requested.title }}</h5><i class="icon-Info" />
        </div>
        <div
          v-for="survey in dueDiligence.surveys"
          :key="survey.id"
          class="request"
        >
          <div class="item">
            <div class="label">
              <p>
                {{ survey.name }}
              </p>
              <div
                v-if="!survey.submit"
                class="send"
              >
                <p>
                  {{ ts.requested.sent }} {{ survey.published | humanDate }}
                  <a :href="`#${surveyTimelineInformation(survey)}`">{{ ts.requested.viewRequest }}</a>
                </p>
              </div>
              <div
                v-else
                class="completed"
              >
                <p>
                  {{ ts.requested.completedOn }} {{ survey.updated | humanDate }}
                  <i :class="[ survey.submit ? 'icon-Submitted' : 'icon-Check-small' ]" />
                </p>
              </div>
            </div>
            <div class="value">
              <button
                v-if="survey.submit"
                class="vadis-form-button-standard-small"
                @click="$emit('details', survey.id)"
              >
                {{ ts.buttons.details }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="dueDiligence.isUnderEvaluation()"
          v-due-diligence-action:requestInfo
          class="info"
        >
          <button
            class="vadis-form-button-standard-small"
            @click="requestInfo()"
          >
            {{ ts.buttons.requestInfo }}
          </button>
          <div
            v-if="$featureEnabled('userManagement') || $featureEnabled('teamManagement')"
            class="manage-form-tag-side"
          >
            <router-link
              :to="{name: 'my-settings-screen', params: {tab: 'surveys'}}"
            >
              {{ ts.link }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.DueDiligenceRequestInfo">
{
  "link": "View & manage forms",
  "nothingRequested": {
    "title": "Would you like some info from the company before making an informed decision?",
    "information": "Send a survey form to a manager of the company"
  },
  "requested": {
    "title": "Information requests",
    "surveyName": "Survey name",
    "preview": "preview",
    "sent": "Sent",
    "completedOn": "Completed on",
    "viewRequest": "view request"
  },
  "buttons": {
    "requestInfo": "Request info",
    "details": "Details",
    "remindSupplier": "Remind supplier"
  }
}
</i18n>

<script>

import _ from 'lodash';
import RequestInfoModal from '@/components/due-diligence/RequestInfoModal';
import { DueDiligence } from 'flair-client';

export default {
  name: 'DueDiligenceRequestInfo',
  components: {
    RequestInfoModal,
  },
  props: {
    dueDiligence: {
      type: DueDiligence,
      default: () => {},
      required: true,
    },
  },
  methods: {
    requestInfo() {
      this.$refs.requestInfoModal.open();
    },
    surveyTimelineInformation(survey) {
      const eventId = _.get(_.find(this.dueDiligence.timeline, (e) => {
        return _.find(e.surveys, (s) => s.id === survey.id);
      }), 'id');
      return eventId;
    },
  },
};
</script>
