export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PersonOverview",
        translations: 





























































{
  "buttons": {
    "close": "Close",
    "personDetails": "Person Details"
  },
  "labels": {
    "relatedCompanies": "Related companies"
  }
}

      }
    }