<template>
  <form
    class="custom-risk-form"
  >
    <div class="composition-container">
      <ToggleSwitch
        v-model="isWeight"
        :left-label="ts.switcher.weight"
        :right-label="ts.switcher.score"
        :right-value="false"
        :left-value="true"
      />
      <template v-if="!isWeight">
        <div class="contact-container">
          <img src="/img/email.png">
          <p>{{ ts.contact }}</p>
          <div class="contact-details">
            <p>{{ ts.sendTo }}</p>
            <a href="mailto:vadis@email.be">vadis@email.be</a>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="explanation">
          <p>
            {{ ts.explanation.giveWeight }}
          </p>
          <p>{{ ts.explanation.finalScore }}</p>
        </div>
        <div
          v-for="(indicator, i) in value.elements"
          :key="i"
        >
          <div class="risk-indicator-weighting">
            <div class="risk-type">
              <div class="indicator-weight">
                <h6>{{ indicator.name | providerRiskIndicator }}</h6>
                <p>{{ indicator.data_provider_id }}</p>
              </div>
              <h2>{{ indicator.weight }}</h2>
              <span>%</span>
            </div>
          </div>
          <Slider
            v-model="indicator.weight"
          />
        </div>
        <div
          class="overall-score"
          :class="{ error: !isCorrectWeight}"
        >
          <h1>{{ calculateTotalWeight }}%</h1>
          <p>{{ ts.completion }}</p>
        </div>
      </template>
    </div>
  </form>
</template>

<i18n namespace="components.CRIComposition">
{
  "switcher": {
    "weight": "Weight",
    "score": "Score"
  },
  "explanation": {
    "giveWeight": "Give weight to your indicators to form a total of 100%.",
    "finalScore": "The final score of your custom indicator will be based on this."
  },
  "contact": "Please contact Vadis for more information",
  "sendTo": "Send an email to",
  "completion": "Custom indicator completion"
}
</i18n>

<script>

import ToggleSwitch from '@/components/ToggleSwitch';
import Slider from '@/components/Slider';

export default {
  name: 'CRIComposition',
  components: {
    ToggleSwitch,
    Slider,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isWeight: true,
  }),
  computed: {
    calculateTotalWeight() {
      return this.value.elements
        .map(e => parseInt(e.weight))
        .reduce((sum, total) => sum + total, 0);
    },
    isCorrectWeight() {
      return this.calculateTotalWeight === 100;
    },
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    async isValid() {
      return this.isCorrectWeight;
    },
  },
};
</script>
