export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SurveyRenameForm",
        translations: 

































{
  "errorSurveyNameCannotBeNull": "A survey cannot have an empty name",
  "button": {
    "renameSurvey": "Rename Survey",
    "cancel": "Cancel"
  },
  "placeholder": {
    "survey": "Survey name..."
  }
}

      }
    }