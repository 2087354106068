<template>
  <div
    class="company-details-view"
    :class="activeTab"
  >
    <div
      v-if="!notFound"
      class="vadis-organism-header"
    >
      <TopBar />
      <Breadcrumb>
        <li>{{ title }}</li>
      </Breadcrumb>
      <div class="header">
        <h1>{{ title }}</h1>
        <div class="buttons">
          <router-link
            v-if="company && this.$featureEnabled('printpdf')"
            target="_blank"
            :to="{ name: 'company-pdf-details-screen', params: {id: company.id, tab: 'summary'} }"
            class="vadis-form-button-line-icon"
          >
            <i class="icon-PDF" />
            {{ ts.exportPDF }}
          </router-link>

          <!-- TODO make a new version of PortfolioButton that includes the optional full button style -->
          <PortfolioButton
            v-if="$featureEnabled('portfolio')"
            :subject="company"
            :button="true"
            :button-class="'vadis-form-button-line-icon'"
            :text="ts.portfolio"
          />
        </div>
      </div>
      <CompanyDetailsTabs
        v-if="company"
        :active="activeTab"
        :company="company"
      />
    </div>
    <TopBar v-else />
    <Loader
      v-if="company && company.loading"
      :global="true"
      :shown="true"
    />
    <div
      v-if="!notFound && company && !company.loading"
      class="vadis-organism-padded-area"
    >
      <component
        :is="tabComponent()"
        :company="company"
      />
    </div>
    <CompanyNotFound v-if="notFound" />
  </div>
</template>

<i18n namespace="views.CompanyDetails">
{
  "exportPDF": "Export PDF",
  "portfolio": "Portfolio"
}
</i18n>

<script>
import TopBar from '@/components/TopBar';
import Loader from '@/components/Loader';
import Breadcrumb from '@/components/Breadcrumb';
import CompanyNotFound from '@/components/company/CompanyNotFound';
import CompanyDetailsTabs from '@/components/company/CompanyDetailsTabs';
import CompanySummaryPane from '@/components/company/CompanySummaryPane';
import CompanyFinancialPane from '@/components/company/CompanyFinancialPane';
import CompanyManagementPane from '@/components/company/CompanyManagementPane';
import CompanyShareholdersPane from '@/components/company/CompanyShareholdersPane';
import CompanySubsidiariesPane from '@/components/company/CompanySubsidiariesPane';
import CompanyNeighborhoodPane from '@/components/company/CompanyNeighborhoodPane';
import CompanyDueDiligencePane from '@/components/company/CompanyDueDiligencePane';
import CompanyEcovadisPane from '@/components/company/CompanyEcovadisPane';
import PortfolioButton from '@/components/portfolio/PortfolioButton';

const TABS = {
  'summary': CompanySummaryPane,
  'financial': CompanyFinancialPane,
  'management': CompanyManagementPane,
  'shareholders': CompanyShareholdersPane,
  'subsidiaries': CompanySubsidiariesPane,
  'neighborhood': CompanyNeighborhoodPane,
  'due-diligence': CompanyDueDiligencePane,
  'ecovadis': CompanyEcovadisPane,
};

export default {
  name: 'CompanyDetails',
  components: {
    TopBar,
    Breadcrumb,
    Loader,
    CompanyNotFound,
    CompanyDetailsTabs,
    CompanyEcovadisPane,
    CompanySummaryPane,
    CompanyFinancialPane,
    CompanyShareholdersPane,
    CompanySubsidiariesPane,
    CompanyManagementPane,
    CompanyNeighborhoodPane,
    PortfolioButton,
  },
  data: () => ({
    company: null,
    activeTab: null,
    notFound: false,
  }),
  beforeRouteEnter: function(to, from, next) {
    const tabExists = TABS[to.params.tab];
    if (tabExists) {
      next(vm => {
        vm.getCompany(to.params.id)
          .then((company) => {
            vm.activeTab = to.params.tab;
            vm.setCompany(company);
          }).catch(err => {
            if (err.response.status === 404) {
              next(vm => vm.notFound = true);
            } else {
              throw err;
            }
          });
      });
    } else {
      next(vm => vm.$router.push('/404'));
    }
  },
  beforeRouteUpdate(to, from, next) {
    const cont = (company) => {
      this.setState({
        company: company,
        activeTab: to.params.tab,
      });
      next();
    };
    if (to.params.id !== from.params.id) {
      this.getCompany(to.params.id).then(cont);
    } else {
      cont(this.company);
    }
  },
  computed: {
    title: function() {
      return this.company && this.company.name;
    },
    inMyPortfolio: function() {
      return this.company && this.company.data.meta.inMyPortfolio;
    },
  },
  methods: {
    getCompany(id) {
      this.company = this.$flairClient.EntitiesService.getCompanyById({ id });
      const payload = {
        type: 'BrowseHistory',
        attributes: {
          entity: {
            type: 'Company',
            id: id,
          },
        },
      };
      return this.company.promise.then(() => {
        this.$flairClient.MyService.addRecentlyBrowsedCompanies(payload);
        return this.company;
      });
    },
    setCompany(company) {
      this.company = company;
    },
    tabComponent() {
      return TABS[this.activeTab];
    },
    setState(state) {
      this.company = state.company;
      this.activeTab = state.activeTab;
    },
  },
};

</script>
