export default function (Component) {
      Component.options._i18n = {
        namespace: "components.TeamEditForm",
        translations: 















































{
  "errorTeamNameCannotBeNull": "A team cannot have an empty name",
  "errorTeamNameAlreadyExists": "A team with the same name already exists",
  "button": {
    "createTeam": "Create team",
    "renameTeam": "Rename team",
    "cancel": "Cancel"
  },
  "placeholder": {
    "team": "Department name..."
  }
}

      }
    }