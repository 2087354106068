export default function (Component) {
      Component.options._i18n = {
        namespace: "components.PasswordResetForm",
        translations: 






































































{
  "title": "Reset password",
  "errors": {
    "passwordRequired": "The new password is required",
    "repeatPasswordRequired": "Passwords must be identical",
    "passwordRules": "Your password needs to include both lower and uppercase characters, at least 1 number, and be between 12-24 characters long"
  },
  "buttons": {
    "confirm": "Confirm"
  },
  "placeholders": {
    "password": "Password",
    "repeatPassword": "Repeat password"
  }
}

      }
    }