<template>
  <div class="person-details-view">
    <div class="vadis-organism-header">
      <TopBar />
      <Breadcrumb>
        <li>{{ title }}</li>
      </Breadcrumb>
      <h1 class="page">
        {{ title }}
      </h1>
    </div>
    <div class="vadis-organism-padded-area">
      {{ person }}
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar';
import Breadcrumb from '@/components/Breadcrumb';

export default {
  name: 'PersonDetails',
  components: {
    TopBar,
    Breadcrumb,
  },
  data: () => ({
    person: null,
    activeTab: null,
  }),
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      vm.getPerson(to.params.id).then((person) => {
        vm.activeTab = to.params.tab;
        vm.setPerson(person);
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
    const cont = (person) => {
      this.setState({
        person: person,
        activeTab: to.params.tab,
      });
      next();
    };
    if (to.params.id !== from.params.id) {
      this.getPerson(to.params.id).then(cont);
    } else {
      cont(this.person);
    }
  },
  computed: {
    title: function() {
      return this.person && this.person.fullName;
    },
  },
  methods: {
    getPerson(id) {
      const person = this.$flairClient.EntitiesService.getPersonById({ id });
      return person.promise.then(() => {
        return person;
      });
    },
    setPerson(person) {
      this.person = person;
    },
    isCurrentTab(tab) {
      return this.activeTab === tab;
    },
    setState(state) {
      this.person = state.person;
      this.activeTab = state.activeTab;
    },
  },
};

</script>
