export default function (Component) {
      Component.options._i18n = {
        namespace: "components.CheckUser",
        translations: 






































{
  "isYou": "Is this you?",
  "buttons": {
    "continue": "Yes, continue to survey",
    "reportIssue": "Report an issue"
  }
}

      }
    }