<template>
  <div class="team-monthly">
    <div class="header">
      <h5> {{ ts.title.teamMonthlyConsumption }}</h5>
      <form
        class="vadis-form-field-select"
      >
        <FilterDropdown
          v-model="date"
          :values="historyFilters"
          :required="true"
          :sorted="false"
        />
      </form>
    </div>
    <table>
      <thead>
        <tr>
          <th
            v-for="(h, i) in HEADERS"
            :key="i"
            class="no-open vadis-controller-column-filter"
          >
            <div class="label">
              {{ ts.th[h.label] }}
              <div
                class="sort-small"
                @click="sort(h)"
              >
                <i :class="{'icon-Arrow-up-small': true, 'active': sortsBy(h.label)}" />
                <i :class="{'icon-Arrow-down-small': true, 'active': sortsBy(`-${h.label}`)}" />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody
        v-if="!teamMonthly"
        class="vadis-molecule-loader"
      >
        <tr>
          <td colspan="5">
            <Loader
              :global="false"
              :shown="true"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="(i, k) in teamMonthly.attributes.teams"
          :key="k"
        >
          <td>{{ i.team.attributes.name }}</td>
          <td>{{ i.department.attributes.name }}</td>
          <td>{{ i.screening }}</td>
          <td>{{ i.monitoring }}</td>
        </tr>
        <tr class="bold">
          <td>Totals</td>
          <td />
          <td>{{ teamMonthly.data.attributes.totalScreening }}</td>
          <td>{{ teamMonthly.data.attributes.totalMonitoring }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<i18n namespace="views.TeamMonthlyConsumption">
{
  "title": {
    "teamMonthlyConsumption": "Team monthly consumption"
  },
  "th": {
    "teamName": "Team name",
    "department": "Department",
    "screening": "Screening",
    "monitoring": "Monitoring"
  }
}
</i18n>

<script>
import _ from 'lodash';
import moment from 'moment';
import Loader from '@/components/Loader';
import FilterDropdown from '@/components/FilterDropdown';

const HEADERS = [{
  label: 'teamName',
  path:'team.attributes.name',
}, {
  label: 'department',
  path: 'department.attributes.name',
}, {
  label: 'screening',
  path: 'screening',
}, {
  label:'monitoring',
  path: 'monitoring',
}];

export default {
  name: 'TeamMonthlyConsumption',
  components: {
    Loader,
    FilterDropdown,
  },
  data: () => ({
    teamMonthly: null,
    date: moment().subtract(1, 'months').format('MM DD YYYY').toString(),
    HEADERS,
    sorts: [],
  }),
  computed: {
    HistoryFilters() {
      return Array.from(Array(12).keys()).reduce((o, i) => {
        const value = moment().subtract(i, 'months').format('MM DD YYYY');
        const label = this.$options.filters.humanDateMonthYear(value);
        o[value] = label;
        return o;
      }, {});
    },
  },
  watch: {
    date: {
      handler: function() {
        this.getDataConsumptionMonthly(moment(this.date).format('YYYY'), moment(this.date).format('MM'));
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataConsumptionMonthly(moment(this.date).format('YYYY'), moment(this.date).format('MM'));
  },
  methods: {
    getDataConsumptionMonthly(year, month) {
      this.$flairClient.AdminService.getDataConsumptionMonthly({ year, month }).promise.then((res) => {
        this.teamMonthly = res;
      });
    },
    sortLists(label, sortsList) {
      const list = [];
      if (sortsList.find(e => e === label)) {
        list.push(`-${label}`);
      } else if (!sortsList.find(e => e === `-${label}`)) {
        list.push(label);
      }
      return list;
    },
    sort(column) {
      this.sorts = this.sortLists(column.label, this.sorts);
      const orders = this.sortsBy(column.label) ? 'asc' : '' || this.sortsBy(`-${column.label}`) ? 'desc' : '';
      this.teamMonthly.attributes.teams = _.orderBy(this.teamMonthly.attributes.teams, [column.path], [orders]);
    },
    sortsBy(column) {
      return this.sorts.find(e => e === column);
    },
  },
};
</script>
